import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { ColorString, LocalizedString, TopSponsor, TopNews, NewsType } from "../../branding/branding"

export interface TopBarLinkRoot {
    /**
     * @title Border bottom
     * @description Value for border bottom of the top bar link root
     * @title_de Border bottom
     * @description_de Value for border bottom of the top bar link root
     */
    borderBottom: string
    /**
     * @title Margin bottom
     * @description Value for margin bottom of the top bar link root
     * @title_de Margin bottom
     * @description_de Value for margin bottom of the top bar link root
     */
    marginBottom: string

    /**
     * @title Margin bottom (mobile)
     * @description Value for margin bottom of the top bar link root (for mobile version)
     * @title_de Margin bottom (mobile)
     * @description_de Value for margin bottom of the top bar link root (for mobile version)
     */
    marginBottomMobile: string

    /**
     * @title Font size
     * @description Font size of the top bar link root
     * @title_de Font size
     * @description_de Font size of the top bar link root
     */
    fontSize: string
}

export interface LobbyExhibitorsBasisPremiumType {
    /**
     * @title Package name
     * @description Package name
     * @title_de Package name
     * @description_de Package name
     */
    packageName: BasisPremiumType
    /**
     * @title Number of exhibitors
     * @description Number of exhibitors
     * @title_de Number of exhibitors
     * @description_de Number of exhibitors
     */
    numberOfExhibitors: number
}

export enum LobbyComponent {
    SHOWFLOOR = "SHOWFLOOR",
    LOUNGES = "LOUNGES",
    LIVE_SESSIONS = "LIVE_SESSIONS",
    TOP_SPEAKERS = "TOP_SPEAKERS",
    SPEAKERS = "SPEAKERS",
    PARTICIPANTS = "PARTICIPANTS",
    NEWS = "NEWS",
    ORGANIZER_NEWS = "ORGANIZER_NEWS",
    BANNER = "BANNER", //this is for compatibility purposes, in branding you should use NETWORKING_BANNER, LEADFINDER_BANNER or PROGRAM_BANNER instead
    NETWORKING_BANNER = "NETWORKING_BANNER",
    LEADFINDER_BANNER = "LEADFINDER_BANNER",
    PROGRAM_BANNER = "PROGRAM_BANNER",
    COLLECTIONS = "COLLECTIONS"
}

export enum MyFairComponent {
    SPONSORS = "SPONSORS",
    UP_NEXT = "UP_NEXT",
    SPEAKERS = "SPEAKERS",
    EXHIBITORS = "EXHIBITORS",
    PRODUCTS = "PRODUCTS",
    TRADEMARKS = "TRADEMARKS",
    NEWS = "NEWS",
    JOBOFFERS = "JOBOFFERS"
}

export interface ReceptionPageSiteBranding {
    /**
     * @title Lobby switcher title
     * @description Title for Lobby page that is shown in the reception page's switcher
     * @title_de Lobby switcher title
     * @description_de Title for Lobby page that is shown in the reception page's switcher
     */
    eventKeyLobby: LocalizedString

    /**
     * @title My page switcher title
     * @description Title for My page that is shown in the reception page's switcher
     * @title_de My page switcher title
     * @description_de Title for My page that is shown in the reception page's switcher
     */
    eventKeyMyPage: LocalizedString

    /**
     * @title Lobby page component order
     * @description Order of components shown in the Lobby page
     * @title_de Lobby page component order
     * @description_de Order of components shown in the Lobby page
     */
    lobbyComponentOrder: LobbyComponent[]

    /**
     * @title My Fair page component order
     * @description Order of components shown in the My Fair page
     * @title_de My Fair page component order
     * @description_de Order of components shown in the My Fair page
     */
    myPageComponentOrder: MyFairComponent[]

    /**
     * @title Lobby page header title
     * @description Title of the header in Lobby page
     * @title_de Lobby page header title
     * @description_de Title of the header in Lobby page
     */
    receptionPageLobbyHeaderTitle: LocalizedString

    /**
     * @title Lobby page header subtitle
     * @description Header subtitle text in Lobby page
     * @title_de Lobby page header subtitle
     * @description_de Header subtitle text in Lobby page
     */
    receptionPageLobbyHeaderDescription: LocalizedString

    /**
     * @title Lobby header pool
     * @description List of the URLs of pictures that are shown in the lobby header
     * @title_de Lobby-Header-Pool
     * @description_de Liste der URLs der Bilder, die in der Lobby-Header angezeigt werden
     */
    lobbyHeaderPool: string[]

    /**
     * @title Lobby header pool number
     * @description Number of how many URLs of pictures that are shown in the lobby header
     * @title_de Lobby-Header-Pool nummer
     * @description_de Nummer auf der URLs der Bilder, die in der Lobby-Header angezeigt werden
     */
    lobbyHeaderPoolNumber: number[]

    /**
     * @title Auto play lobby pool value
     * @description Number value in miliseconds for carousel auto play for lobby header pool
     * @title_de Auto play lobby pool value
     * @description_de Number value in miliseconds for carousel auto play for lobby header pool
     */
    autoPlayValueLobbyPool: number

    /**
     * @title My fair page pool
     * @description List of the URLs of pictures that are shown in the My fair page
     * @title_de My Fair-Header-Pool
     * @description_de Liste der URLs der Bilder, die in der My Fair-Header angezeigt werden
     */
    myFairPageHeaderPool: string[]

    /**
     * @title My fair page pool number
     * @description Number of how many URLs of pictures that are shown in the My fair page
     * @title_de My Fair-Header-Pool nummer
     * @description_de Nummer auf der URLs der Bilder, die in der Lobby-Header angezeigt werden
     */
    myFairPageHeaderPoolNumber: number[]

    /**
     * @title Auto play my fair pool value
     * @description Number value in miliseconds for carousel auto play for my fair header pool
     * @title_de Auto play my fair pool value
     * @description_de Number value in miliseconds for carousel auto play for my fair header pool
     */
    autoPlayValueMyFairPool: number

    /**
     * @title My Schedule page header title
     * @description Title of the header in My Schedule page
     * @title_de My Schedule page header title
     * @description_de Title of the header in My Schedule page
     */
    receptionPageMyScheduleHeaderTitle: LocalizedString

    /**
     * @title My Schedule page header subtitle
     * @description Header subtitle text in My Schedule page
     * @title_de My Schedule page header subtitle
     * @description_de Header subtitle text in My Schedule page
     */
    receptionPageMyScheduleHeaderDescription: LocalizedString

    /**
     * @title My Schedule page pool
     * @description List of the URLs of pictures that are shown in the My Schedule page
     * @title_de Mein Terminkalender-Header-Pool
     * @description_de Liste der URLs der Bilder, die in Mein Terminkalender-Header angezeigt werden
     */
    mySchedulePageHeaderPool: string[]

    /**
     * @title My Schedule page pool number
     * @description Number of how many URLs of pictures that are shown in the My Schedule page
     * @title_de Mein Terminkalender-Header-Pool nummer
     * @description_de Nummer auf der URLs der Bilder, die in der Mein Terminkalender-Header angezeigt werden
     */
    mySchedulePageHeaderPoolNumber: number[]

    /**
     * @title Auto play My Schedule pool value
     * @description Number value in miliseconds for carousel auto play for My Schedule header pool
     * @title_de Auto play My Schedule pool value
     * @description_de Number value in miliseconds for carousel auto play for My Schedule header pool
     */
    autoPlayValueMySchedulePool: number

    /**
     * @title Lobby header title top margin
     * @description Top margin of Lobby page's header title
     * @title_de Lobby header title top margin
     * @description_de Top margin of Lobby page's header title
     */
    lobbyHeaderTitleMarginTop: string

    /**
     * @title Lobby header title top margin for smaller screen
     * @description Top margin of Lobby page's header title when it's shown on smaller screens
     * @title_de Lobby header title top margin for smaller screen
     * @description_de Top margin of Lobby page's header title when it's shown on smaller screens
     */
    lobbyHeaderTitleMarginTopForSmallerScreen: string

    /**
     * @title Nav bar my fair page short name
     * @description Short name for label my fair page  under icon for side icon bar
     * @title_de Nav bar my fair page short name
     * @description_de Short name for label my fair page under icon for side icon bar
     */
    navbarMyPageShortName: LocalizedString

    /**
     * @title Nav bar home page short name
     * @description Short name for label home page under icon for side icon bar
     * @title_de Nav bar home page short name
     * @description_de Short name for label home page under icon for side icon bar
     */
    navbarHomePageShortName: LocalizedString

    /**
     * @title Nav bar showfloor page short name
     * @description Short name for label showfloor page under icon for side icon bar
     * @title_de Nav bar showfloor page short name
     * @description_de Short name for label showfloor page under icon for side icon bar
     */
    navbarShowfloorPageShortName: LocalizedString

    /**
     * @title Nav bar Organizer News page short name
     * @description Short name for label Organizer News page under icon for side icon bar
     * @title_de Nav bar Organizer News page short name
     * @description_de Short name for label Organizer News page under icon for side icon bar
     */
    navbarNewsPageShortName: LocalizedString

    /**
     * @title Nav bar program page short name
     * @description Short name for label program page under icon for side icon bar
     * @title_de Nav bar program page short name
     * @description_de Short name for label program page under icon for side icon bar
     */
    navbarProgramPageShortName: LocalizedString

    /**
     * @title Nav bar virtual cafe page short name
     * @description Short name for label virtual cafe page under icon for side icon bar
     * @title_de Nav bar virtual cafe page short name
     * @description_de Short name for label virtual cafe page under icon for side icon bar
     */
    navbarVirtualCafePageShortName: LocalizedString

    /**
     * @title Nav bar collections page short name
     * @description Short name for label collections page under icon for side icon bar
     * @title_de Nav bar collections page short name
     * @description_de Short name for label collections page under icon for side icon bar
     */
    navbarCollectionsPageShortName: LocalizedString

    /**
     * @title Nav bar Hallplan page short name
     * @description Short name for label Hallplan page under icon for side icon bar
     * @title_de Nav bar Hallenplan page short name
     * @description_de Short name for label Hallenplan page under icon for side icon bar
     */
    navbarHallplanPageShortName: LocalizedString

    /**
     * @title Nav bar networking page short name
     * @description Short name for label networking page under icon for side icon bar
     * @title_de Nav bar networking page short name
     * @description_de Short name for label networking page under icon for side icon bar
     */
    navbarNetworkingPageShortName: LocalizedString

    /**
     * @title Nav bar partners & sponsors page short name
     * @description Short name for label partners & sponsors page under icon for side icon bar
     * @title_de Nav bar partners & sponsors page short name
     * @description_de Short name for label partners & sponsors page under icon for side icon bar
     */
    navbarPartnersSponsorsPageShortName: LocalizedString

    /**
     * @title Nav bar goodie bag page short name
     * @description Short name for label goodie bag page under icon for side icon bar
     * @title_de Nav bar goodie bag page short name
     * @description_de Short name for label goodie bag page under icon for side icon bar
     */
    navbarGoodieBagPageShortName: LocalizedString

    /**
     * @title Nav bar press & media page short name
     * @description Short name for label press & media page under icon for side icon bar
     * @title_de Nav bar press & media page short name
     * @description_de Short name for label press & media page under icon for side icon bar
     */
    navbarPressMediaPageShortName: LocalizedString

    /**
     * @title Nav bar lead finder page short name
     * @description Short name for label lead finder page under icon for side icon bar
     * @title_de Nav bar lead finder page short name
     * @description_de Short name for label lead finder page under icon for side icon bar
     */
    navbarLeadFinderPageShortName: LocalizedString

    /**
     * @title Nav bar iframe page short name
     * @description Short name for label iframe page under icon for side icon bar
     * @title_de Nav bar iframe page short name
     * @description_de Short name for label iframe page under icon for side icon bar
     */
    navbarIFramePageShortName: LocalizedString

    /**
     * @title Nav bar startup page short name
     * @description Short name for label startup page under icon for side icon bar
     * @title_de Nav bar startup page short name
     * @description_de Short name for label startup page under icon for side icon bar
     */
    navbarStartupPageShortName: LocalizedString

    /**
     * @title Nav bar help page short name
     * @description Short name for label help page under icon for side icon bar
     * @title_de Nav bar help page short name
     * @description_de Short name for label help page under icon for side icon bar
     */
    navbarHelpPageShortName: LocalizedString

    /**
     * @title My Fair page header title
     * @description Title of the header in My Fair page
     * @title_de My Fair page header title
     * @description_de Title of the header in My Fair page
     */
    receptionPageMyHeaderTitle: LocalizedString

    /**
     * @title My fair page header subtitle 1
     * @description First part of the header subtitle text in My Fair page
     * @title_de My fair page header subtitle 1
     * @description_de First part of the header subtitle text in My Fair page
     */
    receptionPageMyHeaderDescriptionFirstPart: LocalizedString

    /**
     * @title My fair page header subtitle 2
     * @description Second part of the header subtitle text in My Fair page
     * @title_de My fair page header subtitle 2
     * @description_de Second part of the header subtitle text in My Fair page
     */
    receptionPageMyHeaderDescriptionSecondPart: LocalizedString

    /**
     * @title My fair page header subtitle 3
     * @description Third part of the header subtitle text in My Fair page
     * @title_de My fair page header subtitle 3
     * @description_de Third part of the header subtitle text in My Fair page
     */
    receptionPageMyHeaderDescriptionThirdPart: LocalizedString

    /**
     * @title Visit my profile text
     * @description Text for My Profile page's button in the My Fair page
     * @title_de Visit my profile text
     * @description_de Text for My Profile page's button in the My Fair page
     */
    visitMyProfile: LocalizedString

    /**
     * @title My Schedule navigation link text
     * @description Text for the navigation link of My Schedule section of the My Fair page
     * @title_de My Schedule navigation link text
     * @description_de Text for the navigation link of My Schedule section of the My Fair page
     */
    viewMySchedule: LocalizedString

    /**
     * @title Sponsors title
     * @description Title of the Sponsors section of the My Fair page
     * @title_de Sponsors title
     * @description_de Title of the Sponsors section of the My Fair page
     */
    sponsorsTitle: LocalizedString

    /**
     * @title Up Next title
     * @description Title of the Up Next section of the My Fair page
     * @title_de Up Next title
     * @description_de Title of the Up Next section of the My Fair page
     */
    upNextTitle: LocalizedString

    /**
     * @title Live Sessions title
     * @description Title of the Live Sessions section of the Lobby page
     * @title_de Live Sessions title
     * @description_de Title of the Live Sessions section of the Lobby page
     */
    liveSessions: LocalizedString

    /**
     * @title Speakers navigation link text
     * @description Text for the navigation link of Speakers section of the Lobby page
     * @title_de Speakers navigation link text
     * @description_de Text for the navigation link of Speakers section of the Lobby page
     */
    speakersNavLinkText: LocalizedString

    /**
     * @title Bookmarked Speakers navigation link text
     * @description Text for the navigation link of Bookmarked Speakers section of the My Fair page
     * @title_de Bookmarked Speakers navigation link text
     * @description_de Text for the navigation link of Bookmarked Speakers section of the My Fair page
     */
    speakersBookmarkedNavLinkText: LocalizedString

    /**
     * @title Virtual Cafes title
     * @description Title of the Virtual Cafes section of the Lobby page
     * @title_de Virtual Cafes title
     * @description_de Title of the Virtual Cafes section of the Lobby page
     */
    featuredCompanies: LocalizedString

    /**
     * @title Live sessions navigation link text
     * @description Text for the navigation link of Live sessions section of the Lobby page
     * @title_de Live sessions navigation link text
     * @description_de Text for the navigation link of Live sessions section of the Lobby page
     */
    liveSessionsNavLinkText: LocalizedString

    /**
     * @title Virtual Cafes navigation link text
     * @description Text for the navigation link of Virtual Cafes section of the Lobby page
     * @title_de Virtual Cafes navigation link text
     * @description_de Text for the navigation link of Virtual Cafes section of the Lobby page
     */
    virtualCaffeNavLinkText: LocalizedString

    /**
     * @title Lobby exhibitors basis premium types
     * @description Packages of exhibitors shown in the exhibitors list (enter two of following - "ULTIMATE", "PREMIUM", "STANDARD")
     * @title_de Lobby exhibitors basis premium types
     * @description_de Packages of exhibitors shown in the exhibitors list (enter two of following - "ULTIMATE", "PREMIUM", "STANDARD")
     */
    lobbyExhibitorsBasisPremiumTypesList: LobbyExhibitorsBasisPremiumType[]

    /**
     * @title Lobby exhibitors - show top sponsors
     * @description Determines if top sponsors should be shown at the start of the Lobby page's exhibitors list
     * @title_de Lobby exhibitors - show top sponsors
     * @description_de Determines if top sponsors should be shown at the start of the Lobby page's exhibitors list
     */
    lobbyExhibitorsShowTopSponsors: boolean

    /**
     * @title Showfloor navigation link text
     * @description Text for the navigation link of Showfloor section of the Lobby page
     * @title_de Showfloor navigation link text
     * @description_de Text for the navigation link of Showfloor section of the Lobby page
     */
    showFloorNavLinkText: LocalizedString

    /**
     * @title Products navigation link text
     * @description Text for the navigation link of Bookmarked Products section of the My Fair page
     * @title_de Products navigation link text
     * @description_de Text for the navigation link of Bookmarked Products section of the My Fair page
     */
    productsNavLinkText: LocalizedString

    /**
     * @title Bookmarked Products navigation link text
     * @description Text for the navigation link of Bookmarked Products section of the My Fair page
     * @title_de Bookmarked Products navigation link text
     * @description_de Text for the navigation link of Bookmarked Products section of the My Fair page
     */
    productsBookmarkedNavLinkText: LocalizedString

    /**
     * @title Trademarks navigation link text
     * @description Text for the navigation link of Bookmarked Trademarksection of the My Fair page
     * @title_de Trademarks navigation link text
     * @description_de Text for the navigation link of Bookmarked Trademarks section of the My Fair page
     */
    trademarksNavLinkText: LocalizedString

    /**
     * @title Bookmarked Trademarks navigation link text
     * @description Text for the navigation link of Bookmarked Trademarks section of the My Fair page
     * @title_de Bookmarked Trademarks navigation link text
     * @description_de Text for the navigation link of Bookmarked Trademarks section of the My Fair page
     */
    trademarksBookmarkedNavLinkText: LocalizedString

    /**
     * @title News navigation link text
     * @description Text for the navigation link of Bookmarked Newsection of the My Fair page
     * @title_de News navigation link text
     * @description_de Text for the navigation link of Bookmarked News section of the My Fair page
     */
    newsNavLinkText: LocalizedString

    /**
     * @title Bookmarked News navigation link text
     * @description Text for the navigation link of Bookmarked News section of the My Fair page
     * @title_de Bookmarked News navigation link text
     * @description_de Text for the navigation link of Bookmarked News section of the My Fair page
     */
    newsBookmarkedNavLinkText: LocalizedString

    /**
     * @title Job offers navigation link text
     * @description Text for the navigation link of Bookmarked Job offers section of the My Fair page
     * @title_de Job offers navigation link text
     * @description_de Text for the navigation link of Bookmarked Job offers section of the My Fair page
     */
    joboffersNavLinkText: LocalizedString

    /**
     * @title Bookmarked Job offers navigation link text
     * @description Text for the navigation link of Bookmarked Job offers section of the My Fair page
     * @title_de Bookmarked Job offers navigation link text
     * @description_de Text for the navigation link of Bookmarked Job offers section of the My Fair page
     */
    joboffersBookmarkedNavLinkText: LocalizedString

    /**
     * @title Bookmarked exhibitors navigation link text
     * @description Text for the navigation link of Bookmarked exhibitors section of the My Fair page
     * @title_de Bookmarked exhibitors navigation link text
     * @description_de Text for the navigation link of Bookmarked exhibitors section of the My Fair page
     */
    showFloorBookmarkedNavLinkText: LocalizedString

    /**
     * @title Leadfinder navigation link text
     * @description Text for the navigation link of Leadfinder banner in the Lobby page
     * @title_de Leadfinder navigation link text
     * @description_de Text for the navigation link of Leadfinder banner in the Lobby page
     */
    leadfinderNavLinkText: LocalizedString

    /**
     * @title Networking navigation link text
     * @description Text for the navigation link of Networking banner in the Lobby page
     * @title_de Networking navigation link text
     * @description_de Text for the navigation link of Networking banner in the Lobby page
     */
    networkingNavLinkText: LocalizedString

    /**
     * @title Program navigation link text
     * @description Text for the navigation link of program banner in the Lobby page
     * @title_de Program navigation link text
     * @description_de Text for the navigation link of program banner in the Lobby page
     */
    programNavLinkText: LocalizedString

    /**
     * @title Participants navigation link text
     * @description Text for the navigation link of Participants section of the Lobby page
     * @title_de Participants navigation link text
     * @description_de Text for the navigation link of Participants section of the Lobby page
     */
    participantsNavLinkText: LocalizedString

    /**
     * @title My Schedule
     * @description Title of the My Schedule section of My Fair page
     * @title_de My Schedule
     * @description_de Title of the My Schedule section of My Fair page
     */
    mySchedule: LocalizedString

    /**
     * @title Bookmarked speakers
     * @description Title of the bookmarked speakers section of My Fair page
     * @title_de Bookmarked speakers
     * @description_de Title of the bookmarked speakers section of My Fair page
     */
    speakers: LocalizedString

    /**
     * @title Bookmarked exhibitors
     * @description Title of the bookmarked exhibitors section of My Fair page
     * @title_de Bookmarked exhibitors
     * @description_de Title of the bookmarked exhibitors section of My Fair page
     */
    exhibitors: LocalizedString

    /**
     * @title No events scheduled message
     * @description Message shown in the My Fair page's My schedule section when there are no scheduled events
     * @title_de No events scheduled message
     * @description_de Message shown in the My Fair page's My schedule section when there are no scheduled events
     */
    noEventsScheduled: LocalizedString

    /**
     * @title No bookmarked speakers message
     * @description Message shown in the My Fair page's bookmarked speakers section when there are no bookmarked speakers
     * @title_de No bookmarked speakers message
     * @description_de Message shown in the My Fair page's bookmarked speakers section when there are no bookmarked speakers
     */
    noBookmarkedSpeakers: LocalizedString

    /**
     * @title No bookmarked exhibitors message
     * @description Message shown in the My Fair page's bookmarked exhibitors section when there are no bookmarked exhibitors
     * @title_de No bookmarked exhibitors message
     * @description_de Message shown in the My Fair page's bookmarked exhibitors section when there are no bookmarked exhibitors
     */
    noBookmarkedExhibitors: LocalizedString

    /**
     * @title No bookmarked products message
     * @description Message shown in the My Fair page's bookmarked products section when there are no bookmarked products
     * @title_de No bookmarked products message
     * @description_de Message shown in the My Fair page's bookmarked products section when there are no bookmarked products
     */
    noBookmarkedProducts: LocalizedString

    /**
     * @title No bookmarked trademarks message
     * @description Message shown in the My Fair page's bookmarked trademarks section when there are no bookmarked trademarks
     * @title_de No bookmarked trademarks message
     * @description_de Message shown in the My Fair page's bookmarked trademarks section when there are no bookmarked trademarks
     */
    noBookmarkedTrademarks: LocalizedString

    /**
     * @title No bookmarked news message
     * @description Message shown in the My Fair page's bookmarked news section when there are no bookmarked news
     * @title_de No bookmarked news message
     * @description_de Message shown in the My Fair page's bookmarked news section when there are no bookmarked news
     */
    noBookmarkedNews: LocalizedString

    /**
     * @title No bookmarked job offers message
     * @description Message shown in the My Fair page's bookmarked job offers section when there are no bookmarked job offers
     * @title_de No bookmarked job offers message
     * @description_de Message shown in the My Fair page's bookmarked job offers section when there are no bookmarked job offers
     */
    noBookmarkedJoboffers: LocalizedString

    /**
     * @title Lobby page header description
     * @description Subtitle of the Lobby page header
     * @title_de Lobby page header description
     * @description_de Subtitle of the Lobby page header
     */
    receptionPageLobbyHeaderDesc: string

    /**
     * @title My Fair page header description
     * @description Subtitle of the My Fair page header
     * @title_de My Fair page header description
     * @description_de Subtitle of the My Fair page header
     */
    receptionPageMyFairHeaderDesc: string

    /**
     * @title Networking button
     * @description Text for the button in the Networking banner
     * @title_de Networking button
     * @description_de Text for the button in the Networking banner
     */
    networkingButton: LocalizedString

    /**
     * @title Tile row icon visible
     * @description Enable / disable visibility of tile row icon
     * @title_de Tile row icon visible
     * @description_de Enable / disable visibility of tile row icon
     */
    tileRowIconVisible: boolean

    /**
     * @title Tile row title visible
     * @description Enable / disable visibility of tile row title
     * @title_de Tile row title visible
     * @description_de Enable / disable visibility of tile row title
     */
    tileRowTitleVisible: boolean
    /**
     * @title Tile row nav link visible
     * @description Enable / disable visibility of tile row nav link
     * @title_de Tile row nav link visible
     * @description_de Enable / disable visibility of tile row nav link
     */
    tileRowNavLinkVisible: boolean

    /**
     * @title Tile row icon visible
     * @description Enable / disable visibility of tile row icon
     * @title_de Tile row icon visible
     * @description_de Enable / disable visibility of tile row icon
     */
    tileRowProgramIconVisible: boolean

    /**
     * @title Tile row title visible
     * @description Enable / disable visibility of tile row title
     * @title_de Tile row title visible
     * @description_de Enable / disable visibility of tile row title
     */
    tileRowProgramTitleVisible: boolean
    /**
     * @title Tile row nav link visible
     * @description Enable / disable visibility of tile row nav link
     * @title_de Tile row nav link visible
     * @description_de Enable / disable visibility of tile row nav link
     */
    tileRowProgramNavLinkVisible: boolean

    /**
     * @title Tile row icon visible
     * @description Enable / disable visibility of tile row icon
     * @title_de Tile row icon visible
     * @description_de Enable / disable visibility of tile row icon
     */
    tileRowLeadFinderIconVisible: boolean

    /**
     * @title Tile row title visible
     * @description Enable / disable visibility of tile row title
     * @title_de Tile row title visible
     * @description_de Enable / disable visibility of tile row title
     */
    tileRowLeadFinderTitleVisible: boolean
    /**
     * @title Tile row nav link visible
     * @description Enable / disable visibility of tile row nav link
     * @title_de Tile row nav link visible
     * @description_de Enable / disable visibility of tile row nav link
     */
    tileRowLeadFinderNavLinkVisible: boolean
    /**
     * @title Networking text 1
     * @description First part of the text in the Networking banner
     * @title_de Networking text 1
     * @description_de First part of the text in the Networking banner
     */
    networkingText1: LocalizedString

    /**
     * @title Networking text 2
     * @description Second part of the text in the Networking banner
     * @title_de Networking text 2
     * @description_de Second part of the text in the Networking banner
     */
    networkingText2: LocalizedString

    /**
     * @title Lead Finder button
     * @description Text for the button in the Lead Finder banner
     * @title_de Lead Finder button
     * @description_de Text for the button in the Lead Finder banner
     */
    leadFinderButton: LocalizedString

    /**
     * @title Lead Finder text 1
     * @description First part of the text in the Lead Finder banner
     * @title_de Lead Finder text 1
     * @description_de First part of the text in the Lead Finder banner
     */
    leadFinderText1: LocalizedString

    /**
     * @title Lead Finder text 2
     * @description Second part of the text in the Lead Finder banner
     * @title_de Lead Finder text 2
     * @description_de Second part of the text in the Lead Finder banner
     */
    leadFinderText2: LocalizedString

    /**
     * @title Lead Finder button
     * @description Text for the button in the Lead Finder banner
     * @title_de Lead Finder button
     * @description_de Text for the button in the Lead Finder banner
     */
    programButton: LocalizedString

    /**
     * @title Lead Finder text 1
     * @description First part of the text in the Lead Finder banner
     * @title_de Lead Finder text 1
     * @description_de First part of the text in the Lead Finder banner
     */
    programText1: LocalizedString

    /**
     * @title Lead Finder text 2
     * @description Second part of the text in the Lead Finder banner
     * @title_de Lead Finder text 2
     * @description_de Second part of the text in the Lead Finder banner
     */
    programText2: LocalizedString

    /**
     * @title "Live tomorrow" text
     * @description Text shown on tiles of exhibitors which have events that will be live on the next day
     * @title_de "Live tomorrow " text
     * @description_de Text shown on tiles of exhibitors which have events that will be live on the next day
     */
    liveTomorrow: LocalizedString

    /**
     * @title "Now live" text
     * @description Text shown on tiles of exhibitors which have events that are currently live
     * @title_de "Now live" text
     * @description_de Text shown on tiles of exhibitors which have events that are currently live
     */
    nowLive: LocalizedString

    /**
     * @title "Live on" text
     * @description First part of "Live on" text shown on tiles of exhibitors which have upcoming events
     * @title_de "Live on [date]" text
     * @description_de First part of "Live on [date]" text shown on tiles of exhibitors which have upcoming events
     */
    liveOn: LocalizedString

    /**
     * @title Bookmark tile text color
     * @description Text color for exhibitor/product/trademark tiles in My Fair page
     * @title_de Bookmark tile text color
     * @description_de Text color for exhibitor/product/trademark tiles in My Fair page
     */
    myFairBookmarkTileTextColor: string

    /**
     * @title Bookmark tile icon color
     * @description Bookmark icon color for exhibitor/product/trademark tiles in My Fair page
     * @title_de Bookmark tile icon color
     * @description_de Bookmark icon color for exhibitor/product/trademark tiles in My Fair page
     */
    myFairBookmarkTileIconColor: string

    /**
     * @title Visit Showfloor text
     * @description Text for Showfloor page link
     * @title_de Visit Showfloor text
     * @description_de Text for Showfloor page link
     */
    visitExhibitorText: LocalizedString

    /**
     * @title Visit Products text
     * @description Text for Products page link
     * @title_de Visit Products text
     * @description_de Text for Products page link
     */
    visitProductText: LocalizedString

    /**
     * @title Visit Trademarks text
     * @description Text for Trademarks page link
     * @title_de Visit trademarks text
     * @description_de Text for Trademarks page link
     */
    visitTrademarkText: LocalizedString

    /**
     * @title Visit News text
     * @description Text for News page link
     * @title_de Visit News text
     * @description_de Text for News page link
     */
    visitNewsText: LocalizedString

    /**
     * @title Organizer News section title
     * @description Text for Organizer News section title
     * @title_de Organizer News section title
     * @description_de Text for Organizer News section title
     */
    organizerNewsTitle: LocalizedString

    /**
     * @title Visit Organizer News text
     * @description Text for Organizer News page link
     * @title_de Visit Organizer News text
     * @description_de Text for Organizer News page link
     */
    visitOrganizerNewsText: LocalizedString

    /**
     * @title Visit Organizer News URL
     * @description URL for Organizer News page link
     * @title_de Visit Organizer News URL
     * @description_de URL for Organizer News page link
     */
    visitOrganizerNewsUrl: string

    /**
     * @title News types
     * @description Defines the types of news displayed in Organizer News section
     * @title_de News types
     * @description_de Defines the types of news displayed in Organizer News section
     */
    newsTypes: NewsType[]

    /**
     * @title Top Speakers section title
     * @description Text for Top Speakers section title
     * @title_de Titel des Abschnitts „Top Speakers“
     * @description_de Text für den Titel des Abschnitts „Top Speakers“
     */
    topSpeakersTitle: LocalizedString

    /**
     * @title Visit Top Speakers link label
     * @description Text for Top Speakers page link label
     * @title_de Linktext "Top-Speaker besuchen"
     * @description_de Text für das Link-Label der Top-Speaker-Seite
     */
    visitTopSpeakersText: LocalizedString

    /**
     * @title Visit Top Speakers URL
     * @description URL for Top Speakers page link
     * @title_de URL „Top-Speaker besuchen“.
     * @description_de URL für den Link zur Top-Speaker-Seite
     */
    visitTopSpeakersUrl: string

    /**
     * @title Visit Job offer text
     * @description Text for Job offer page link
     * @title_de Visit Job offer text
     * @description_de Text for Job offer page link
     */
    visitJobofferText: LocalizedString

    /**
     * @title Tile row link styling
     * @description CSS configuration for tile row's link
     * @title_de Tile row link styling
     * @description_de CSS configuration for tile row's link
     */
    topBarLinkRoot: TopBarLinkRoot

    /**
     * @title Tile row header title font size
     * @description Font size value for the title of the tile row header
     * @title_de Tile row header title font size
     * @description_de Font size value for the title of the tile row header
     */
    topBarTitleFontSize: string

    /**
     * @title My Fair page's subtitle top margin
     * @description Top margin of My Fair page's header subtitle
     * @title_de My Fair page's subtitle top margin
     * @description_de Top margin of My Fair page's header subtitle
     */
    myFairHeaderSubtitleMarginTop: string

    /**
     * @title Tile row header title font weight
     * @description Font weight value for the title of the tile row header
     * @title_de Tile row header title font weight
     * @description_de Font weight value for the title of the tile row header
     */
    topBarTitleFontWeight: string

    /**
     * @title Reception page header font size
     * @description Font size value for reception page's header
     * @title_de Reception page header font size
     * @description_de Font size value for reception page's header
     */
    receptionPageHeaderFontSize: string

    /**
     * @title Reception page header font weight
     * @description Font weight value for reception page's header
     * @title_de Reception page header font weight
     * @description_de Font weight value for reception page's header
     */
    receptionPageHeaderFontWeight: string

    /**
     * @title Empty tile text color
     * @description Color of the text of empty tile in My Fair page
     * @title_de Empty tile text color
     * @description_de Color of the text of empty tile in My Fair page
     */
    emptyTileTextColor: string

    /**
     * @title Empty tile background color
     * @description Color of the background of empty tile in My Fair page
     * @title_de Empty tile background color
     * @description_de Color of the background of empty tile in My Fair page
     */
    emptyTileBgColor: string

    /**
     * @title Lobby page header linear gradient
     * @description CSS linear gradient value for Lobby page's header
     * @title_de Lobby page header linear gradient
     * @description_de CSS linear gradient value for Lobby page's header
     */
    lobbyHeaderLinearGradient1: string

    /**
     * @title My Fair page header linear gradient
     * @description CSS linear gradient value for My Fair page's header
     * @title_de My Fair page header linear gradient
     * @description_de CSS linear gradient value for My Fair page's header
     */
    lobbyHeaderLinearGradient2: string

    /**
     * @title My Schedule title color
     * @description Color of the title of the My Schedule section in My Fair Page
     * @title_de My Schedule title color
     * @description_de Color of the title of the My Schedule section in My Fair Page
     */
    myScheduleTitleColor: string

    /**
     * @title Virtual cafe access button font size
     * @description Font size for the access button in Virtual Cafe tiles
     * @title_de Virtual cafe access button font size
     * @description_de Font size for the access button in Virtual Cafe tiles
     */
    virtualCafeAccessButtonFontSize: string

    /**
     * @title Header title font size index
     * @description Font size index for header title
     * @title_de Header title font size index
     * @description_de Font size index for header title
     */
    headerTitleFontSizeIndex: number

    /**
     * @title Header title initial font size
     * @description Font size index for the bottom part of the header title
     * @title_de Header title initial font size
     * @description_de Font size index for the bottom part of the header title
     */
    headerFooterFontSizeIndex: number

    /**
     * @title Header title initial font size
     * @description Initial font size of the reception page's header title
     * @title_de Header title initial font size
     * @description_de Initial font size of the reception page's header title
     */
    headerTitleInitialFontSize: string

    /**
     * @title Networking banner text color
     * @description Color of the text in the networking banner
     * @title_de Networking banner text color
     * @description_de Color of the text in the networking banner
     */
    networkingBannerTextColor: string

    /**
     * @title Lobby tile text width
     * @description Width of text in lobby's showfloor tile
     * @title_de Lobby tile text width
     * @description_de Width of text in lobby's showfloor tile
     */
    lobbyTileTextWidth: string

    /**
     * @title Person component actions top margin
     * @description Value for top margin of action buttons in person tile
     * @title_de Person component actions top margin
     * @description_de Value for top margin of action buttons in person tile
     */
    personComponentActionsMarginTop: string

    /**
     * @title Person component actions right margin
     * @description Value for right margin of action buttons in person tile
     * @title_de Person component actions right margin
     * @description_de Value for right margin of action buttons in person tile
     */
    personComponentActionsMarginRight: string

    /**
     * @title Reception page header title text decoration
     * @description CSS value for decoration of the title of the header shared by Lobby and My Fair page
     * @title_de Reception page header title text decoration
     * @description_de CSS value for decoration of the title of the header shared by Lobby and My Fair page
     */
    receptionPageHeaderTitleTextDecoration: string

    /**
     * @title My fair page top sponsors
     * @description List of exhibitors that are always displayed at the start of the sponsors list in My Fair page
     * @title_de My fair page top sponsors
     * @description_de List of exhibitors that are always displayed at the start of the sponsors list in My Fair page
     */
    sponsoredExhibitorsMyPageList: TopSponsor[]

    /**
     * @title Show premium exhibitors with sponsors
     * @description Determines showing of premium exhibitors in the My Fair page sponsors list
     * @title_de Show premium exhibitors with sponsors
     * @description_de Determines showing of premium exhibitors in the My Fair page sponsors list
     */
    showPremiumExhibitorsWithSponsors: boolean

    /**
     * @title Lobby top sponsors
     * @description List of exhibitors that are always displayed at the start of the exhibitors list
     * @title_de Lobby top sponsors
     * @description_de List of exhibitors that are always displayed at the start of the exhibitors list
     */
    lobbyTopSponsors: TopSponsor[]

    /**
     * @title Networking banner title
     * @description Title shown above the Networking banner
     * @title_de Networking banner title
     * @description_de Title shown above the Networking banner
     */
    networkingBannerTitle: LocalizedString

    /**
     * @title Lead Finder banner title
     * @description Title shown above the Lead Finder banner
     * @title_de Lead Finder banner title
     * @description_de Title shown above the Lead Finder banner
     */
    leadFinderBannerTitle: LocalizedString

    /**
     * @title Program banner title
     * @description Title shown above the program banner
     * @title_de Program banner title
     * @description_de Title shown above the program banner
     */
    programBannerTitle: LocalizedString

    /**
     * @title Darken overlay for participants
     * @description Darken overlay value for person tiles
     * @title_de Darken overlay for participants
     * @description_de Darken overlay value for person tiles
     */
    darkenOverlayForParticipants: string
    /**
     * @title Darken overlay on hover option for participants
     * @description Darken overlay on hover option value for person tiles
     * @title_de Darken overlay on hover option for participants
     * @description_de Darken overlay on hover option value for person tiles
     */
    darkenOverlayOnHoverForParticipants: string
    /**
     * @title Speakers tile grid gap
     * @description Grid gap size between speakers tiles on lobby page
     * @title_de Speakers tile grid gap
     * @description_de Grid gap size between speakers tiles on lobby page
     */
    speakersTileGridGap: string
    /**
     * @title Lobby page showfloor tiles darken overlay
     * @description Rgba value for darken overlay on showfloor tiles inside of the Lobby page
     * @title_de Lobby page showfloor tiles darken overlay
     * @description_de Rgba value for darken overlay on showfloor tiles inside of the Lobby page
     */
    lobbypageShowfloorTilesDarkenOverlay: string
    /**
     * @title Top bar link header text color
     * @description Text color for header top bar link
     * @title_de Top bar link header text color
     * @description_de Text color for header top bar link
     */
    topBarLinkHeaderTextColor: ColorString
    /**
     * @title Top bar link header text color subtitleo on hover
     * @description Text color on hover option for header top bar subtitle link
     * @title_de Top bar link header text color subtitle on hover
     * @description_de Text color on hover option for header top bar subtitle link
     */
    topBarLinkHeaderTextColorSubtitle: ColorString
    /**
     * @title Top bar link header text color
     * @description Text color for header top bar link
     * @title_de Top bar link header text color
     * @description_de Text color for header top bar link
     */
    topBarLinkHeaderTextColorOnHover: ColorString
    /**
     * @title Top bar link header text color subtitle on hover
     * @description Text color on hover option for header top bar subtitle link
     * @title_de Top bar link header text color subtitle on hover
     * @description_de Text color on hover option for header top bar subtitle link
     */
    topBarLinkHeaderTextColorSubtitleOnHover: ColorString
    /**
     * @title Showfloor tiles margins
     * @description All margin sizes in one row for lobby showfloor tiles
     * @title_de Showfloor tiles margins
     * @description_de All margin sizes in one row for lobby showfloor tiles
     */
    showfloorTileMargins: string
    /**
     * @title My fair page exhibitor tiles margin
     * @description Right margin size between my fair page exhibitor tiles
     * @title_de My fair page exhibitor tiles margin
     * @description_de Right margin size between my fair page exhibitor tiles
     */
    myFairPageExhibitorTilesMargin: string
    /**
     * @title My fair page exhibitor tiles border
     * @description Right border size between my fair page exhibitor tiles
     * @title_de My fair page exhibitor tiles border
     * @description_de Right border size between my fair page exhibitor tiles
     */
    myFairPageExhibitorTilesBorder: string
    /**
     * @title My fair page exhibitor tiles background color
     * @description Right background color size between my fair page exhibitor tiles
     * @title_de My fair page exhibitor tiles background color
     * @description_de Right background color size between my fair page exhibitor tiles
     */
    myFairPageExhibitorTilesBgColor: string
    /**
     * @title Show navigation link next to title in tile row
     * @description Show navigation link next to title in tile row
     * @title_de Show navigation link next to title in tile row
     * @description_de Show navigation link next to title in tile row
     */
    tileRowShowNavLinkNextToTitle: boolean

    /**
     * @title Tile row title font family
     * @description Font family for tile row's title
     * @title_de Tile row title font family
     * @description_de Font family for tile row's title
     */
    tileRowTitleFont: string

    /**
     * @title Tile row subtitle font family
     * @description Font family for tile row's subtitle
     * @title_de Tile row subtitle font family
     * @description_de Font family for tile row's subtitle
     */
    tileRowSubtitleFont: string

    /**
     * @title Tile row subtitle text transform
     * @description Text transform value (uppercase, lowercase, none, etc) for tile row's subtitle
     * @title_de Tile row subtitle text transform
     * @description_de Text transform value (uppercase, lowercase, none, etc) for tile row's subtitle
     */
    tileRowSubtitleTextTransform: string

    /**
     * @title Tile row bottom border
     * @description Bottom border of the tile row title
     * @title_de Tile row bottom border
     * @description_de Bottom border of the tile row title
     */
    tileRowBottomBorder: string

    /**
     * @title Tile row arrow color
     * @description Color of the arrow shown with the tile row title
     * @title_de Tile row arrow color
     * @description_de Color of the arrow shown with the tile row title
     */
    tileRowArrowColor: string

    /**
     * @title Top bar link right arrow color
     * @description Color of the arrow shown with top bar link
     * @title_de Top bar link right arrow color
     * @description_de Color of the arrow shown with top bar link
     */
    topBarLinkRightArrowColor: string

    /**
     * @title Top bar link right arrow color (mobile)
     * @description Color of the arrow shown with top bar link
     * @title_de Top bar link right arrow color (mobile)
     * @description_de Color of the arrow shown with top bar link
     */
    mobileTopBarLinkRightArrowColor: string

    /**
     * @title Header title font family
     * @description Font family for reception page's header title
     * @title_de Header title font family
     * @description_de Font family for reception page's header title
     */
    headerTitleFontFamily: string

    /**
     * @title Header title text transform
     * @description Text transform value (uppercase, lowercase, none, etc) for reception page's header title
     * @title_de Header title text transform
     * @description_de Text transform value (uppercase, lowercase, none, etc) for reception page's header title
     */
    headerTitleTextTransform: string

    /**
     * @title Header title letter spacing
     * @description Letter spacing value for reception page's header title
     * @title_de Header title letter spacing
     * @description_de Letter spacing value for reception page's header title
     */
    headerTitleLetterSpacing: string

    /**
     * @title Header title font weight
     * @description Font weight value for reception page's header title
     * @title_de Header title font weight
     * @description_de Font weight value for reception page's header title
     */
    headerTitleFontWeight: string

    /**
     * @title Tab items order
     * @description Defines the order of the tabs, to remove a tab, simply remove the array item
     * @title_de Tab items order
     * @description_de Defines the order of the tabs, to remove a tab, simply remove the array item
     */
    crsTabsItemsOrder: string[]

    /**
     * @title Tile row icon visible
     * @description Enable / disable visibility of tile row icon
     * @title_de Tile row icon visible
     * @description_de Enable / disable visibility of tile row icon
     */
    tileRowCollectionsIconVisible: boolean

    /**
     * @title Tile row title visible
     * @description Enable / disable visibility of tile row title
     * @title_de Tile row title visible
     * @description_de Enable / disable visibility of tile row title
     */
    tileRowCollectionsTitleVisible: boolean
    /**
     * @title Tile row nav link visible
     * @description Enable / disable visibility of tile row nav link
     * @title_de Tile row nav link visible
     * @description_de Enable / disable visibility of tile row nav link
     */
    tileRowCollectionsNavLinkVisible: boolean

    /**
     * @title Collections title
     * @description Title shown above the Collections
     * @title_de Collections title
     * @description_de Title shown above the Collections
     */
    collectionsBannerTitle: LocalizedString

    /**
     * @title Collections navigation link text
     * @description Text for the navigation link of Collections section of the Lobby page
     * @title_de Collections navigation link text
     * @description_de Text for the navigation link of Collections section of the Lobby page
     */
    collectionsNavLinkText: LocalizedString

    /**
     * @title Top news
     * @description List of news that are always displayed at the start of the news list in Lobby page
     * @title_de Top news
     * @description_de List of news that are always displayed at the start of the news list in Lobby page
     */
    topNewsList: TopNews[]
}

export interface ColorBranding {
    /**
     * @title Background color
     * @description Background color
     * @title_de Background color
     * @description_de Background color
     */
    backgroundColor: ColorString

    /**
     * @title Background color on hover
     * @description Background color on hover
     * @title_de Background color on hover
     * @description_de Background color on hover
     */
    hoverColor: ColorString

    /**
     * @title Text color
     * @description Text color
     * @title_de Text color
     * @description_de Text color
     */
    textColor: ColorString

    /**
     * @title Border (top, right, bottom)
     * @description Border (top, right, bottom)
     * @title_de Border (top, right, bottom)
     * @description_de Border (top, right, bottom)
     */
    border: string

    /**
     * @title Border (left)
     * @description Border (left)
     * @title_de Border (left)
     * @description_de Border (left)
     */
    borderLeft: string
}

export interface MySchedulePageBranding {
    /**
     * @title My schedule title color
     * @description Text color for my schedule title
     * @title_de My schedule title color
     * @description_de Text color for my schedule title
     */
    myScheduleTitleColor: ColorString

    /**
     * @title Private event colors
     * @description Colors for private eventdates
     * @title_de Past event colors
     * @description_de Colors for private eventdates
     */
    privateEventColors: ColorBranding

    /**
     * @title Open event colors
     * @description Colors for meetings and eventdates with open access
     * @title_de Open event colors
     * @description_de Colors for meetings and eventdates with open access
     */
    openEventColors: ColorBranding

    /**
     * @title My Fair page event colors
     * @description Text color for my schedule entries in the My Fair page
     * @title_de My Fair page event colors
     * @description_de Text color for my schedule entries in the My Fair page
     */
    myFairPageEventColors: ColorBranding

    /**
     * @title Today button title
     * @description Text for button leading to today's events and meetings
     * @title_de Today button title
     * @description_de Text for button leading to today's events and meetings
     */
    todayButtonTitle: LocalizedString

    /**
     * @title Dropdown filter title
     * @description Title for dropdown filter
     * @title_de Dropdown filter title
     * @description_de Title for dropdown filter
     */
    dropdownFilterTitle: LocalizedString

    /**
     * @title Dropdown filter live session text
     * @description Text for live session option in dropdown filter
     * @title_de Dropdown filter live session text
     * @description_de Text for live session option in dropdown filter
     */
    dropdownFilterLiveSession: LocalizedString

    /**
     * @title Dropdown filter meeting text
     * @description Text for meeting option in dropdown filter
     * @title_de Dropdown filter meeting text
     * @description_de Text for meeting option in dropdown filter
     */
    dropdownFilterMeeting: LocalizedString

    /**
     * @title Default starting date
     * @description Default value for the first selected date (ignored if empty)
     * @title_de Default starting date
     * @description_de Default value for the first selected date (ignored if empty)
     */
    defaultStartDate: string

    /**
     * @title No items text
     * @description Text for empty list view
     * @title_de No items text
     * @description_de Text for empty list view
     */
    noItemsText: LocalizedString
}
