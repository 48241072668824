import { RosterType } from "amazon-chime-sdk-component-library-react"
import { getConversationParticipants } from "../../backendServices/GraphQLServices"
import { MeetingKind } from "../../backendServices/MeetingServices"
import { ChatType } from "../enums/ChatType"

export async function getChatType(
    meetingKind: MeetingKind | null,
    roster: RosterType,
    conversationId: string | null
): Promise<ChatType | null> {
    if (!meetingKind || !roster || !conversationId) return null
    let chatType: ChatType | null = ChatType.CALL_UNKNOWN
    switch (meetingKind) {
        case "calenderEntry":
            chatType = ChatType.CALENDARENTRY
            break
        case "call":
            const participants = await getConversationParticipants(conversationId, 10)
            if (participants) {
                if (participants?.length === 2) {
                    chatType = ChatType.CALL_PRIVATE
                } else if (participants?.length > 2) {
                    chatType = ChatType.CALL_GROUP
                } else {
                    chatType = ChatType.CALL_UNKNOWN
                }
            }

            break
        default:
            return null
    }

    return chatType
}
