import * as React from "react"
import { PresenceType } from "../../backendServices/GraphQLServices"
import branding from "../../branding/branding"
import { IconDoNotDisturb } from "../../ui/Icons"
import { EventType, usePresenceState } from "../../ui/PresenceIndicator"
import ToggleSwitch from "../../ui/ToggleSwitch/ToggleSwitch"
import { SettingsAreaRow } from "../settings/SettingsTab"

export const DoNotDisturbToggle: React.FC<{ scaleSize?: number }> = (props) => {
    const myPresence = usePresenceState()

    return (
        <SettingsAreaRow {...props}>
            {IconDoNotDisturb({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
            <p>{branding.communicationArea.silentChat}</p>
            <ToggleSwitch
                activatedSliderBorderColor={branding.dangerButtonColor}
                activatedToggleBorderColor={branding.dangerButtonColor}
                scaleSize={props.scaleSize}
                isEnabled={myPresence.getMyPresence() === PresenceType.DONOTDISTURB}
                onClick={() => {
                    myPresence.updateMyPresence(
                        EventType.DONOTDISTURB_TOGGLE,
                        myPresence.getMyPresence() !== PresenceType.DONOTDISTURB
                            ? PresenceType.DONOTDISTURB
                            : PresenceType.AVAILABLE
                    )
                    localStorage.setItem(
                        "presenceBeforeRefresh",
                        myPresence.getMyPresence() !== PresenceType.DONOTDISTURB
                            ? PresenceType.DONOTDISTURB
                            : PresenceType.AVAILABLE
                    )
                }}
            />
        </SettingsAreaRow>
    )
}
