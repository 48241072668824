import styled from "styled-components"

export const TimerRoot = styled.div`
    width: 200px;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 35px 20px 20px 20px;
    font-size: 14px;
`

export const TimerOutput = styled.div`
    color: #fff;
    text-align: center;
`
