export interface InputProps {
    label: string
    inputPlaceholder: string
    inputType: InputType
    inputValue: string
    required: boolean
    inputWidth: string
    showParticipants?: boolean
    showRadioInput?: boolean
    className?: string
    showIconInInput?: boolean
    icon?: string
    time?: string
    customPlaceholder?: string
    showToggleSwitcher?: boolean
}

export enum InputType {
    TextArea = "textarea",
    Select = "select",
    TextWithDropdown = "text-with-dropdown",
    Date = "date",
    Search = "search",
    Counter = "counter",
    Text = "text",
    Toggle = "toggle"
}

export interface RadioButtonProps {
    type: string
    id: string
    text: string
}

export const data = [
    {
        label: "Title",
        className: "text-input",
        inputPlaceholder: "Enter the Title",
        inputType: InputType.Text,
        inputValue: "",
        required: true,
        inputWidth: "100%",
        showIconInInput: false
    },
    {
        label: "Date",
        inputPlaceholder: "Select date",
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "100%",
        className: "input-with-icon",
        showIconInInput: true,
        icon: "calendar",
        time: "day"
    },
    {
        label: "Start",
        inputPlaceholder: "Select time",
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "41%",
        showIconInInput: true,
        icon: "clock",
        showDropdownIcon: true,
        className: "select-time",
        time: "start"
    },
    {
        label: "End",
        inputPlaceholder: "Select time",
        inputType: InputType.Date,
        inputValue: "",
        required: true,
        inputWidth: "41%",
        showIconInInput: true,
        icon: "clock",
        showDropdownIcon: true,
        className: "select-time",
        time: "end"
    },
    {
        label: "",
        inputPlaceholder: "",
        inputType: InputType.Counter,
        inputValue: "",
        required: false,
        inputWidth: "12%",
        showIconInInput: false,
        icon: "",
        showDropdownIcon: false,
        className: "counter",
        time: ""
    },
    {
        label: "Time Zone",
        inputPlaceholder: "(GMT+01:00) CET - Berlin",
        inputType: InputType.Select,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showIconInInput: false,
        showDropdownIcon: true
    },
    {
        label: "Location",
        inputPlaceholder: "Enter stand or exhibitor name",
        customPlaceholder: "Enter location description",
        inputType: InputType.TextWithDropdown,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showRadioInput: true,
        showToggleSwitcher: true
    },
    {
        label: "Participants",
        inputPlaceholder: "Add participants",
        inputType: InputType.Search,
        inputValue: "",
        required: false,
        inputWidth: "100%",
        showParticipants: true,
        showIconInInput: true,
        icon: "search"
    },
    {
        label: "Note",
        inputPlaceholder: "Add personal note (option)",
        inputType: InputType.TextArea,
        inputValue: "",
        required: false,
        inputWidth: "100%"
    }
]

export const radioButtons = [
    {
        type: "radio",
        id: "stand",
        text: "Stand"
    },
    {
        type: "radio",
        id: "custom",
        text: "Custom"
    }
]
