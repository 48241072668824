import { Fragment } from "react"
import ReactDOM from "react-dom"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import branding from "../../branding/branding"
import { InternalLink } from "../../contentArea/userRegistration/UserRegistrationSiteBranding"
import { IconPointerRight } from "../Icons"
import {
    ModalDialog,
    ModalRoot,
    ModalBody,
    ModalContent,
    ModalBodyTitle,
    ModalBodyText,
    SubmitButtonContainer,
    AgreeButton
} from "./ConfirmationModal"

const InternalLinkRoot = styled.div`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 18px;
    color: ${branding.userRegistrationSite.selectedTabColor};
    font-family: ${branding.font1};
    font-weight: 500;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: -15px;
`

const InternalLinkIconContainer = styled.div`
    margin-right: 15px;
`

interface ModalProps {
    onClose: () => void
}

export default function UserRegistrationConfirmationModal(props: ModalProps) {
    const history = useHistory()
    const linksList = branding.userRegistrationSite.internalLinksList

    const text = branding.userRegistrationSite.successfulEditConfirmationModalText

    return ReactDOM.createPortal(
        <Fragment>
            <ModalRoot className="modal d-block" role="dialog">
                <ModalDialog width="480px" className="modal-dialog modal-dialog-centered" role="document">
                    <ModalContent className="modal-content" style={{ padding: "40px 30px 20px 30px" }}>
                        <ModalBody className="modal-body">
                            <div className="row">
                                <ModalBodyTitle style={{ marginBottom: "0px" }}>
                                    {branding.userRegistrationSite.successfulEditConfirmationModalTitle.replaceAll(
                                        "${fairName}",
                                        branding.pageTitle
                                    )}
                                </ModalBodyTitle>
                                <ModalBodyText style={{ marginBottom: "25px" }}>
                                    {text.split("\n").map((item: string, i: number) => (
                                        <span key={i}>
                                            {item}
                                            <br />
                                        </span>
                                    ))}
                                </ModalBodyText>
                            </div>

                            {linksList.map((link: InternalLink, index: number) => {
                                return (
                                    <InternalLinkRoot
                                        key={index}
                                        onClick={() => {
                                            history.push(link.path)
                                            props.onClose()
                                        }}
                                    >
                                        <InternalLinkIconContainer>
                                            {IconPointerRight({ fill: branding.userRegistrationSite.selectedTabColor })}
                                        </InternalLinkIconContainer>
                                        <div>{link.label}</div>
                                    </InternalLinkRoot>
                                )
                            })}
                            <SubmitButtonContainer className="row d-flex mt-4">
                                <AgreeButton
                                    onClick={() => props.onClose()}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    {branding.userRegistrationSite.welcomeModalConfirmButtonText}
                                </AgreeButton>
                            </SubmitButtonContainer>
                        </ModalBody>
                    </ModalContent>
                </ModalDialog>
            </ModalRoot>
        </Fragment>,
        document.body
    )
}
