import styled from "styled-components"

export const StageIndicatorsRoot = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
    background-color: transparent;
    padding: 25px;
    pointer-events: none;
`
