import { ActionType, Severity, useNotificationDispatch, useNotificationState } from "amazon-chime-sdk-component-library-react"
import { useEffect } from "react"
import { IconClose } from "../../../ui/Icons"
import {
    StageNotificationContent,
    StageNotificationDismissButton,
    StageNotificationIconWrapper,
    StageNotificationRoot
} from "./StageNotificationStyles"

interface StageNotificationProps {
    icon?: React.ReactElement
    id: any
    severity: Severity | undefined
    message: string | undefined
    onClose?: () => void | undefined
    autoCloseDelay?: number | undefined
    isClosable?: boolean | undefined
    replaceAll?: boolean | undefined
    button?: React.ReactElement
}

function StageNotification(props: StageNotificationProps) {
    const dispatch = useNotificationDispatch()
    const { notifications } = useNotificationState()

    useEffect(() => {
        if (props.autoCloseDelay) {
            setTimeout(() => {
                dispatch({
                    type: ActionType.REMOVE,
                    payload: props.id
                })
            }, props.autoCloseDelay)
        }

        if (props.replaceAll) {
            notifications.forEach((notification: any) => {
                if (notification.id !== props.id) {
                    dispatch({
                        type: ActionType.REMOVE,
                        payload: notification.id
                    })
                }
            })
        }
        // eslint-disable-next-line
    }, [props.autoCloseDelay])

    return (
        <StageNotificationRoot severity={props.severity}>
            <StageNotificationContent>
                <StageNotificationIconWrapper>{props.icon}</StageNotificationIconWrapper>
                {props.message}
                {props.button}
            </StageNotificationContent>
            {props.isClosable ? (
                <StageNotificationDismissButton
                    onClick={() => {
                        dispatch({
                            type: ActionType.REMOVE,
                            payload: props.id
                        })
                        if (props.onClose) {
                            props.onClose()
                        }
                    }}
                >
                    <IconClose fill={"#fff"} width={"14px"} height={"14px"} />
                </StageNotificationDismissButton>
            ) : null}
        </StageNotificationRoot>
    )
}

export default StageNotification
