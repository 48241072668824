import { fetchDataRest, topic } from "./BackendServicesUtils"
import { Collection } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"

/*********************************************************************************************
 * COLLECTIONS LIST
 **********************************************************************************************/
export interface CollectionListResponse {
    totalCount: number
    currentCount: number
    results: Collection[]
}

export interface CollectionListParams {
    pageNumber: number
    pageSize: number
}

export async function loadCollectionListData(
    params: CollectionListParams,
    signal?: AbortSignal
): Promise<CollectionListResponse | null> {
    const defaultRoute: string = `/collection/topicName/${topic}/list`

    try {
        const result = await fetchDataRest(defaultRoute, params, "GET")

        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}

/*********************************************************************************************
 * COLLECTION DETAILS
 **********************************************************************************************/

export async function loadCollectionData(collectionId: string, signal?: AbortSignal): Promise<Collection | null> {
    const defaultRoute: string = `/collection/topicName/${topic}/collectionId/${collectionId}`

    try {
        const result = await fetchDataRest(defaultRoute, null, "GET")

        if ((result as any).httpStatus) {
            return null
        }
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return null
    }
}
