import { RemoteAvatarMini } from "../RemoteAvatarMini/RemoteAvatarMini"
import { RemoteVideoMini } from "../RemoteVideoMini/RemoteVideoMini"
import { RemoteTileMiniRoot } from "./RemoteTIleMiniStyles"

export interface RemoteTileMiniProps {
    attendeeToTileId: any
    attendee: any
    isMouseHovering: boolean
    isVideoTile?: boolean
}

export function RemoteTileMini(props: RemoteTileMiniProps) {
    return (
        <RemoteTileMiniRoot>
            {props.isVideoTile ? (
                <RemoteVideoMini
                    isMouseHovering={props.isMouseHovering}
                    attendee={props.attendee}
                    attendeeToTileId={props.attendeeToTileId}
                />
            ) : (
                <RemoteAvatarMini isMouseHovering={props.isMouseHovering} attendee={props.attendee} />
            )}
        </RemoteTileMiniRoot>
    )
}
