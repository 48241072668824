import { Fragment, useState, useEffect } from "react"
import * as React from "react"
import TileRow from "../../ui/TileRow"
import { useLanguageState } from "../../globalStates/LanguageState"
import branding from "../../branding/branding"
import { IconSchedule } from "../../ui/Icons"
import {
    bookmarkedExhibitorsPageRoute,
    showfloorPageRoute,
    bookmarkedSpeakersPageRoute,
    speakersPageRoute,
    productsPageRoute,
    trademarksPageRoute,
    exhibitorNewsPageRoute,
    bookmarkedProductsPageRoute,
    bookmarkedTrademarksPageRoute,
    bookmarkedNewsPageRoute,
    bookmarkedJoboffersPageRoute,
    joboffersPageRoute,
    homePageRoute,
    mySchedulePageRoute
} from "../../navigationArea/RoutePaths"
import EventDateEntry from "../program/eventDateEntry/EventDateEntry"
import { EventDate, Person, Exhibitor, Product, Trademark, News, JobOffer } from "../../backendServices/Types"
import { loadPersonsData, PersonsDataRequestParams } from "../../backendServices/PersonServices"
import { CalendarEntryParticipationStatus } from "../../API"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import moment from "moment"
import { useFavoriteState } from "../../globalStates/Favorites"
import { momentWithoutTimezoneFromTimezonedMoment, getIndexOfInitialDayToShowIgnoreDefault } from "../../utils/DateUtils"
import { useAppState } from "../../globalStates/AppState"
import { orderBy } from "lodash"
import { getNextPackage, getPackageNumber } from "./LobbyPageContent"
import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { LobbyExhibitorsBasisPremiumType, MyFairComponent } from "./ReceptionPageContentBranding"
import { EntityCarousel } from "../../ui/carousels/EntityCarousel"
import { loadExhibitorsData } from "../../backendServices/ExhibitorServices"
import { loadTrademarksData } from "../../backendServices/TrademarkServices"
import { loadJobofferListData } from "../../backendServices/JobofferServices"
import { loadEventDateDates, loadEventDateList } from "../../backendServices/EventdateServices"
import { CalendarEntry, getCalendarEntries } from "../../backendServices/GraphQLServices"
import { loadProductsData } from "../../backendServices/ProductServices"
import { loadNewsListData } from "../../backendServices/NewsServices"

export enum CalendarEntrySortType {
    ALL,
    PAST,
    FUTURE
}

interface MyFairPageContentProps {
    eventKey: string
    setEventKey: (value: string) => void
    guestBannerHeight: number
    isMobile: boolean
}

const MyFairPageContent: React.FunctionComponent<MyFairPageContentProps> = (props) => {
    const abortController = new AbortController()
    const abortSignal = abortController.signal
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    let userState = useLoggedInState()
    const favorites = useFavoriteState()

    const [eventDates, setEventDates] = useState<EventDate[]>([])

    const [meetings, setMeetings] = useState<CalendarEntry[]>([])
    let [all, setAll]: Array<any> = useState([]) //meetings + eventdates
    const [personsBookmarks, setPersonsBookmarks] = useState<Person[]>([])
    const [showfloorsBookmarks, setShowfloorsBookmarks] = useState<Exhibitor[]>([])
    const [productsBookmarks, setProductsBookmarks] = useState<Product[]>([])
    const [trademarksBookmarks, setTrademarksBookmarks] = useState<Trademark[]>([])
    const [newsBookmarks, setNewsBookmarks] = useState<News[]>([])
    const [joboffersBookmarks, setJoboffersBookmarks] = useState<JobOffer[]>([])
    const [myPageExhibitorSponsors, setMyPageExhibitorSponsors] = useState<Exhibitor[]>()
    const [exhibitors, setExhibitors] = useState<Exhibitor[]>([])
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const timezone = useAppState().timezone

    const [nextPackage, setNextPackage] = useState<BasisPremiumType>(BasisPremiumType.NONE)

    const [refreshKey, setRefreshKey] = useState(0)

    const [days, setDays] = useState<moment.Moment[]>([])
    const eventDays = branding.eventTiming.eventDays
    const [selectedDay, setSelectedDay] = useState(0)

    const basisPremiumList = branding.receptionPage.lobbyExhibitorsBasisPremiumTypesList

    const componentOrder: MyFairComponent[] = branding.receptionPage.myPageComponentOrder ?? [
        "SPONSORS",
        "UP_NEXT",
        "SPEAKERS",
        "EXHIBITORS",
        "PRODUCTS",
        "TRADEMARKS",
        "NEWS",
        "JOBOFFERS"
    ]

    const eventDateFavorites = favorites.get("eventdate", true)
    useEffect(() => {
        setEventDates((favoriteList) => favoriteList.filter((favorite) => favorites.is("eventdate", favorite.id)))
    }, [eventDateFavorites]) //eslint-disable-line

    const personFavorites = favorites.get("person", true)
    useEffect(() => {
        setPersonsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("person", favorite.id)))
    }, [personFavorites]) //eslint-disable-line

    const showfloorsFavorites = favorites.get("organization", true)
    useEffect(() => {
        setShowfloorsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("organization", favorite.id)))
    }, [showfloorsFavorites]) //eslint-disable-line

    const productFavorites = favorites.get("product", true)
    useEffect(() => {
        setProductsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("product", favorite.id)))
    }, [productFavorites]) //eslint-disable-line

    const trademarkFavorites = favorites.get("trademark", true)
    useEffect(() => {
        setTrademarksBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("trademark", favorite.id)))
    }, [trademarkFavorites]) //eslint-disable-line

    const newsFavorites = favorites.get("news", true)
    useEffect(() => {
        setNewsBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("news", favorite.id)))
    }, [newsFavorites]) //eslint-disable-line

    const joboffersFavorites = favorites.get("joboffer", true)
    useEffect(() => {
        setJoboffersBookmarks((favoriteList) => favoriteList.filter((favorite) => favorites.is("joboffer", favorite.id)))
    }, [joboffersFavorites]) //eslint-disable-line

    const loadPremiumOrStandardExhibitors = (basisPremium: BasisPremiumType, numResultRows?: number) => {
        const basisPremiumPackage: LobbyExhibitorsBasisPremiumType | undefined = basisPremiumList.find(
            (e: LobbyExhibitorsBasisPremiumType) => e.packageName === basisPremium
        )

        if (basisPremiumPackage as LobbyExhibitorsBasisPremiumType) {
            const params: any = {
                filterlist: "entity_orga",
                basispremium: getPackageNumber(basisPremium),
                startresultrow: 0,
                numresultrows: basisPremiumPackage?.numberOfExhibitors,
                order: "random",
                lang: lang
            }

            loadExhibitorsData(params).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                const exhibitorsTemp = exhibitors.filter((it) => !ex.some((e) => e.id === it.id))

                const respData = branding.receptionPage.lobbyExhibitorsShowTopSponsors
                    ? ex.filter((it) => !branding.receptionPage.lobbyTopSponsors.some((e) => e.id === it.id))
                    : ex

                setExhibitors(exhibitorsTemp.length === 0 ? respData : exhibitorsTemp.concat(respData))
                setNextPackage(getNextPackage(basisPremium))
            })
        } else {
            setNextPackage(getNextPackage(basisPremium))
            return
        }
    }

    useEffect(() => {
        if (favorites.get("eventdate").length > 0) {
            const upNextFavoriteEventsParams: any = {
                filterlist: [favorites.get("eventdate")],
                startresultrow: 0,
                numresultrows: 50,
                order: "chrono",
                day: eventDays[selectedDay],
                lang: lang,
                future: "true"
            }

            loadEventDateList(upNextFavoriteEventsParams, abortController.signal).then((response) => {
                setEventDates(response.eventDates)
            })
        }
        if (favorites.get("person").length > 0) {
            const personFunc = branding.configuration.speakerPersonFunctions.map((func) => `personfunc_${func}`).join(", ")
            const eventDateParticipation = branding.configuration.eventDateParticipation ? "evtdpartcp_" : ""
            const defaultPersonBookmarksParams: PersonsDataRequestParams = {
                filterlist: ["entity_pers", favorites.get("person"), personFunc, eventDateParticipation],
                startresultrow: 0,
                numresultrows: 15,
                order: branding.programSpeakers.orderType
            }

            loadPersonsData(defaultPersonBookmarksParams, abortSignal).then((response) => {
                setPersonsBookmarks(response.persons)
            })
        }
        if (favorites.get("organization").length > 0) {
            const defaultShowfloorBookmarkParams: any = {
                filterlist: ["entity_orga", favorites.get("organization")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadExhibitorsData(defaultShowfloorBookmarkParams, abortSignal).then((resp) => {
                setShowfloorsBookmarks(resp.entities as Exhibitor[])
            })
        }
        if (favorites.get("product").length > 0) {
            const defaultProductsBookmarkParams: any = {
                filterlist: ["entity_prod", favorites.get("product")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadProductsData(defaultProductsBookmarkParams, abortSignal).then((resp) => {
                setProductsBookmarks(resp.products)
            })
        }
        if (favorites.get("trademark").length > 0) {
            const defaultTrademarksBookmarkParams: any = {
                filterlist: ["entity_trad", favorites.get("trademark")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadTrademarksData(defaultTrademarksBookmarkParams, abortSignal).then((resp) => {
                setTrademarksBookmarks(resp.trademarks)
            })
        }
        if (favorites.get("news").length > 0) {
            const defaultNewsBookmarkParams: any = {
                filterlist: ["entity_news", favorites.get("news")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadNewsListData(defaultNewsBookmarkParams, abortSignal).then((resp) => {
                setNewsBookmarks(resp.newsList)
            })
        }

        if (favorites.get("joboffer").length > 0) {
            const defaultJoboffersBookmarkParams: any = {
                filterlist: ["entity_job", favorites.get("joboffer")],
                startresultrow: 0,
                numresultrows: 15,
                order: "lexic",
                lang: lang
            }

            loadJobofferListData(defaultJoboffersBookmarkParams, abortSignal).then((resp) => {
                setJoboffersBookmarks(resp.jobofferList)
            })
        }

        getCalendarEntries(
            userState.user()?.profileId!,
            CalendarEntrySortType.FUTURE,
            CalendarEntryParticipationStatus.ACCEPTED,
            undefined
        ).then((resp) => {
            let temp: CalendarEntry[] = []
            if (resp)
                resp.items.forEach((x) => {
                    temp.push(x.calendarEntry)
                })
            setMeetings(temp)
        })

        const sponsoredExhibitorsMyPageList = branding.receptionPage.sponsoredExhibitorsMyPageList
        let sponsoredExhibitorsFilterList: string[] = []

        if (sponsoredExhibitorsMyPageList) {
            sponsoredExhibitorsMyPageList.forEach((orga) => {
                sponsoredExhibitorsFilterList.push("id_orga_" + orga.id)
            })

            let sponsoredExhibitorsParams: any = {
                filterlist: "entity_orga, " + sponsoredExhibitorsFilterList.join(", "),
                startresultrow: 0,
                order: "lexic",
                lang: lang
            }
            loadExhibitorsData(sponsoredExhibitorsParams, abortSignal).then((resp) => {
                const ex = resp.entities as Exhibitor[]
                if (ex) {
                    ex.forEach((exhibitor) => {
                        exhibitor.order = sponsoredExhibitorsMyPageList.find((x) => x.id === exhibitor.id)?.order || -1
                    })

                    setMyPageExhibitorSponsors((e) => orderBy(ex, ["order"], ["asc"]))
                }
            })

            setIsLoaded(true)
        }

        let params = { lang: lang }
        loadEventDateDates(params)
            .then((resp) => {
                if (resp.dates?.length > 0) {
                    setDays(resp.dates.map((date) => moment(date)))
                }
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setDays(eventDays.map((x) => moment(x)))
            })
        setRefreshKey(0)

        return () => {
            abortController.abort()
        }
        // Load the stuff only if we are logged in …
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.eventKey, lang, refreshKey, timezone])

    useEffect(() => {
        setNextPackage(BasisPremiumType.ULTIMATE)
    }, [isLoaded])

    useEffect(() => {
        if (nextPackage === BasisPremiumType.NONE) {
            return
        }
        loadPremiumOrStandardExhibitors(nextPackage)

        //eslint-disable-next-line
    }, [nextPackage])

    useEffect(() => {
        setSelectedDay(getIndexOfInitialDayToShowIgnoreDefault(days))
    }, [days, timezone])

    useEffect(() => {
        if (basisPremiumList.length > 1) {
            loadPremiumOrStandardExhibitors(basisPremiumList[1].packageName, basisPremiumList[1].numberOfExhibitors)
        }

        //eslint-disable-next-line
    }, [isLoaded])

    useEffect(() => {
        let temp: Array<any> = []
        let currentTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).format("HH:mm")
        let [currentHour, currentMinutes] = currentTime.split(":")
        let currentTimeStamp = parseInt(currentHour) * 60 + parseInt(currentMinutes)
        if (eventDates) {
            eventDates.forEach((x) => {
                if (
                    moment(x.dateTimeStart).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") &&
                    x.endHour * 60 + x.endMinutes > currentTimeStamp
                ) {
                    temp.push({
                        type: "eventdate",
                        object: x
                    })
                }
            })
        }

        if (meetings) {
            meetings.forEach((x) => {
                let date = momentWithoutTimezoneFromTimezonedMoment(moment(x.end), timezone).format("HH:mm")
                let [dateHours, dateMinutes] = date.split(":")
                let dateTimeStamp = parseInt(dateHours) * 60 + parseInt(dateMinutes)
                if (
                    moment(x.start).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") &&
                    dateTimeStamp > currentTimeStamp
                ) {
                    temp.push({
                        type: "meeting",
                        object: x
                    })
                }
            })
        }

        setAll(temp)
    }, [eventDates, meetings, props.eventKey]) // eslint-disable-line

    return (
        <Fragment>
            {componentOrder.map((component: MyFairComponent, outerKey: number) => {
                switch (component) {
                    case MyFairComponent.SPONSORS:
                        const allOrgas: Exhibitor[] = []
                        if (myPageExhibitorSponsors) allOrgas.push(...myPageExhibitorSponsors)
                        if (branding.receptionPage.showPremiumExhibitorsWithSponsors && exhibitors) allOrgas.push(...exhibitors)
                        return <EntityCarousel key={outerKey} type="organization" entities={allOrgas} src="BOOKMARK" />
                    case MyFairComponent.UP_NEXT:
                        return (
                            all.length > 0 && (
                                /* Up next section */
                                <TileRow
                                    key={outerKey}
                                    icon={IconSchedule({ fill: branding.sideIconBar.sideIconColorDark })}
                                    iconVisible={true}
                                    title={branding.receptionPage.upNextTitle}
                                    titleVisible={true}
                                    navLink={
                                        branding.receptionPage.crsTabsItemsOrder.includes("MY_SCHEDULE")
                                            ? homePageRoute
                                            : mySchedulePageRoute
                                    }
                                    navLinkTextVisible={true}
                                    navLinkText={branding.receptionPage.viewMySchedule}
                                    scrollComponent={!props.isMobile}
                                    hideShadowsAndScrollButtons={true}
                                    childCount={all.length}
                                    childWidth={props.isMobile ? 205 + 4 : 350 + 10}
                                    customLinkAction={
                                        branding.receptionPage.crsTabsItemsOrder.includes("MY_SCHEDULE")
                                            ? () => props.setEventKey("MY_SCHEDULE")
                                            : undefined
                                    }
                                >
                                    {all.map((eventDate: any) => {
                                        return (
                                            <div
                                                key={eventDate.id}
                                                style={{
                                                    marginRight: props.isMobile ? "0px" : "10px",
                                                    marginBottom: props.isMobile ? "0px" : "1.25rem"
                                                }}
                                            >
                                                <EventDateEntry
                                                    key={eventDate.id}
                                                    eventDate={eventDate.object}
                                                    myScheduleObject={eventDate.object}
                                                    marginTop={10}
                                                    height={280}
                                                    mySchedule={eventDate.object.hasOwnProperty("title")}
                                                    upcoming={true}
                                                    isMobile={props.isMobile}
                                                    source="BOOKMARK"
                                                    isMyFairPage
                                                />
                                            </div>
                                        )
                                    })}
                                </TileRow>
                            )
                        )
                    case MyFairComponent.SPEAKERS:
                        return (
                            <EntityCarousel
                                emptyTileMessage={branding.receptionPage.noBookmarkedSpeakers}
                                type="person"
                                entities={personsBookmarks}
                                linkText={
                                    personsBookmarks.length > 0
                                        ? branding.receptionPage.speakersBookmarkedNavLinkText
                                        : branding.receptionPage.speakersNavLinkText
                                }
                                link={personsBookmarks.length > 0 ? bookmarkedSpeakersPageRoute : speakersPageRoute}
                            />
                        )
                    case MyFairComponent.EXHIBITORS:
                        return (
                            /* Bookmarked exhibitors */
                            <EntityCarousel
                                type="organization"
                                lobby={false}
                                myFair
                                entities={showfloorsBookmarks}
                                emptyTileMessage={branding.receptionPage.noBookmarkedExhibitors}
                                linkText={
                                    showfloorsBookmarks.length > 0
                                        ? branding.receptionPage.showFloorBookmarkedNavLinkText
                                        : branding.receptionPage.showFloorNavLinkText
                                }
                                link={showfloorsBookmarks.length > 0 ? bookmarkedExhibitorsPageRoute : showfloorPageRoute}
                            />
                        )
                    case MyFairComponent.PRODUCTS:
                        return (
                            /* Bookmarked products */
                            <EntityCarousel
                                type="product"
                                lobby={false}
                                myFair
                                entities={productsBookmarks}
                                emptyTileMessage={branding.receptionPage.noBookmarkedProducts}
                                linkText={
                                    productsBookmarks.length > 0
                                        ? branding.receptionPage.productsBookmarkedNavLinkText
                                        : branding.receptionPage.productsNavLinkText
                                }
                                link={productsBookmarks.length > 0 ? bookmarkedProductsPageRoute : productsPageRoute}
                            />
                        )
                    case MyFairComponent.TRADEMARKS:
                        return (
                            /* Bookmarked trademarks */
                            <EntityCarousel
                                type="trademark"
                                lobby={false}
                                myFair
                                entities={trademarksBookmarks}
                                emptyTileMessage={branding.receptionPage.noBookmarkedTrademarks}
                                linkText={
                                    trademarksBookmarks.length > 0
                                        ? branding.receptionPage.trademarksBookmarkedNavLinkText
                                        : branding.receptionPage.trademarksNavLinkText
                                }
                                link={trademarksBookmarks.length > 0 ? bookmarkedTrademarksPageRoute : trademarksPageRoute}
                            />
                        )
                    case MyFairComponent.NEWS:
                        return (
                            /* Bookmarked news */
                            <EntityCarousel
                                type="news"
                                lobby={false}
                                myFair
                                entities={newsBookmarks}
                                emptyTileMessage={branding.receptionPage.noBookmarkedNews}
                                linkText={
                                    newsBookmarks.length > 0
                                        ? branding.receptionPage.newsBookmarkedNavLinkText
                                        : branding.receptionPage.newsNavLinkText
                                }
                                link={newsBookmarks.length > 0 ? bookmarkedNewsPageRoute : exhibitorNewsPageRoute}
                            />
                        )
                    case MyFairComponent.JOBOFFERS:
                        return (
                            /* Bookmarked joboffers */
                            <EntityCarousel
                                type="joboffer"
                                lobby={false}
                                myFair
                                entities={joboffersBookmarks}
                                emptyTileMessage={branding.receptionPage.noBookmarkedJoboffers}
                                linkText={
                                    joboffersBookmarks.length > 0
                                        ? branding.receptionPage.joboffersBookmarkedNavLinkText
                                        : branding.receptionPage.joboffersNavLinkText
                                }
                                link={joboffersBookmarks.length > 0 ? bookmarkedJoboffersPageRoute : joboffersPageRoute}
                            />
                        )
                    default:
                        return null
                }
            })}
        </Fragment>
    )
}

export default MyFairPageContent
