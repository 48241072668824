import { useEffect, useState, Suspense } from "react"
import * as React from "react"
import { useLocation } from "react-router-dom"
import FilterBar from "../../ui/FilterBar"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import CenteredLoader from "../../ui/CenteredLoader"
import { Person } from "../../backendServices/Types"
import { getPrefix, useFavoriteState } from "../../globalStates/Favorites"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import moment from "moment"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import BackendError from "../../ui/BackendError"
import EmptyTile from "../reception/EmptyTile"
import { defaultLogger as logger, useAppState } from "../../globalStates/AppState"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import TopBar from "../../navigationArea/TopBar"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import { ViewMode } from "../../ui/CrsTabs"
import SpeakersTilesLayout from "../../ui/SpeakersTilesLayout"
import SpeakersListLayout from "../../ui/SpeakersListLayout"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import InView from "react-intersection-observer"
import { SearchParameters, SectionType, Sections, fetchDataHelper, sectionOrder } from "../../utils/searchUtils"
import {
    ContentRoot,
    NextPageLoader,
    SectionContentContainer,
    SectionHeaderContainer,
    SectionHeaderTitle,
    SpeakersRoot,
    TabContentContainer
} from "./SpeakersStyledComponents"

/*********************************************************************************************
 * variable definitions
 **********************************************************************************************/

let content: JSX.Element = <div />

interface SpeakersProps {
    addBannerHeight?: number
    onScroll?: Function
    guestBannerHeight?: number
    showOnlyBookmarks?: boolean
    listViewMode: boolean
    showOnlyFavorites?: boolean
}

const Speakers: React.FunctionComponent<SpeakersProps> = (props) => {
    const appState = useAppState()
    const [viewMode, setViewMode] = useState(props.showOnlyBookmarks ? ViewMode.LIST : ViewMode.TILES)
    const [showOnlyBookmarks, setShowOnlyBookmarks] = useState<boolean>(props.showOnlyBookmarks ? true : false)

    const [page, setPage] = useState(0)
    const [sections, setSections] = useState<Sections>({})

    const [isError, setIsError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [alpha, setAlpha] = useState<string | null>(null)
    const location = useLocation()
    const favorites = useFavoriteState()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const lang = useLanguageState().getLanguage()
    const [bookmarkRefreshKey, setBookmarkRefreshKey] = useState(0)
    const favoriteState = useFavoriteState()

    const { useMobileDesign } = useWindowDimensions()

    const adjustedHeightForScrollContainer = useMobileDesign
        ? 110
        : (props.addBannerHeight ?? 0) + (props.guestBannerHeight ?? 0) + 200 // topBanner + guestBanner + other elements above real content (220px)

    /* #region  Helper methods */
    const cleanRemovedFavorites = () => {
        const entityFavoritesFromState: string = favoriteState.get("person")

        const entityFavorites: string[] = entityFavoritesFromState
            .split(",")
            .map((item: string) => item.replace(getPrefix("person") as string, ""))

        const results: Sections = { ...sections }
        for (let sectionKey of sectionOrder) {
            const section = sections[sectionKey]

            if (section) {
                results[sectionKey] = {
                    type: section?.type,
                    count: section?.count - 1,
                    hasMoreData: section?.hasMoreData,
                    entities: section?.entities?.filter((entity) => entityFavorites.includes(entity.id))
                }
            }
        }

        setSections(results)
    }

    const clearResults = () => {
        setSections({})
        setPage(0)
    }

    const handleFilterChange = (alphaKey: string) => {
        if (alphaKey !== alpha) {
            setAlpha(alphaKey)
            clearResults()
        }
    }

    const hasMoreData = () => {
        return Object.values(sections).filter((result) => result && result.hasMoreData).length > 0
    }

    /* #endregion */

    /* #region  useEffects */
    useEffect(() => {
        if (showOnlyBookmarks) {
            cleanRemovedFavorites()
        }
        // eslint-disable-next-line
    }, [favoriteState.get("person")])

    useEffect(() => {
        setViewMode(props.listViewMode ? ViewMode.LIST : ViewMode.TILES)
    }, [props.listViewMode])

    useEffect(() => {
        setShowOnlyBookmarks(props.showOnlyBookmarks ?? props.showOnlyFavorites ?? false)
        clearResults()
        // eslint-disable-next-line
    }, [props.showOnlyFavorites])

    useEffect(() => {
        if (props.showOnlyBookmarks) {
            appState.setCurrentMobileBreadcrumb(branding.programSpeakers.bookmarkedSpeakersTitle)
        }
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        function syncLocalStorage() {
            if (loggedInUserId) {
                syncFavorites({
                    profileId: loggedInUserId,
                    body: {
                        currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // "2020-01-01 12:00:00",
                        lastSyncTime: favorites.getLastSyncTime()
                    }
                })
                    .then((resp) => {
                        favorites.setLastSyncTime(new Date())
                        const temp: Array<any> = resp.content.favorites
                        temp.forEach((item) => {
                            if (!item.deleted && !favorites.is("person", item.id)) {
                                favorites.add("person", item.id)
                            }
                        })
                        setBookmarkRefreshKey(1)
                    })
                    .catch((error: { message: React.SetStateAction<string> }) => {
                        logger.error({ message: "Speakers Fav sync failed", errorMessage: error.message })
                    })
            }
        }
        syncLocalStorage()
        //eslint-disable-next-line
    }, [location, loggedInUserId])

    const refreshKey = [page, showOnlyBookmarks, bookmarkRefreshKey, lang, alpha].join("_")

    useEffect(() => {
        const speakerPersonFunctions = branding.configuration.speakerPersonFunctions
            .map((personFunction) => `personfunc_${personFunction}`)
            .join(", ")

        const eventDateParticipation = branding.configuration.eventDateParticipation

        let searchParams: SearchParameters = {
            page: page,
            order: branding.programSpeakers.orderType,
            alpha: alpha,
            showOnlyBookmarks: showOnlyBookmarks,
            entityTypes: ["person"],
            searchEntities: [],
            favorites: favoriteState.get("person"),
            speakerPersonFunctions: speakerPersonFunctions,
            eventDateParticipation: eventDateParticipation,
            searchValue: ""
        }

        setIsLoading(true)
        fetchDataHelper(searchParams, sections)
            .then((resp) => {
                setIsLoading(false)
                const results: Sections = { ...sections }

                for (let sectionKey of sectionOrder) {
                    const oldSection = sections[sectionKey]
                    const newSection = resp[sectionKey]

                    if (!newSection || newSection.count === 0) continue

                    results[sectionKey] = {
                        type: newSection.type,
                        count: newSection.count,
                        hasMoreData: newSection.hasMoreData,
                        entities:
                            oldSection?.entities && oldSection.entities.length > 0 && page !== 0
                                ? oldSection?.entities.concat(newSection.entities)
                                : newSection.entities
                    }
                }
                setSections(results)
            })
            .catch((err) => {
                setIsLoading(false)
                setIsError(err)
            })
        // eslint-disable-next-line
    }, [refreshKey])

    let totalCount = 0
    Object.values(sections).forEach((result) => {
        if (result) totalCount += result.count
    })

    if (isError) {
        content = (
            <div style={{ marginTop: "20%" }}>
                <BackendError />
            </div>
        )
    } else if (page === 0 && isLoading) {
        content = (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    } else if (totalCount === 0 && showOnlyBookmarks && !isLoading) {
        content = (
            <div style={{ marginTop: "12%" }}>
                <EmptyTile header={branding.programSpeakers.noBookmarkedSpeakers} bgColor="transparent" hideButton />
            </div>
        )
    } else if (totalCount > 0) {
        content = (
            <>
                {
                    <ContentRoot id="customScrollbarSpeakers">
                        <ContentScrollContainer
                            adjustForHeaderWith={`${adjustedHeightForScrollContainer}px`}
                            handleScroll={(e: any) => {
                                if (props.onScroll) {
                                    props.onScroll({
                                        clientHeight: (e as { clientHeight: number }).clientHeight,
                                        contentScrollHeight: (e as { scrollHeight: number }).scrollHeight,
                                        scrollTop: (e as { scrollTop: number }).scrollTop
                                    })
                                }
                            }}
                        >
                            <TabContent sections={sections} viewMode={viewMode} />
                            {hasMoreData() && (
                                <InView
                                    threshold={0.1}
                                    onChange={(inView) => {
                                        if (inView) {
                                            setPage(page + 1)
                                        }
                                    }}
                                >
                                    <NextPageLoader />
                                </InView>
                            )}
                        </ContentScrollContainer>
                    </ContentRoot>
                }
            </>
        )
    }

    const locations = calcBreadcrumbLocations()
    const breadcrumb: any[] = [
        {
            to: "/",
            name: branding.sideIconBar.lobbyMenuText
        }
    ]
    breadcrumb.push(
        { to: "/", name: branding.receptionPage.receptionPageMyHeaderTitle },
        { to: locations[1], name: branding.programSpeakers.bookmarkedSpeakersTitle }
    )

    return (
        <div style={{ background: "#fff" }}>
            {props.showOnlyBookmarks && (
                <>
                    <TopBar />
                    <Breadcrumb breadcrumb={breadcrumb} pageCategory="List" />
                    <FilterBar activeFilter={alpha} onFilterChanged={handleFilterChange} showTopBorder />
                </>
            )}
            <SpeakersRoot>
                <Suspense fallback={<NextPageLoader />}>
                    <div>{content}</div>
                </Suspense>
            </SpeakersRoot>
        </div>
    )
}

interface TabContentProps {
    sections: Sections
    viewMode: ViewMode
}

const TabContent: React.FunctionComponent<TabContentProps> = (props: TabContentProps) => {
    const { isMobile } = useWindowDimensions()
    const appState = useAppState()

    const numberOfSections: number = Object.keys(props.sections).length

    function getTitle(sectionType: SectionType) {
        switch (sectionType) {
            case SectionType.TOP:
                return branding.programSpeakers.sectionTitleTopSpeakers
            case SectionType.ALL:
                return branding.programSpeakers.sectionTitleAllSpeakers
            default:
                return ""
        }
    }

    return (
        <TabContentContainer currentPage={appState.currentItem}>
            {sectionOrder.map((sectionType, key) => {
                const section = props.sections[sectionType]!

                if (!section || (section && section.count === 0)) return null

                const title: string = getTitle(section.type)
                const speakersList: Person[] = section.entities.map((entity: any) => entity as Person)

                return (
                    <div key={key}>
                        {numberOfSections > 1 && (
                            <SectionHeaderContainer
                                style={{ marginTop: isMobile ? (sectionType === SectionType.TOP ? "0" : "25px") : "0" }}
                            >
                                <SectionHeaderTitle>{title}</SectionHeaderTitle>
                            </SectionHeaderContainer>
                        )}
                        <SectionContent speakersList={speakersList} viewMode={props.viewMode} />
                    </div>
                )
            })}
        </TabContentContainer>
    )
}

interface SectionContentProps {
    speakersList: Person[]
    viewMode: ViewMode
}

const SectionContent: React.FunctionComponent<SectionContentProps> = (props: SectionContentProps) => {
    return (
        <SectionContentContainer>
            {props.viewMode === ViewMode.TILES && props.speakersList && (
                <SpeakersTilesLayout speakersList={props.speakersList} componentType={"person"} />
            )}
            {props.viewMode === ViewMode.LIST && props.speakersList && <SpeakersListLayout speakersList={props.speakersList} />}
        </SectionContentContainer>
    )
}

export default Speakers
