import { createState, State, useState } from "@hookstate/core"
import { chimeSdk } from "../ChimeSdkWrapper"
import { DataMessageType } from "../enums/DataMessageType"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface ModContext {
    modKick: (attendeeId: string, reason: string) => void
    modBan: (attendeeId: string, reason: string) => void
    modMute: (attendeeId: string) => void
    modStopContentShare: (attendeeId: string) => void
    isMod: () => boolean
}

const createStateWrapper = (state: State<StateValues>) => {
    return {
        modKick(attendeeId: string, reason: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.KICK, data: reason })
        },
        modBan(attendeeId: string, reason: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.BAN, data: reason })
        },
        modMute(attendeeId: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.MUTE })
        },
        modStopContentShare(attendeeId: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.STOP_SCREENSHARE })
        },
        isMod: () => {
            return chimeSdk.userRole === "moderator"
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useModContext = (): ModContext => createStateWrapper(useState(state))
