import styled from "styled-components"
import branding from "../../../branding/branding"
import { device } from "../../../utils/Device"
import { Col } from "react-bootstrap"

export const EventLogoDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    width: 150px;
    height: 150px;
    padding: 20px;
    background: #fff;

    @media (max-width: 1600px) {
        width: 120px;
        height: 120px;
        padding: 25px;
    }

    @media (max-width: 1400px) {
        width: 100px;
        height: 100px;
        padding: 15px;
    }

    @media (max-width: 1200px) {
        width: 80px;
        height: 80px;
        padding: 10px;
    }

    @media ${device.mobile} {
        width: 95px;
        height: 95px;
        padding: 10px;
        margin-left: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
`
export const EventLogo = styled.img`
    object-fit: contain;
    width: 100%;
    height: 100%;
`
export const LocationCol = styled(Col)`
    display: flex;
    width: 100%;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const EventLocation = styled.div`
    width: 85%;
    height: auto;
    line-height: 12px;
    /* margin-left: 15px; */
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 1px;

    @media ${device.mobile} {
        text-transform: none;
        font-size: 16px;
        line-height: 24px;
    }
`
export const EventFormattedDate = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    width: auto;
    line-height: 30px;
`

export const SectionRoot = styled.div<{ marginTop?: string }>`
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: ${(props) => props.marginTop ?? "0px"};

    border-radius: 5px;
    padding-bottom: 10px;

    &.event {
        margin: 0 20px 10px 20px;
    }
`

export const SectionTopButton = styled.div`
    position: absolute;
    right: 3rem;
    margin-top: -30px;
    cursor: pointer;
`

export const EventLocationRoot = styled.div`
    position: relative;
    width: 80%;
    height: 20px;
    margin-left: 10px;
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
`

export const EventLocationContainer = styled.div`
    margin: 0;
    position: absolute;
    margin-top: 7px;
`

export const SpacingCol = styled(Col)`
    @media ${device.mobile} {
        display: none;
    }
`

export const BasicInfoDescription = styled.div`
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;
    font-family: ${branding.font1};
`

export const BasicInfoDescriptionTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const BasicInfoDescriptionText = styled.div`
    font-size: 14px;
`

export const ShowMoreButton = styled.div`
    font-family: ${branding.font1};
    display: inline-flex;
    font-size: 14px;
    margin-top: 15px;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    height: 40px;
`
