import styled from "styled-components"
import branding from "../branding/branding"
import { IconChevronLeft, IconChevronLeftTemplate, IconChevronRight, IconChevronRightTemplate } from "./Icons"
import useWindowDimensions from "./WindowDimensionsHook"

interface SliderControlerProps {
    isVisible?: boolean
    slide: (isLeft?: boolean) => void
    isOrgaDetail?: boolean
}

export const SliderControllLeft: React.FunctionComponent<SliderControlerProps> = (props: SliderControlerProps) => {
    const { useMobileDesign } = useWindowDimensions()

    return (
        <>
            {props.isVisible ? (
                <SliderControlLeft isOrgaDetail={props.isOrgaDetail}>
                    {useMobileDesign ? (
                        <SliderControllAction onTouchStartCapture={() => props.slide(true)}>
                            {IconChevronLeft({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "15",
                                height: "15"
                            })}
                        </SliderControllAction>
                    ) : (
                        <SliderControllAction onClick={() => props.slide(true)}>
                            {props.isOrgaDetail
                                ? IconChevronLeftTemplate({
                                      fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                      width: "16",
                                      height: "28"
                                  })
                                : IconChevronLeft({
                                      fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                      width: "15",
                                      height: "15"
                                  })}
                        </SliderControllAction>
                    )}
                </SliderControlLeft>
            ) : null}
        </>
    )
}

export const SliderControllRight: React.FunctionComponent<SliderControlerProps> = (props: SliderControlerProps) => {
    const { useMobileDesign } = useWindowDimensions()

    return (
        <>
            {props.isVisible ? (
                <SliderControlRight isOrgaDetail={props.isOrgaDetail}>
                    {useMobileDesign ? (
                        <SliderControllAction onTouchStartCapture={() => props.slide(false)}>
                            {IconChevronRight({
                                fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                width: "15",
                                height: "15"
                            })}
                        </SliderControllAction>
                    ) : (
                        <SliderControllAction onClick={() => props.slide(false)}>
                            {props.isOrgaDetail
                                ? IconChevronRightTemplate({
                                      fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                      width: "16",
                                      height: "28"
                                  })
                                : IconChevronRight({
                                      fill: branding.crsTabs.tabItemDefaultActiveStateColor,
                                      width: "15",
                                      height: "15"
                                  })}
                        </SliderControllAction>
                    )}
                </SliderControlRight>
            ) : null}
        </>
    )
}

const SliderControlRoot = styled.div<{ isOrgaDetail?: boolean }>`
    background-color: #fff;
    position: absolute;
    top: ${(props) => (props.isOrgaDetail ? "65px" : "0")};
    bottom: ${(props) => (props.isOrgaDetail ? "20px" : "0")};
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
`

const SliderControlLeft = styled(SliderControlRoot)<{ isOrgaDetail?: boolean }>`
    left: ${(props) => (props.isOrgaDetail ? "-30px" : "-1px")};
`

const SliderControlRight = styled(SliderControlRoot)<{ isOrgaDetail?: boolean }>`
    right: ${(props) => (props.isOrgaDetail ? "-30px" : "-1px")};
`

const SliderControllAction = styled.div`
    cursor: pointer;
`
