import { createState, State, useState } from "@hookstate/core"
import { useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js"
import { ChimeMeetingData, createOrJoinMeeting, leaveRoom } from "../../backendServices/MeetingServices"

interface StateValues {
    meetingCheckId: string | null
    userId: string | null
    isCheckActive: boolean
}

const getStartValues = (): StateValues => {
    return {
        meetingCheckId: null,
        userId: null,
        isCheckActive: false
    }
}

const state = createState<StateValues>(getStartValues())

export interface MeetingReadinessCheckContext {
    startCheck: (meetingCheckId: string, userId: string) => Promise<void>
    stopCheck: () => Promise<void>
    getIsCheckActive: () => boolean
}

const useStateWrapper = (state: State<StateValues>): MeetingReadinessCheckContext => {
    const meetingManager = useMeetingManager()
    return {
        startCheck: async (meetingCheckId: string, userId: string) => {
            state.merge({ meetingCheckId, userId, isCheckActive: true })
            try {
                let backendResponse = (await createOrJoinMeeting(meetingCheckId, userId)) as ChimeMeetingData
                if (backendResponse.chime && backendResponse.attendee && backendResponse.meeting) {
                    const meetingSessionConfiguration = new MeetingSessionConfiguration(
                        backendResponse.chime.Meeting,
                        backendResponse.chime.Attendee
                    )

                    await meetingManager.join(meetingSessionConfiguration)
                }
            } catch (error: any) {
                console.log("Something went wrong with createOrJoinMeetng in MeetingReadinessCheckContext ", error)
            }
        },
        stopCheck: async () => {
            await Promise.all([
                await leaveRoom(state.value.meetingCheckId || "", state.value.userId || ""),
                await meetingManager.leave()
            ]).then(() => {
                state.merge({ meetingCheckId: null, userId: null, isCheckActive: false })
            })
        },
        getIsCheckActive: () => {
            return state.value.isCheckActive
        }
    }
}
/**
 *
 * This is a meeting readyness check or better said a fake meeting initializer.
 * There is no possibility to have a video stream before entering a meeting, so
 * i implemented it in this way. So if there is no meeting acitve and the user
 * opens the settings, there will be a fake meeting generated in the background.
 * I chose to use the cl_ as prefix and added the MEETING-READINESS-CHECK words
 * aswell as the users id as the external meeting id so we are 100% sure the
 * meeting id is unique and nobody will meet each other. Also, the meeting is only
 * initialized and not started, so there is no possibility there will a real meeting
 * happen. This implementation is inspired by the official aws chime-sdk development
 * team as mentioned here: https://github.com/aws/amazon-chime-sdk-js/issues/923, also
 * i took this demo project as reference https://github.com/aws/amazon-chime-sdk-js/
 * and it helped me a lot during development.
 */
export const useMeetingReadinessCheckContext = (): MeetingReadinessCheckContext => useStateWrapper(useState(state))
