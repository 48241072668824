import { GroupConfigType } from "./../backendServices/SuggestionServices"
import { EntityType, Entity } from "./../backendServices/Types"
import { backOff } from "exponential-backoff"
import { defaultLogger as logger } from "../globalStates/AppState"
import branding from "../branding/branding"
import { ExhibitorListRequestParameter, loadExhibitorsData } from "../backendServices/ExhibitorServices"
import { SearchEntityConfig } from "../contentArea/ContentAreaPageBranding"

export const ALL_ENTITY_TYPES: EntityType[] = [
    "product",
    "trademark",
    "eventdate",
    "news",
    "networking_user",
    "organization",
    "coupon",
    "person",
    "joboffer"
]

const pageSize = 27

/* #region Interfaces */
export enum SectionType {
    TOP = "top",
    ALL = "all"
}
export const sectionOrder = [SectionType.TOP, SectionType.ALL]

export interface Section {
    type: SectionType
    count: number
    entities: Entity[]
    hasMoreData: boolean
}

export type Sections = {
    [key in SectionType]?: Section
}

export interface SearchParameters {
    /**
     * Used for pagination. Represents page number when fetching results from the server.
     */
    page: number

    /**
     * Order kind which defines sort type for the results.
     * Possible values: lexic (default), lexicAlt, chrono, hall, random, basispremium, totl, relevance.
     * @link https://corussoft.atlassian.net/wiki/spaces/MGD/pages/9140928652/Search
     */
    order: string

    /**
     * Represents the start letter of the entities which needs to be searched.
     */
    alpha: string | null

    /**
     * Boolean value which defines will we show all or only bookmarked (favorites) entities.
     */
    showOnlyBookmarks: boolean

    /**
     * Text which is used for searching entities.
     */
    searchValue?: string

    /**
     * Boolean value which defines are all search entities checkboxes empty or not.
     * If this value is true, then all entity types are included in the global search.
     */
    emptyCheckBoxes?: boolean

    /**
     * List of entity types which are selected and will be searched.
     */
    entityTypes: EntityType[]

    /**
     * List of search entities which are selected and will be searched.
     */
    searchEntities: SearchEntityConfig[]

    /**
     * Contains merged list of favorites for selected entity groups.
     */
    favorites: string

    /**
     * List of category ids which will be used to search only entities connected to these categories.
     */
    categoryFilter?: string

    /**
     * Search will only return products of this type (category)
     */
    productTypeFilter?: string

    /**
     * Search will only return news of this type
     */
    newsTypeFilter?: string

    /**
     * List of category ids which will be used to search only entities connected to these categories.
     */
    dropdownFilterParams?: any

    /**
     * Used on organizations.
     * Valid basispremium value (0-5).
     * Reduces organization results to those with the given basispremium value
     */
    basispremium?: number

    /**
     * Boolean value which defines sort type for News.
     * If this is set to true then News will be sorted in descending order, by the News date field.
     */
    newsDesc?: boolean

    /**
     * Search will only return persons with person functions (list defined in branding) which identify them as a speaker
     */
    speakerPersonFunctions?: string

    /**
     * Search will only return persons which have bindings to eventdates
     */
    eventDateParticipation?: boolean
}
/* #endregion */

/* #region  Helper methods */
const getEntityFilterName = (entityType: EntityType | "all") => {
    switch (entityType) {
        case "product":
            return "entity_prod"
        case "trademark":
            return "entity_trad"
        case "news":
            return "entity_news"
        case "eventdate":
            return "entity_evtd"
        case "networking_user":
            return "entity_sotu"
        case "coupon":
            return "entity_coup"
        case "person":
            return "entity_pers"
        case "joboffer":
            return "entity_job"
        default:
            return "entity_orga"
    }
}

const filterListWithCategories = (filterList: string[], searchParams: SearchParameters): string[] => {
    let extendedFilterList = Array.from(filterList)
    let categories: string[] = []

    for (let i = 0; i < searchParams.searchEntities.length; i++) {
        const x = searchParams.searchEntities[i]
        if (x.categories.length === 0) {
            categories = []
            break
        }
        categories = [...categories, ...x.categories]
    }

    if (searchParams.searchEntities.length > 0 && searchParams.entityTypes.length === 1 && categories.length > 0) {
        categories = [...new Set(categories)]
        extendedFilterList.push(categories.map((cat) => `cat_${cat}$OR`).join(","))
    } else {
        extendedFilterList = extendedFilterList.filter((filter) => !filter.startsWith("cat_"))
    }

    return extendedFilterList
}

const filterListWithAdditionalParams = (filterList: string[], searchParams: SearchParameters): string[] => {
    let extendedFilterList = Array.from(filterList)
    let additionalFilterParams: string[] = []

    for (let i = 0; i < searchParams.searchEntities.length; i++) {
        const x = searchParams.searchEntities[i]
        if (x.additionalFilterParams.length === 0) {
            additionalFilterParams = []
            break
        }
        additionalFilterParams = [...additionalFilterParams, ...x.additionalFilterParams]
    }

    if (searchParams.searchEntities.length > 0 && searchParams.entityTypes.length === 1 && additionalFilterParams.length > 0) {
        additionalFilterParams = [...new Set(additionalFilterParams)]
        extendedFilterList.push(additionalFilterParams.map((param) => param).join(","))
    } else {
        extendedFilterList = extendedFilterList.filter((filter) => !filter.startsWith("newstype_"))
    }

    return extendedFilterList
}

function getRequestParams(
    sectionType: SectionType,
    searchParams: SearchParameters,
    isGlobalSearchPage?: boolean,
    external?: boolean
): ExhibitorListRequestParameter {
    let filterList: string[] = []
    const onlyNews = searchParams.entityTypes.includes("news") && searchParams.entityTypes.length === 1
    let order = "lexic"
    if (sectionType === SectionType.TOP) {
        filterList.push("featured_")
        order = "totl" //order entities by totl value and then alphabetically
    } else {
        order = onlyNews ? "chrono" : "lexic"
    }

    let entityFilter = ""
    if (searchParams.showOnlyBookmarks && searchParams.favorites) entityFilter = searchParams.favorites
    else {
        const entityTypes =
            searchParams.entityTypes.length === 0 && isGlobalSearchPage ? ALL_ENTITY_TYPES : searchParams.entityTypes
        const filteredEntityTypes = isGlobalSearchPage
            ? entityTypes.filter((x) => branding.globalSearchResultPage.searchEntities.find((y) => y.entityType === x))
            : entityTypes
        entityFilter = filteredEntityTypes.map((x) => getEntityFilterName(x)).join(", ")
    }

    filterList.push(entityFilter)

    if (onlyNews && searchParams.newsTypeFilter === undefined) filterList.push("newskind_orga") // filter for news which have reference to an organization

    // If there is an list of categories specified for some search entity group then we want to include them in the filterlist
    // (only when there is exactly one entityType). When multiple entity types are searched at the same time, we will ignore categories.
    if (isGlobalSearchPage && !external) {
        filterList = filterListWithCategories(filterList, searchParams)
        filterList = filterListWithAdditionalParams(filterList, searchParams)
    }

    if (searchParams.categoryFilter) {
        filterList.push(searchParams.categoryFilter)
    }

    if (searchParams.productTypeFilter) {
        filterList.push(searchParams.productTypeFilter)
    }

    if (searchParams.newsTypeFilter) {
        filterList.push(searchParams.newsTypeFilter)
    }

    if (searchParams.speakerPersonFunctions) {
        filterList.push(searchParams.speakerPersonFunctions)
    }

    if (searchParams.eventDateParticipation !== undefined && searchParams.eventDateParticipation === true) {
        filterList.push("evtdpartcp_")
    }

    if (searchParams.searchValue) {
        const parsedSearchValueArray = searchParams.searchValue.split(",")
        parsedSearchValueArray.forEach((value) => {
            const parsedSearchValue =
                !value.startsWith("cat_") && !value.startsWith("prodtype_") && !value.startsWith("country_")
                    ? value.replaceAll("_", "\\_")
                    : value
            filterList.push(parsedSearchValue)
        })
    }

    if (searchParams.hasOwnProperty("dropdownFilterParams")) {
        searchParams.dropdownFilterParams.forEach((el: any) => {
            if (el.type === GroupConfigType.cat && el.alias) {
                filterList.push("cat_" + el.alias)
            }

            if (el.type === GroupConfigType.hall && el.alias) {
                filterList.push("hall_" + el.alias)
            }

            if (el.type === GroupConfigType.country && el.alias) {
                filterList.push("country_" + el.alias)
            }

            if (el.type === GroupConfigType.city && el.alias) {
                filterList.push("city_" + el.alias)
            }

            if (el.type === GroupConfigType.employment_type && el.alias) {
                filterList.push("empltype_" + el.alias)
            }

            if (el.type === GroupConfigType.job_location && el.alias) {
                filterList.push("jobloc_" + el.alias)
            }

            if (el.type === GroupConfigType.prodtype && el.alias) {
                filterList.push("prodtype_" + el.alias)
            }
        })
    }

    const requestParams: ExhibitorListRequestParameter = {
        numresultrows: pageSize,
        startresultrow: searchParams.page * pageSize,
        filterlist: filterList,
        order: order,
        desc: sectionType !== SectionType.TOP && onlyNews && searchParams.newsDesc
    }
    if (searchParams.alpha) requestParams.alpha = searchParams.alpha
    if (searchParams.basispremium) requestParams.basispremium = searchParams.basispremium
    return requestParams
}

export const fetchDataHelper = async (
    searchParams: SearchParameters,
    sections: Sections,
    isGlobalSearchPage?: boolean,
    external?: boolean
) => {
    // Only work with existing results if we are on a next page
    const existingSections = searchParams.page > 0 ? sections : {}

    // List of data loaders for the different sections
    const loadSection = []
    for (let sectionType of sectionOrder) {
        // We don't want totl results for categories, coupons and joboffer
        if (
            sectionType === SectionType.TOP &&
            searchParams.entityTypes.includes("category") &&
            searchParams.entityTypes.includes("coupon") &&
            searchParams.entityTypes.includes("joboffer") &&
            searchParams.entityTypes.length === 2
        )
            continue

        if (searchParams.showOnlyBookmarks && !searchParams.favorites) {
            loadSection.push({
                type: sectionType,
                count: 0,
                hasMoreData: false,
                entities: []
            })
        }
        // only load data if we either do not have results, or we have more incoming
        else if (!existingSections[sectionType] || existingSections[sectionType]?.hasMoreData) {
            const requestParams = getRequestParams(sectionType, searchParams, isGlobalSearchPage, external)
            loadSection.push(loadExhibitors(sectionType, requestParams))
        }
    }

    // If there is no section to load we can end here
    if (loadSection.length === 0) return {}

    // wait for all requests
    const loadedSections = await Promise.all(loadSection)

    // handle request results
    const newSections: Sections = {}
    for (let sectionType of sectionOrder) {
        let matchedSection: Section | null = null
        for (let loadedSection of loadedSections) {
            if (loadedSection.type === sectionType) {
                matchedSection = loadedSection
                break
            }
        }
        // No new data loaded. Use the existing one
        if (!matchedSection) {
            continue
        }

        // entirely new results.
        newSections[sectionType] = {
            type: sectionType,
            count: matchedSection.count,
            entities: matchedSection.entities,
            hasMoreData: matchedSection.hasMoreData
        }
    }

    return newSections
}

// actual data loading
async function loadExhibitors(sectionType: SectionType, requestParams: ExhibitorListRequestParameter): Promise<Section> {
    const resp = await backOff(() => loadExhibitorsData(requestParams), {
        retry: (error: any, attemptNumber: number) => {
            logger.error({
                message: "ExhibitorsPageContent loadExhibitors attempt " + attemptNumber + " failed.",
                errorMessage: error.message,
                errorStack: error.stack
            })
            return true
        }
    })

    return {
        type: sectionType,
        count: resp.count,
        entities: resp.entities,
        hasMoreData: resp.count > requestParams.startresultrow + pageSize
    }
}
/* #endregion */
