import { momentWithoutTimezoneFromTimezonedMoment } from "../../utils/DateUtils"
/**
This function calculates the difference in milliseconds between the current date/time, 
adjusted to the provided timezone, and the specified event end date.
@param {string} currentEventEndDate The end date/time of the event, provided as a string.
@param {any} timezone The timezone to adjust the current date/time to. 
This can be any valid timezone string recognized by Moment.js.
@returns {number} The difference in milliseconds between the current date/time adjusted to the provided timezone,
 and the specified event end date.
*/
export function calculateEnd(currentEventEndDate: string, timezone: any) {
    return moment(moment(currentEventEndDate)).diff(momentWithoutTimezoneFromTimezonedMoment(moment(), timezone))
}
