import { Suggestion } from "../backendServices/Types"
import { useFavoriteState } from "../globalStates/Favorites"
import { createState, State, useState, Downgraded } from "@hookstate/core"
import { fetchDataHelper, SearchParameters, Sections, sectionOrder, Section } from "../utils/searchUtils"
import { Persistence } from "@hookstate/persistence"
import { SearchEntityConfig } from "./ContentAreaPageBranding"
import branding from "../branding/branding"

const localStorageKey = "global-search-state"

/**
 * This method calculates and returns list of entity types and list of search entities.
 * It is called whenever suggestions are updated.
 *
 * Empty lists are returned in case when we want to search for all entity types.
 * When showMore param is true, then we want to search only group for which "showMore" is clicked.
 * In other cases, we fill the lists according to the suggestions array.
 * @param suggestions - represents list of currently active suggestions
 * @param showMoreEntities - if true showMore suggestion is clicked
 * @param external - if true then we know that suggestion comes from other place  (collections, categories...), not from the suggest box
 * @returns object which contains 2 lists: entity types and search entities
 */
const getEntityTypesAndSearchEntities = (suggestions: Suggestion[], showMoreEntities?: boolean, external?: boolean) => {
    let entityTypes = state.searchParams.entityTypes.value
    let searchEntities = state.searchParams.searchEntities.value

    const emptySuggestions = (suggestions.length === 0 ||
        suggestions.find((s) => s.type === null || s.type === undefined)) as boolean

    if (emptySuggestions) {
        entityTypes = []
        searchEntities = []
    } else if (showMoreEntities) {
        const showMoreSuggestion = suggestions[suggestions.length - 1]
        if (showMoreSuggestion) {
            entityTypes = [showMoreSuggestion.type!]
            const searchEntity = branding.globalSearchResultPage.searchEntities.find(
                (x) => x.suggestionGroupId === showMoreSuggestion.suggestionGroupId
            )
            searchEntities = searchEntity ? [searchEntity] : []
        }
    } else if (state.searchParams.entityTypes.length === 0) {
        entityTypes = suggestions.filter((s) => s.type).map((s) => s.type!)
    } else if (external) {
        const searchEntity = branding.globalSearchResultPage.searchEntities.find(
            (x) => x.entityType === suggestions[suggestions.length - 1].type
        )
        if (searchEntity) {
            entityTypes = [searchEntity.entityType]
            searchEntities = [searchEntity]
        }
    }

    return {
        entityTypes: entityTypes,
        searchEntities: searchEntities
    }
}

interface StateValues {
    searchParams: SearchParameters
    sections: Sections
    isLoading: boolean
    isInit: boolean
}

const useWrapState = (state: State<StateValues>) => {
    const favoriteState = useFavoriteState()
    const results = { ...state.sections.value }
    state.attach(Downgraded)
    state.attach(Persistence(localStorageKey))

    const mergeFavorites = () => {
        let allFavorites: string[] = []
        state.searchParams.entityTypes.forEach((type: any) => {
            let favoritesType = type.value
            if (type.value === "networking_user") favoritesType = "sotuser"

            allFavorites.push(favoriteState.get(favoritesType))
        })
        return allFavorites.join(",")
    }

    const fetchData = async (searchParams: SearchParameters, external?: boolean) => {
        // 1. If there are no favorites and we only want to see those, then we don't need to load anything.
        // 2. we don't need bookmarked coupons and categories
        // 3. If there are no selected entity type, we can return empty sections
        if (searchParams.showOnlyBookmarks && !searchParams.favorites) {
            state.sections.set({})
            return
        }

        // set isLoading to true to be able to display loader
        state.set((prev) => {
            prev.isLoading = true
            return prev
        })

        fetchDataHelper(searchParams, state.sections.value, true, external).then((resp) => {
            state.batch((s) => {
                s.set((prev) => {
                    prev.isLoading = false
                    prev.isInit = true
                    return prev
                })
                for (let sectionKey of sectionOrder) {
                    const oldSection = s.sections[sectionKey] as State<Section>
                    const newSection = resp[sectionKey]

                    if (!newSection || newSection.count === 0) continue

                    oldSection.merge({
                        type: newSection.type,
                        count: newSection.count,
                        hasMoreData: newSection.hasMoreData
                    })

                    if (oldSection.entities.value && oldSection.entities.value.length > 0 && state.searchParams.page.value !== 0)
                        oldSection.entities.merge(newSection.entities)
                    else oldSection.entities.set(newSection.entities)
                }
            })
        })
    }

    return {
        searchParams: state.searchParams.value,
        results: state.sections.value,
        isLoading: state.isLoading.value,
        searchFunctions: {
            nextPage: () => {
                state.searchParams.set((prev) => {
                    prev.page = state.searchParams.value.page + 1
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            setBookmarksOnly: (showOnlyBookmarks: boolean) => {
                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.showOnlyBookmarks = showOnlyBookmarks
                    prev.searchParams.favorites = mergeFavorites()
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            },
            setSearchEntities: (entities: SearchEntityConfig[]) => {
                const entitiesNew = entities.map((e) => {
                    return {
                        ...e,
                        categories: branding.globalSearchResultPage.searchEntities.find((x) => x.id === e.id)?.categories ?? [],
                        additionalFilterParams:
                            branding.globalSearchResultPage.searchEntities.find((x) => x.id === e.id)?.additionalFilterParams ??
                            []
                    }
                })
                state.batch((s) => {
                    s.set((prev) => {
                        prev.searchParams.page = 0
                        prev.searchParams.entityTypes = [...new Set(entities.map((e) => e.entityType))]
                        prev.searchParams.searchEntities = entitiesNew
                        prev.searchParams.favorites = mergeFavorites()
                        prev.searchParams.emptyCheckBoxes = prev.searchParams.searchEntities.length === 0
                        prev.searchParams.newsTypeFilter = ""
                        prev.sections = {}
                        return prev
                    })
                })

                fetchData(state.searchParams.value)
            },
            setSuggestions: (suggestions: Suggestion[], showMoreEntities?: boolean, external?: boolean) => {
                const suggestionsConfig = getEntityTypesAndSearchEntities(suggestions, showMoreEntities, external)
                const entityTypes = suggestionsConfig.entityTypes
                const searchEntities = suggestionsConfig.searchEntities
                const searchValues = suggestions
                    .map((suggestion) => {
                        if (suggestion.active) return suggestion.value
                        return null
                    })
                    .join(",")

                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.searchValue = searchValues
                    prev.searchParams.entityTypes = entityTypes
                    prev.searchParams.searchEntities = searchEntities.map((searchEntity) => (searchEntity as any).jsonData)
                    prev.searchParams.emptyCheckBoxes = prev.searchParams.entityTypes.length === 0
                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value, external)
            },
            hasMoreData: () => {
                if (!state.isInit.value) return false
                return Object.values(results).filter((result) => result && result.hasMoreData).length > 0
            },
            reset: () => {
                state.set((prev) => {
                    prev.searchParams.page = 0
                    prev.searchParams.emptyCheckBoxes = true
                    prev.searchParams.entityTypes = []
                    prev.searchParams.searchEntities = []

                    prev.sections = {}
                    return prev
                })
                fetchData(state.searchParams.value)
            }
        }
    }
}

const state = createState<StateValues>({
    isInit: false,
    isLoading: false,
    sections: {} as Sections,
    searchParams: {
        page: 0,
        order: "lexic",
        alpha: null,
        showOnlyBookmarks: false,
        emptyCheckBoxes: true,
        entityTypes: [],
        searchEntities: [],
        categoryFilter: undefined,
        favorites: "",
        searchValue: ""
    }
})

export const useSearchContext = () => useWrapState(useState(state))
