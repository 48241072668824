import styled from "styled-components"

export const RosterAttendeeClosedRoot = styled.div`
    position: relative;
    color: #fff;
    margin-bottom: 10px;

    .activity {
        width: 22px;
        display: inline-block;

        .ch-bg-volume-wrapper {
            width: 21%;
            left: 36%;
            top: 36%;
        }
        .ch-bg-volume-fill {
            background-color: #5db130;
        }
    }
`
interface IconPositions {
    top: string
    left: string
}
export const RosterAttendeeClosedIcon = styled.div<{ position: IconPositions }>`
    position: absolute;
    top: ${(props) => (props.position.top ? props.position.top : 0)};
    left: ${(props) => (props.position.left ? props.position.left : 0)};
    z-index: 15;
    width: 18px;
    height: 18px;
`
