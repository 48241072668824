import styled from "styled-components"

const handleGoLiveButtonBackground = (disabled?: boolean, isLive?: boolean) => {
    if (disabled) {
        return "#aa050e"
    }

    return "#e30613"
}

export const GoLiveButtonRoot = styled.div<{ disabled?: boolean; isLive?: boolean }>`
    background-color: ${(props) => handleGoLiveButtonBackground(props.disabled, props.isLive)};
    border-radius: 3px;
    color: #fff;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: normal;
    padding: 8px 20px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    animation: ${(props) => (props.isLive ? "liveAnimation 2s infinite ease-in-out" : "none")};

    @keyframes liveAnimation {
        0% {
            background-color: #aa050e;
        }

        50% {
            background-color: #e30613;
        }

        100% {
            background-color: #aa050e;
        }
    }

    height: 35px;
    min-width: 140px;

    :hover {
        background-color: #aa050e;
    }
`
