import { MicrophoneActivity, RemoteVideo } from "amazon-chime-sdk-component-library-react"
import { useContext } from "react"
import { IconHandYellow } from "../../../../ui/Icons"
import { RaisedHandContext } from "../../../ConferenceRoom/ConferenceRoom"
import { AvatarNamePlate } from "../../RemoteAvatar/RemoteAvatarStyles"
import { RemoteVideoMiniRoot, RemoteVideoOverlay } from "./RemoteVideoMiniStyles"

export interface RemoteVideMiniProps {
    attendeeToTileId: any
    attendee: any
    isMouseHovering: boolean
}

export function RemoteVideoMini(props: RemoteVideMiniProps) {
    const { attendeeToTileId, attendee, isMouseHovering } = props
    const raisedHands = useContext(RaisedHandContext)
    return (
        <RemoteVideoMiniRoot>
            <RemoteVideoOverlay isMouseHovering={isMouseHovering} />
            <RemoteVideo tileId={attendeeToTileId[attendee?.chimeAttendeeId]} />
            {!isMouseHovering && (
                <AvatarNamePlate>
                    {raisedHands && raisedHands.includes(attendee?.id) && <IconHandYellow height="18px" width="18px" />}
                    {attendee?.name}
                    <MicrophoneActivity className="activity" attendeeId={attendee?.chimeAttendeeId} />
                </AvatarNamePlate>
            )}
        </RemoteVideoMiniRoot>
    )
}
