import styled, { css } from "styled-components"
import branding from "../../../branding/branding"
import { Col, Row } from "react-bootstrap"
import { device } from "../../../utils/Device"

export const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
    color: ${branding.primaryColor};
`

export const PersonAttachmentRow = styled(Row)`
    display: flex;
    /* margin-top: 10px; */
    margin-bottom: 25px;

    &.newsPage {
        width: 300px !important;

        @media (min-width: 1800px) {
            width: 420px !important;
        }

        @media (max-width: 1660px) {
            width: 350px !important;
        }
    }
`

export const AttachmentRow = styled(Row)`
    display: flex;
    /* margin-bottom: 25px; */
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    padding: 25px 0;
`

export const PersonAttachmentImageCol = styled(Col)`
    display: auto;
    align-items: center;
`

export const PersonColInfo = styled(Col)`
    font-family: ${branding.font1};
    display: flex;
    align-items: left;
`

export const PersonInfoDiv = styled.div<{ size: number }>`
    /* margin-left: 10px; */
    font-family: ${branding.font1};
    width: ${(props) => (props.size > 1600 ? "55%" : "40%")};
    margin-left: -20px;
`

export const PersonAttachmentTitle = styled.h5`
    font-family: ${branding.font1};
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px !important;
    margin-top: 10px;
`

export const AttachmentTitle = styled.h5`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px !important;
    margin-top: 10px;
`

export const PersonPosition = styled.p`
    /* margin-top: 3px; */
    font-family: ${branding.font1};
    font-size: 12px;
    white-space: pre-wrap;
    margin-bottom: 0px !important;
`

export const PersonAttachmentColIcons = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const AttachmentExstensionDiv = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    padding: 3px 5px;
    margin-left: 20px;
    max-height: 30px;
`

export const ExstensionText = styled.p`
    font-size: 16px;
    font-family: ${branding.font1};
`

export const DownloadIconDiv = styled.div`
    cursor: pointer;
`

export const PersonsAttachmentsContentRow = styled(Row)<{ descriptionExists?: any }>`
    font-family: ${branding.font1};
    margin-left: "30px";
    border-left: ${(props) =>
        props.descriptionExists ? (branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9") : "none"};
    padding-left: 20px;

    &.newsPage {
        @media ${device.mobile} {
            padding-left: 25px;
        }
    }
`

export const PersonsAttachmentsContentCol = styled(Col)`
    font-family: ${branding.font1};
    /* border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}; */
    padding: 0px 0;
`

interface PersonsAttachmentsHeaderPositionRowProps {
    type: "person" | "attachment"
}

export const PersonsAttachmentsHeaderPositionRow = styled(Row)<PersonsAttachmentsHeaderPositionRowProps>`
    margin: ${(props) => (props.type === "person" ? "30px 0 25px 0" : "30px 0 25px 0")};

    @media ${device.mobile} {
        margin: ${(props) => (props.type === "person" ? "30px 0 0 0" : "30px 0 25px 0")};
    }

    &.newsPage {
        margin: 10px 0px 10px 0px;
    }
`

export const PersonsAttachmentsHeaderTitle = styled.h3`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
`

export const FormattedDateCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
    padding-left: 0px;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const LocationCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 25px 0 0 0px;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

export const JoinBreakoutCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

export const EventDateLocationRoot = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 12px;
    margin-left: 10px;
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
`

export const EventDateLocationContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    white-space: nowrap;
`

export const ShareBookmarkDiv = styled.div`
    float: right;
    display: flex;
    /* margin: 50px 5px 0 50px; */
    margin-top: 15px;
    font-size: 20px;
`

export const EventDateFormattedDate = styled.p`
    font-family: ${branding.font1};
    font-size: 12px;
    width: auto;
    height: 15px;
`

export const EventDateNameTeaserCol = styled(Col)`
    font-family: ${branding.font1};
    padding-left: 0px;
    @media (max-width: 769px) {
        margin-top: 100px;
    }
`

export const EventDateName = styled.h2`
    /* margin-bottom:  10px; */
    font-family: ${branding.font1};
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
`

export const EventDateTeaserArea = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    /* border-right: 1px solid #d9d9d9; */
    width: 95%;
`
export const EventDateTeaser = styled.p`
    font-size: 14px;
    margin-top: 25px;
    font-family: ${branding.font1};
    /* padding-right: 20px; */
    width: 95%;
`

export const EventDateTeaserTitle = styled.div`
    font-size: 18px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
`

export const EventDateDetailBodyRoot = styled.div<{ adjustHeight: number }>`
    font-family: ${branding.font1};
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${(props) => `calc(100vh - ${props.adjustHeight}px)`};

    .ScrollbarsCustom-Content {
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
        padding: 0 !important;
    }

    .container-root {
        padding-bottom: 40px;
    }

    @media ${device.mobile} {
        background: ${branding.myProfilePageContent.mobilePageBackgroundColor};
    }
`
export const RoundTableAccessDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    height: 100px;
    font-size: 16px;
    position: relative;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
`
export const ActionButtonCol = styled(Col)`
    padding: 0;
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    & p {
        font-size: 12px;
        margin-bottom: 0px;
        font-family: ${branding.font1};
    }
`
export const ComingUpContentRoot = styled.div`
    margin-top: "-50px";
    padding-bottom: 25px;
    padding-top: 20px;

    @media (max-width: 1500px) {
        margin-top: "-30px";
    }

    @media ${device.mobile} {
        margin-left: 25px;
        margin-right: 25px;
    }
`

export const LiveTileHeader = styled.div`
    position: absolute;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    gap: 20px;
    padding: 15px;
    width: 100%;
    color: white;
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    background-blend-mode: multiply;
`

export const LiveTileHeaderTitle = styled.div`
    display: flex;
    flex-direction: row;
`

export const LiveTileRoot = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: auto;
    max-height: 500px;

    & img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        overflow: hidden;
    }

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 25px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
    }
`

export const VideoOnDemandRoot = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }
`

export const LiveEventRoot = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
`

export const PrivateEventMarkerRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    position: absolute;
    bottom: 0;
    height: 44px;
    background-color: #c4c4c4;

    & .private-event-marker-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 10px;

        & p {
            font-family: ${branding.font1};
            margin: 12px auto auto auto;
            color: ${branding.primaryColor};
            font-size: 18px;
            line-height: 24px;
        }
    }
`
export const PrivateEventUnlockedDiv = styled.div`
    display: inline-flex;
    position: relative;
`

export const PrivateEventUnlockedLabel = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    margin-top: 6px;
`

export const TagsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: ${branding.mainIconsColor} !important;
`
export const TagWrapper = styled.div`
    height: 26px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    font-size: 12px;
`
export const TagIcon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
`
export const TagText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 4px;
`

export const TypeformSectionDivider = styled.div`
    border-bottom: ${branding.mainBorder ?? "1px solid #d9d9d9"};
    margin-top: 20px;
`

export const TypeformSectionDescriptionText = styled.div`
    /* margin-bottom:  10px; */
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    margin: 0;
    white-space: pre-line;

    @media ${device.mobile} {
        font-size: 12px;
        margin-top: 25px;
        margin-left: 15px;
        max-width: 50%;
    }
`

export const TypeformSectionButton = styled.button`
    flex: 0 0 auto;
    margin-top: 8px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: auto;
    outline: none;

    @media ${device.tablet} {
        min-width: 189px;
    }

    @media ${device.mobile} {
        max-width: 120px;
        margin-top: 15px;
        margin-right: 15px;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  border: 1px solid ${branding.crsTabs.defaultBorderColor};
                  background: ${branding.crsTabs.defaultBorderColor};
                  transition-property: none;
                  cursor: default;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

export const CompanyDetailsRoot = styled.div`
    @media ${device.tablet} {
        margin-top: -12px;
        margin-bottom: 15px;
    }

    @media ${device.mobile} {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
`

export const CompanyDetailsHeader = styled.div`
    margin: 35px 0px -40px 0px;
    width: auto;
    height: 17px;
    font-size: 14px;
    line-height: 17px;
    font-family: ${branding.font1};

    @media ${device.mobile} {
        font-size: 16px;
        margin-left: 15px;
        margin-bottom: 20px;
        font-weight: bold;
    }
`

export const LinksSectionRow = styled(Row)`
    @media ${device.mobile} {
        margin-left: 15px !important;
    }
`

export const MediaSectionCol = styled(Col)`
    margin-top: 15px;
    margin-left: -15px;

    @media ${device.mobile} {
        margin-left: -5px;
    }
`

export const HalleStandSection = styled.div<{ isMobile?: boolean }>`
    padding: 0px 0px 30px 0px;
    border-bottom: ${(props) => (props.isMobile ? "none" : branding.mainBorder)};

    @media ${device.mobile} {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
`

export const HalleStandSingle = styled.div`
    display: inline-block;
    padding: 10px 30px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: ${branding.organizationDetailPageContent.halleStandItemBgColor};
    color: ${branding.organizationDetailPageContent.halleStandItemColor};
    cursor: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "pointer" : "auto"};
    pointer-events: ${branding.organizationDetailPageContent.halleStandLinkEnabled ? "auto" : "none"};

    @media ${device.mobile} {
        margin-left: 15px;
    }
`
export const HallStandSectionHeader = styled.div`
    margin-bottom: 20px;
    width: auto;
    height: 17px;
    font-weight: bold;
    font-size: 18px;
    font-family: ${branding.font1};

    @media ${device.mobile} {
        font-size: 16px;
        margin-left: 15px;
        margin-bottom: 20px;
        font-weight: bold;
    }
`

export const ChatAreaContainer = styled.div<{ multiSwitchItemsVisible?: boolean }>`
    width: 25%;
    display: ${(props) => (props.multiSwitchItemsVisible ? "block" : "none")};
    @media ${device.mobile} {
        display: none;
    }
`
