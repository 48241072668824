import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Category, EntityType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { device } from "../../../utils/Device"
import { DetailSectionTitle, ViewMoreButton } from "./DetailPageSections"
import SimpleTreeComponent, { TreeData } from "./SimpleTreeComponent"
import { HeaderTitle } from "./StyledComponents"
import { compareAlphabeticallyIgnoreCase } from "../../../utils/StringUtils"
import { useDetailSectionModal } from "../../../ui/modals/DetailSectionModal"

export const CategoryItemRoot = styled.div<{
    backgroundColor: string
    textColor: string
}>`
    display: flex;
    padding: 8px 15px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};
    font-size: 14px;
    font-weight: 700;

    margin: 5px;
    margin-left: 0;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
`

export const IconTriangleDivRight = styled.div`
    margin-right: 10px;
    margin-left: 3px;
`

export const IconTriangleDivDown = styled.div`
    margin-right: 9px;
`

interface CategoryTreeProps {
    categories: Category[]
    showCategoryHierarchy?: boolean
}
const generateCategoryLabel: any = (category: Category, language: string) => {
    const showCode = branding.categoriesPageContent.categoriesBadgeShowCode
    if (language === "en") {
        if (showCode && category.code) {
            return category.code + " " + category.name
        } else {
            return category.name
        }
    } else {
        if (showCode && category.code) {
            return category.code + " " + (category.nameDe ?? category.name)
        } else {
            return category.nameDe ?? category.name
        }
    }
}

const sortAndMapNodes = (nodes: Category[] | undefined, lang: string): TreeData[] => {
    if (!nodes || nodes.length === 0) return []
    return sortCategories(nodes).map((node) => {
        return {
            key: node.key,
            label: generateCategoryLabel(node, lang),
            nodes: sortAndMapNodes(node.nodes || [], lang)
        }
    }) as unknown as TreeData[]
}

const CategoryTree = (props: CategoryTreeProps) => {
    const lang = useLanguageState().getLanguage()
    return (
        <SimpleTreeComponent
            treeData={sortAndMapNodes(props.categories, lang)}
            showCategoryHierarchy={props.showCategoryHierarchy}
        />
    )
}

const CategoriesContainer = styled.div`
    margin-left: 25px;

    @media ${device.tablet} {
        margin-left: 15px;
        margin-right: 0;
        width: calc(100% - 30px);

        &.evtdDetail {
            margin-left: 5px;
            margin-right: 0;
            width: calc(100% - 10px);
        }
    }
`

export function sortCategories(categories: Category[]) {
    let categoriesToSort: Category[] = []

    categories.forEach((category: Category) => {
        categoriesToSort.push(Object.assign({}, category))
    })
    categoriesToSort.sort((a: Category, b: Category) => {
        return compareAlphabeticallyIgnoreCase(a.orderkey, b.orderkey)
    })
    return categoriesToSort
}

interface CategoriesProps {
    categories: Category[]
    categoryType: EntityType
    viewAllInitially?: boolean
    className?: string
    showLargeHeading?: boolean
    showCategoryHierarchy?: boolean
}

const Categories = (props: CategoriesProps) => {
    const { DetailSectionModal, showDetailSectionModal } = useDetailSectionModal()
    const maxNumEntries = 3
    let rootCategories: string[] = []

    switch (props.categoryType) {
        case "organization":
            rootCategories = branding.organizationDetailPageContent.rootCategories
            break
        case "product":
            rootCategories = branding.productDetailPageContent.rootCategories
            break
        case "trademark":
            rootCategories = branding.trademarkDetailPageContent.rootCategories
            break
        case "eventdate":
            rootCategories = branding.eventDateDetailPageContent.rootCategories
            break
        case "news":
            rootCategories = branding.newsPageContent.rootCategories
            break
        case "joboffer":
            rootCategories = branding.jobofferDetailPageContent.rootCategories
            break
    }

    const modalContent =
        rootCategories.length > 0 && props.showCategoryHierarchy ? (
            <>
                {props.categories.map((category, index) => {
                    return (
                        <CategoriesContent
                            {...props}
                            key={category.id ?? index}
                            categories={category.nodes ?? [category]}
                            headerTitle={category.label ?? ""}
                            showCategoryHierarchy={props.showCategoryHierarchy}
                        />
                    )
                })}
            </>
        ) : (
            <>
                <CategoriesContent {...props} categories={props.categories} showCategoryHierarchy={props.showCategoryHierarchy} />
            </>
        )

    return (
        <>
            {rootCategories.length > 0 && props.showCategoryHierarchy ? (
                <>
                    {sortCategories(props.categories)
                        .slice(0, maxNumEntries)
                        .map((category, index) => {
                            return (
                                <CategoriesContent
                                    {...props}
                                    key={category.id ?? index}
                                    categories={sortCategories(category.nodes ?? [category])}
                                    headerTitle={category.label ?? ""}
                                    showCategoryHierarchy={props.showCategoryHierarchy}
                                />
                            )
                        })}
                </>
            ) : (
                <CategoriesContent
                    {...props}
                    categories={sortCategories(props.categories).slice(0, maxNumEntries)}
                    headerTitle={branding.organizationDetailPageContent.categoriesSectionTitle}
                    showCategoryHierarchy={props.showCategoryHierarchy}
                />
            )}

            <ViewMoreButton
                entries={props.categories}
                showDetailSectionModal={() =>
                    showDetailSectionModal(modalContent, branding.organizationDetailPageContent.categoriesSectionTitle)
                }
                maxNumEntries={maxNumEntries}
            />
            <DetailSectionModal />
        </>
    )
}

interface CategoriesContentProps extends CategoriesProps {
    headerTitle?: string
    showCategoryHierarchy?: boolean
}

const CategoriesContent: React.FunctionComponent<CategoriesContentProps> = (props) => {
    return (
        <Row className="mt-1">
            <Col xs={12} className="pr-0 pl-0">
                <CategoriesContainer className={props.className ?? ""}>
                    {props.headerTitle && <CategoriesHeader {...props}></CategoriesHeader>}
                    <CategoryTree categories={props.categories} showCategoryHierarchy={props.showCategoryHierarchy} />
                </CategoriesContainer>
            </Col>
        </Row>
    )
}

const CategoriesHeader: React.FunctionComponent<CategoriesContentProps> = (props) => {
    if (props.showLargeHeading)
        return (
            <DetailSectionTitle marginTop="50px" marginBottom="20px">
                {props.headerTitle}
            </DetailSectionTitle>
        )
    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "inline-block" }}>
                <HeaderTitle className="mb-3">{props.headerTitle}</HeaderTitle>
            </div>
        </div>
    )
}

export default Categories
