import styled from "styled-components"
import branding from "../../../branding/branding"

export const PreMeetingScreenBackground = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999 !important;
    animation: fadein 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    *::selection {
        background: transparent;
    }
`

export const PreMeetingModalRoot = styled.div`
    width: 676px;
    height: 503px;
    background-color: #202428;
    border-radius: 5px;
    padding: 25px 20px 20px 20px;
    position: relative;
`
export const PremeetingTitle = styled.div`
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    font-family: ${branding.font1};
    margin-bottom: 5px;
    font-weight: 500;
`
export const PreMeetingSubtitle = styled.div`
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    font-family: ${branding.font1};
    font-weight: 100;
`
