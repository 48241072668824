import styled from "styled-components"

export const RemoteAvatarMiniRoot = styled.div`
    background-color: #202428;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
`
