import { useEffect, useState } from "react"
import * as React from "react"
import { Nav, Row } from "react-bootstrap"
import styled, { css } from "styled-components"
import { Category } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { IconEdit } from "../../ui/Icons"
import { CategoriesItem } from "../../ui/ProfilePageStyledComponents"
import { getCategorySectionTitle } from "./EditMyProfileLayout"
import MobileEditMyProfileLayout from "./MobileEditMyProfileLayout"
import MobileViewMyProfileLayout from "./MobileViewMyProfileLayout"
import { EditProfileSection, MyProfilePageMode } from "./MyProfilePageContent"
import { CategoryType } from "./MyProfilePageContentBranding"
import { CategoryData } from "./ViewMyProfileLayout"

export const ProfileRoot = styled.div<{ background?: string }>`
    background: ${(props) => props.background ?? branding.myProfilePageContent.mobilePageBackgroundColor};
    height: calc(100vh - 35px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: ${branding.font1};

    @supports (-webkit-touch-callout: none) {
        max-height: calc(100vh - 120px);
    }

    &.editProfile {
        height: calc(100vh - 150px);

        @supports (-webkit-touch-callout: none) {
            height: calc(100vh - 250px);
            padding-bottom: 20px;
        }
    }
`

export const SectionTopButton = styled.div`
    position: absolute;
    right: 1rem;
    margin-top: 15px;
    cursor: pointer;
`

export const EditPhotoButton = styled.div`
    color: ${branding.myProfilePageContent.editPhotoMobileButtonTextColor};
    font-size: 14px;
    cursor: pointer;
    font-family: ${branding.font1};
`

export const EditProfileLabel = styled.div`
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-bottom: 8px;
    font-weight: bold;
`

export const EditProfileCategoryListLabel = styled.div`
    text-align: left;
    font-size: 16px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-bottom: 8px;
    font-weight: bold;
`

export const EditProfileInformationField = styled.div`
    margin-top: 30px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
`

export const ProfileSectionsContainer = styled.div`
    padding-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
`

export const ProfileSectionRoot = styled.div`
    position: relative;
    height: auto;
    margin-top: 10px;
    text-align: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 5px;
    background: #fff;

    &.editProfile {
        @supports (-webkit-touch-callout: none) {
            padding-bottom: 120px !important;
        }
    }
`

export const UserImageDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: auto;

    .avatar-styled {
        width: 100px;
        height: 100px;
        line-height: 100px;
    }

    .avt-cont {
        width: 85px;
    }
`

export const StyledButton = styled.button`
    flex: 0 0 auto;
    margin: 10px;
    padding: 7px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: ${branding.myProfilePageContent.contactButtonBgColor};
    color: ${branding.sideIconBar.sideIconColorDark};
    transition: 0.5s;
    cursor: pointer;
    width: 150px;
    outline: none;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

export const SaveChangesSectionRoot = styled.div`
    margin-top: 0px;
    width: calc(100% - 38px);
    bottom: 0;
    position: fixed;
    z-index: 1500;
    margin-left: 18px;
    background: transparent;
`

export const ErrorMessageContainer = styled.div<{ success: boolean }>`
    font-size: 14px;
    height: 28px;
    text-align: center;
    color: ${(props) => (props.success ? branding.myProfilePageContent.successSavingMessageColor : branding.dangerButtonColor)};
`

export const SaveChangesButtonContainer = styled.div`
    margin-left: -38px;
    margin-right: -19px;
    background: #fff !important;
    z-index: 1600;

    -webkit-box-shadow: ${branding.myProfilePageContent.saveChangesSectionTopShadow ?? "0px -1px 15px rgba(50, 50, 50, 0.75)"};
    -moz-box-shadow: ${branding.myProfilePageContent.saveChangesSectionTopShadow ?? "0px -1px 15px rgba(50, 50, 50, 0.75)"};
    box-shadow: ${branding.myProfilePageContent.saveChangesSectionTopShadow ?? "0px -1px 15px rgba(50, 50, 50, 0.75)"};
`

export const SaveChangesButton = styled.button`
    flex: 0 0 auto;
    padding: 7px;
    height: 45px;
    margin-top: 15px;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: ${branding.myProfilePageContent.saveChangesMobileBgColor};
    color: ${branding.myProfilePageContent.saveChangesMobileTextColor};
    font-size: 16px;
    line-height: 16px;
    transition: 0.5s;
    cursor: pointer;
    margin-left: 28px;
    width: calc(100% - 36px);
    outline: none;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const MobileProfilePageContent: React.FunctionComponent = () => {
    const params = new URLSearchParams(window.location.search).get("mode")
    const [editProfileSection, setEditProfileSection] = useState<EditProfileSection>(EditProfileSection.ALL)

    const [myProfileMode, setMyProfileMode] = useState<MyProfilePageMode>(
        params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW
    )

    useEffect(() => {
        setMyProfileMode(params && params === "EDIT" ? MyProfilePageMode.EDIT : MyProfilePageMode.VIEW)
    }, [params])

    useEffect(() => {
        if (myProfileMode === MyProfilePageMode.VIEW) {
            setEditProfileSection(EditProfileSection.ALL)
        }
    }, [myProfileMode])

    return (
        <>
            {myProfileMode === MyProfilePageMode.VIEW && (
                <MobileViewMyProfileLayout
                    setMyProfileMode={setMyProfileMode}
                    setEditProfileSection={setEditProfileSection}
                    guestBannerHeight={0}
                />
            )}
            {myProfileMode === MyProfilePageMode.EDIT && (
                <MobileEditMyProfileLayout
                    setMyProfileMode={setMyProfileMode}
                    section={editProfileSection}
                    guestBannerHeight={0}
                />
            )}
        </>
    )
}

export default MobileProfilePageContent

export const Title = styled.p`
    margin-bottom: 10px;
    font-weight: bold;
    font-family: ${branding.font1};
    font-size: 16px;
    line-height: 18.75px;
`

export const Paragraph = styled.p`
    white-space: pre-line;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 16.41px;

    @media (max-width: 1600px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 20px;
    }
`

export const BiographyContainer = styled.div`
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
`

export const ContactSectionIconContainer = styled.div`
    margin-right: 10px;
    display: inline-block;
`

export const ContactSectionTextContainer = styled.div`
    display: inline-block;
`

export const ContactSectionButtonsContainer = styled.div`
    width: 100%;
    justify-content: space-between;
`

export interface EditProfileProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    section: EditProfileSection
    setSection?: (section: EditProfileSection) => void
    guestBannerHeight: number
    showShadow?: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditProfile: React.FunctionComponent<EditProfileProps> = (props: EditProfileProps) => {
    return (
        <SectionTopButton
            onClick={() => {
                if (props.setSection) {
                    props.setSection(props.section)
                }
                props.setMyProfileMode(MyProfilePageMode.EDIT)
            }}
        >
            {IconEdit({ fill: branding.myProfilePageContent.editButtonColor, width: "16", height: "16" })}
        </SectionTopButton>
    )
}

export interface SectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    setEditProfileSection: (section: EditProfileSection) => void
}

export interface CategoriesSectionProps {
    setMyProfileMode: (mode: MyProfilePageMode) => void
    categoryData: CategoryData
    setEditProfileSection: (section: EditProfileSection) => void
}

export const SocialMediaContainer = styled.div`
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
`

export const ProfileSocialsWrapper = styled(Nav)`
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 1px !important;
`

export const ProfileSocialItem = styled(Nav.Link)`
    margin-right: 25px;

    &.nav-link {
        padding: 0 !important;
    }
`

export const InterestsTitleRow = styled(Row)`
    margin: 30px 0 0 0;
    margin-top: 0px;
    justify-content: space-between;
`

export const InterestsViewRow = styled(Row)`
    display: flex;
    margin: 0 5px 20px -5px;
    flex-wrap: wrap;
    justify-content: flex-start;
`

export function getNoSelectedCategoriesMessage(type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return branding.myProfilePageContent.noInterestsSelectedMessage

        case CategoryType.OFFERS:
            return branding.myProfilePageContent.noOffersSelectedMessage

        case CategoryType.NEEDS:
            return branding.myProfilePageContent.noNeedsSelectedMessage

        default:
            return branding.myProfilePageContent.noInterestsSelectedMessage
    }
}

export function getCategoriesLength(data: CategoryData, type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return data.interests.length || 0

        case CategoryType.OFFERS:
            return data.offerings.length || 0

        case CategoryType.NEEDS:
            return data.lookingfor.length || 0

        default:
            return 0
    }
}

interface ContactCategoryBindingsProps {
    data: CategoryData
    type: CategoryType
}

export const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = (props) => {
    let content: JSX.Element = <div />

    if (getCategoriesLength(props.data, props.type) > 0) {
        let selectedDataSet: Category[] = []
        switch (props.type) {
            case CategoryType.INTERESTS:
                selectedDataSet = props.data.selectedInterests
                break
            case CategoryType.OFFERS:
                selectedDataSet = props.data.selectedOfferings
                break
            case CategoryType.NEEDS:
                selectedDataSet = props.data.selectedLookingfor
                break
        }

        if (selectedDataSet.length > 0) {
            content = (
                <>
                    {selectedDataSet.map((category) => {
                        return <CategoriesItem>{category.name}</CategoriesItem>
                    })}
                </>
            )
        } else {
            content = (
                <div style={{ margin: ".3rem" }}>
                    <Paragraph>{getNoSelectedCategoriesMessage(props.type)}</Paragraph>
                </div>
            )
        }
    }

    return (
        <>
            <InterestsTitleRow>
                <Title>{getCategorySectionTitle(props.type)}</Title>
            </InterestsTitleRow>
            <InterestsViewRow>{content}</InterestsViewRow>
        </>
    )
}
