import { MeetingKind } from "../../backendServices/MeetingServices"

export function getMeetingKindFromExternalMeetingId(externalMeetingId: string) {
    if (!externalMeetingId) return ""
    const prefix = externalMeetingId.slice(0, externalMeetingId.indexOf("_"))
    let kind: MeetingKind = "virtualCafe"
    switch (prefix) {
        case "cl":
            kind = "call"
            break
        case "sr":
            kind = "showroom"
            break
        case "vc":
            kind = "virtualCafe"
            break
        case "ce":
            kind = "calenderEntry"
            break
        case "gr":
            kind = "greenroom"
            break
        case "rt":
            kind = "roundtable"
            break
        case "br":
            kind = "breakout"
            break
        case "cr":
            kind = "conferenceroom"
            break
    }
    return kind
}
