import { RefObject, useEffect } from "react"
import styled from "styled-components"
import branding from "../../branding/branding"
import { parseNumber } from "../../utils/NumberUtils"
import { useChimeContext } from "../context/ChimeContext"
import { useContentShareContext } from "../context/ContentShareContext"
import { useRosterContext } from "../context/RosterContext"
import { useVideoContext } from "../context/VideoContext"
import { MeetingStatusCode } from "../enums/MeetingStatusCode"
import useDynamicRefs from "../hooks/useDynamicRefs"
import RemoteVideo from "./RemoteVideo"
/* -60px = Topbar, -100px = controls */
const RemoteVideoGroupRoot = styled.div<{ guestBannerHeight: number }>`
    max-height: calc(
        100vh - 60px - 80px - ${(props) => props.guestBannerHeight + "px"}
    ); /* Topbar 60, Controls 80, GuestBanner 50 */
    &.shareScreen {
        position: absolute;
        left: 0;
        bottom: 110px;
        height: 100px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        z-index: -1;

        opacity: 0.5;
        &:hover {
            opacity: 1;
        }

        & > div {
            flex-grow: 1;
            min-width: calc(100% / 6);
            overflow: hidden;
        }
    }

    &.normal {
        display: grid;
        height: 100vh;

        &.videos-1 {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(1, 100%);
        }
        &.videos-2 {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(1, 100%);
        }
        &.videos-3 {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 50%);
            & > :nth-child(1) {
                grid-column: 1 / span 2;
            }
        }
        &.videos-4 {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 50%);
        }
        &.videos-5 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(2, 50%);
            & > :nth-child(1) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(2) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(3) {
                grid-column: 1 / span 2;
            }
            & > :nth-child(4) {
                grid-column: 3 / span 2;
            }
            & > :nth-child(5) {
                grid-column: 5 / span 2;
            }
        }
        &.videos-6 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 50%);
        }
        &.videos-7 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(3, 33.33%);
            & > :nth-child(1),
            & > :nth-child(3) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(2),
            & > :nth-child(4) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(5) {
                grid-column: 1 / span 2;
            }
            & > :nth-child(6) {
                grid-column: 3 / span 2;
            }
            & > :nth-child(7) {
                grid-column: 5 / span 2;
            }
        }
        &.videos-8 {
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(3, 33.33%);
            & > :nth-child(1),
            & > :nth-child(6) {
                grid-column: 1 / span 2;
            }
            & > :nth-child(2),
            & > :nth-child(7) {
                grid-column: 3 / span 2;
            }
            & > :nth-child(3),
            & > :nth-child(8) {
                grid-column: 5 / span 2;
            }
            & > :nth-child(4) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(5) {
                grid-column: 4 / span 3;
            }
        }
        &.videos-9 {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 33.33%);
        }
        &.videos-10 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(3, 33.33%);
            & > :nth-child(1),
            & > :nth-child(4) {
                grid-column: 1 / span 4;
            }
            & > :nth-child(2),
            & > :nth-child(5) {
                grid-column: 5 / span 4;
            }
            & > :nth-child(3),
            & > :nth-child(6) {
                grid-column: 9 / span 4;
            }
            & > :nth-child(7) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(8) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(9) {
                grid-column: 7 / span 3;
            }
            & > :nth-child(10) {
                grid-column: 10 / span 3;
            }
        }
        &.videos-11 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(3, 33.33%);
            & > :nth-child(1) {
                grid-column: 1 / span 4;
            }
            & > :nth-child(2) {
                grid-column: 5 / span 4;
            }
            & > :nth-child(3) {
                grid-column: 9 / span 4;
            }
            & > :nth-child(4),
            & > :nth-child(8) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(5),
            & > :nth-child(9) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(6),
            & > :nth-child(10) {
                grid-column: 7 / span 3;
            }
            & > :nth-child(7),
            & > :nth-child(11) {
                grid-column: 10 / span 3;
            }
        }
        &.videos-12 {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 33.33%);
        }
        &.videos-13 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(4, 25%);
            & > :nth-child(1) {
                grid-column: 1 / span 6;
            }
            & > :nth-child(2) {
                grid-column: 7 / span 6;
            }
            & > :nth-child(3) {
                grid-column: 1 / span 4;
            }
            & > :nth-child(4) {
                grid-column: 5 / span 4;
            }
            & > :nth-child(5) {
                grid-column: 9 / span 4;
            }
            & > :nth-child(6),
            & > :nth-child(10) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(7),
            & > :nth-child(11) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(8),
            & > :nth-child(12) {
                grid-column: 7 / span 3;
            }
            & > :nth-child(9),
            & > :nth-child(13) {
                grid-column: 10 / span 3;
            }
        }
        &.videos-14 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(4, 25%);
            & > :nth-child(1),
            & > :nth-child(4) {
                grid-column: 1 / span 4;
            }
            & > :nth-child(2),
            & > :nth-child(5) {
                grid-column: 5 / span 4;
            }
            & > :nth-child(3),
            & > :nth-child(6) {
                grid-column: 9 / span 4;
            }
            & > :nth-child(7),
            & > :nth-child(11) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(8),
            & > :nth-child(12) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(9),
            & > :nth-child(13) {
                grid-column: 7 / span 3;
            }
            & > :nth-child(10),
            & > :nth-child(14) {
                grid-column: 10 / span 3;
            }
        }
        &.videos-15 {
            grid-template-columns: repeat(12, 1fr);
            grid-template-rows: repeat(4, 25%);
            & > :nth-child(1) {
                grid-column: 1 / span 4;
            }
            & > :nth-child(2) {
                grid-column: 5 / span 4;
            }
            & > :nth-child(3) {
                grid-column: 9 / span 4;
            }
            & > :nth-child(4),
            & > :nth-child(8),
            & > :nth-child(12) {
                grid-column: 1 / span 3;
            }
            & > :nth-child(5),
            & > :nth-child(9),
            & > :nth-child(13) {
                grid-column: 4 / span 3;
            }
            & > :nth-child(6),
            & > :nth-child(10),
            & > :nth-child(14) {
                grid-column: 7 / span 3;
            }
            & > :nth-child(7),
            & > :nth-child(11),
            & > :nth-child(15) {
                grid-column: 10 / span 3;
            }
        }
        &.videos-16 {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(4, 25%);
        }
    }
`

const NoAttendees = styled.div<{ guestBannerHeight: number }>`
    display: flex;
    height: 100vh;
    max-height: calc(100vh - 60px - 80px - ${(props) => props.guestBannerHeight + "px"});
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-family: ${branding.font1};
    color: #fff;
`

interface Props {
    guestBannerHeight: number
}

export default function RemoteVideoGroup(props: Props) {
    const chime = useChimeContext()
    const [getRef, setRef] = useDynamicRefs()

    const videoContext = useVideoContext()
    const remoteTiles = videoContext.getRemoteTiles()
    const rosterContext = useRosterContext()
    const roster = rosterContext.getRoster()
    const contentShare = useContentShareContext()

    const meetingKind = chime.getKind()
    const meetingId = chime.getExternalMeetingId()

    const attendeesWithVideo = new Set<string>()

    const maxTiles = Math.min(parseNumber(branding.meetingRoomMaxTiles, 16)!, 16)

    useEffect(() => {
        if (!remoteTiles) return
        for (let i = 0; i < Math.min(remoteTiles.length, maxTiles); i++) {
            if (!remoteTiles[i]?.tileId) return

            const videoElement = getRef(`${meetingId}_remoteVideoId_${remoteTiles[i].tileId}`)
            if (!videoElement) return
            try {
                videoContext.bindVideoElement(remoteTiles[i].tileId!, videoElement.current as HTMLVideoElement)
            } catch (e) {
                // What should we do here? Seems to happen only when switching between rooms while an async call tries to update a no longer existing video stream
            }
        }
    })

    if (chime.getMeetingStatus().meetingStatusCode !== MeetingStatusCode.Succeeded || contentShare.isLocalAttendeeSharingScreen())
        return null

    const rosterKeys = Object.keys(roster)

    const hasAttendees = rosterKeys.length > 1

    let hideTilesCount = 1 // don't show tile for yourself
    if (meetingKind === "greenroom") {
        const hasRecorder = hasAttendees && !!rosterKeys.find((key) => roster[key].id === "recorder")
        if (hasRecorder) hideTilesCount++ // don't show tile for recorder
    }
    const videoTileCount = Math.min(rosterKeys.length - hideTilesCount, maxTiles)

    let numTiles = 0

    return (
        <>
            {!hasAttendees && (
                <NoAttendees guestBannerHeight={props.guestBannerHeight}>{branding.conferenceTexts.noAttendees}</NoAttendees>
            )}
            {hasAttendees && (
                <RemoteVideoGroupRoot
                    className={contentShare.isSharingScreen() ? "shareScreen" : "normal videos-" + videoTileCount}
                    guestBannerHeight={props.guestBannerHeight}
                >
                    {remoteTiles &&
                        remoteTiles.map((tile) => {
                            attendeesWithVideo.add(tile.boundAttendeeId!!)
                            numTiles++
                            return (
                                <RemoteVideo
                                    key={`${meetingId}${tile.tileId}`}
                                    videoElementRef={
                                        setRef(`${meetingId}_remoteVideoId_${tile.tileId}`) as RefObject<HTMLVideoElement>
                                    }
                                    attendeeId={tile.boundAttendeeId}
                                />
                            )
                        })}
                    {rosterKeys.map((key) => {
                        if (
                            attendeesWithVideo.has(key) ||
                            key === rosterContext.getLocalAttendeeId() ||
                            numTiles > maxTiles - 1 ||
                            (meetingKind === "greenroom" && roster[key].id === "recorder")
                        )
                            return null
                        numTiles++
                        return (
                            <RemoteVideo
                                key={meetingId + key}
                                attendeeId={key}
                                attendeeName={roster[key]?.name}
                                attendeeImg={roster[key]?.avatarUrl}
                                attendeePosition={roster[key]?.position}
                                attendeeCompany={roster[key]?.company}
                            />
                        )
                    })}
                </RemoteVideoGroupRoot>
            )}
        </>
    )
}
