// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useAudioVideo, useLogger, useMeetingManager, useVideoInputs } from "amazon-chime-sdk-component-library-react"
import { BaseSdkProps } from "amazon-chime-sdk-component-library-react/lib/components/sdk/Base"
import React, { useEffect, useRef } from "react"
import { StyledPreview } from "./PreviewVideoStyles"

export const PreviewVideo: React.FC<BaseSdkProps> = (props) => {
    const logger = useLogger()
    const audioVideo = useAudioVideo()
    const { selectedDevice } = useVideoInputs()
    const videoEl = useRef<HTMLVideoElement>(null)
    const meetingManager = useMeetingManager()

    useEffect(() => {
        async function startPreview(): Promise<void> {
            if (!audioVideo || !selectedDevice || !videoEl.current) {
                return
            }

            try {
                await meetingManager.startVideoInputDevice(selectedDevice)
                audioVideo.startVideoPreviewForVideoInput(videoEl.current)
            } catch (error) {
                logger.error("Failed to start video preview")
            }
        }

        startPreview()
        //eslint-disable-next-line
    }, [audioVideo, selectedDevice])

    return <StyledPreview {...props} ref={videoEl} />
}

export default PreviewVideo
