import { RosterAttendeeType } from "amazon-chime-sdk-component-library-react/lib/types"
import RemoteAvatar from "../RemoteAvatar/RemoteAvatar"
import RemoteVideo from "../RemoteVideo/RemoteVideo"
import { RemoteTileRoot } from "./RemoteTileStyles"

interface RemoteVideoTileProps {
    attendee: RosterAttendeeType
    tileId: number
    isVideoTile: boolean
}

function RemoteVideoTile(props: RemoteVideoTileProps) {
    const { attendee, tileId, isVideoTile } = props

    return (
        <RemoteTileRoot>
            {isVideoTile ? (
                <RemoteVideo attendee={attendee} tileId={tileId} />
            ) : (
                <RemoteAvatar attendee={attendee} tileId={tileId} />
            )}
        </RemoteTileRoot>
    )
}
export default RemoteVideoTile
