import { useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { Form, Modal } from "react-bootstrap"
import { IconClose } from "../Icons"
import CrsMultiSwitch from "../CrsMultiSwitch"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import TextLimit from "../TextLimit"
import { reportUser } from "../../backendServices/SeriesOfTopicsUserServices"

const ReportModalRoot = styled(Modal)`
    font-family: ${branding.font1};

    .form-control {
        font-size: 12px;
        line-height: 17px;
        padding: 1rem;
    }

    .form-control::placeholder {
        font-size: 12px;
        line-height: 17px;
    }

    .modal-content {
        padding: 0 30px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        padding: 30px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
    }

    .modal-title {
        display: flex;
        align-items: center;
    }

    .modal-header .modal-title {
        color: ${branding.mainInfoColor};
        font-weight: 300;
        overflow: visible;
        white-space: nowrap;
    }

    .btn-secondary {
        color: ${branding.primaryColor ?? "#000"} !important;
        display: inline-block;
        background-color: white;
        font-family: ${branding.font1};
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        border-width: initial;
        border-style: none;
        border-image: initial;
        border-color: white;
        cursor: pointer;
    }
`
const ReportModalHeader = styled(Modal.Header)`
    /* display: flex;
    justify-content: space-between;
    align-content: center; 
    align-items: stretch;
    color: ${branding.primaryColor};
    svg {
        color: ${branding.primaryColor};
    } */
    color: ${branding.mainInfoColor};
    font-weight: 300;
    overflow: visible;
    white-space: nowrap;
`
const ReportModalFooter = styled(Modal.Footer)`
    justify-content: space-between;
    border: none;
    padding: 0px;
    margin-bottom: 10px;
`
const ReportModalTitle = styled.span`
    display: inline-block;
    font-weight: bold;
    font-size: 18px;
    line-height: 17px;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
`

const ReportModalBody = styled(Modal.Body)`
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem 1rem 0rem;
`
const CloseModalBtn = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
`
const NotesRoot = styled.div`
    #recommend-textarea {
        font-family: ${branding.font1};
        font-size: 12px;
        line-height: 20px;
        font-style: normal;
        font-weight: normal;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        /* resize: auto; */
        cursor: default;
    }
`

const SubmitButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
`
const SubmitModalBtn = styled.div`
    display: block;
    width: 28%;
    height: 30px;
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

interface ReportModalProps {
    show: boolean
    onHide: () => void
    userToReportId: string
}

enum reportType {
    REPORT_USER = 1,
    CLAIM_ACCOUNT = 2
}

// const CancelButton = styled.div`
//     width: 30%;
//     height: 30px;
//     font-size: 12px;
//     justify-content: left;
//     border: none;
//     background: white;
//     color: ${branding.primaryColor ?? "#fff"};
//     font-family: ${branding.font1};
//     border-radius: 25px;
//     cursor: pointer;

//     &:active {
//         background-color: transparent;
//         border: 1px solid transparent;
//     }

//     &:hover{
//         text-decoration: none;
//     }

// `
const DisagreeButton = styled.div`
    display: block;
    width: 28%;
    height: 30px;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;
    justify-content: center;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

const ReportModal: React.FC<ReportModalProps> = (props) => {
    const userState = useLoggedInState()
    const [reportDescription, setReportDescription] = useState("")
    const reportTypeData = [
        { value: reportType.REPORT_USER, label: branding.communicationArea.userReportText },
        { value: reportType.CLAIM_ACCOUNT, label: branding.communicationArea.claimAccountText }
    ]
    const [currentReportType, setCurrentReportType] = useState(reportType.REPORT_USER)
    const MAX_NOTES_LENGTH = 250

    const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length > MAX_NOTES_LENGTH) {
            return
        }
        setReportDescription(e.target.value)
    }

    const onReportSubmit = () => {
        reportUser({
            reporterId: userState.user()?.profileId || "",
            reportProfileId: props.userToReportId,
            reportType:
                currentReportType === reportType.REPORT_USER
                    ? branding.communicationArea.userReportText
                    : branding.communicationArea.claimAccountText,
            message: reportDescription
        })
            .then((res) => {
                props.onHide()
            })
            .catch((err) => {
                // Logged in BackendServices
            })
    }

    return (
        <ReportModalRoot show={props.show} onHide={props.onHide} backdrop="static" centered animation={false}>
            <div onClick={(e) => e.stopPropagation()}>
                <ReportModalHeader>
                    <div className={"d-flex flex-column mt-3"}>
                        {/* {IconReport({fill: branding.sideIconBar.sideIconColorDark})} */}
                        <ReportModalTitle>{branding.communicationArea.userReportTextTitle}</ReportModalTitle>
                    </div>
                    <CloseModalBtn onClick={() => props.onHide()}>
                        {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                    </CloseModalBtn>
                </ReportModalHeader>
                <ReportModalBody>
                    {CrsMultiSwitch({
                        items: reportTypeData,
                        activeItem: currentReportType,
                        onItemClick: setCurrentReportType,

                        style: {
                            msActiveItem: { zIndex: 0 },
                            msItem: { position: "relative", zIndex: 1 },
                            msRootItem: {
                                width: "100%",
                                fontSize: "0.8rem",
                                margin: "0 auto 1.5rem auto",
                                padding: "1px",
                                border: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"
                            }
                        }
                    })}
                    <NotesRoot>
                        <Form.Control
                            as="textarea"
                            value={reportDescription}
                            onChange={onDescriptionChange}
                            rows={4}
                            placeholder={branding.globalTexts.descriptionText}
                        />
                        <TextLimit textLength={reportDescription?.length || 0} maxTextLength={MAX_NOTES_LENGTH} />
                    </NotesRoot>
                </ReportModalBody>
                <ReportModalFooter>
                    <SubmitButtonContainer>
                        <DisagreeButton
                            className="d-flex align-items-center justify-content-center"
                            onClick={() => props.onHide()}
                        >
                            {/* <BootstrapButton variant="secondary" onClick={() => props.onHide()}> */}
                            {branding.globalTexts.cancel}
                            {/* </BootstrapButton> */}
                        </DisagreeButton>
                        <SubmitModalBtn onClick={onReportSubmit} className="d-flex align-items-center justify-content-center">
                            {branding.communicationArea.sendReportText}
                        </SubmitModalBtn>
                    </SubmitButtonContainer>
                </ReportModalFooter>
            </div>
        </ReportModalRoot>
    )
}

export default ReportModal
