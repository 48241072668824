// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect, useRef, useState, useMemo } from "react"

import * as React from "react"
import { useHistory, useRouteMatch } from "react-router-dom"
import styled from "styled-components/macro"
import { useChimeContext, ShallowVideoTileState } from "./context/ChimeContext"
import RemoteVideo from "./components/RemoteVideo"
import { AttendeeData } from "../backendServices/MeetingServices"
import { throttle } from "lodash"
import RosterAttendeeType from "./types/RosterAttendeeType"
import Controls, { ButtonsColumn } from "./components/Controls"
import Draggable, { DraggableData, DraggableEvent, DraggableEventHandler } from "react-draggable"
import branding from "../branding/branding"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { useActiveSpeakerContext } from "./context/ActiveSpeakerContext"
import { IconCloseFilled } from "../ui/Icons"
import { MeetingStatusCode } from "./enums/MeetingStatusCode"
import { useAudioContext } from "./context/AudioContext"
import { useRosterContext } from "./context/RosterContext"
import { useVideoContext } from "./context/VideoContext"
import { useContentShareContext } from "./context/ContentShareContext"
import { replaceKickOrBanReasonPlaceholderText } from "../utils/StringUtils"
import ContentVideo from "./components/ContentVideo"
import CenteredLoader from "../ui/CenteredLoader"

/**
 * Don't toggle visibility via meetingStatusCode. That leads to trouble and memory leaks.
 * @returns
 */
export default function ConferenceOverlay() {
    const audioElement = useRef(null)
    const chime = useChimeContext()
    const audio = useAudioContext()
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    let meetingStatusCode: MeetingStatusCode | null = chime.getMeetingStatus().meetingStatusCode
    let [lastVisitedRoom, setLastVisitedRoom] = useState<any>("")

    useEffect(() => {
        if (chime.getExternalMeetingId() !== null) {
            setLastVisitedRoom(chime.getExternalMeetingId())
        }
        //eslint-disable-next-line
    }, [chime.getExternalMeetingId()])

    useEffect(() => {
        if (isMeetingPage) {
            chime.setShowConferenceOverlay(false)
        } else if (
            (meetingStatusCode === MeetingStatusCode.Succeeded && !isMeetingPage) ||
            (meetingStatusCode === MeetingStatusCode.Kicked && !isMeetingPage) ||
            (meetingStatusCode === MeetingStatusCode.Banned && !isMeetingPage)
        ) {
            if (!chime.showConferenceOverlay()) chime.setShowConferenceOverlay(true)
        }
        // eslint-disable-next-line
    }, [isMeetingPage, meetingStatusCode, lastVisitedRoom])

    useEffect(
        () => {
            if (meetingStatusCode !== MeetingStatusCode.Succeeded) return

            if (audioElement && audioElement.current) audio.bindAudioElement(audioElement)
            return () => {
                audio.unbindAudioElement()
            }
        },
        // eslint-disable-next-line
        [meetingStatusCode]
    )

    return (
        <>
            <audio ref={audioElement} style={{ display: "none" }} muted={audio.getVolume() === 0} />
            <VideoOverlay lastVisitedRoom={lastVisitedRoom || null} />
        </>
    )
}

const KickOrBanRoot = styled.div<{ isBanned: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #373737;
    color: #fff;
    height: 100%;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    ${(props) => (props.isBanned ? "cursor: default !important;" : "")}
`

const KickOrBanMessageActionContainer = styled.div`
    max-height: 80px;
    :hover {
        color: #d3d3d3;
    }
`

const KickOrBanMessageNoActionContainer = styled.div``

const CloseBtnRoot = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
`

const VideoOverlayRoot = styled.div`
    position: absolute;
    left: 100px;
    bottom: 109px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 240px;
    cursor: pointer;
    z-index: 1000;

    .custom-loader {
        border: none;
    }

    & {
        & > :last-child {
            display: block;
        }
    }

    & video + div {
        display: none; /** no name tag */
    }
`

// const StyledContentVideo = styled(ContentVideo)`
//   max-height: 100%;
//   background: ${branding.videoBackground};
// `

const NoAttendees = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${branding.mainInfoColor ?? "black"};
    font-family: ${branding.font1};
    font-size: ${branding.conferenceTexts.noAttendeesLabelTextSize ?? "16px"};
    color: #fff;
    border-radius: 4px;
    border: 1px solid #fff;
`
const RoomNameLabel = styled.div`
    display: none;
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    padding: 3px 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #000;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StyledControls = styled(Controls)`
    position: relative;
    bottom: 0px;
    height: 40px;

    & ${ButtonsColumn} {
        border-top: none;
    }

    box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    & button {
        height: 40px;
        width: 48px;
        border-radius: none;

        &:nth-child(5) {
            display: none;
        }
        &:nth-child(6) {
            display: none;
        }
    }
`
interface AttendeeDataWithType extends AttendeeData {
    type: "video" | "avatar"
}

interface VideoOverlayProps {
    lastVisitedRoom?: string
}

function VideoOverlay(props: VideoOverlayProps) {
    const chime = useChimeContext()
    let meetingStatus: MeetingStatusCode | null = chime.getMeetingStatus().meetingStatusCode
    const videoElement = useRef<HTMLVideoElement>(null)
    const history = useHistory()
    const video = useVideoContext()
    const remoteTiles = video.getRemoteTiles()
    const rosterContext = useRosterContext()
    const roster = rosterContext.getRoster()
    const contentShare = useContentShareContext()
    const activeSpeakers = useActiveSpeakerContext().getActiveSpeakers()
    const [lastActiveSpeaker, setLastActiveSpeaker] = useState<string | null>(null)
    const [displayedAttendee, setDisplayedAttendee] = useState<null | AttendeeDataWithType>(null)

    const [isDragging, setIsDragging] = useState(false)
    const noClickOnDragThreshold = 5 // number of pixels, if Overlay is dragged less than this number, a click will still be registered on its buttons, to make clicking easier

    let startClickX = useRef<number>(0)
    let startClickY = useRef<number>(0)

    const rosterCheck = (
        roster: { [attendeeId: string]: RosterAttendeeType },
        remoteTiles: ShallowVideoTileState[],
        activeSpeakers: string[],
        currentlyDisplayedAttendee: null | { id: string; type: "video" | "avatar"; name?: string }
    ) => {
        const rosterKeys = Object.keys(roster)
        // if there is only one entry in the roster, it is the local user, therefore we are waiting for others
        if (rosterKeys.length <= 1) {
            setDisplayedAttendee(null)
            setLastActiveSpeaker(null)
            return
        }

        let rosterKeyToUse: string = ""
        let localAttendeeIsActiveSpeaker = false
        // If we do have an attendee currently and he's still speaking, do nothing
        if (currentlyDisplayedAttendee && activeSpeakers.indexOf(currentlyDisplayedAttendee.id!) >= 0) {
            return
        }

        // if there are no active speakers and and last remembered active speaker has not left the room, use the last remembered active speaker
        if (activeSpeakers.length === 0 && lastActiveSpeaker !== null && roster[lastActiveSpeaker]) {
            rosterKeyToUse = lastActiveSpeaker
        }

        // Find the first active speaker that is not the local user, and differs from the currentAttendee
        for (let activeSpeaker of activeSpeakers) {
            if (
                activeSpeaker !== rosterContext.getLocalAttendeeId() &&
                (currentlyDisplayedAttendee === null || currentlyDisplayedAttendee.id !== activeSpeaker) &&
                roster[activeSpeaker] &&
                roster[activeSpeaker].id !== "tmp"
            ) {
                setLastActiveSpeaker(activeSpeaker)
                rosterKeyToUse = activeSpeaker
                break
            } else if (activeSpeaker === rosterContext.getLocalAttendeeId()) {
                localAttendeeIsActiveSpeaker = true
            }
        }

        // No active speakers? No last active speaker is remembered? And local user is not the active speaker? -> Select the first roster entry
        if (!rosterKeyToUse && !localAttendeeIsActiveSpeaker) {
            for (let rosterKey of rosterKeys) {
                if (rosterKey !== rosterContext.getLocalAttendeeId() && roster[rosterKey].id !== "tmp") {
                    rosterKeyToUse = rosterKey
                    setLastActiveSpeaker(rosterKey)
                    break
                }
            }
        }

        if (!rosterKeyToUse || !roster[rosterKeyToUse]) return

        // Try to find a corresponding remoteTile
        let remoteTileToUse: ShallowVideoTileState | null = null
        for (let remoteTile of remoteTiles) {
            if (remoteTile && remoteTile.boundAttendeeId === rosterKeyToUse) {
                remoteTileToUse = remoteTile
            }
        }
        // If remoteTile found, display it
        if (remoteTileToUse) {
            video.bindVideoElement(remoteTileToUse.tileId!, videoElement.current as HTMLVideoElement)
            setDisplayedAttendee({ ...roster[rosterKeyToUse], type: "video" })
        }
        // Otherwise show an avatar for the name of the person
        else {
            // Show Avatar
            setDisplayedAttendee({ ...roster[rosterKeyToUse], type: "avatar" })
        }
    }

    const [throttledRosterCheck] = useThrottledCallback(rosterCheck, 2000)
    const tmpRoster: string = Object.keys(roster).join(",")
    const tmpRosterIds = Object.keys(roster)
        .map((key) => roster[key].id)
        .join(",")
    const tmpRemoteVideoAttendees = remoteTiles.map((tile) => tile.boundAttendeeId).join(",")
    const tmpActiveSpeakers = activeSpeakers.join(",")

    let mode: "empty" | "avatar" | "video" | "screenShare" | "kickedOrBanned" = "empty"
    if (contentShare.isSharingScreen()) mode = "screenShare"
    else if (displayedAttendee?.type === "avatar") mode = "avatar"
    else if (displayedAttendee?.type === "video") mode = "video"
    else mode = "empty"

    if (chime.showKickOrBanMessage()) mode = "kickedOrBanned"
    // show the current speaker
    useEffect(
        () => {
            if (contentShare.isSharingScreen()) return

            if (displayedAttendee) throttledRosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
            else rosterCheck(roster, remoteTiles, activeSpeakers, displayedAttendee)
        },
        // eslint-disable-next-line
        [history.location, tmpRoster, tmpRemoteVideoAttendees, tmpActiveSpeakers, tmpRosterIds, mode]
    )

    let roomName = ""
    if (chime.getKind() === "virtualCafe") {
        for (let group of branding.meetingRoomGroups) {
            for (let meetingRoom of group.meetingRooms) {
                if (meetingRoom.id === chime.getExternalMeetingId()) {
                    roomName = meetingRoom.title
                    break
                }
            }
            if (roomName) break
        }
    }

    let isClosing = false

    function closeOverlay() {
        if (!isDragging) {
            isClosing = true
            chime.setShowConferenceOverlay(false)
        }
    }

    function onPipScreenClick(event: any) {
        if (!isDragging && !isClosing) {
            if (meetingStatus === MeetingStatusCode.Kicked) {
                if (props.lastVisitedRoom) history.push(`/meeting/${props.lastVisitedRoom}/createorjoin`)
            } else if (meetingStatus === MeetingStatusCode.Banned) {
                // do nothing
            } else {
                chime.gotoCurrentMeeting()
            }
            event.stopPropagation()
        }

        setIsDragging(false)
    }

    const handleDragStart: DraggableEventHandler = (draggableEvent: DraggableEvent, data: DraggableData) => {
        setIsDragging(true)
        if ((draggableEvent as any).hasOwnProperty("nativeEvent")) {
            draggableEvent = (draggableEvent as any).nativeEvent
        }
        if (draggableEvent instanceof MouseEvent) {
            startClickX.current = draggableEvent.screenX
            startClickY.current = draggableEvent.screenY
        } else if (draggableEvent instanceof TouchEvent) {
            startClickX.current = draggableEvent.changedTouches[0].clientX
            startClickY.current = draggableEvent.changedTouches[0].clientY
        }
        draggableEvent.stopPropagation()
        draggableEvent.preventDefault()
    }

    const handleDragStop: DraggableEventHandler = (draggableEvent: DraggableEvent, data: DraggableData) => {
        if (draggableEvent instanceof MouseEvent) {
            if (
                Math.abs(startClickX.current - draggableEvent.screenX) < noClickOnDragThreshold &&
                Math.abs(startClickY.current - draggableEvent.screenY) < noClickOnDragThreshold
            ) {
                setIsDragging(false)
            }
        } else if (draggableEvent instanceof TouchEvent) {
            if (
                Math.abs(startClickX.current - draggableEvent.changedTouches[0].clientX) < noClickOnDragThreshold &&
                Math.abs(startClickY.current - draggableEvent.changedTouches[0].clientY) < noClickOnDragThreshold
            ) {
                setIsDragging(false)
            }
        }
        draggableEvent.stopPropagation()
    }

    return (
        <Draggable bounds="html" onStart={handleDragStart} onStop={handleDragStop}>
            <VideoOverlayRoot style={{ display: "block" }} onClick={onPipScreenClick} onTouchEndCapture={onPipScreenClick}>
                <div
                    style={{
                        height: "140px",
                        boxShadow: "0px 5px 15px 1px rgba(0, 0, 0, 0.4)",
                        borderRadius: "4px"
                    }}
                >
                    {mode === "screenShare" && (
                        <ContentVideo guestBannerHeight={50} className={"content-video-conference-overlay"} />
                    )}
                    {/* && <StyledContentVideo guestBannerHeight={0} /> */}
                    {
                        // display change instead of dom appending because video must be present when we try to bind it in the useEffect
                    }
                    <div style={{ display: mode === "video" ? "block" : "none", height: "100%" }}>
                        <RemoteVideo
                            videoElementRef={videoElement}
                            attendeeId={displayedAttendee ? displayedAttendee.id! : null}
                        />
                    </div>
                    {mode === "avatar" && (
                        <RemoteVideo
                            attendeeId={displayedAttendee!.id!}
                            attendeeName={displayedAttendee!.name}
                            attendeeImg={displayedAttendee!.avatarUrl}
                            attendeePosition={displayedAttendee!.position}
                            attendeeCompany={displayedAttendee!.company}
                            avatarSize={60}
                        />
                    )}
                    {mode === "empty" && meetingStatus === MeetingStatusCode.Succeeded && (
                        <NoAttendees>{branding.conferenceTexts.noAttendees}</NoAttendees>
                    )}

                    {mode === "empty" && meetingStatus !== MeetingStatusCode.Succeeded && (
                        <NoAttendees>
                            <CenteredLoader className="custom-loader" />
                        </NoAttendees>
                    )}

                    {mode === "kickedOrBanned" && <KickOrBanMessage onClose={closeOverlay} />}

                    {roomName && <RoomNameLabel>{roomName}</RoomNameLabel>}
                </div>
                {!chime.showKickOrBanMessage() && (
                    <StyledControls isDragging={isDragging} isPictureInPictureMode={true} isConferenceRoom={false} />
                )}
            </VideoOverlayRoot>
        </Draggable>
    )
}

function useThrottledCallback(callback: any, delay: number) {
    const callbackfunc = useMemo(() => throttle(callback, delay), [callback, delay])
    return [callbackfunc]
}

interface KickOrBanMessageProps {
    onClose: () => void
    onAction?: () => void
}

function KickOrBanMessage({ onClose, onAction }: KickOrBanMessageProps) {
    const chime = useChimeContext()
    const message =
        chime.getMeetingStatus().meetingStatusCode === MeetingStatusCode.Banned
            ? replaceKickOrBanReasonPlaceholderText(
                  branding.conferenceTexts.statusBanned,
                  chime.getMeetingStatus().errorMessage || "",
                  branding.conferenceTexts.statusBannedDefault
              )
            : replaceKickOrBanReasonPlaceholderText(
                  branding.conferenceTexts.statusKicked,
                  chime.getMeetingStatus().errorMessage || "",
                  branding.conferenceTexts.statusKickedDefault
              )
    return (
        <KickOrBanRoot isBanned={chime.getMeetingStatus().meetingStatusCode === MeetingStatusCode.Banned}>
            <CloseBtnRoot onClick={onClose}>
                {IconCloseFilled({ fill: "transparent", stroke: "#fff", height: "30px", width: "30px" })}
            </CloseBtnRoot>
            {onAction && <KickOrBanMessageActionContainer onClick={() => onAction()}>{message}</KickOrBanMessageActionContainer>}

            {!onAction && <KickOrBanMessageNoActionContainer>{message}</KickOrBanMessageNoActionContainer>}
        </KickOrBanRoot>
    )
}
