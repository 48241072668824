import * as React from "react"
import { HTMLAttributes, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components/macro"
import { ConversationType } from "../../API"
import branding from "../../branding/branding"
import { ChatConversationParam } from "../../communicationArea/ChatPage"
import { DetailNavLink } from "../../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import {
    IconCamera,
    IconCameraOff,
    IconChat,
    IconEndCall,
    IconLowerHand,
    IconMicrophone,
    IconMicrophoneOff,
    IconRaiseHand,
    IconSettings,
    IconShareScreen,
    IconShareScreenStop,
    IconVolumeHigh,
    IconVolumeOff
} from "../../ui/Icons"
import { accessPresenceState, EventType } from "../../ui/PresenceIndicator"
import { useAudioContext } from "../context/AudioContext"
import { useChimeContext } from "../context/ChimeContext"
import { useContentShareContext } from "../context/ContentShareContext"
import { useModContext } from "../context/ModContext"
import { useRosterContext } from "../context/RosterContext"
import { useVideoContext } from "../context/VideoContext"
import { LocalVideoStatus } from "../enums/LocalVideoStatus"
import { MeetingStatusCode } from "../enums/MeetingStatusCode"
import { Button } from "./Button"
import LocalVideo from "./LocalVideo"
import RemainingTime from "./RemainingTime"
import { RoundTableEndCountdownTimer } from "./RoundTableCountDown"
import { trackSelectContent } from "../../utils/GTMTracking"

/* #region  Style */

const ControlsRoot = styled.div`
    display: flex;
    flex-wrap: nowrap;
    height: 80px;
    width: 100%;
    z-index: 10;
    margin: 0 auto;
`

const HostedByColumn = styled.div`
    width: 180px;
    padding: 20px 30px;

    & :first-child {
        font-family: ${branding.font1};
        font-size: 0.9rem;
    }
    & :nth-child(2) {
        font-family: ${branding.font2};
        font-size: 1.3rem;
    }
`

export const ButtonsColumn = styled.div`
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #fff;
    position: relative;

    & > button {
        position: relative;
        margin: 0;
        border-radius: 0;
        width: 60px;
        height: 40px;

        @media (max-width: 1500px) {
            width: 50px;
            height: 33px;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            height: 20px;
        }

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            &:before {
                display: none;
            }
        }

        background-color: buttonface;

        &:disabled {
            background-color: ${branding.communicationAreaDefaultAvatarColor} !important;
        }
    }
`

const LocalVideoColumn = styled.div<{ isRosterOpen?: boolean | null }>`
    justify-self: right;
    width: 145px;
    text-align: right;
    position: absolute;
    right: 0;

    ${({ isRosterOpen }) =>
        !isRosterOpen &&
        `
     @media(max-width: 1200px) {
      bottom: 65px;
  }
  `}

    ${({ isRosterOpen }) =>
        isRosterOpen &&
        `
      @media(max-width: 1400px) {
        bottom: 65px;
    }
    `}
`
/* #endregion */
interface DraggingProps {
    isDragging?: boolean
}

interface Props extends HTMLAttributes<HTMLDivElement>, EndCallButtonProps, OpenChatButtonProps {
    isPictureInPictureMode?: boolean
}

export default function Controls(props: Props) {
    const chime = useChimeContext()
    const audio = useAudioContext()
    const rosterContext = useRosterContext()
    const [hoster, setHoster] = useState<{
        organizationId: string | undefined
        organizationName: string | undefined
    } | null>(null)
    const profileId = useLoggedInState().user()?.profileId

    async function shutDown() {
        if (chime.getKind() === "call") window.sessionStorage.removeItem("currentTabCall")
        const meetingStatus = Object.assign({}, chime.getMeetingStatus())
        await chime.leaveRoom(meetingStatus)
        // reset everything when leaving the room
        audio.setMutedByMod(false)
        audio.realtimeUnmuteLocalAudio()
        audio.setVolume(100)
    }

    useEffect(
        () => {
            const meetingName = chime.getExternalMeetingId()

            let found = false
            for (const meetingRoomGroup of branding.meetingRoomGroups) {
                for (const meetingRoom of meetingRoomGroup.meetingRooms) {
                    if (meetingRoom.id === meetingName) {
                        setHoster(meetingRoomGroup)
                        found = true
                        break
                    }
                }
                if (found) break
            }

            return () => {
                if (!chime.keepCallActive()) {
                    shutDown()
                }
            }
        },
        // eslint-disable-next-line
        []
    )

    if (profileId === undefined) {
        return <div>Not logged in</div>
    } else {
        return (
            <ControlsRoot className={props.className}>
                {!props.isPictureInPictureMode && hoster && hoster.organizationId && hoster.organizationName && (
                    <HostedByColumn>
                        <div>{branding.globalTexts.hostedByText}</div>
                        <DetailNavLink
                            id={hoster.organizationId}
                            name={hoster.organizationName}
                            type="organization"
                            source="VC"
                            onClick={() => {
                                trackSelectContent(
                                    "organization",
                                    hoster.organizationId!,
                                    hoster.organizationName!,
                                    "Entity link",
                                    "organization"
                                )
                            }}
                        >
                            {hoster.organizationName}
                        </DetailNavLink>
                    </HostedByColumn>
                )}
                <RemainingTime />
                {chime.getKind() === "roundtable" && <RoundTableEndCountdownTimer />}
                <RemainingTime forceShowTimer={chime.getKind() === "breakout" && chime.getTimeLeft()! > 0} />
                <ButtonsColumn>
                    <MuteButton {...props} />
                    <SoundOnOffButton {...props} />
                    <CamOnOffButton {...props} />
                    <ShareScreenButton {...props} />
                    <RaiseHandButton {...props} />
                    <OpenChatButton {...props} />
                    {!props.isPictureInPictureMode && <SettingsButton {...props} />}
                    <EndCallButton {...props} />
                </ButtonsColumn>
                {!props.isPictureInPictureMode && (
                    <LocalVideoColumn isRosterOpen={rosterContext.isOpen()}>
                        <LocalVideo />
                    </LocalVideoColumn>
                )}
            </ControlsRoot>
        )
    }
}

/* #region  MuteButton */
function MuteButton(props: DraggingProps) {
    const audioContext = useAudioContext()
    const muted = audioContext.isMuted()

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        if (muted) {
            audioContext.realtimeUnmuteLocalAudio()
        } else {
            audioContext.realtimeMuteLocalAudio()
        }
        // Adds a slight delay to close the tooltip before rendering the updated text in it
        await new Promise((resolve) => setTimeout(resolve, 10))
    }

    return (
        <Button
            tooltip={muted ? branding.conferenceTexts.unmute : branding.conferenceTexts.mute}
            icon={
                muted
                    ? IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })
                    : IconMicrophone({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })
            }
            onClick={onClick}
        />
    )
}
/* #endregion */

/* #region  SoundOnOffButton */
function SoundOnOffButton(props: DraggingProps) {
    const audioContext = useAudioContext()

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        audioContext.setVolume(audioContext.getVolume() === 0 ? 100 : 0)
    }

    return (
        <Button
            tooltip={audioContext.getVolume() === 100 ? branding.conferenceTexts.soundOff : branding.conferenceTexts.soundOn}
            icon={
                audioContext.getVolume() === 100
                    ? IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark })
                    : IconVolumeOff({ fill: branding.sideIconBar.sideIconColorDark })
            }
            onClick={onClick}
        />
    )
}
/* #endregion */

/* #region  CamOnOffButton */
function CamOnOffButton(props: DraggingProps) {
    const videoContext = useVideoContext()
    const videoStatus = videoContext.getLocalVideoStatus()

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        videoContext.toggleLocalVideoTile()
    }

    return (
        <Button
            tooltip={
                videoStatus === LocalVideoStatus.Disabled ? branding.conferenceTexts.videoOn : branding.conferenceTexts.videoOff
            }
            icon={
                videoStatus === LocalVideoStatus.Enabled
                    ? IconCamera({ fill: branding.sideIconBar.sideIconColorDark, width: "21", height: "21" })
                    : IconCameraOff({ fill: branding.sideIconBar.sideIconColorDark, width: "21", height: "21" })
            }
            disabled={!videoContext.hasWebcam() || videoContext.getLocalVideoStatus() === LocalVideoStatus.Loading}
            onClick={onClick}
        />
    )
}

/* #region  ShareScreenButton */
function ShareScreenButton(props: DraggingProps) {
    const chime = useChimeContext()
    const contentShareContext = useContentShareContext()
    const modContext = useModContext()

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        if (contentShareContext.isLocalAttendeeSharingScreen()) {
            contentShareContext.stopContentShare()
        } else {
            contentShareContext.startContentShareFromScreenCapture()
        }
    }

    return (
        <Button
            tooltip={
                contentShareContext.isLocalAttendeeSharingScreen()
                    ? branding.conferenceTexts.shareScreenStop
                    : branding.conferenceTexts.shareScreenStart
            }
            icon={
                contentShareContext.isLocalAttendeeSharingScreen()
                    ? IconShareScreenStop({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "22" })
                    : IconShareScreen({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "22" })
            }
            disabled={
                (!contentShareContext.isLocalAttendeeSharingScreen() && contentShareContext.isSharingScreen()) ||
                ((chime.getKind() === "breakout" || chime.getKind() === "showroom") && !modContext.isMod())
            }
            onClick={onClick}
        />
    )
}

/* #region  RaiseHandButton */
function RaiseHandButton(props: DraggingProps) {
    const rosterContext = useRosterContext()

    const isHandRaised = rosterContext.isMyHandRaised()

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        rosterContext.raiseHand(rosterContext.getLocalAttendeeId()!, !isHandRaised)
    }
    return (
        <Button
            tooltip={isHandRaised ? branding.conferenceTexts.lowerHand : branding.conferenceTexts.raiseHand}
            icon={
                isHandRaised
                    ? IconLowerHand({ fill: branding.sideIconBar.sideIconColorDark, width: "23", height: "25" })
                    : IconRaiseHand({ fill: branding.sideIconBar.sideIconColorDark, width: "23", height: "25" })
            }
            onClick={onClick}
        />
    )
}

/* #endregion */

/* #region  OpenChatButton */
interface OpenChatButtonProps extends DraggingProps {
    isChatGroup?: boolean
}

function OpenChatButton(props: OpenChatButtonProps) {
    const chime = useChimeContext()
    const rosterContext = useRosterContext()
    const appState = useAppState()
    const profileId = useLoggedInState().user()?.profileId

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()

        const meetingKind = chime.getKind()
        const meetingId = chime.getExternalMeetingId()
        const conversationId = meetingId?.substr(3)
        switch (meetingKind) {
            case "call":
                const roster = rosterContext.getRoster()
                const rosterKeys = Object.keys(roster)
                const attendeeIds = rosterKeys.map((key) => roster[key].id)
                const opponentIds = attendeeIds.filter((id) => id !== profileId)
                if (!props.isChatGroup && opponentIds.length === 1 && opponentIds[0]) {
                    appState.setShowChatsTab(ChatConversationParam.privateConversationByOpponentId(opponentIds[0]))
                } else if (conversationId) {
                    appState.setShowChatsTab(
                        ChatConversationParam.conversationByConversationId(ConversationType.GROUP, conversationId)
                    )
                }
                break
            case "calenderEntry":
                if (conversationId) {
                    appState.setShowChatsTab(
                        ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, conversationId)
                    )
                }
                break
            case "virtualCafe":
            case "showroom":
            case "breakout":
            case "conferenceroom":
            case "greenroom":
            case "roundtable":
                appState.setIsMeetingChatShown(true)
                rosterContext.setOpen(true)
                break
            default:
                // TODO behavior not yet defined
                break
        }
    }
    return (
        <Button
            tooltip={branding.conferenceTexts.openChat}
            icon={IconChat({ width: "17", height: "19", fill: branding.sideIconBar.sideIconColorDark })}
            onClick={onClick}
        />
    )
}
/* #endregion */

/* #region  SettingsButton */
function SettingsButton(props: DraggingProps) {
    const appState = useAppState()

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        appState.setIsAudioVideoSettingsOpen(true)
    }

    return (
        <>
            <Button
                tooltip={branding.conferenceTexts.settings}
                icon={IconSettings({ fill: branding.sideIconBar.sideIconColorDark, width: "19", height: "19" })}
                onClick={onClick}
            />
        </>
    )
}

/* #endregion */

/* #region  EndCallButton */
interface EndCallButtonProps extends DraggingProps {
    isConferenceRoom?: boolean
}
function EndCallButton(props: EndCallButtonProps) {
    const chime = useChimeContext()
    const history = useHistory()

    const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.isDragging) {
            return
        }

        e.stopPropagation()
        chime.setKeepCallActive(false)
        chime.setMeetingStatus({ meetingStatusCode: MeetingStatusCode.Ended })

        // change presence only if user is not on masterclasses watching live stream
        if (history.location.pathname.indexOf("/masterclasses/") < 0) {
            accessPresenceState.updateMyPresence(EventType.EVENT_END)
        }

        if (props.isConferenceRoom) {
            if (window.localStorage.getItem("routeBeforeCall")) {
                history.push(window.localStorage.getItem("routeBeforeCall") as string)
            } else {
                history.push("/")
            }
        } else {
            // ConferenceOverlay
            chime.setShowConferenceOverlay(false)
        }
    }

    return (
        <Button
            tooltip={branding.conferenceTexts.leave}
            icon={IconEndCall({ fill: "#fff" })}
            color="#fff"
            backgroundColor="red"
            onClick={onClick}
        />
    )
}
/* #endregion */
