import { v4 as uuidv4 } from "uuid"
import { MeetingKind } from "../../backendServices/MeetingServices"

export function generateMeetingURL(meetingKind: MeetingKind, meetingID?: string) {
    switch (meetingKind) {
        case "call":
            // A call meeting ID is generated by the app itself
            return "cl_" + uuidv4()
        case "showroom":
            // A showroom meeting id is the exibitor alias
            return "sr_" + meetingID
        case "virtualCafe":
            // A virtual caffe meeting id is defined custom in the branding.js file
            return "vc_" + meetingID
        case "calenderEntry":
            // TODO:
            return "ce_" + meetingID
        case "greenroom":
            // A green room meeting id is the channel alias
            return "gr_" + meetingID
        case "roundtable":
            // A roundtable room meeting id se the eventdate alias:
            return "rt_" + meetingID
        case "breakout":
            // A breakout room meeting id is the event date alias
            return "br_" + meetingID
        case "conferenceroom":
            // A conference room meeting id is the exhibitor alias
            return "cr_" + meetingID
        default:
            return ""
    }
}
