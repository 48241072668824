import { useState, useEffect } from "react"

const useCallDurationTimer = (startAt = 0) => {
    const [duration, setDuration] = useState(startAt)
    const [isRunning, setIsRunning] = useState(false)
    useEffect(() => {
        let intervalId: any
        if (isRunning) {
            intervalId = setInterval(() => {
                setDuration((d) => d + 1)
            }, 1000)
        }
        return () => clearInterval(intervalId)
    }, [isRunning])

    const start = () => {
        setIsRunning(true)
    }

    const stop = () => {
        setIsRunning(false)
    }

    const formattedDuration = () => {
        const hours = Math.floor(duration / 3600)
        if (hours > 0) {
            const minutes = Math.floor((duration % 3600) / 60)
            const seconds = duration % 60
            return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`
        } else {
            const minutes = Math.floor(duration / 60)
            const seconds = duration % 60
            return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        }
    }

    return { duration, start, stop, formattedDuration }
}

export default useCallDurationTimer
