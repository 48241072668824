import styled from "styled-components"

export const DeviceSettingsRoot = styled.div`
    color: #fff !important; // dark mode only currently
    label {
        color: #fff;
    }
    select {
        color: #fff !important;
        border-color: #17181a;
        background-color: #17181a;
        color: #fff;
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }
`
export const SettingsGroup = styled.div`
    margin-bottom: 35px;
`
export const TestSpeakerButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
export const TestSpeakerButton = styled.div`
    background-color: #202428;
    border-radius: 3px;
    color: #fff;
    padding: 8px 12px;
    font-size: 12px;
    border: 1px solid #666666;
    transition: 0.3s;
    cursor: pointer;

    :hover {
        background-color: #666666;
        border: 1px solid #666666;
        outline: none;
        box-shadow: none;
    }
`
