import styled from "styled-components"
import branding from "../../branding/branding"

export const ConferenceRoomMainContainer = styled.div`
    /* This is kind of a restriction for the Settings modal, 
    but also the wrapper of the conference room */
    position: relative;
`

export const ConferenceRoomContainer = styled.div`
    background-color: #202428;
    height: 100vh;
    width: 100%;
    display: flex;

    /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

export const MainContent = styled.div`
    flex-grow: 8;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 62px);
    width: 100%;
    overflow: hidden;
    background-color: #202428;
`
export const BreadcrumbRoot = styled.div`
    background-color: ${branding.mainInfoColor ?? "black"};
    height: 62px;
    padding-bottom: 20px;
`

export const Remote = styled.div`
    background-color: #202428;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;

    div[data-testid="video-grid"] {
        background-color: #202428 !important;
    }
`

export const Local = styled.div`
    width: 100%;
    position: relative;
`

export const BottomPanel = styled.div`
    width: 100%;
    height: 100px;
    background-color: #202428;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`
