import { getMinutesBeforeSwitchingToTheNextEvent } from "../../utils/DateUtils"
/**
 * Calculates the amount of time between the end of the current event and the start of the next event,
 * accounting for a buffer time before switching to the next event.
 * @param {string} currentEventEndDate - The end date and time of the current event, in string format.
 * @param {string} nextEventStartDate - The start date and time of the next event, in string format.
 * @returns {number} The amount of time (in milliseconds) between the end of the current event and the start of the next event.
 */
export function calculateChange(currentEventEndDate: string, nextEventStartDate: string) {
    const changeDiff = moment(moment(nextEventStartDate)).diff(moment(currentEventEndDate))
    if (changeDiff <= 10 * 60 * 1000) {
        return changeDiff / 2
    } else {
        return changeDiff - getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000
    }
}
