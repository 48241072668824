import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { loadChannelFirstEventDate } from "../../../../backendServices/EventdateServices"
import { EventDate } from "../../../../backendServices/Types"
import branding from "../../../../branding/branding"

import { useAppState } from "../../../../globalStates/AppState"
import { BreakoutButtonIcon } from "../../../../ui/Icons"
import { useMeetingController } from "../../../context/MeetingController"
import { IconWrapper } from "../../../Roster/Recorder/RecorderStyles"
import { calculateChange } from "../../../utils/calculateChange"
import { calculateEnd } from "../../../utils/calculateEnd"
import { BreakoutRoomButtonRoot, BreakoutRoomButtonWrapper } from "./BreakoutRoomButtonStyles"
import { getMinutesBeforeSwitchingToTheNextEvent } from "../../../../utils/DateUtils"

function BreakoutRoomButton() {
    const meetingController = useMeetingController()
    const history = useHistory()
    const [currentEventDate, setCurrentEventDate] = useState<EventDate>()
    const timezone = useAppState().timezone

    /**
     * This method loads data about the current and next event dates from the backend
     * and updates the current event date in the component state. It also sets timers
     * to trigger a reload of the data when the current event ends or when the next
     * event starts.
     *
     * The method depends on the external meeting ID passed through the `meetingController`
     * prop. When this ID changes, the effect is triggered again.
     *
     * When the component unmounts, the effect clears the timers to avoid memory leaks.
     */

    const loadData = useCallback(async () => {
        const date = new Date()
        console.log(`loadData at ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`)

        // Define variables to hold the timers
        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        try {
            // Call the backend API to get the event dates
            const { currentEventDate, nextEventDate } = (await loadChannelFirstEventDate(
                meetingController.getExternalMeetingId()?.slice(3) || "",
                getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000
            )) as any

            // Update the current event date in the component state
            setCurrentEventDate(currentEventDate)

            // Calculate the remaining time until the end of the current event
            const currentEventDateEndTime = currentEventDate?.enddatetime
            const remainingCurrentEventDate = currentEventDateEndTime ? calculateEnd(currentEventDateEndTime, timezone) : 0

            // Set the timer to trigger a reload of the data when the current event ends
            if (remainingCurrentEventDate > 0) {
                if (currentEventDate && !nextEventDate) {
                    remainingCurrentEventDateTimer = window.setTimeout(loadData, remainingCurrentEventDate)
                } else if (currentEventDate && nextEventDate) {
                    const timeToLive =
                        calculateChange(currentEventDateEndTime!, nextEventDate.startdatetime) + remainingCurrentEventDate

                    changeExecutionTimer = window.setTimeout(loadData, timeToLive)
                }
                // Set the timer to trigger a reload of the data when the next event starts
            } else if (currentEventDate && nextEventDate) {
                const changeExecution =
                    calculateChange(currentEventDateEndTime!, nextEventDate.startdatetime) - Math.abs(remainingCurrentEventDate)
                changeExecutionTimer = window.setTimeout(loadData, changeExecution)
            }
        } catch (err) {
            console.error("Could not load data in loadChannelFirstEventDate method. Error: ", err)
        }
        // Clear the timers when the component unmounts to avoid memory leaks
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // Load the data when the effect runs for the first time or when the external meeting ID changes
        loadData()
        // eslint-disable-next-line
    }, [meetingController.getExternalMeetingId()])

    const handleClick = () => {
        meetingController.setIsSwitchingRoom(true)
        if (currentEventDate) history.push(`/meetingV2/br_${currentEventDate.id}`)
    }

    if (
        meetingController.getMeetingKind() !== "greenroom" ||
        !branding.configuration.breakoutEnabled ||
        !currentEventDate ||
        !currentEventDate?.breakoutAccess
    )
        return null

    return (
        <BreakoutRoomButtonWrapper>
            <BreakoutRoomButtonRoot onClick={handleClick} title={`/meetingV2/br_${currentEventDate.id}`}>
                <IconWrapper>
                    <BreakoutButtonIcon fill="#fff" width="18" height="18" />
                </IconWrapper>
                {branding.videoPageContentBranding.joinBreakoutButtonText}
            </BreakoutRoomButtonRoot>
        </BreakoutRoomButtonWrapper>
    )
}

export default BreakoutRoomButton
