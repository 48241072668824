// Docs:
// https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application

import { SyntheticEvent, useRef, useState } from "react"
import { DeviceSettingsRoot, SettingsGroup } from "../AudioSettings/AudioSettingsStyles"
import { PreviewVideoContainer, PreviewVideoOverlay } from "../VideoSettings/VideoSettingsStyles"

import ToggleSwitch from "../../../ui/ToggleSwitch/ToggleSwitch"
import { detect } from "detect-browser"
import { SettingsLabel, SettingsSectionTitle } from "../Settings/SettingsStyles"
import { Col, Container, Row } from "react-bootstrap"
import { IconTrash, IconUpload } from "../../../ui/Icons"
import {
    BackgroundGalleryContainer,
    BackgroundGalleryItem,
    CustomBackgroundActionsOverlay,
    IconContainer,
    NoContentShift,
    ToggleGroup,
    TrashIconContainer
} from "./BackgroundSettingsStyles"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import { useConferenceRoomSettingsContext } from "../../context/ConferenceRoomSettingsContext"
import branding from "../../../branding/branding"

function BackgroundSettings() {
    const {
        getBackgroundGalleryItems,
        setSelectedBackground,
        getSelectedBackground,
        removeBackground,
        toggleBlur,
        getIsBlurActive,
        uploadBackground,
        getCustomUploadedBackground,
        deleteCustomBackground
    } = useConferenceRoomSettingsContext()
    const [galleryImageUrls] = useState(getBackgroundGalleryItems())
    const browserInfo = detect()
    const backgroundInputRef = useRef<HTMLInputElement | null>(null)

    return (
        <>
            <DeviceSettingsRoot>
                <SettingsGroup>
                    <PreviewVideoContainer>
                        <NoContentShift browser={browserInfo?.name}>
                            <PreviewVideo />
                            <PreviewVideoOverlay>
                                <span>{branding.audioVideoSettings.preview}</span>
                            </PreviewVideoOverlay>
                        </NoContentShift>
                    </PreviewVideoContainer>

                    <ToggleGroup>
                        <SettingsLabel>{branding.audioVideoSettings.radioBlurBackground}</SettingsLabel>
                        <ToggleSwitch
                            scaleSize={0.7}
                            isEnabled={getIsBlurActive()}
                            onClick={async () => {
                                toggleBlur()
                            }}
                        />
                    </ToggleGroup>
                </SettingsGroup>

                <SettingsGroup>
                    <Container className="p-0">
                        <Row>
                            <Col>
                                <SettingsSectionTitle>
                                    {branding.audioVideoSettings.backgroundGalleryTitleLabel}
                                </SettingsSectionTitle>
                            </Col>
                            <Col>
                                <IconContainer
                                    onClick={() => {
                                        // this fixes problem with reuploading same image not working
                                        if (backgroundInputRef.current) {
                                            backgroundInputRef.current.value = ""
                                        }
                                        backgroundInputRef.current?.click()
                                    }}
                                >
                                    {IconUpload({ fill: "#fff", width: "15px", height: "15px" })}
                                </IconContainer>
                                <input
                                    onChange={(e: SyntheticEvent) => {
                                        uploadBackground(e)
                                    }}
                                    ref={backgroundInputRef}
                                    type="file"
                                    accept="image/png, image/jpg, image/jpeg"
                                    style={{ display: "none" }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BackgroundGalleryContainer>
                                    <BackgroundGalleryItem
                                        isActive={getSelectedBackground() === null && getIsBlurActive() === false}
                                        onClick={() => removeBackground()}
                                        image={""}
                                    >
                                        {branding.audioVideoSettings.radioNoBackground}
                                    </BackgroundGalleryItem>

                                    {galleryImageUrls.map((url, key) => {
                                        return (
                                            <BackgroundGalleryItem
                                                onClick={() => {
                                                    if (url === getCustomUploadedBackground()) return
                                                    setSelectedBackground(url)
                                                }}
                                                key={key}
                                                isActive={getSelectedBackground() === url}
                                                image={url}
                                            >
                                                {getCustomUploadedBackground() === url && (
                                                    <>
                                                        <CustomBackgroundActionsOverlay
                                                            onClick={() => {
                                                                if (url === getSelectedBackground()) return
                                                                setSelectedBackground(url)
                                                            }}
                                                        ></CustomBackgroundActionsOverlay>

                                                        <div className="actions">
                                                            <TrashIconContainer
                                                                onClick={() => {
                                                                    deleteCustomBackground()
                                                                }}
                                                            >
                                                                {IconTrash({
                                                                    width: "12px",
                                                                    height: "12x",
                                                                    fill: "#fff"
                                                                })}
                                                            </TrashIconContainer>
                                                        </div>
                                                    </>
                                                )}
                                            </BackgroundGalleryItem>
                                        )
                                    })}
                                </BackgroundGalleryContainer>
                            </Col>
                        </Row>
                    </Container>
                </SettingsGroup>
            </DeviceSettingsRoot>
        </>
    )
}

export default BackgroundSettings
