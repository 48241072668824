import { Fragment, useEffect, useState } from "react"
import styled from "styled-components"
import { Category } from "../backendServices/Types"
import branding from "../branding/branding"
import { useLanguageState } from "../globalStates/LanguageState"
import SimpleTreeComponent, { TreeData } from "./detailPages/components/SimpleTreeComponent"
import { device } from "../utils/Device"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../navigationArea/RoutePaths"
import useWindowDimensions from "../ui/WindowDimensionsHook"
import { ViewMode } from "../ui/CrsTabs"
import Highlighter from "react-highlight-words"

const MainRoot = styled.div`
    width: 100%;
    padding: 0 20px 50px 25px;

    @media ${device.tablet} {
        padding: 0 25px 50px 25px;
    }
`

const MainCategoriesRoot = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`
const CategoriesTilesContainer = styled.div`
    position: relative;
    width: calc(100% + 15px);
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    font-family: ${branding.font1};
`
const HeaderTitle = styled.h1<{ isLink?: boolean; fontSize?: string }>`
    width: 100%;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")} !important;
    font-style: normal;
    font-weight: 700;
    margin: 20px 0 10px 0;
    cursor: ${(props) => (props.isLink ? "pointer" : "default")};
    text-decoration: ${(props) => (props.isLink ? "underline" : "none")};
    font-family: ${branding.font1};
`
const SimpleTreeComponentRoot = styled.div`
    width: 100%;
`
const FullWidthRoot = styled.div`
    width: 100%;
    padding-bottom: 20px;
`
export const EmptyListMessage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${branding.font1};
    color: ${branding.primaryColor};
    font-size: 20px;
    margin-top: 100px;
`

enum categoriesTitlePositions {
    TOP = "TOP",
    CENTER = "CENTER",
    BOTTOM = "BOTTOM"
}

const categoryTilesCustomBackgrounds: any = branding.categoriesPageContent.categoriesTileColorMappings

const CategoryTile = styled.div<{
    disabled: boolean
    titlePosition?: string
    alias?: string
    defaultBackground?: string
    activeBackground?: string
    disabledBackground?: string
}>`
    min-height: 50px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: ${(props) => {
        if (props.titlePosition === categoriesTitlePositions.TOP) {
            return "flex-start"
        } else if (props.titlePosition === categoriesTitlePositions.CENTER) {
            return "center"
        } else if (props.titlePosition === categoriesTitlePositions.BOTTOM) {
            return "flex-end"
        } else {
            return "center"
        }
    }};
    padding: 25px 3px;
    background-color: ${(props) => {
        const curObj = categoryTilesCustomBackgrounds.filter((obj: any) => obj.alias === props.alias)
        if (curObj.length) {
            return curObj[0].defaultColor
        } else {
            if (props.disabled) {
                return branding.categoriesPageContent.categoriesTileDisabledColor
            } else {
                return branding.categoriesPageContent.categoriesTileInctiveColor
            }
        }
    }};
    margin: 8px;
    text-align: center;
    color: #fff;
    width: calc(16.6666666667% - 16px);
    border-radius: 5px;
    cursor: pointer;

    &.active {
        background-color: ${(props) => {
            const curObj = categoryTilesCustomBackgrounds.filter((obj: any) => obj.alias === props.alias)
            if (curObj.length) {
                return curObj[0].activeColor
            } else {
                return branding.categoriesPageContent.categoriesTileActiveColor
            }
        }};

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            width: 0;
            height: 0;
            border-top: ${(props) => {
                const curObj = categoryTilesCustomBackgrounds.filter((obj: any) => obj.alias === props.alias)
                if (curObj.length) {
                    return "solid 10px " + curObj[0].activeColor
                } else {
                    return "solid 10px " + branding.categoriesPageContent.categoriesTileActiveColor
                }
            }};
            border-left: solid 10px transparent;
            border-right: solid 10px transparent;

            @media ${device.tablet} {
                margin-left: -20px;
                border-top: ${(props) => {
                    const curObj = categoryTilesCustomBackgrounds.filter((obj: any) => obj.alias === props.alias)
                    if (curObj.length) {
                        return "solid 20px " + curObj[0].activeColor
                    } else {
                        return "solid 20px " + branding.categoriesPageContent.categoriesTileActiveColor
                    }
                }};

                border-left: solid 20px transparent;
                border-right: solid 20px transparent;
            }
        }
    }

    @media (max-width: 1200px) {
        width: calc(33.3333333% - 16px);
    }

    @media ${device.tablet} {
        min-height: 150px;
        height: auto;
        padding: 25px 20px;
    }

    @media ${device.mobile} {
        align-items: center;
    }
`
const CategoryTileLabel = styled.p`
    width: 100%;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 12px;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    @media ${device.tablet} {
        font-size: 16px;
    }
`

const showCodeForTile = branding.categoriesPageContent.categoriesTileShowCode
const showCodeForList = branding.categoriesPageContent.categoriesListShowCode

const generateLabel = (showCode: boolean, category: Category): string => {
    if (showCode && category.code !== null) {
        return `${category.code} ${category.label}`
    } else {
        return `${category.label}`
    }
}

const generateNodes = (showCode: boolean, node: Category): any => {
    return {
        key: node.key!,
        label: generateLabel(showCode, node),
        nodes: node.nodes?.map((a: Category) => {
            return generateNodes(showCode, a)
        }) as unknown as TreeData
    }
}

/*********************************************************************************************
 * HELPER FUNCTIONS
 **********************************************************************************************/
const findTreeComponentRowPosition = (categories: Category[] | undefined, selectedCategory: Category | null) => {
    if (!categories?.length || !selectedCategory) return null
    let selectedCategoryIndex = categories?.findIndex((x) => x.key === selectedCategory?.key)
    let treeComponentRowPosition: number | null = 0
    let numberOfItemsPerRow = window.innerWidth > 1200 ? 6 : 3
    if (selectedCategoryIndex !== -1) {
        selectedCategoryIndex = (selectedCategoryIndex + 1) / numberOfItemsPerRow
        if (selectedCategoryIndex % 1 !== 0) selectedCategoryIndex = Math.ceil(selectedCategoryIndex)

        let lastOfTheRowIndex = selectedCategoryIndex * numberOfItemsPerRow - 1
        categories[lastOfTheRowIndex]
            ? (treeComponentRowPosition = lastOfTheRowIndex)
            : (treeComponentRowPosition = categories!.length - 1)
    } else treeComponentRowPosition = null
    return treeComponentRowPosition
}

/*********************************************************************************************
 * TILES VIEW WITH NO ROOT CATEGORIES
 **********************************************************************************************/

interface TilesWithNoRootCategoriesProps {
    categories: Category[]
    selectedCategory: Category | null
    onCategorySelect: (category: Category) => void
    language: string
    showSearchResults?: boolean
    searchParam?: string
}

const TilesWithNoRootCategories = (props: TilesWithNoRootCategoriesProps) => {
    const { useMobileDesign } = useWindowDimensions()

    const [treeComponentRowPosition, setTreeComponentRowPosition] = useState(0) // position of the last element from the row, just right before the place where the tree component should be injected
    useEffect(() => {
        let position = findTreeComponentRowPosition(props.categories, props.selectedCategory)
        if (position) setTreeComponentRowPosition(position)
        // eslint-disable-next-line
    }, [props.selectedCategory])
    return (
        <MainCategoriesRoot>
            <HeaderTitle>{branding.categoriesPageContent.categoriesPageTitle}</HeaderTitle>
            <CategoriesTilesContainer>
                {props.categories.map((cat, index) => {
                    return (
                        <Fragment key={index}>
                            <CategoryTile
                                alias={cat.alias}
                                titlePosition={branding.categoriesPageContent.categoriesTileTitlePosition}
                                key={cat.key}
                                className={`${cat.key === props.selectedCategory?.key && "active"}`}
                                onClick={() => props.onCategorySelect(cat)}
                                disabled={cat.nodes?.length === 0}
                            >
                                <CategoryTileLabel>
                                    {useMobileDesign ? cat.label?.replace(/(.{25})..+/, "$1…") : cat.label}
                                </CategoryTileLabel>
                            </CategoryTile>
                            {props.selectedCategory && props.selectedCategory.nodes && index === treeComponentRowPosition && (
                                <SimpleTreeComponentRoot>
                                    <SimpleTreeComponent
                                        horizontalPadding={true}
                                        treeData={props.selectedCategory.nodes.map((node: Category) => {
                                            return generateNodes(showCodeForTile, node) as unknown as TreeData
                                        })}
                                        showSearchResults={props.showSearchResults}
                                        searchParam={props.searchParam}
                                        showCategoryHierarchy={true}
                                    />
                                </SimpleTreeComponentRoot>
                            )}
                        </Fragment>
                    )
                })}
            </CategoriesTilesContainer>
        </MainCategoriesRoot>
    )
}

/*********************************************************************************************
 * TILES VIEW WITH ROOT CATEGORIES
 **********************************************************************************************/

interface TilesWithRootCategoriesProps {
    categories: Category[]
    selectedCategory: Category | null
    onCategorySelect: (category: Category) => void
    language: string
    showSearchResults?: boolean
    searchParam?: string
}

const TilesWithRootCategories = (props: TilesWithRootCategoriesProps) => {
    const rootCategory = props.categories && props.categories[0]
    const rootCategoryConfig = branding.categoriesPageContent.categoriesConfiguration.find(
        (cat) => cat.rootCategoryAlias === rootCategory.key
    )
    const categoriesToShow =
        rootCategoryConfig?.subCategories && rootCategoryConfig.subCategories.length > 0
            ? rootCategory.nodes?.filter((cat) => rootCategoryConfig.subCategories.includes(cat.key ?? ""))
            : props.categories

    const emptyList = categoriesToShow?.every((cat) => cat.nodes?.length === 0)

    if (emptyList) return <EmptyListMessage>{branding.categoriesPageContent.emptyListMessage}</EmptyListMessage>
    return (
        <FullWidthRoot>
            {categoriesToShow?.map((cat, index) => {
                return (
                    <TilesWithRootCategoriesRow
                        key={index}
                        category={cat}
                        selectedCategory={props.selectedCategory}
                        onCategorySelect={props.onCategorySelect}
                        language={props.language}
                        showSearchResults={props.showSearchResults}
                        searchParam={props.searchParam}
                    />
                )
            })}
        </FullWidthRoot>
    )
}

interface TilesWithRootCategoriesRowProps {
    category: Category
    selectedCategory: Category | null
    onCategorySelect: (category: Category) => void
    language: string
    showSearchResults?: boolean
    searchParam?: string
}

const HeaderTitleWrapper = styled.div<{ color?: string }>`
    padding: 0 8px 0 8px;
`
const TilesWithRootCategoriesRow = (props: TilesWithRootCategoriesRowProps) => {
    const [treeComponentRowPosition, setTreeComponentRowPosition] = useState(0) // position of the last element from the row, just right before the place where the tree component should be injected
    const { useMobileDesign } = useWindowDimensions()
    const suggestState = useSuggestContext()
    const history = useHistory()

    useEffect(() => {
        let position = findTreeComponentRowPosition(props.category.nodes, props.selectedCategory)
        if (position) setTreeComponentRowPosition(position)
        // eslint-disable-next-line
    }, [props.selectedCategory])

    const addCategoryToSearchFilter = (key: string, label: string, props: any) => {
        const formatedKey = key.replace(props.parent + "/", "")
        suggestState.addExternalSuggestion({
            icon: "",
            id: "",
            subtitle: "",
            title: label,
            value: "cat_" + formatedKey,
            active: true
        })
        history.push(globalSearchResultPageRoute)
    }

    if (!props.category.nodes?.length) return <></>

    return (
        <FullWidthRoot>
            <HeaderTitle fontSize={"18px"}>{props.category.label}</HeaderTitle>
            <CategoriesTilesContainer>
                {props.category.nodes &&
                    props.category.nodes.length &&
                    props.category.nodes.map((cat, index) => {
                        return (
                            <Fragment key={index}>
                                <CategoryTile
                                    titlePosition={branding.categoriesPageContent.categoriesTileTitlePosition}
                                    key={cat.key}
                                    alias={cat.key}
                                    className={cat.key === props.selectedCategory?.key ? "active" : ""}
                                    disabled={cat.nodes?.length === 0}
                                    onClick={() => props.onCategorySelect(cat)}
                                >
                                    <CategoryTileLabel>
                                        <Highlighter
                                            caseSensitive={false}
                                            searchWords={[props.searchParam || ""]}
                                            highlightStyle={{
                                                display: "inline",
                                                backgroundColor:
                                                    branding.categoriesPageContent.categorySearchHighlightBackgroundColor,
                                                color: branding.categoriesPageContent.categorySearchHighlightFontColor,
                                                fontWeight: "bold",
                                                padding: 0
                                            }}
                                            textToHighlight={
                                                useMobileDesign
                                                    ? generateLabel(showCodeForTile, cat).replace(/(.{25})..+/, "$1…")
                                                    : generateLabel(showCodeForTile, cat)
                                            }
                                        />
                                    </CategoryTileLabel>
                                </CategoryTile>
                                {props.selectedCategory &&
                                    props.selectedCategory.nodes &&
                                    props.selectedCategory.nodes.length &&
                                    props.category.nodes?.find((cat) => cat.key === props.selectedCategory?.key) &&
                                    index === treeComponentRowPosition && (
                                        <SimpleTreeComponentRoot>
                                            <HeaderTitleWrapper>
                                                {branding.categoriesPageContent.categoriesTileListHeaderVisible ? (
                                                    <HeaderTitle
                                                        fontSize={"16px"}
                                                        isLink={true}
                                                        onClick={() =>
                                                            addCategoryToSearchFilter(
                                                                props.selectedCategory?.key as string,
                                                                generateLabel(
                                                                    showCodeForTile,
                                                                    props.selectedCategory as Category
                                                                ),
                                                                []
                                                            )
                                                        }
                                                    >
                                                        {generateLabel(showCodeForTile, props.selectedCategory)}
                                                    </HeaderTitle>
                                                ) : (
                                                    <div style={{ height: "20px" }}></div>
                                                )}
                                            </HeaderTitleWrapper>

                                            <SimpleTreeComponent
                                                horizontalPadding={true}
                                                key={cat.key}
                                                treeData={props.selectedCategory.nodes.map((node) => {
                                                    return generateNodes(showCodeForTile, node) as unknown as TreeData
                                                })}
                                                showSearchResults={props.showSearchResults}
                                                searchParam={props.searchParam}
                                                showCategoryHierarchy={true}
                                            />
                                        </SimpleTreeComponentRoot>
                                    )}
                            </Fragment>
                        )
                    })}
            </CategoriesTilesContainer>
        </FullWidthRoot>
    )
}

/*********************************************************************************************
 * LIST VIEW WITH NO ROOT CATEGORIES
 **********************************************************************************************/

interface ListWithNoRootCategoriesProps {
    categories: Category[]
    language: string
    showSearchResults?: boolean
    searchParam?: string
}

const ListWithNoRootCategories = (props: ListWithNoRootCategoriesProps) => {
    return (
        <FullWidthRoot>
            <HeaderTitle>{branding.categoriesPageContent.categoriesPageTitle}</HeaderTitle>
            <SimpleTreeComponentRoot>
                <SimpleTreeComponent
                    treeData={props.categories.map((node) => {
                        return generateNodes(showCodeForList, node) as unknown as TreeData
                    })}
                    showSearchResults={props.showSearchResults}
                    searchParam={props.searchParam}
                    showCategoryHierarchy={true}
                />
            </SimpleTreeComponentRoot>
        </FullWidthRoot>
    )
}

/*********************************************************************************************
 * LIST VIEW WITH ROOT CATEGORIES
 **********************************************************************************************/

interface ListViewWithRootCategoriesProps {
    categories: Category[]
    language: string
    showSearchResults?: boolean
    searchParam?: string
}

const ListViewWithRootCategories = (props: ListViewWithRootCategoriesProps) => {
    const rootCategory = props.categories && props.categories[0]
    const rootCategoryConfig = branding.categoriesPageContent.categoriesConfiguration.find(
        (cat) => cat.rootCategoryAlias === rootCategory.key
    )
    const categoriesToShow =
        rootCategoryConfig?.subCategories && rootCategoryConfig.subCategories.length > 0 ? rootCategory.nodes : props.categories
    const emptyList = categoriesToShow?.every((cat) => cat.nodes?.length === 0)

    if (emptyList) return <EmptyListMessage>{branding.categoriesPageContent.emptyListMessage}</EmptyListMessage>
    else
        return (
            <FullWidthRoot>
                {categoriesToShow &&
                    categoriesToShow.length > 0 &&
                    categoriesToShow.map((cat, index) => {
                        if (!cat.nodes?.length) return null
                        return (
                            <Fragment key={index}>
                                <HeaderTitle>{cat.label}</HeaderTitle>
                                {cat.nodes && cat.nodes.length && (
                                    <SimpleTreeComponentRoot>
                                        <SimpleTreeComponent
                                            treeData={cat.nodes.map((node) => {
                                                return generateNodes(showCodeForList, node) as unknown as TreeData
                                            })}
                                            showSearchResults={props.showSearchResults}
                                            searchParam={props.searchParam}
                                            showCategoryHierarchy={true}
                                        />
                                    </SimpleTreeComponentRoot>
                                )}
                            </Fragment>
                        )
                    })}
            </FullWidthRoot>
        )
}

/*********************************************************************************************
 * MAIN CONTENT
 **********************************************************************************************/

interface MainCategoriesProps {
    categories: Category[]
    viewType: ViewMode
    hasRootCategories: boolean
    custom?: boolean
    showSearchResults?: boolean
    searchParam?: string
}

const MainCategories = (props: MainCategoriesProps) => {
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
    const lang = useLanguageState().getLanguage()
    const suggestState = useSuggestContext()
    const history = useHistory()

    const onCategorySelect = (category: Category) => {
        if (selectedCategory?.key === category.key) {
            setSelectedCategory(null)
        } else {
            if (category.nodes?.length) {
                setSelectedCategory(category)
            } else {
                setSelectedCategory(null)
                if (category.key && category.label) {
                    suggestState.addExternalSuggestion({
                        icon: "",
                        id: "",
                        subtitle: "",
                        title:
                            branding.categoriesPageContent.categoriesTileShowCode && category.code
                                ? category.code + " " + category.label
                                : category.label,
                        value: "cat_" + category.key,
                        active: true
                    })
                    history.push(globalSearchResultPageRoute)
                }
            }
        }
    }

    return (
        <MainRoot>
            {/* When tiles view is active and there is no root categories */}
            {props.viewType === ViewMode.TILES && !props.hasRootCategories && (
                <TilesWithNoRootCategories
                    categories={props.categories}
                    selectedCategory={selectedCategory}
                    onCategorySelect={onCategorySelect}
                    language={lang}
                    showSearchResults={props.showSearchResults}
                    searchParam={props.searchParam}
                />
            )}

            {/* When tiles view is active and there are root categories */}
            {props.viewType === ViewMode.TILES && props.hasRootCategories && (
                <TilesWithRootCategories
                    categories={props.categories}
                    selectedCategory={selectedCategory}
                    onCategorySelect={onCategorySelect}
                    language={lang}
                    showSearchResults={props.showSearchResults}
                    searchParam={props.searchParam}
                />
            )}

            {/* When the list view is active and there is no root categories */}
            {props.viewType === ViewMode.LIST && !props.hasRootCategories && (
                <ListWithNoRootCategories
                    categories={props.categories}
                    language={lang}
                    showSearchResults={props.showSearchResults}
                    searchParam={props.searchParam}
                />
            )}

            {/* When the list view is active and there are root categories */}
            {props.viewType === ViewMode.LIST && props.hasRootCategories && (
                <ListViewWithRootCategories
                    categories={props.categories}
                    language={lang}
                    showSearchResults={props.showSearchResults}
                    searchParam={props.searchParam}
                />
            )}
        </MainRoot>
    )
}

export default MainCategories
