import { useMemo } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../branding/branding"
import CrsMultiSwitch from "../ui/CrsMultiSwitch"
import { Row } from "react-bootstrap"
import useWindowDimensions from "./WindowDimensionsHook"

const FilterBarWrapper = styled.div`
    padding: 0 25px;
`

const FilterBarRoot = styled(Row)`
    margin: 0 auto;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d9d9d9;
    border-top: ${(props) => (props.showTopBorder ? "1px solid #D9D9D9" : "none")};
    width: 100%;
`
const FilterBarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 20px; /* added */
    /* width: 97%; */
`

interface FilterBarProps {
    activeFilter: string | null
    onFilterChanged(value: string | null): void
    showTopBorder?: boolean
}

const FilterBar: React.FunctionComponent<FilterBarProps> = (props: FilterBarProps) => {
    const filterItems = useMemo(() => {
        const items = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").map((c) => {
            return { value: c, label: c }
        }) as { value: string | null; label: string }[]
        items.unshift({ value: null, label: "" })
        return items
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // "All" text changes on language change. Therefor this cannot be part of the memo
    filterItems[0].label = branding.filterBar.allFilterTitle

    const { isMobile } = useWindowDimensions()

    if (isMobile) return null

    return (
        <FilterBarWrapper>
            <FilterBarRoot showTopBorder={props.showTopBorder}>
                <FilterBarContainer>
                    <CrsMultiSwitch
                        items={filterItems}
                        activeItem={props.activeFilter}
                        onItemClick={props.onFilterChanged}
                        style={{
                            mainWidth: "100%",
                            border: "1px solid transparent",
                            msRootItem: { fontSize: "0.8rem" },
                            msItem: { position: "relative", zIndex: 1 },
                            msActiveItem: {
                                backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherDefaultBgColor,
                                color: branding.crsMultiSwitcher.crsMultiSwitcherDefaultColor,
                                position: "absolute",
                                zIndex: 0
                            }
                        }}
                    />
                </FilterBarContainer>
            </FilterBarRoot>
        </FilterBarWrapper>
    )
}

export default FilterBar
