import React, { Dispatch, SetStateAction } from "react"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import TextLimit from "../../TextLimit"
import { TextareaInput, TextareaWrapper } from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode, onInputTextChangedNotes } from "./ModalFunctions"

interface PersonalNoteProps {
    placeholder: string
    setNote?: Dispatch<SetStateAction<string | undefined>>
    note?: string | undefined
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
}
const MAX_NOTES_LENGTH = 200

const TextareaPersonalNote = (props: PersonalNoteProps) => {
    return (
        <TextareaWrapper>
            <TextareaInput
                placeholder={props.placeholder}
                value={props.note}
                onChange={(event) =>
                    props.viewMode === CalendarEntryModalViewMode.VIEW
                        ? undefined
                        : onInputTextChangedNotes(event, MAX_NOTES_LENGTH, props.setNote!)
                }
            />
            <TextLimit
                position="absolute"
                bottom="5px"
                right="5px"
                textLength={props.note?.length || 0}
                maxTextLength={MAX_NOTES_LENGTH}
            />
        </TextareaWrapper>
    )
}

export default TextareaPersonalNote
