import moment, { Moment } from "moment"
import "moment-timezone"
import { EventDate } from "../backendServices/Types"
import * as _ from "lodash"
import { format } from "date-fns"
import en from "date-fns/locale/en-GB"
import de from "date-fns/locale/de"
import branding from "../branding/branding"
import { isSoftOpeningPhase, isLivePhase } from "./EventPhaseChecker"
import { orderBy } from "lodash"

export const eventTimezoneName = "Europe/Berlin"
const selectedTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()

export interface Timezone {
    timezones: string[]
    utcDifference: number
    name: string
}

export function isToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isSame(today, "d")
}

export function isYesterday(dateMoment: Moment): boolean {
    const yesterday = moment.tz(selectedTimezoneName).subtract(1, "days").startOf("day")
    return dateMoment.isSame(yesterday, "d")
}

export function isTomorrow(dateMoment: Moment): boolean {
    const tomorrow = moment.tz(selectedTimezoneName).add(1, "days").startOf("day")
    return dateMoment.isSame(tomorrow, "d")
}

export function isAfterToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isAfter(today, "d")
}

export function isBeforeToday(dateMoment: Moment): boolean {
    const today = moment.tz(selectedTimezoneName).startOf("day")
    return dateMoment.isBefore(today, "d")
}

export function isNotIncludedInRange(dateMoment: Moment) {
    const timezone = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const endDateMoment = moment(branding.personDetailPageContent.meetingSlotsSection.endDate).tz(timezone)
    return dateMoment.isAfter(endDateMoment, "d")
}

export function getIndexOfInitialDayToShowIgnoreDefault(days: Moment[]): number {
    const indexToday = days.findIndex((day) => isToday(day))
    const indexFirstDayAfterToday = days.findIndex((day) => isAfterToday(day))
    const daysCopy = [...days]
    const lastDayBeforeToday = daysCopy.reverse().find((day) => isBeforeToday(day))
    const indexLastDayBeforeToday = lastDayBeforeToday ? days.findIndex((day) => day.isSame(lastDayBeforeToday)) : 0
    const indexBeforeToday = indexLastDayBeforeToday === -1 ? 0 : indexLastDayBeforeToday

    // 1. if current day is available (i.e. the day is element of branding.eventTiming.meetingDays or it has and eventDate): display it,
    // 2. if 1. is not the case: try to display the first next day after today,
    // 3. if 2. is not the case: display the last available day before today
    return indexToday === -1 ? (indexFirstDayAfterToday === -1 ? indexBeforeToday : indexFirstDayAfterToday) : indexToday
}

export function getIndexOfInitialDayToShow(days: Moment[], mySchedule?: boolean): number {
    if (days.length === 0) return 0
    const daysCopy = [...days]

    // make sure days are in order
    if (daysCopy.length > 0) daysCopy.sort((day1: Moment, day2: Moment) => day1.diff(day2))

    // if default start date was not set in branding: ignore default
    const defaultStartDate: Moment = moment(
        mySchedule ? branding.mySchedule.defaultStartDate : branding.programSchedule.defaultStartDate
    )
    if (!defaultStartDate.isValid()) return getIndexOfInitialDayToShowIgnoreDefault(days)

    // if default day is today or before today: ignore default
    if (isToday(defaultStartDate) || isBeforeToday(defaultStartDate)) return getIndexOfInitialDayToShowIgnoreDefault(days)

    let defaultStartDateIndex = daysCopy.findIndex((day) => day.isSame(defaultStartDate))
    // if default day is available, return it
    if (defaultStartDateIndex !== -1) return defaultStartDateIndex

    const firstIndexAfterDefaultDay = daysCopy.findIndex((day) => day.isAfter(defaultStartDate, "d"))
    // if default day is not available: return first day after default day
    if (firstIndexAfterDefaultDay !== -1) return firstIndexAfterDefaultDay

    const reversedDays = [...daysCopy]
    const lastDayBeforeDefaultDay = reversedDays.reverse().find((day) => day.isBefore(defaultStartDate, "d"))
    const lastIndexBeforeDefault = lastDayBeforeDefaultDay ? daysCopy.findIndex((day) => day.isSame(lastDayBeforeDefaultDay)) : 0
    // if no future day is available: return last day before default day
    if (lastIndexBeforeDefault !== -1) return lastIndexBeforeDefault
    return 0
}

export const getTimezoneOffest = () => {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const eventTimeOffset = (moment.tz(eventTimezoneName) as unknown as { _offset: number })._offset
    const localTimeOffset = localTimezoneName
        ? (moment.tz(localTimezoneName) as unknown as { _offset: number })._offset
        : moment(moment.tz(moment.tz.guess()).format()).utcOffset()

    let timezoneMinutesDifference = Math.abs(eventTimeOffset - localTimeOffset)

    if (eventTimeOffset > localTimeOffset) timezoneMinutesDifference = -timezoneMinutesDifference

    return timezoneMinutesDifference
}

export function momentWithoutTimezoneFromTimezonedMoment(momentWithTimezone: Moment, timezone: string): Moment {
    return moment(momentWithTimezone.tz(timezone).format("YYYY-MM-DDTHH:mm:ss"))
}

let timeZones: Timezone[] = []
export function getTimezones(): Timezone[] {
    if (timeZones.length > 0) return timeZones
    var momentTimezones = moment.tz.names().filter((tz) => tz.indexOf("/") > -1 && tz.indexOf("GMT") < 0 && tz.indexOf("Etc") < 0)
    var timezones = _.groupBy(
        momentTimezones.map((mTz) => {
            const hourDifference = moment().tz(mTz).utcOffset() / 60
            return { utcDifference: hourDifference, timezone: mTz, area: mTz.slice(0, mTz.indexOf("/")) }
        }),
        (item) => {
            return [item.area, item.utcDifference]
        }
    )
    var timezonesGrouped: Timezone[] = []
    _.forEach(timezones, (tzGroupedList) => {
        var groupedTimezones: string[] = []
        tzGroupedList.forEach((it) => groupedTimezones.push(it.timezone))
        var names = groupedTimezones.map((it) => it.slice(it.indexOf("/") + 1))
        timezonesGrouped.push({
            timezones: groupedTimezones,
            utcDifference: tzGroupedList[0].utcDifference,
            name:
                tzGroupedList[0].area +
                ` UTC(${tzGroupedList[0].utcDifference > 0 ? "+" : ""}${tzGroupedList[0].utcDifference}) - ` +
                names
        })
    })
    timeZones = timezonesGrouped.sort((a, b) => (a.utcDifference > b.utcDifference ? 1 : -1))
    return timeZones
}

export function findTimezoneName(timezoneName: string): string {
    return getTimezones().find((it) => it.timezones.indexOf(timezoneName) > -1)?.name ?? ""
}

export function isEventDateLive(eventdate: EventDate) {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const now = momentWithoutTimezoneFromTimezonedMoment(moment(), localTimezoneName)
    const start = moment(eventdate.date + " " + eventdate.start)
    const end = moment(eventdate.date + " " + eventdate.end)

    if (now.isBetween(start, end)) return true
    return false
}

export function activateChannelBefore(eventdate: EventDate) {
    const localTimezoneName = JSON.parse(localStorage.getItem("virtualGuide-app") ?? "{}").timezone ?? moment.tz.guess()
    const now = momentWithoutTimezoneFromTimezonedMoment(moment(), localTimezoneName)
    const start = moment(eventdate.date + " " + eventdate.start)
    const end = moment(eventdate.date + " " + eventdate.end)
    const activateBefore = branding.programSchedule.activateChannelBeforeMinutes ?? 5

    if (!isSoftOpeningPhase && !isLivePhase) return false

    if (now.clone().add(activateBefore, "minutes").isSameOrAfter(start) && now.isSameOrBefore(end)) return true
    return false
}

export function getTimeToLiveString(eventDate: EventDate, language: string) {
    const lang = language === "de" ? de : en
    if (isEventDateLive(eventDate)) {
        return branding.receptionPage.nowLive
    } else if (isTomorrow(moment(eventDate.date))) {
        return branding.receptionPage.liveTomorrow
    } else if (isToday(moment(eventDate.date))) {
        const now = moment.tz(eventTimezoneName)
        const start = moment(eventDate.date + " " + eventDate.start)
        const duration = moment.duration(start.diff(now))
        return "Live " + duration.locale(language).humanize(true)
    } else {
        return (
            branding.receptionPage.liveOn +
            " " +
            format(moment(eventDate.date).toDate(), branding.eventTiming.eventDaysFormatPatternShort, { locale: lang })
        )
    }
}

const getOnlyTimestampForTime = (date: Moment) => {
    const h: number = date.hours()
    const m = date.minutes()
    const s = date.seconds()
    return h * 3600 + m * 60 + s
}

export const showCurrentTimeMarkerHelper = (selectedDate: string, timezone: string) => {
    const currentDateTime = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone)
    if (isToday(moment(selectedDate))) {
        const dayStart = momentWithoutTimezoneFromTimezonedMoment(moment(branding.eventTiming.eventStartDateTime), timezone)
        return getOnlyTimestampForTime(currentDateTime) >= getOnlyTimestampForTime(dayStart)
    }

    return false
}

export const checkLatestTime = (eventdates: EventDate[]) => {
    if (eventdates && eventdates.length > 0) {
        const sortedEventdates = orderBy(eventdates, ["dateTimeEnd"], ["desc"])
        const latestTime = moment(sortedEventdates[0]?.dateTimeEnd).format("HH:mm")
        return latestTime
    }
    return null
}

export const compareWhichIsEarlier = (a: EventDate, b: EventDate) => {
    if (moment(a.date + " " + a.start).isAfter(moment(b.date + " " + b.start))) {
        return -1
    }
    if (moment(a.date + " " + a.start).isBefore(moment(b.date + " " + b.start))) {
        return 1
    }
    return 0
}
interface Timepart {
    name: string
    nameDe: string
    div: number
    p?: string
    pDe?: string
    s?: string
    sDe?: string
}

const timeparts: Timepart[] = [
    { name: "millenni", nameDe: "Jahrtausend", div: 31556736000, p: "a", pDe: "e", s: "um", sDe: "" },
    { name: "centur", nameDe: "Jahrhundert", div: 3155673600, p: "ies", pDe: "e", s: "", sDe: "" },
    { name: "decade", nameDe: "decade", div: 315567360 },
    { name: "year", nameDe: "Jahr", div: 31556736 },
    { name: "month", nameDe: "Monat", div: 2629728 },
    { name: "d", nameDe: "Tg.", div: 86400, p: "", pDe: "" },
    { name: "h", nameDe: "Std.", div: 3600, p: "", pDe: "" },
    { name: "min", nameDe: "min.", div: 60, p: "", pDe: "" },
    { name: "second", nameDe: "Sekunden", div: 1 }
]

function getName(timepart: Timepart, lang: string): string {
    return lang === "de" ? timepart.nameDe : timepart.name
}

function getPluralEnding(timepart: Timepart, lang: string): string {
    return lang === "de" ? timepart.pDe ?? "e" : timepart.p ?? "s"
}

function getSingularending(timepart: Timepart, lang: string): string {
    return lang === "de" ? timepart.sDe ?? "" : timepart.s ?? ""
}

export function timeAgo(lang: string, comparisonDate: Date) {
    var i: number = 0,
        timeString: string = "",
        interval: number = Math.floor((new Date().getTime() - comparisonDate.getTime()) / 1000)

    for (; interval > 0; i += 1) {
        var value = Math.floor(interval / timeparts[i].div)
        interval = interval - value * timeparts[i].div

        if (value && timeparts[i].div > 1 && timeString.length === 0) {
            timeString =
                value +
                " " +
                getName(timeparts[i], lang) +
                (value !== 1 ? getPluralEnding(timeparts[i], lang) : getSingularending(timeparts[i], lang))
        }
    }

    if (timeString.length === 0) {
        return ""
    }

    return branding.eventTiming.timeAgoTemplate.replace("${time}", timeString)
}

export function getMinutesBeforeSwitchingToTheNextEvent(): number {
    //Should a 0 be entered as a value in the branding, the frontend will convert it to a 1 and send it to the backend
    //thus preventing the issue with channel closing after the last event
    //because backend doesn't support getting the last event alone if the value of this branding property is 0

    if (branding.videoPageContentBranding.minutesBeforeSwitchingToTheNextEvent === 0) {
        return 1
    }

    return branding.videoPageContentBranding.minutesBeforeSwitchingToTheNextEvent
}
