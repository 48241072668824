import React, { Dispatch, SetStateAction } from "react"
import { IconLocation } from "../../Icons"
import {
    HallLocationName,
    LocationCardBackground,
    LocationHeader,
    LocationIconWrapper,
    LocationName
} from "./CalendarEntryModal.styled"
import { LocationOption } from "./ModalFunctions"

interface LocationCardProps {
    label?: string
    options?: LocationOption[]
    onClick?: () => void
    setCurrentValue: Dispatch<SetStateAction<string>>
    setOpenLocationDropdownList: Dispatch<SetStateAction<boolean>>
    openLocationDropdownList: boolean
}

const LocationCard = (props: LocationCardProps) => {
    return (
        <LocationCardBackground>
            <LocationIconWrapper>
                <IconLocation width="14px" height="14px" />
            </LocationIconWrapper>
            <LocationHeader>
                <LocationName>{props.label}</LocationName>
                {props.options?.map((op: LocationOption) => {
                    return (
                        <HallLocationName
                            onClick={() => {
                                props.setCurrentValue(props.label + " " + op.hallName)
                                setTimeout(() => props.setOpenLocationDropdownList(false))
                            }}
                        >
                            {op.hallName}
                        </HallLocationName>
                    )
                })}
            </LocationHeader>
        </LocationCardBackground>
    )
}

export default LocationCard
