import { darkTheme } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import { useDeviceController } from "../../../conference/context/DeviceController"
import { DeviceSelect } from "../Settings/SettingsStyles"

function CameraSelection() {
    const deviceController = useDeviceController()

    return (
        <>
            <DeviceSelect
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                theme={darkTheme}
                placeholder={deviceController.getSelectedVideoInputDevice()}
                options={deviceController.getVideoInputDevices()}
                value={deviceController.getSelectedVideoInputDevice()}
                onChange={async (device: DeviceType) => {
                    deviceController.setSelectedVideoInputDevice(device.deviceId)
                }}
            />
        </>
    )
}

export default CameraSelection
