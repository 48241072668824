import { LocalVideo, MicrophoneActivity, useMeetingManager } from "amazon-chime-sdk-component-library-react"
import { useContext, useState } from "react"
import { IconHandYellow } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { LocalAvatarNamePlate } from "../LocalAvatar/LocalAvatarStyles"
import { LocalVideoRoot } from "./LocalVideoStyles"

function LocalVideoCustom() {
    const meetingManager = useMeetingManager()
    const [chimeAttendeeId] = useState(meetingManager.meetingSession?.configuration.credentials?.attendeeId)
    const raisedHands = useContext(RaisedHandContext)
    return (
        <LocalVideoRoot>
            <LocalVideo />
            <LocalAvatarNamePlate>
                {raisedHands &&
                    raisedHands.includes(meetingManager.meetingSession?.configuration.credentials?.externalUserId || "") && (
                        <IconHandYellow height="18px" width="18px" />
                    )}
                {"Me"}
                <MicrophoneActivity className="activity" attendeeId={chimeAttendeeId || ""} />
            </LocalAvatarNamePlate>
        </LocalVideoRoot>
    )
}

export default LocalVideoCustom
