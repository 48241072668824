import * as React from "react"
import { useCallback, useState } from "react"
import useWindowDimensions from "../WindowDimensionsHook"
import styled from "styled-components"
import branding from "../../branding/branding"
import { Modal } from "react-bootstrap"
import { IconClose } from "../Icons"

export const useDetailSectionModal = (onCloseCallback?: () => void) => {
    const [isVisible, setIsVisible] = useState(false)
    const [content, setContent] = useState<React.ReactNode>(null)
    const [heading, setHeading] = useState<string>("")
    const customWidth = Math.min((useWindowDimensions().width * 3) / 4, 1024)

    const closeCallback = useCallback(() => {
        if (onCloseCallback) onCloseCallback()
    }, [onCloseCallback])

    const showModal = (content: React.ReactNode, heading: string) => {
        setContent(content)
        setHeading(heading)
        setIsVisible(true)
    }

    const hideModal = () => {
        setIsVisible(false)
    }

    const DetailSectionModalInternal = () => (
        <DetailSectionModalRoot
            animation={false}
            width={customWidth}
            show={isVisible}
            onHide={() => {
                closeCallback()
                setIsVisible(false)
            }}
        >
            <Modal.Header>
                <Modal.Title>{heading}</Modal.Title>
                <CloseButton
                    onClick={(event) => {
                        event.stopPropagation()
                        closeCallback()
                        setIsVisible(false)
                    }}
                >
                    {IconClose({ fill: branding.organizationDetailPageContent.modalCloseIconColor, width: "15", height: "15" })}
                </CloseButton>
            </Modal.Header>
            <ScrollableDiv>{content}</ScrollableDiv>
        </DetailSectionModalRoot>
    )

    return {
        showDetailSectionModal: showModal,
        hideDetailSectionModal: hideModal,
        DetailSectionModal: DetailSectionModalInternal
    }
}

const DetailSectionModalRoot = styled(Modal)<{ width: number }>`
    color: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    overflow-y: hidden !important;

    .modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal-header {
        background-color: white;
        z-index: 1200;
        padding-bottom: 32px;
        min-height: 50px;
    }

    .modal-title {
        font-weight: 700;
        font-size: 18px;
    }

    .modal-dialog {
        height: 97%;
        width: ${(props) => props.width + "px"};
        min-width: ${(props) => props.width + "px"};
    }

    .modal-content {
        width: ${(props) => props.width + "px"};
        height: 97%;
        min-width: 400px;
        min-height: 400px;
        padding: 25px 35px 10px 35px;
    }
`

const ScrollableDiv = styled.div`
    width: 100%;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`

const CloseButton = styled.div`
    position: absolute;
    right: 34px;
    top: 26px;
    z-index: 1;
    cursor: pointer;
`
