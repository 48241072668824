import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { useAppState } from "../../../globalStates/AppState"
import { IconCamera, IconClose, IconSettings } from "../../../ui/Icons"
import ToggleSwitch from "../../../ui/ToggleSwitch/ToggleSwitch"
import { useMeetingController } from "../../context/MeetingController"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import {
    ControlLabel,
    ControlToggleHelperWrapper,
    PreMeetingControlsWrapper,
    PremeetingDeviceItem,
    PreMeetingSettingsBtn,
    PreMeetingVideoWrapper,
    PreviewPlaceholderRoot // eslint-disable-line
} from "../PreviewVideo/PreviewVideoStyles"
import { CloseIconContainer } from "../Settings/SettingsStyles"
import { PreMeetingModalRoot, PreMeetingScreenBackground, PreMeetingSubtitle, PremeetingTitle } from "./PreMeetingScreen.styled"

function PreMeetingScreen() {
    const appState = useAppState()
    const meetingController = useMeetingController()
    const history = useHistory()

    return (
        <PreMeetingScreenBackground>
            <PreMeetingModalRoot>
                <Container>
                    <Row>
                        <Col>
                            <PremeetingTitle>Device settings</PremeetingTitle>
                            <PreMeetingSubtitle>Choose your video and audio options</PreMeetingSubtitle>
                        </Col>
                        <Col>
                            <CloseIconContainer
                                onClick={() => {
                                    appState.setIsPreMeetingScreenOpen(false)
                                    history.push("/")
                                }}
                            >
                                {IconClose({ fill: "#fff", width: "15", height: "15" })}
                            </CloseIconContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div>
                                <PreMeetingVideoPreview />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <button
                            onClick={() => {
                                meetingController.startMeeting()
                                appState.setIsPreMeetingScreenOpen(false)
                            }}
                        >
                            Join meeting
                        </button>
                    </Row>
                </Container>
            </PreMeetingModalRoot>
        </PreMeetingScreenBackground>
    )
}

/** TODO: Replace this component in seperate file */
function PreMeetingVideoPreview() {
    const [isCameraOn, setIsCameraOn] = useState(false) // eslint-disable-line
    const [isMicOn, setIsMicOn] = useState(false) // eslint-disable-line

    return (
        <PreMeetingVideoWrapper>
            <PreviewVideo />
            <PreMeetingControls />
        </PreMeetingVideoWrapper>
    )
}
/** TODO: Replace this component in seperate file */
function PreMeetingControls() {
    const appState = useAppState()
    return (
        <PreMeetingControlsWrapper>
            <PremeetingDeviceItem style={{ borderRight: "1px solid #fff" }}>
                <IconCamera fill="#fff" width="20" height="20" /> <ControlLabel>Video</ControlLabel>
                <ControlToggleHelperWrapper>
                    <ToggleSwitch scaleSize={0.6} isEnabled={true} onClick={async () => {}} />
                </ControlToggleHelperWrapper>
            </PremeetingDeviceItem>
            <PremeetingDeviceItem>
                <IconCamera fill="#fff" width="20" height="20" /> <ControlLabel>Audio</ControlLabel>
                <ControlToggleHelperWrapper>
                    <ToggleSwitch scaleSize={0.6} isEnabled={true} onClick={async () => {}} />
                </ControlToggleHelperWrapper>
            </PremeetingDeviceItem>
            <PreMeetingSettingsBtn
                onClick={() => {
                    appState.setIsPreMeetingScreenOpen(false)
                    appState.setIsAudioVideoSettingsV2Open(true)
                }}
            >
                <IconSettings fill="#fff" width="20" height="20" /> <ControlLabel>Settings</ControlLabel>
            </PreMeetingSettingsBtn>
        </PreMeetingControlsWrapper>
    )
}

export default PreMeetingScreen
