// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useState } from "react"
import { useRouteMatch } from "react-router-dom"
import styled from "styled-components/macro"
import branding from "../../branding/branding"
import { meetingPageRoute } from "../../navigationArea/RoutePaths"
import { IconClose } from "../../ui/Icons"
import { useChimeContext } from "../context/ChimeContext"
import { useRosterContext } from "../context/RosterContext"

export const RemainingTimeRoot = styled.div<{ $isMeetingPage: boolean }>`
    margin: ${(props) => (props.$isMeetingPage ? "22px 0 0 0" : "initial")};
    position: absolute;
    /* position: ${(props) => (props.$isMeetingPage ? "initial" : "absolute")}; */
    bottom: ${(props) => (props.$isMeetingPage ? "auto" : "42px")};
    width: ${(props) => (props.$isMeetingPage ? "100%" : "inherit")};
    text-indent: ${(props) => (props.$isMeetingPage ? "25px" : "11px")};
    color: ${(props) => (props.$isMeetingPage ? "#fff" : "#212529")};
`

export const TimeLine = styled.div<{ $length: number; $color: string; $isMeetingPage: boolean }>`
    margin-top: 5px;
    width: 100%;
    height: 5px;
    background-color: #ffffff4c;
    position: ${(props) => (props.$isMeetingPage ? "absolute" : "initial")};
    bottom: ${(props) => (props.$isMeetingPage ? "43px" : "none")};

    &:before {
        content: "";
        display: block;
        width: ${(props) => props.$length}%;
        height: 5px;
        background-color: ${(props) => props.$color};
    }
`

export const AlertPopup = styled.div<{ $isMeetingPage: boolean }>`
    position: absolute;
    top: ${(props) => (props.$isMeetingPage ? "-80px" : "74px")};
    box-shadow: ${(props) => (props.$isMeetingPage ? "none" : "0px 5px 15px 1px rgba(0, 0, 0, 0.4)")};
    text-indent: 0;
    width: ${(props) => (props.$isMeetingPage ? "initial" : "inherit")};
    height: ${(props) => (props.$isMeetingPage ? "initial" : "56px")};
    padding: ${(props) => (props.$isMeetingPage ? "10px 40px 10px 20px" : "7px 40px 10px 20px")};

    left: 50%;
    transform: translateX(-50%);
    background-color: #000;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
`
export const AlertClose = styled.div`
    position: absolute;
    right: 10px;
    top: 9px;
    cursor: pointer;
`

interface RemainingTimeProps {
    forceShowTimer?: boolean
}

export default function RemainingTime(props: RemainingTimeProps) {
    const chime = useChimeContext()
    const [alertVisible, setAlertVisible] = useState(true)
    const isOnMeetingPage = useRouteMatch(meetingPageRoute) !== null
    const rosterContext = useRosterContext()

    if (
        !props.forceShowTimer &&
        ((chime.getKind() !== "call" &&
            chime.getKind() !== "calenderEntry" &&
            chime.getKind() !== "conferenceroom" &&
            chime.getKind() !== "breakout") ||
            !chime.getTimeLeft() ||
            (chime.getKind() !== "breakout" && !chime.getMaxDuration()) ||
            (chime.getKind() === "breakout" && chime.getTimeLeft()! <= 0))
    ) {
        return null
    }
    const moderatorsInRoom = Object.values(rosterContext.getRoster()).filter(
        (attendee: any) => attendee.role === "moderator"
    ).length

    let secondsLeft: number | null = chime.getTimeLeft()!
    let color: string = ""
    let text: string = ""
    let remainingPercent: number = 0
    let remainingMinutes: number = 0
    let remainingSeconds: number = 0
    let alertText: string = ""

    if (chime.getKind() === "breakout" && moderatorsInRoom > 0) {
        secondsLeft = null
    }

    if (secondsLeft) {
        remainingMinutes = Math.floor(secondsLeft / 60)
        remainingSeconds = Math.floor(secondsLeft % 60)
        remainingPercent =
            100 -
            (secondsLeft / (chime.getKind() === "breakout" ? branding.breakoutTimeoutDuration : chime.getMaxDuration()!)) * 100
        color = secondsLeft < 60 ? "red" : "green"
        // eslint-disable-next-line
        text = branding.conferenceTexts.timerRemaining
            .replace("${minutes}", "" + remainingMinutes)
            .replace("${seconds}", remainingSeconds < 10 ? "0" + remainingSeconds : "" + remainingSeconds)
        // eslint-disable-next-line
        alertText = branding.conferenceTexts.timerUpAlert.replace("${seconds}", "" + remainingSeconds)
    }

    return (
        <>
            {secondsLeft && (
                <RemainingTimeRoot $isMeetingPage={isOnMeetingPage}>
                    {text}
                    <TimeLine $length={remainingPercent} $color={color} $isMeetingPage={isOnMeetingPage} />
                    {secondsLeft < 60 && alertVisible && (
                        <AlertPopup $isMeetingPage={isOnMeetingPage}>
                            {alertText}
                            <AlertClose
                                onClick={(event) => {
                                    setAlertVisible(false)
                                    event.stopPropagation()
                                    event.preventDefault()
                                }}
                            >
                                {IconClose}
                            </AlertClose>
                        </AlertPopup>
                    )}
                </RemainingTimeRoot>
            )}
        </>
    )
}
