/**
 * This enum is used only when we check the response from our backend.
 * For example, we first check the backend response, if a user is banned, kicked
 * or any other status, then we check the chime status with the meetingManager.meetingStatus.
 * You can see in ConferenceRoom.tsx where we first check getEGMeetingStatus(), if this is null this means,
 * the user can enter the room considering our backend, then after this, we check the chime status and generate the layout.
 */

export enum EGMeetingStatus {
    Banned = 1,
    Kicked = 2,
    TimeUp = 3,
    GreenRoomLive = 4,
    Disconnected = 5,
    Full = 6,
    InitError = 7,
    NoModeratorInRoom = 8,
    NoConferenceRoomAccess = 9,
    NoConferenceRoomAccessGranted = 10,
    NoConferenceRoom = 11,
    NoEventdate = 12,
    NoAuth = 13
}
