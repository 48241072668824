import RosterAttendee from "../RosterAttendee/RosterAttendee"
import { AttendeeData } from "../RosterAttendees.tsx/RosterAttendees"
import { AttendeesListRoot } from "../RosterAttendees.tsx/RosterAttendees.styled"

interface AttendeesListProps {
    attendees?: AttendeeData[]
}

function AttendeesList(props: AttendeesListProps) {
    return props.attendees ? (
        <AttendeesListRoot>
            {props.attendees.map((attendee: any, key: number) => {
                return <RosterAttendee key={key} attendee={attendee} />
            })}
        </AttendeesListRoot>
    ) : null
}

export default AttendeesList
