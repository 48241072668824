import { MouseEventHandler, useCallback, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import branding from "../../branding/branding"
import { Modal } from "react-bootstrap"
import ReactDOM from "react-dom"
import { IconClose } from "../Icons"

const NoStaffMembersModalRoot = styled(Modal)`
    .modal-content {
        padding: 10px;
        border-radius: 3px;
        border: none;
        font-family: ${branding.font1};
    }
`

const NoStaffMembersModalBody = styled(Modal.Body)`
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
    font-family: ${branding.font1};
`

const NoStaffMembersTitle = styled.h3`
    font-size: 18px;
    text-align: justify;
    font-family: ${branding.font1};
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
    @media (max-width: 1400px) {
        font-size: 14px;
    }
`

const NoStaffMembersDescription = styled.p`
    font-size: 14px;
    font-family: ${branding.font1};
    @media (max-width: 1400px) {
        font-size: 13px;
    }
`

const CloseIconWrapper = styled.div`
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
`

interface NoStaffMembersModalProps {
    close: () => void
}

const NoStaffMembersModal: React.FunctionComponent<NoStaffMembersModalProps> = (props) => {
    const handleClose: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
        props.close()
    }

    const preventClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation()
    }

    return (
        <NoStaffMembersModalRoot
            show={true}
            onHide={() => props.close()}
            backdrop="static"
            centered
            animation={false}
            onClick={preventClick}
        >
            <NoStaffMembersModalBody>
                <CloseIconWrapper onClick={handleClose}>
                    <IconClose fill={branding.primaryColor} width={"12"} height={"12"} />
                </CloseIconWrapper>
                <NoStaffMembersTitle>{branding.noStaffMembersModal.title}</NoStaffMembersTitle>
                <NoStaffMembersDescription>{branding.noStaffMembersModal.description}</NoStaffMembersDescription>
            </NoStaffMembersModalBody>
        </NoStaffMembersModalRoot>
    )
}

export const useStaffMembersModal = (onCloseCallback?: () => void) => {
    const [isVisible, setIsVisible] = useState(false)
    const closeCallback = useCallback(() => {
        if (onCloseCallback) onCloseCallback()
    }, [onCloseCallback])

    const show = () => {
        setIsVisible(true)
    }

    const Modal = () =>
        ReactDOM.createPortal(
            <React.Fragment>
                {isVisible && (
                    <NoStaffMembersModal
                        close={() => {
                            closeCallback()
                            setIsVisible(false)
                        }}
                    />
                )}
            </React.Fragment>,
            document.body
        )

    return {
        showNoStaffMembersModal: show,
        NoStaffMembersModal: Modal
    }
}
