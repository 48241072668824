import { useState } from "react"
import { IconArrowDownFull } from "../../../ui/Icons"
import { useMeetingController } from "../../context/MeetingController"
import AttendeesList from "../RosterAttendeeList/RosterAttendeeList"
import MuteAllButton from "../RosterAttendees.tsx/MuteAllButton/MuteAllButton"
import { MuteAllButtonWrapper } from "../RosterAttendees.tsx/MuteAllButton/MuteAllButton.styled"
import { AttendeeData, AttendeeRole } from "../RosterAttendees.tsx/RosterAttendees"
import {
    AttendeesGroupContent,
    AttendeesGroupHeader,
    AttendeesGroupIcon,
    AttendeesGroupRoot,
    AttendeesGroupTitle
} from "../RosterAttendees.tsx/RosterAttendees.styled"

interface RosterAttendeesGroupProps {
    title: string
    attendees?: AttendeeData[]
    groupType?: AttendeeRole
}

function RosterAttendeesGroup(props: RosterAttendeesGroupProps) {
    const [isOpen, setIsOpen] = useState(true)
    const meetingController = useMeetingController()

    return (
        <AttendeesGroupRoot>
            <AttendeesGroupHeader>
                <AttendeesGroupTitle onClick={() => setIsOpen(!isOpen)}>
                    <AttendeesGroupIcon isOpen={isOpen}>
                        {IconArrowDownFull({ fill: "#727272", width: "12px", height: "12px" })}
                    </AttendeesGroupIcon>
                    {props.title} ({props.attendees?.length})
                </AttendeesGroupTitle>
                {props.groupType === "member" && meetingController.getIsMod() && (
                    <MuteAllButtonWrapper>
                        <MuteAllButton />
                    </MuteAllButtonWrapper>
                )}
            </AttendeesGroupHeader>
            <AttendeesGroupContent isOpen={isOpen}>
                <AttendeesList attendees={props.attendees} />
            </AttendeesGroupContent>
        </AttendeesGroupRoot>
    )
}

export default RosterAttendeesGroup
