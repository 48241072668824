import React, { Dispatch, SetStateAction } from "react"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import useComponentVisible from "../../../navigationArea/useComponentVisible"
import { getTimezones } from "../../../utils/DateUtils"
import { IconArrowDownTimezoneDropdown, IconArrowUpTimezoneDropdown } from "../../Icons"
import { Input, TimezoneDropdownList, TimezoneInput, TimezoneName, TimezoneWrapper } from "./CalendarEntryModal.styled"
import { CalendarEntryModalViewMode } from "./ModalFunctions"

interface TimezonePickerProps {
    selectedTimezone?: string
    setSelectedTimezone?: Dispatch<SetStateAction<string>>
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
}

export const TimezonePicker = (props: TimezonePickerProps) => {
    const timezones = getTimezones()
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    return (
        <TimezoneWrapper ref={ref}>
            <TimezoneInput onKeyPress={(e) => e.preventDefault()} onClick={() => setIsComponentVisible(!isComponentVisible)}>
                <Input placeholder={props.selectedTimezone} value={props.selectedTimezone} />
                {props.viewMode !== CalendarEntryModalViewMode.VIEW ? (
                    isComponentVisible ? (
                        <IconArrowUpTimezoneDropdown width="10px" height="10px" />
                    ) : (
                        <IconArrowDownTimezoneDropdown width="10px" height="10px" />
                    )
                ) : (
                    <></>
                )}
            </TimezoneInput>
            {isComponentVisible && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                <TimezoneDropdownList>
                    {timezones?.map((timezone, index) => (
                        <TimezoneName
                            key={index}
                            onClick={() => {
                                props.setSelectedTimezone!(timezone.name)
                                setIsComponentVisible(false)
                            }}
                        >
                            {timezone.name}
                        </TimezoneName>
                    ))}
                </TimezoneDropdownList>
            )}
        </TimezoneWrapper>
    )
}

export default TimezonePicker
