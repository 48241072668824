import { useEffect, useState } from "react"
import styled from "styled-components"
import { getRootCategoriesByTopic } from "../backendServices/TopicServices"
import { Category } from "../backendServices/Types"
import branding from "../branding/branding"
import MainCategories, { EmptyListMessage } from "./MainCategories"
import CenteredLoader from "../ui/CenteredLoader"
import BackendError from "../ui/BackendError"
import { ViewMode } from "../ui/CrsTabs"

const CategoriesPageContentRoot = styled.div``

function isElligible(category: Category, filter: string) {
    const eligible = category.label && category.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0

    return eligible
}

function searchCategories(filter: string, categories?: Category[]): Category[] {
    const nodes: Category[] = []

    if (categories && Array.isArray(categories)) {
        categories?.forEach!((category: Category) => {
            let node = Object.assign({}, category)

            node.nodes = searchCategories(filter, category.nodes)

            if (isElligible(category, filter) || searchCategories(filter, category.nodes).length > 0) {
                nodes.push(node)
            }
        })
    }

    return nodes
}

interface MainCategoriesPageContentProps {
    viewType: ViewMode
    selectedRootCategory?: string
    custom?: boolean
    searchParam: string
    setSearchParam: (value: string) => void
    currentPath: string
}

const MainCategoriesPageContent = (props: MainCategoriesPageContentProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [categories, setCategories] = useState<Category[]>()

    const [filteredCategories, setFilteredCategories] = useState<Category[]>([])

    let rootCategories = props.selectedRootCategory ? [props.selectedRootCategory] : branding.mainRootCategories

    const abortController = new AbortController()
    const abortSignal = abortController.signal

    useEffect(() => {
        setIsLoading(true)
        getRootCategoriesByTopic(rootCategories, abortSignal)
            .then((res) => {
                setIsLoading(false)
                setCategories(res.content)
                setFilteredCategories(searchCategories(props.searchParam, res.content || []))
            })
            .catch((err) => {
                setIsLoading(false)
                setIsError(true)
            })

        return () => {
            abortController.abort() // Cancel all loads on unmount
        }
        // eslint-disable-next-line
    }, [props.selectedRootCategory])

    useEffect(() => {
        setFilteredCategories(searchCategories(props.searchParam, categories || []))
        // eslint-disable-next-line
    }, [props.searchParam, props.viewType])

    if (isLoading)
        return (
            <div style={{ marginTop: "15%" }}>
                <CenteredLoader />
            </div>
        )
    else if (isError)
        return (
            <div style={{ marginTop: "15%" }}>
                <BackendError />
            </div>
        )
    else
        return (
            <div>
                <CategoriesPageContentRoot>
                    {filteredCategories && filteredCategories?.length > 0 && (
                        <MainCategories
                            categories={filteredCategories}
                            viewType={props.viewType}
                            hasRootCategories={
                                props.custom ||
                                branding.categoriesPageContent.categoriesConfiguration.length > 0 ||
                                branding.mainRootCategories.length > 0
                            }
                            custom={props.custom}
                            showSearchResults={props.searchParam.length > 0}
                            searchParam={props.searchParam}
                        />
                    )}
                    {filteredCategories && filteredCategories?.length === 0 && props.searchParam.length > 0 && (
                        <EmptyListMessage>{branding.exhibitorsPageContent.noSearchResultsText}</EmptyListMessage>
                    )}
                </CategoriesPageContentRoot>
            </div>
        )
}

export default MainCategoriesPageContent
