import { MicrophoneActivity, ScreenShare, useContentShareState } from "amazon-chime-sdk-component-library-react"
import { useContext } from "react"
import { AvatarWithDefault } from "../../../../ui/AvatarWithDefault"
import { CrownIcon, IconHandYellow } from "../../../../ui/Icons"
import { RaisedHandContext } from "../../../ConferenceRoom/ConferenceRoom"
import { AttendeeData } from "../../RosterAttendees.tsx/RosterAttendees"
import { RosterAttendeeClosedIcon, RosterAttendeeClosedRoot } from "./RosterAttendeeClosed.styed"

interface RosterAttendeeClosedProps {
    attendee: AttendeeData
}
function RosterAttendeeClosed(props: RosterAttendeeClosedProps) {
    const { attendee } = props
    const raisedHands = useContext(RaisedHandContext)
    const { sharingAttendeeId } = useContentShareState()
    return (
        <RosterAttendeeClosedRoot>
            <RosterAttendeeClosedIcon position={{ top: "-7px", left: "32px" }}>
                {raisedHands && raisedHands.includes(attendee.id || "") && <IconHandYellow width="18px" height="18px" />}
            </RosterAttendeeClosedIcon>
            <RosterAttendeeClosedIcon position={{ top: "-7px", left: "0" }}>
                {attendee.role === "moderator" && <CrownIcon width="18px" height="18px" fill="#fff" />}
            </RosterAttendeeClosedIcon>

            <RosterAttendeeClosedIcon position={{ top: "27px", left: "27px" }}>
                <MicrophoneActivity className="activity" attendeeId={attendee?.chimeAttendeeId} />
            </RosterAttendeeClosedIcon>

            <RosterAttendeeClosedIcon position={{ top: "29px", left: "0" }}>
                {sharingAttendeeId?.replace("#content", "") === attendee.chimeAttendeeId && (
                    <ScreenShare width="20px" height="20px" />
                )}
            </RosterAttendeeClosedIcon>

            <AvatarWithDefault
                size={45}
                badgesize={45}
                alt={attendee.name ? attendee.name : ""}
                src={attendee.avatarUrl}
                backgroundSize="cover"
            />
        </RosterAttendeeClosedRoot>
    )
}

export default RosterAttendeeClosed
