import styled from "styled-components"

export const PointRoot = styled.div`
    display: inline-block;

    .animatePoint {
        animation: fadeInFadeOut 2s infinite linear;
    }

    @keyframes fadeInFadeOut {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
`

export const PointItem = styled.div`
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #e30613;
    margin-left: 5px;
`
