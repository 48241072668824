export enum DataMessageType {
    KICK = 1,
    BAN = 2,
    MUTE = 3,
    RAISEHAND = 4,
    CHANNEL_STATUS = 5,
    TIMELIMITCHANGED = 6,
    STOP_SCREENSHARE = 7,
    CALL_ENDED_FOR_ALL = 8
}
