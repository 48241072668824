import { useState } from "react"
import * as React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../branding/branding"
import { IconCheckbox, IconCheckboxEmpty, IconClose } from "../Icons"
import { SubmitButtonContainer, SubmitButtonNew } from "./SayHelloModal"

const ModalRoot = styled(Modal)`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    svg {
        color: ${branding.primaryColor};
    }

    .close {
        text-shadow: none;
        color: ${branding.mainInfoColor};
        margin-top: -25px;
    }

    .modal-dialog {
        width: 290px;
        left: calc(50% - 160px);
        top: 205px;
    }

    .modal-header-close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 26px;
        font-weight: 10;
        line-height: 1.66666667;
        color: ${branding.mainInfoColor};
        width: 30px;
        padding: 0 4px;
        background: none;
        border-style: none;
        margin-right: 0px;
        margin-top: -10px;
    }

    .modal-content {
        font-family: ${branding.font1};
        padding: 0 10px;
        padding-right: 25px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 35px 0 20px 0;
    }
    .modal-footer {
        justify-content: space-between;
        border: none;
        padding: 0px 8px;
    }

    .modal-body {
        padding: 0px 15px 10px 15px;
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
    }

    .btn-primary.disabled  {
        pointer-events: none !important;
        opacity: 0.5 !important;
    }
`

const CheckboxButtonRoot = styled.div`
    cursor: pointer;
    margin-bottom: 10px;
`

const CheckboxIconContainer = styled.div`
    display: inline-block;
    margin-right: 20px;
`

const CheckboxLabelContainer = styled.div`
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

interface CheckboxButtonProps {
    checked: boolean
    setChecked: (checked: boolean) => void
    label: string
}

const CheckboxButton: React.FunctionComponent<CheckboxButtonProps> = (props: CheckboxButtonProps) => {
    return (
        <CheckboxButtonRoot onClick={() => props.setChecked(!props.checked)}>
            <CheckboxIconContainer>
                {props.checked
                    ? IconCheckbox({ fill: branding.sideIconBar.sideIconColorDark })
                    : IconCheckboxEmpty({ fill: branding.sideIconBar.sideIconColorDark })}
            </CheckboxIconContainer>
            <CheckboxLabelContainer>{props.label}</CheckboxLabelContainer>
        </CheckboxButtonRoot>
    )
}

interface ExportContactsModalProps {
    doDownloadCsv?: () => void
    doDownloadVCard?: () => void
    onClose: () => void
}

const ExportContactsModal: React.FunctionComponent<ExportContactsModalProps> = (props: ExportContactsModalProps) => {
    const [checkedVCF, setCheckedVCF] = useState<boolean>(false)
    const [checkedCSV, setCheckedCSV] = useState<boolean>(false)

    function handleDownload() {
        if (checkedVCF && props.doDownloadVCard) {
            props.doDownloadVCard()
        }

        if (checkedCSV && props.doDownloadCsv) {
            props.doDownloadCsv()
        }

        setTimeout(() => {
            props.onClose()
        }, 1000)
    }

    return (
        <ModalRoot
            backdrop={true}
            onHide={() => props.onClose()}
            backdropClassName="custom-backdrop"
            show={true}
            animation={false}
        >
            <div onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h3 className="modal-title">{branding.communicationArea.exportContactsModalTitle}</h3>
                    <button type="button" className="close" onClick={() => props.onClose()} aria-label="Close">
                        {IconClose({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })}
                    </button>
                </div>

                <div className="modal-body">
                    <CheckboxButton
                        checked={checkedVCF}
                        setChecked={setCheckedVCF}
                        label={branding.communicationArea.exportContactsModalVCFLabel}
                    />
                    <CheckboxButton
                        checked={checkedCSV}
                        setChecked={setCheckedCSV}
                        label={branding.communicationArea.exportContactsModalCSVLabel}
                    />
                </div>
                <div className="modal-footer">
                    <SubmitButtonContainer justifyContent="center" className={!checkedVCF && !checkedCSV ? "disabled" : ""}>
                        <SubmitButtonNew
                            type="submit"
                            onClick={() => handleDownload()}
                            className="d-flex align-items-center justify-content-center"
                            width="240px"
                            disabled={!checkedVCF && !checkedCSV}
                        >
                            {branding.communicationArea.exportContactsModalDownloadButtonText}
                        </SubmitButtonNew>
                    </SubmitButtonContainer>
                </div>
            </div>
        </ModalRoot>
    )
}

export default ExportContactsModal
