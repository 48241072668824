import styled from "styled-components"

export const ContentShareRoot = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

export const ContentShareUserVideoRoot = styled.div`
    width: 200px;
    height: 150px;
    background-color: green;
    position: absolute;
    bottom: 5px;
    left: 15px;
    z-index: 10;
`
