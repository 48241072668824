import branding from "../../branding/branding"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { IconNetworking } from "../../ui/Icons"
import ToggleSwitch from "../../ui/ToggleSwitch/ToggleSwitch"
import { SettingsAreaRow } from "../settings/SettingsTab"
import { updateMyProfileData } from "../../backendServices/SeriesOfTopicsUserServices"
import styled from "styled-components"
import { device, deviceSize } from "../../utils/Device"
import { useEffect, useState } from "react"

interface NetworkingToggleProps {
    customEnabled?: boolean
    customFunction?: () => void
    alwaysShowToggle?: boolean
    isMatchActive?: boolean
    setIsMatchActive?: (value: boolean) => void
    scaleSize?: number
}

export default function NetworkingToggle(props: NetworkingToggleProps) {
    const userState = useLoggedInState()
    const [isMobile, setIsMobile] = useState(window.innerWidth < deviceSize.laptop)

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsMobile(window.innerWidth < deviceSize.laptop)
        })
    }, [])

    async function toggleNetworkingState() {
        if (userState.user()) {
            if (props.customFunction) {
                props.customFunction()
            }

            const res = await updateMyProfileData({
                profileId: userState.user()!.profileId,
                profileData: { ...userState.user(), matchActive: !userState.isMatchActive(), id: userState.user()?.profileId }
            })
            userState.setMatchActive(res.content.matchActive)
        }
    }

    if (!branding.configuration.networkingToggle && !props.alwaysShowToggle) return null
    return (
        <SettingsAreaRow>
            {IconNetworking({ fill: branding.sideIconBar.sideIconColorDark })}
            <Title>{branding.navigationArea.networkingItemTitle}</Title>
            {!props.isMatchActive && (
                <ActivateNetworkingMsg>{branding.communicationArea.activateNetworkingText}</ActivateNetworkingMsg>
            )}
            <LblAndToggleWrapper>
                <ActivateNetworkingLbl>
                    {props.isMatchActive
                        ? branding.communicationArea.deactivateNetworkingToggleLabel
                        : branding.communicationArea.activateNetworkingToggleLabel}
                </ActivateNetworkingLbl>
                <ToggleSwitch
                    isEnabled={isMobile ? props.isMatchActive : props.customEnabled || userState.isMatchActive()}
                    onClick={isMobile ? () => props.setIsMatchActive!(!props.isMatchActive) : toggleNetworkingState}
                    scaleSize={props.scaleSize}
                />
            </LblAndToggleWrapper>
        </SettingsAreaRow>
    )
}

const Title = styled.p`
    padding: 20px 20px 0 20px;
    font-weight: bold;
    font-size: 14px;
    margin: 0;

    @media ${device.laptop} {
        padding: 0;
        font-size: 16px;
        font-weight: normal;
    }
`

const ActivateNetworkingMsg = styled.p`
    padding: 0 20px 0 20px;
    font-size: 14px;
    line-height: 16.41px;
    margin-top: 15px;
    margin-bottom: 15px;

    @media ${device.laptop} {
        display: none;
    }
`

const ActivateNetworkingLbl = styled.span`
    font-size: 16px;
    line-height: 18.75px;
    color: rgba(114, 114, 114, 1);
    margin-top: 5px;

    @media ${device.laptop} {
        display: none;
    }
`

const LblAndToggleWrapper = styled.div`
    padding: 5px 20px 20px 20px;
    display: flex;
    justify-content: space-between;

    @media ${device.laptop} {
        padding: 0;
    }
`
