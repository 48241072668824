import { detect } from "detect-browser"
import { SyntheticEvent, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { IconBottomLeft, IconBottomRight, IconTopLeft, IconTopRight, IconTrash, IconUpload } from "../../../ui/Icons"
import { DeviceSettingsRoot, SettingsGroup } from "../AudioSettings/AudioSettingsStyles"
import {
    CustomBackgroundActionsOverlay,
    IconContainer,
    NoContentShift,
    TrashIconContainer
} from "../BackgroundSettings/BackgroundSettingsStyles"
import { SettingsSectionTitle } from "../Settings/SettingsStyles"
import { PreviewVideoContainer, PreviewVideoOverlay } from "../VideoSettings/VideoSettingsStyles"
import { LogoGalleryContainer, LogoGalleryItem, LogoPositionButton, LogoPositionButtonsContainer } from "./LogoSettingsStyles"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import { useConferenceRoomSettingsContext } from "../../context/ConferenceRoomSettingsContext"
import { LogoPositions } from "../../enums/LogoPosition"
import branding from "../../../branding/branding"

function LogoSettings() {
    const {
        setSelectedLogo,
        uploadLogo,
        getLogoGalleryItems,
        getSelectedLogo,
        removeLogo,
        setLogoPosition,
        getLogoPosition,
        getIsLogoProcessorActive,
        getCustomUploadedLogo,
        deleteCustomUploadedLogo
    } = useConferenceRoomSettingsContext()
    const browserInfo = detect()
    const [isLoading] = useState<boolean>(false)
    const logoInputRef = useRef<HTMLInputElement | null>(null)

    return (
        <DeviceSettingsRoot>
            <SettingsGroup>
                <PreviewVideoContainer>
                    <NoContentShift browser={browserInfo?.name}>
                        {!isLoading && <PreviewVideo />}
                        <PreviewVideoOverlay>
                            <span>{branding.audioVideoSettings.preview}</span>
                        </PreviewVideoOverlay>
                    </NoContentShift>
                </PreviewVideoContainer>
                <LogoPositionButtonsContainer>
                    <LogoPositionButton
                        isActive={getLogoPosition() === LogoPositions.TOP_RIGHT && getIsLogoProcessorActive()}
                        onClick={() => setLogoPosition(LogoPositions.TOP_RIGHT)}
                    >
                        {IconTopRight({ fill: "#fff", width: "12", height: "12" })}
                        {branding.audioVideoSettings.radioLogoPositionTopRight}
                    </LogoPositionButton>
                    <LogoPositionButton
                        isActive={getLogoPosition() === LogoPositions.TOP_LEFT && getIsLogoProcessorActive()}
                        onClick={() => setLogoPosition(LogoPositions.TOP_LEFT)}
                    >
                        {IconTopLeft({ fill: "#fff", width: "12", height: "12" })}
                        {branding.audioVideoSettings.radioLogoPositionTopLeft}
                    </LogoPositionButton>
                    <LogoPositionButton
                        isActive={getLogoPosition() === LogoPositions.BOTTOM_RIGHT && getIsLogoProcessorActive()}
                        onClick={() => setLogoPosition(LogoPositions.BOTTOM_RIGHT)}
                    >
                        {IconBottomLeft({ fill: "#fff", width: "12", height: "12" })}
                        {branding.audioVideoSettings.radioLogoPositionBottomRight}
                    </LogoPositionButton>
                    <LogoPositionButton
                        isActive={getLogoPosition() === LogoPositions.BOTTOM_LEFT && getIsLogoProcessorActive()}
                        onClick={() => setLogoPosition(LogoPositions.BOTTOM_LEFT)}
                    >
                        {IconBottomRight({ fill: "#fff", width: "12", height: "12" })}
                        {branding.audioVideoSettings.radioLogoPositionBottomLeft}
                    </LogoPositionButton>
                </LogoPositionButtonsContainer>
            </SettingsGroup>
            <SettingsGroup>
                <Container className="p-0">
                    <Row>
                        <Col>
                            <SettingsSectionTitle>{branding.audioVideoSettings.logoGalleryTitleLabel}</SettingsSectionTitle>
                        </Col>
                        <Col>
                            <IconContainer
                                onClick={() => {
                                    if (logoInputRef.current) {
                                        logoInputRef.current.value = ""
                                    }
                                    logoInputRef.current?.click()
                                }}
                            >
                                {IconUpload({ fill: "#fff", width: "15px", height: "15px" })}
                            </IconContainer>
                            <input
                                ref={logoInputRef}
                                onChange={(e: SyntheticEvent) => {
                                    uploadLogo(e)
                                }}
                                type="file"
                                accept="image/png, image/jpg, image/jpeg"
                                style={{ display: "none" }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <LogoGalleryContainer>
                                <LogoGalleryItem isActive={!getSelectedLogo()} onClick={() => removeLogo()} logo={""}>
                                    {branding.audioVideoSettings.radioNoLogo}
                                </LogoGalleryItem>

                                {getLogoGalleryItems()?.length &&
                                    getLogoGalleryItems().map((url: any) => {
                                        return (
                                            <LogoGalleryItem isActive={getSelectedLogo() === url} logo={url}>
                                                <CustomBackgroundActionsOverlay
                                                    onClick={() => {
                                                        setSelectedLogo(url)
                                                    }}
                                                ></CustomBackgroundActionsOverlay>

                                                {url === getCustomUploadedLogo() && (
                                                    <>
                                                        <div className="actions">
                                                            <TrashIconContainer onClick={() => deleteCustomUploadedLogo()}>
                                                                {IconTrash({
                                                                    width: "12px",
                                                                    height: "12x",
                                                                    fill: "#fff"
                                                                })}
                                                            </TrashIconContainer>
                                                        </div>
                                                    </>
                                                )}
                                            </LogoGalleryItem>
                                        )
                                    })}
                            </LogoGalleryContainer>
                        </Col>
                    </Row>
                </Container>
            </SettingsGroup>
        </DeviceSettingsRoot>
    )
}

export default LogoSettings
