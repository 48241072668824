export enum LiveStatus {
    UNKNOWN = 1,
    OFF_AIR_UNLOCKED = 2,
    PENDING_LOCKING = 3,
    PENDING_UNLOCKING = 4,
    OFF_AIR_LOCKED = 5,
    PENDING_OFF_ON = 6,
    ON_AIR = 7,
    PENDING_ON_OFF = 8
}
