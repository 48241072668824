import styled, { css } from "styled-components"

export const LocalAvatarRoot = styled.div`
    background-color: #37383a;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LocalAvatarContainer = styled.div<{ isHandRaised: boolean; isSpeaking: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    /* Raised hand border */
    ${(props) =>
        props.isHandRaised &&
        css`
            border: 3px solid #eecb11;
            box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
            -webkit-box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
            -moz-box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
        `}

    /* Speaking border */
    ${(props) =>
        props.isSpeaking &&
        !props.isHandRaised &&
        css`
            border: 3px solid #5db130;
            box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
            -webkit-box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
            -moz-box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
        `}
`
export const LocalAvatarNamePlate = styled.div`
    color: #fff;
    position: absolute;
    left: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: #202428;
    font-size: 14px;
    line-height: 17px;
    padding: 3px 3px 3px 6px;

    .activity {
        width: 23px;
        display: inline-block;

        .ch-bg-volume-wrapper {
            width: 23%;
            left: 36%;
        }
        .ch-bg-volume-fill {
            background-color: #5db130;
        }
    }

    .hand {
        fill: #eecb11;

        path {
            fill: #eecb11;
        }
    }

    svg {
        margin-right: 3px;
    }
`
