import { VideoTile } from "amazon-chime-sdk-component-library-react"
import styled from "styled-components"

export const StyledPreview = styled(VideoTile)`
    height: auto;
    background: unset;
    /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='60px' width='60px' font-family='helvetica' text-align='center'><text x='0' y='15' fill='white' font-size='12'>Loading...</text></svg>"); */
    background-repeat: no-repeat;
    background-position: center;

    video {
        position: static;
    }
`
export const PreMeetingVideoWrapper = styled.div`
    position: relative;
`

export const PreMeetingControlsWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 5px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
`
export const SettingsGroup = styled.div`
    flex: 0 0 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 13px;
`

export const ControlToggleHelperWrapper = styled.div`
    height: 25px;
    display: inline-block;
    .switch {
        margin-bottom: -4px;
    }
`

export const ControlLabel = styled.span`
    margin-left: 7px;
    line-height: 15px;
    color: #fff;
`

export const PreMeetingSettingsBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
    flex: 1;
    padding-right: 15px;
    cursor: pointer;
    :hover {
        span {
            color: #d4d4d4;
        }
        svg {
            path {
                fill: #d4d4d4;
            }
        }
    }
`

export const PremeetingDeviceItem = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 25px;
    padding: 3px 5px 3px 15px;
    width: 130px;
`

export const PreviewPlaceholderRoot = styled.div`
    background-color: #000;
    width: 100%;
    height: auto;
`
