import styled from "styled-components"
import branding from "../../../branding/branding"
import { DetailNavLink } from "../../detailPages/DetailNavLink"
import { device } from "../../../utils/Device"

export const TagsWrapper = styled.div`
    display: flex;
    align-items: center;

    &.mySchedule {
        display: inline-block;
    }
`
export const TagWrapper = styled.div<{ hasLogo?: boolean; orgaDetailPage?: boolean }>`
    width: ${(props) => (props.orgaDetailPage ? "30px" : "40px")};
    height: 26px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    padding-top: ${(props) => (props.hasLogo ? "0px" : "8px")};

    &.list {
        margin-top: ${(props) => (props.orgaDetailPage ? "-6px" : "-15px")};
    }

    &.mySchedule {
        display: inline-block;
        width: 26px;
        margin-top: 8px;
    }
`
export const TagIcon = styled.div`
    display: flex;
    align-items: center;

    &.mySchedule {
        display: inline-block;
    }
`

export const PrivateEventMarkerRoot = styled.div<{ bottomPadding?: string; parentHeight?: number; alwaysSticky?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    &.tile-layout {
        width: 113%;
        position: sticky;
        top: ${(props) => (props.alwaysSticky || (props.parentHeight && props.parentHeight - 4 <= 370) ? "0" : "100%")};
        bottom: ${(props) => props.bottomPadding ?? "0"};
        margin-left: -6.5%;
        margin-right: -6.5%;
        height: 50px;
        background-color: #c4c4c4;

        & .private-event-marker-text {
            & p {
                font-size: 16px;
                margin-top: 15px;
            }
        }
    }

    &.tile-layout-absolute {
        width: 106%;
        position: absolute;
        bottom: ${(props) => props.bottomPadding ?? "0"};
        margin-left: -6.5%;
        margin-right: -6.5%;
        height: 50px;
        background-color: #c4c4c4;

        & .private-event-marker-text {
            & p {
                font-size: 16px;
                margin-top: 15px;
            }
        }
    }

    &.list-layout {
        width: 120px;
        height: 30px;
        border: ${branding.mainBorder};
        border-radius: 10px;
        margin-top: 20px;

        & .private-event-marker-text {
            & p {
                font-size: 12px;
                line-height: 30px; // height of the parent div (30px) - to be able to center text vertically
            }
        }
    }

    & .private-event-marker-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 10px;

        & p {
            font-family: ${branding.font1};
            margin: auto;
            color: ${branding.primaryColor};
        }
    }
`
export const EventDateBackgroundFixer = styled.div`
    width: 350px;
    min-width: 350px;
    background: #fff;
    position: relative;
    z-index: 2;
`

interface EventDateEntryRootProps {
    marginTop: number
    marginBottom: number
    marginLeft: number
    height: number
    isOpen: boolean
    background: string
    border: string
}

export const EventDateEntryRoot = styled.div<EventDateEntryRootProps>`
    border: ${branding.programSchedule.borderWidthForProgramTiles} solid ${(props) => props.border};
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 0;
    overflow: hidden;
    background-color: ${(props) => props.background};
    position: relative;
    display: block;
    flex-wrap: wrap;
    color: ${branding.mainInfoColor};

    margin-top: ${(props) => props.marginTop + "px"};
    margin-left: ${(props) => props.marginLeft + "px"};
    min-height: ${(props) => props.height - 4 + "px"};
    height: ${(props) => (props.isOpen ? "auto" : props.height - 4 + "px")};
    min-width: 350px;
    width: 350px;
    -webkit-transition-duration: 0.4s;
    -ms-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transition-property: "height, min-height, margin-bottom";
    -ms-transition-property: "height, min-height, margin-bottom";
    transition-property: "height, min-height, margin-bottom";
    z-index: ${(props) => (props.isOpen ? "2" : "")};
    overflow: hidden;

    &:hover {
        background-color: #f2f2f2; // #f9f9f9;
        cursor: pointer;
        -webkit-transition: background-color 0.4s ease-in-out !important;
        -ms-transition: background-color 0.4s ease-in-out !important;
        transition: background-color 0.4s ease-in-out !important;
        overflow-y: auto;
    }
    z-index: 2;
`

export const EventDateBadgeWrapper = styled.div`
    display: inline-block;
    position: relative;
    padding-block-start: 5px;
    padding-block-end: 5px;
    padding-inline-start: 25px;
    padding-inline-end: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 20px;
    justify-self: right;
    font-size: 13px;
`
export const EventDateBadgeDot = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #f44336;
    margin-left: -16px;
    margin-right: 5px;
    margin-bottom: 1px;
`

export const EventLogoBadgeContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px;
    margin-bottom: 15px;
    margin-top: 5px;
`
export const EventDateTileHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    position: relative;
`

export const EventDateFavBookmarkRoot = styled.div`
    right: 13px;
    top: 19px;
    font-size: 20px;
`
export const TimeAndTagsWrapper = styled.div`
    display: flex;
    align-items: center;
`
export const EventDateEntryTime = styled.div<{ upcoming?: boolean; hasLogo?: string }>`
    font-size: ${(props) => (props.upcoming ? "14px" : "14px")};
    line-height: 17px;
    font-family: ${branding.font1};
    color: ${(props) => props.color};
    margin-top: ${(props) => props.hasLogo};
`
export const EventDateEntryName = styled.div`
    padding-right: 15px;
    font-weight: bold;
    font-family: ${branding.font1};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
    margin-top: 20px;
`

export const EventDateEntryLogoDiv = styled.div`
    height: 30px;
    & img {
        height: 100%;
        width: auto;
    }
`

export const PersonColumn = styled.div`
    margin-top: 14px;
`

interface EventDateTileRootProps {
    height: number
    marginTop: number
    marginLeft: number
    width?: string
    zIndex: number
}

export const EventDateTileRoot = styled.div<EventDateTileRootProps>`
    height: ${(props) => props.height + "px"};
    margin-top: ${(props) => props.marginTop + "px"};
    margin-left: ${(props) => props.marginLeft + "px"};
    border-radius: 5px;
    padding: 10px;
    background: #fff;
    min-width: 320px;
    width: 320px;
    font-size: 16px;
    line-height: 17px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: ${(props) => props.zIndex};
    font-family: ${branding.font1};

    &.private {
        background: ${branding.mySchedule.privateEventColors.backgroundColor} !important;
        color: ${branding.mySchedule.privateEventColors.textColor} !important;
        border: ${branding.mySchedule.privateEventColors.border};
        border-left: ${branding.mySchedule.privateEventColors.borderLeft};
        min-width: 320px;
        width: 320px;

        &:hover {
            background: ${branding.mySchedule.privateEventColors.hoverColor} !important;
        }
    }

    &.open {
        background: ${branding.mySchedule.openEventColors.backgroundColor} !important;
        color: ${branding.mySchedule.openEventColors.textColor} !important;
        border: ${branding.mySchedule.openEventColors.border};
        border-left: ${branding.mySchedule.openEventColors.borderLeft};
        min-width: 320px;
        width: 320px;

        &:hover {
            background: ${branding.mySchedule.openEventColors.hoverColor} !important;
        }
    }

    &.isMyFairPage {
        background: ${branding.mySchedule.myFairPageEventColors.backgroundColor} !important;
        color: ${branding.mySchedule.myFairPageEventColors.textColor} !important;
        border: ${branding.mySchedule.myFairPageEventColors.border};
        border-left: ${branding.mySchedule.myFairPageEventColors.borderLeft};
        min-width: 350px;
        width: ${(props) => (props.width ? props.width : "350px")};
        font-size: 16px;
        line-height: 20px;
        padding: 25px;
    }
`

export const LocationName = styled.div`
    margin-top: 5px;
    svg {
        width: 13px;
        height: 13px;
        margin-bottom: 2.6px;
    }
`

export const TileTitleDiv = styled.div`
    max-width: 300px;
    max-height: 200px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    font-weight: bold;
`

export const RoundTableAccess = styled.div<{ accessGranted: boolean }>`
    margin-top: 5px;
    border: 1px solid;
    color: ${(props) => (props.accessGranted ? "#00B300" : branding.dangerButtonColor)};
    border-radius: 20px;
    width: fit-content;
    text-align: center;
    font-size: 12px;
    padding: 5px;
    padding-top: 1px;
    padding-bottom: 2px;
`

interface SliderParentProps {
    height: string
    marginTop: string
    itemsCount: number
    size: number
}
export const SliderParent = styled.div<SliderParentProps>`
    width: 100%;
    height: ${(props) => props.height};
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: ${(props) => props.marginTop};

    &:hover,
    &:focus {
        //overflow-x: scroll;
        z-index: 1000;
    }

    .sliderWrapper {
        display: flex;
        //width: ${(props) => props.itemsCount * props.size + "px"};
        width: 100%;
    }
`

export const MeetingDescriptionDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    display: inline-block;
    width: 90%;
    font-size: 14px;
    font-family: ${branding.font1};
`
export const SliderItem = styled.div<{ width: number }>`
    display: inline-block;
    width: ${(props) => props.width + "px"};
`

export const MyScheduleEntryFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 10px;
    right: 10px;
`

export const PointsBadge = styled.div<{ textColor: string; fillColor: string; borderColor: string }>`
    display: inline-block;
    padding: 5px;
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.fillColor};
    border-color: ${(props) => props.borderColor};
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-size: 12px;
    font-family: ${branding.font1};
`

export const DescriptionRoot = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    width: 100%;

    &.list-view {
        margin-top: 5px;
        -webkit-line-clamp: 1;
        color: ${branding.eventDateDetailPageContent.eventListLayoutDescriptionColor};
    }

    svg {
        width: 12px;
        height: 12px;
    }
`
export const StyledDetailNavlink = styled(DetailNavLink)`
    display: block;
`

export const MobileEventDateEntryTime = styled.div`
    display: flex;
    flex-direction: row;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    margin-top: 15px;
    margin-left: 12px;
    overflow: hidden;
`

export const MobileEventDateEntryTitle = styled.div`
    display: inherit;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
    margin-left: 12px;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const MobileEventDateEntryDescription = styled.div`
    display: inherit;
    flex-direction: row;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 5px;
    margin-left: 12px;
    overflow: hidden;
    width: 180px;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const MobileEventDateEntryRoot = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    height: 95px;
    width: 205px;
    min-width: 205px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};
`

export const DateTimeLocationDiv = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #727272;
`

export const EventDateFavBookmarkRoot1 = styled.div`
    display: flex;
    flex: 1;

    &.center-inside * {
        display: flex;
        justify-content: center;
    }
`
export const EventDateBadgeWrapper1 = styled.div`
    display: inline-block;
    padding-block-start: 5px;
    padding-block-end: 5px;
    padding-inline-start: 25px;
    padding-inline-end: 15px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 12px;
`
export const EventDateBadgeDot1 = styled.div`
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #f44336;
    margin-left: -16px;
    margin-right: 5px;
    margin-bottom: 1px;
`

export const SpeakersDiv = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: none;
    font-size: 14px;
    color: ${branding.eventDateDetailPageContent.eventListLayoutDescriptionColor};
`

export const ListEventDateEntryDataContainer = styled.div`
    display: flex !important;
    width: 100%;
    padding-right: 10px;
    margin-left: 25px;

    @media ${device.mobile} {
        margin-top: -4px;
    }
`

export const NoResultsContent = styled.div`
    font-family: ${branding.font1};
    color: ${branding.exhibitorsPageContent.noSearchResultsTextColor};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-top: 20%;
    text-align: center;
`

export const DateBox = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${branding.organizationDetailPageContent.dateBoxColor};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Day = styled.div`
    font-size: 30px;
    line-height: 24px;
    font-weight: 500;
`

export const Month = styled.div`
    font-size: 21px;
    line-height: 22.5px;
    font-weight: 400;
`

export const EventDateImageContainer = styled.div<{ orgaDetailPage?: boolean }>`
    width: ${(props) => (props.orgaDetailPage ? "102px" : "95px")};
    height: ${(props) => (props.orgaDetailPage ? "102px" : "95px")};
    border: ${(props) => (props.orgaDetailPage ? "none" : "1px solid #c9c9c9")};
    border-radius: 5px;
    object-fit: contain;
    display: flex;
    align-items: center;
    overflow: hidden;
`

export const EventDateImage = styled.img`
    object-fit: contain;
    width: 100%;
    margin: 0 auto;
`
