import styled from "styled-components"

export const RecorderRoot = styled.div`
    margin-bottom: 20px;
    min-height: 65px;
`

export const RecorderItem = styled.div`
    padding: 5px;
    display: flex;
    min-height: 65px;
    transition: 0.3s;
    align-items: space-between;
    margin-bottom: 10px;
    background-color: #313233;
    cursor: pointer;

    :hover {
        background-color: #202428;
    }

    @keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    .spin {
        animation: rotation 1s linear infinite;
    }

    .disabledRestart {
        & svg path {
            stroke: grey;
            fill: grey;
        }
        cursor: not-allowed;
    }
`

export const RecorderAvatar = styled.div`
    padding-left: 5px;
    flex: 1;
`

export const RecorderTitle = styled.div<{ isRecorderAvailable: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: ${(props) => (props.isRecorderAvailable ? "#fff" : "grey")};
    font-size: 14px;
    line-height: 15px;
    font-weight: 700;
    flex: 1;
    padding-left: 10px;
`

export const RecorderRestartBtn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: 8px;
    line-height: 15px;
    font-weight: 700;
    flex: 3;
    padding-right: 12px;
`

export const IconWrapper = styled.div`
    width: 18px;
    height: 18px;
`

export const RecorderIndicator = styled.div`
    padding-left: 8px;
    margin-top: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
`
