// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import moment from "moment"
import queryString from "query-string"
import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { NavLink, useParams, useRouteMatch } from "react-router-dom"
import styled, { css } from "styled-components"
import { ConversationType } from "../../API"
import { isUserEdited } from "../../App"
import { restartRecorder, RestartRecorderResponse } from "../../backendServices/MeetingServices"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { EventDateChannelFirstDetailsResponse, loadChannelFirstEventDate } from "../../backendServices/EventdateServices"
import { User } from "../../backendServices/GraphQLServices"
import { doConnectAction } from "../../backendServices/SeriesOfTopicsUserServices"
import { Contact, EventDate } from "../../backendServices/Types"
import branding from "../../branding/branding"
import ChatPage, {
    calcConversationId,
    ChatConversationParam,
    ConversationParticipant,
    DisplayStyle
} from "../../communicationArea/ChatPage"
import { Action } from "../../communicationArea/CommunicationOptions"
import { ContactEntry, ContactEntryType } from "../../communicationArea/ContactEntry"
import { StagedOpponentsArea } from "../../communicationArea/ConversationDetails"
import SearchPersonInput from "../../communicationArea/SearchPersonInput"
import { calculateChange, calculateEnd } from "../../contentArea/VideoPageContent"
import { useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { meetingPageRoute } from "../../navigationArea/RoutePaths"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import CrsMultiSwitch from "../../ui/CrsMultiSwitch"
import {
    IconArrowDownLangSwitcher,
    IconArrowUpLangSwitcher,
    IconBlockContact,
    IconClose,
    IconLowerHand,
    IconMicrophoneOff,
    IconNetworkingToggleClose,
    IconRaiseHand,
    IconRemoveFromCall,
    IconShareScreenStop,
    IconStaff,
    IconVolumeHigh
} from "../../ui/Icons"
import { useKickUserModal } from "../../ui/modals/KickUserModal"
import MuteAllModal from "../../ui/modals/MuteAllModal"
import { TextareaRoot } from "../../ui/modals/SayHelloModal"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import TextLimit from "../../ui/TextLimit"
import { getMinutesBeforeSwitchingToTheNextEvent, momentWithoutTimezoneFromTimezonedMoment } from "../../utils/DateUtils"
import { useActiveSpeakerContext } from "../context/ActiveSpeakerContext"
import { useAudioContext } from "../context/AudioContext"
import { useChimeContext } from "../context/ChimeContext"
import { useContentShareContext } from "../context/ContentShareContext"
import { useGreenRoomContext } from "../context/GreenRoomContext"
import { useMeetingContext } from "../context/MeetingContext"
import { useModContext } from "../context/ModContext"
import { useRosterContext } from "../context/RosterContext"
import { ChannelStatus } from "../enums/ChannelStatus"
import RosterAttendeeType from "../types/RosterAttendeeType"
import { chimeSdk } from "../ChimeSdkWrapper"
import { DataMessageType } from "../enums/DataMessageType"

const RosterRoot = styled.div`
    background-color: white;
    border-left: 1px solid white;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex-grow: 1;
`

const RosterPannel = styled.div`
    border-left: 1px solid white;
    width: 330px;
    margin: 0px;
    height: 100%;
    margin-top: -1px;

    &.pannelCollapsed {
        width: 60px;
    }
`

const PannelHead = styled.div`
    text-align: center;
`

const PannelBody = styled.div<{ meetingKind?: string }>`
    display: flex;
    flex-direction: column;
    /*padding-top: 20px;*/
    height: ${(props) => (props.meetingKind === "greenroom" ? "100vh" : "calc(100vh - 191px)")};
    overflow-x: hidden;

    &.pannelCollapsed {
        height: calc(100vh - 133px);
        align-items: center;
    }
`

const PannelFooter = styled.div`
    position: absolute;
    bottom: 0px;
    text-align: center;
    /* margin: 15px 20px 5px 20px; */
    align-content: center;
    max-width: 100%;
    width: 330px;
`

const ButtonAdd = styled.div`
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor ?? "#000"};
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor ?? "#000"};
    border-radius: 5px;
    width: calc(100% - 40px);
    margin: 5px 20px 20px 20px;
    font-family: ${branding.font1};
    font-size: 14px;
    cursor: pointer;
    padding: 8px 0;
    transition: 0.2s;

    :hover {
        opacity: 0.7;
    }
`

const ButtonConnect = styled(ButtonAdd)`
    white-space: nowrap;
    color: ${branding.roster.buttonConnectColor ?? "#000"};
    background: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
    &:hover {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background-color: ${branding.crsTabs.defaultActionItemActiveStateColor} !important;
        opacity: 1 !important;
    }
    &:focus {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background: ${branding.roster.buttonConnectColor ?? "#000"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#FFF"};
    }

    &:active {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background: ${branding.roster.buttonConnectColor ?? "#000"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#FFF"};
    }

    &:not {
        color: ${branding.roster.buttonConnectColor ?? "#000"};
        background: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#000"};
    }
`

const AttendiesPannel = styled.div`
    display: flex;
    flex-direction: column;
`

const AttendiesPannelHeader = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 0 20px;
    height: 62px;

    align-items: center;

    &.pannelCollapsed {
        border-bottom: 0px solid white;
    }

    .active {
        color: ${branding.crsMultiSwitcher.crsMultiSwitcherActiveColorInMeeting};
    }
`

const AttendiesTitle = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    width: calc(100% - 60px);
    text-align: center;
`

const NetworkingToggleIcon = styled.div`
    position: relative;
    right: 5px;
    cursor: pointer;
    margin-bottom: -1px;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    &:hover {
        fill: gray;
    }

    &.pannelCollapsed {
        transform: rotate(180deg) scale(1);
    }
`
const StyledContactEntry = styled(ContactEntry)`
    /** Color of the attendee name */
    & > :first-child:hover {
        color: ${branding.primaryColor};
    }

    /** Color of the menu action buttons */
    & .expanded .expanded > div > div > div:hover {
        color: #000;
    }

    & .disabled:hover {
        color: black !important;
    }
`
const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin: 16px;
    /* border-bottom: 1px solid ${branding.roster.searchInputColor ?? "#000"}; */
    line-height: 35px;
    color: ${branding.roster.searchInputColor ?? "#000"};
    text-align: left;
    font-family: ${branding.font1};

    input {
        /* margin-left: 15px; */
        background-color: ${branding.roster.searchInputBgColor ?? "#FFF"};
        color: ${branding.roster.searchInputColor ?? "#000"};
        border-radius: 5px;
    }
    input::placeholder {
        color: ${branding.roster.searchInputColor ?? "#000"};
        opacity: 0.6;
    }
`
const MaxAttendees = styled.div`
    padding: 20px;
    font-weight: bold;
`

const AttendeeRoot = styled.div`
    position: relative;

    &:hover > div > svg {
        color: #000;
    }
`

const ModIcon = styled.span<{ left?: string; top?: string; fontSize?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => props.top ?? 0};
    left: ${(props) => props.left ?? "5px"};
    font-size: ${(props) => props.fontSize ?? "20px"};
`
const HandRaisedIcon = styled.div<{ top?: string; right?: string; bottom?: string; left?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => (props.top ? props.top : "32px")};
    right: ${(props) => (props.right ? props.right : "auto")};
    bottom: ${(props) => (props.bottom ? props.bottom : "auto")};
    left: ${(props) => (props.left ? props.left : "8px")};
`
const SpeakingIcon = styled.div<{ top?: string; right?: string; bottom?: string; left?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => (props.top ? props.top : "8px")};
    right: ${(props) => (props.right ? props.right : "auto")};
    bottom: ${(props) => (props.bottom ? props.bottom : "auto")};
    left: ${(props) => (props.left ? props.left : "50px")};
`
const MainModal = styled(Modal)`
    .modal-content {
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 18px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 35px 0 20px 0;
    }

    .modal-body {
        display: flex;
        padding: 0px 10px;
    }

    .modal-footer {
        border-top: none;
        justify-content: space-between;
        margin: 0px;
    }

    .close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 46px;
        font-weight: 10;
        line-height: 1.66666667;
        color: #000;
        width: 25px;
        padding: 0 2px;
        background: none;
        border-style: none;
        margin-right: 1px;
        margin-top: -16px;
    }
`
const CancelButton = styled(Button)`
    display: block;
    width: 25%;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`
const ConfirmButton = styled(Button)`
    display: block;
    width: 25%;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

export enum SideBarViewMode {
    ROSTER,
    CHAT
}
export enum KickOrBanMode {
    NONE,
    KICK,
    BAN
}
export default function Roster(props: {
    conversationParticipants?: User[]
    selectedItem?: SideBarViewMode
    guestBannerHeight: number
}) {
    const [selectedItem, setSelectedItem] = useState<SideBarViewMode>()
    const chime = useChimeContext()
    const rosterContext = useRosterContext()
    const roster = rosterContext.getRoster()
    const mod = useModContext()
    const appState = useAppState()
    const isOpen = useAppState().isRosterOpen
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const cachedParticipantsMap = useRef<Map<string, RosterAttendeeType>>(new Map()) // Holds company information for users who left the call
    const meetingKind = chime.getKind()
    const hasChatRoom =
        meetingKind === "virtualCafe" ||
        meetingKind === "showroom" ||
        meetingKind === "greenroom" ||
        meetingKind === "roundtable" ||
        meetingKind === "breakout" ||
        meetingKind === "conferenceroom"
    const loggedInUserId = useLoggedInState().user()?.profileId

    function onRosterToggleClick(value: SideBarViewMode) {
        if (value === SideBarViewMode.ROSTER) {
            appState.setIsMeetingChatShown(false)
        } else {
            appState.setIsMeetingChatShown(true)
        }
    }

    useEffect(() => {
        if (appState.isMeetingChatShown) {
            setSelectedItem(SideBarViewMode.CHAT)
        } else {
            setSelectedItem(SideBarViewMode.ROSTER)
        }
        // eslint-disable-next-line
    }, [appState.isMeetingChatShown, isOpen])

    useEffect(() => {
        appState.setIsMyHandRaised(false)
        appState.setIsRosterOpen(true)

        Object.values(meetingParticipants).forEach((particpant) => {
            if (particpant.id === loggedInUserId && appState.isMyHandRaised) {
                particpant.handRaised = true
                appState.setIsMyHandRaised(true)
            }
        })

        // eslint-disable-next-line
    }, [])

    const isSupportedVirtualCafe = branding.roster.supportVirtualCafes.find((it) => window.location.href.indexOf("vc_" + it) > -1)
    const rosterKeys = Object.keys(roster)

    const meetingParticipants: RosterAttendeeType[] = rosterKeys.map((attendeeId) => {
        return { rosterId: attendeeId, ...rosterContext.getAttendee(attendeeId) }
    })
    meetingParticipants.forEach((meetingParticipant) => {
        if (meetingParticipant.id) {
            cachedParticipantsMap.current.set(meetingParticipant.id, meetingParticipant)
        }
    })

    if (!hasChatRoom && props.conversationParticipants) {
        props.conversationParticipants.forEach((p) => {
            const meetingParticipant = meetingParticipants.find((attendee) => attendee.id === p.id)
            if (!meetingParticipant) {
                const { pictureUrl, ...rest } = p
                const cachedParticipant = cachedParticipantsMap.current.get(p.id)
                meetingParticipants.push({
                    signalStrength: 0,
                    volume: 0,
                    muted: false,
                    handRaised: false,
                    avatarUrl: pictureUrl,
                    position: cachedParticipant?.position,
                    company: cachedParticipant?.company,
                    ...rest
                })
            }
        })
    }

    const itemList = [
        { label: branding.conferenceTexts.participantsSwitch, value: SideBarViewMode.ROSTER },
        { label: branding.conferenceTexts.chatSwitch, value: SideBarViewMode.CHAT }
    ]
    return (
        <RosterRoot className={isOpen ? "" : ""}>
            <RosterPannel className={isOpen ? "" : "pannelCollapsed"}>
                <AttendiesPannel>
                    <div>
                        <AttendiesPannelHeader
                            className={isOpen ? "" : "pannelCollapsed"}
                            style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}
                        >
                            <NetworkingToggleIcon
                                onClick={() => appState.setIsRosterOpen(!isOpen)}
                                className={isOpen ? "" : "pannelCollapsed"}
                            >
                                {IconNetworkingToggleClose({
                                    fill: isMeetingPage
                                        ? branding.sideIconBar.sideIconColorDark ?? "#fff"
                                        : branding.sideIconBar.sideIconColorDark
                                })}
                            </NetworkingToggleIcon>
                            {isOpen && hasChatRoom && (
                                <CrsMultiSwitch
                                    items={itemList}
                                    activeItem={selectedItem}
                                    onItemClick={onRosterToggleClick}
                                    style={{
                                        border: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeBorderColor,
                                        msRootItem: {
                                            flex: "1 1 auto",
                                            fontSize: "0.9rem",
                                            padding: "1px",
                                            margin: "0px 38px 0 8px",
                                            color: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor,
                                            position: "relative",
                                            zIndex: 1
                                        },
                                        msItem: { position: "relative", zIndex: 1 },
                                        msActiveItem: {
                                            backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeBgColor,
                                            color: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor,
                                            position: "absolute",
                                            zIndex: 1
                                        },
                                        itemColor: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor
                                    }}
                                />
                            )}
                            {isOpen && !hasChatRoom && (
                                <AttendiesTitle>{branding.conferenceTexts.participantsSwitch}</AttendiesTitle>
                            )}
                        </AttendiesPannelHeader>
                    </div>
                    {isOpen ? (
                        <>
                            {" "}
                            {hasChatRoom && (
                                <ChatContainer
                                    viewMode={selectedItem ?? SideBarViewMode.ROSTER}
                                    guestBannerHeight={props.guestBannerHeight}
                                    virtualCafe={meetingKind === "virtualCafe" && isSupportedVirtualCafe !== undefined}
                                />
                            )}
                            {(!hasChatRoom || selectedItem === SideBarViewMode.ROSTER) && (
                                <>
                                    {meetingKind === "greenroom" ? (
                                        mod.isMod() ? (
                                            <GreenRoomOperatorView
                                                meetingParticipants={meetingParticipants}
                                                channelId={chime.getExternalMeetingId()?.substr(3)}
                                            />
                                        ) : (
                                            <GreenRoomPresentersView
                                                meetingParticipants={meetingParticipants}
                                                channelId={chime.getExternalMeetingId()?.substr(3)}
                                            />
                                        )
                                    ) : (
                                        <ParticipantRoster
                                            meetingParticipants={meetingParticipants}
                                            showParticipantSearch={meetingKind !== "roundtable"}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <PannelBody
                            className="pannelCollapsed"
                            style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}
                        >
                            <AttendeesLoadedAvatarContent
                                isMyHandRaised={appState.isMyHandRaised}
                                meetingParticipants={meetingParticipants}
                            />
                        </PannelBody>
                    )}
                </AttendiesPannel>
            </RosterPannel>
        </RosterRoot>
    )
}

const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`
const SubmitButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
    margin-right: 20px;
`

interface ParticipantRosterProps {
    meetingParticipants: RosterAttendeeType[]
    showParticipantSearch: boolean
}

const ParticipantRoster: React.FC<ParticipantRosterProps> = (props) => {
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const rosterContext = useRosterContext()
    const roster = rosterContext.getRoster()
    const loggedInUserId = useLoggedInState().user()?.profileId

    const userState = useLoggedInState()

    const [showConnectWithAllModal, setShowConnectWithAllModal] = useState(false)
    const [stagedOpponents, setStagedOpponents] = useState<ConversationParticipant[]>([])

    const sayHelloTextAreaRef = useRef<HTMLTextAreaElement>(null)
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const maxTextLength: number = 150
    const [notes, setNotes] = useState("")

    function onNotesChanged(value: string) {
        if (value.length > maxTextLength) {
            return
        }
        setNotes(value)
    }

    const handleClose = (helloMessage: string) => {
        Object.keys(roster).map((attendeeId: string) => {
            const attendee = rosterContext.getAttendee(attendeeId)
            if (attendee) {
                if (attendee.id !== (loggedInUserId as string) && attendee.id !== "recorder") {
                    // TODO && externalUser.myConnectionStatus === "UNRELATED") {
                    doConnectAction({
                        profileId: loggedInUserId as string,
                        targetProfileId: attendee.id!,
                        message: helloMessage,
                        action: "request"
                    })
                        .then((response: any) => {})
                        .catch((e: { message: React.SetStateAction<string> }) => {
                            // Logged in BackendServices
                        })
                }
            }
            return null
        })
        setShowConnectWithAllModal(false)
    }

    const onPersonSelected = (person: Contact) => {
        const personAddedAlready = stagedOpponents.find((x) => x.id === person.id) || null
        if (person && personAddedAlready === null) {
            const newOpponent = {
                id: person.id,
                name: [person.firstName, person.lastName].join(" "),
                pictureUrl: person.logoUrl,
                position: person.position,
                organization: person.organization
            }
            setStagedOpponents(stagedOpponents.concat([newOpponent]))
        }
    }

    const connectWithAllParticipantsModal = (
        <>
            <MainModal
                show={showConnectWithAllModal}
                onHide={() => setShowConnectWithAllModal(false)}
                backdrop="static"
                centered
                animation={false}
            >
                <Modal.Header>
                    {/* <span style={{ marginTop: "2px", marginRight: "10px" }}>{IconConnect({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark })}</span> */}
                    <Modal.Title>
                        <h3 className="modal-title">{branding.sayHelloModal.headerTextSayHello}</h3>
                    </Modal.Title>
                    <CloseButton onClick={() => setShowConnectWithAllModal(false)}>
                        {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                    </CloseButton>
                </Modal.Header>

                <Modal.Body>
                    <div style={{ margin: "10px 20px 10px 20px" }}>
                        <div style={{ alignItems: "center", fontSize: "14px", lineHeight: "18px", marginBottom: "30px" }}>
                            {branding.sayHelloModal.subtitleConnectRequestPart1}{" "}
                            {branding.sayHelloModal.subtitleConnectRequestPart2}
                        </div>
                        <TextareaRoot>
                            <textarea
                                maxLength={maxTextLength}
                                autoFocus={true}
                                ref={sayHelloTextAreaRef}
                                value={notes}
                                onChange={(event) => onNotesChanged(event.target.value)}
                                name="modalInputMessage"
                                // onChange={props.onChange}
                                className="form-control"
                                placeholder={branding.sayHelloModal.sendMessagePlaceholder}
                            ></textarea>
                            <TextLimit textLength={notes?.length || 0} maxTextLength={maxTextLength} />
                        </TextareaRoot>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButtonContainer>
                        {/* <Button variant="secondary" onClick={() => handleClose(textAreaRef1.current!.value)}>
          Sent
        </Button>
        <Button variant="primary" onClick={() => setShow(false)}>
          Close
        </Button> */}
                        <CancelButton
                            onClick={() => setShowConnectWithAllModal(false)}
                            className="d-flex align-items-center justify-content-center"
                        >
                            {branding.globalTexts.cancel}
                        </CancelButton>
                        <ConfirmButton
                            variant="primary"
                            onClick={() => handleClose(sayHelloTextAreaRef.current!.value)}
                            className="d-flex align-items-center justify-content-center"
                        >
                            {branding.globalTexts.confirm}
                        </ConfirmButton>
                    </SubmitButtonContainer>
                </Modal.Footer>
            </MainModal>
        </>
    )

    // Handle full room
    let addPerson
    if (!props.showParticipantSearch) {
        addPerson = null
    } else if (props.meetingParticipants.length + stagedOpponents.length >= rosterContext.getMaxAttendees()) {
        addPerson = <MaxAttendees>{branding.conferenceTexts.maxAttendees}</MaxAttendees>
    } else {
        addPerson = (
            <StyledSearchPersonInput
                shortenPersonInfo={true}
                className="styled-search-person-input"
                placeholder={branding.conferenceTexts.searchForParticipantsPlaceholder}
                personClicked={onPersonSelected}
                disableSearchIcon={false}
                disableIds={[
                    ...stagedOpponents.map((p) => p.id),
                    ...Object.keys(roster)
                        .map((attendeeId: string) => {
                            const attendee = rosterContext.getAttendee(attendeeId)
                            if (attendee?.id) return attendee.id!
                            else return ""
                        })
                        .map((p) => p)
                ]}
                disabledText="Added"
            />
        ) // TODO extract string resource
    }

    return (
        <>
            <PannelHead style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                {addPerson}
                {stagedOpponents.length > 0 && (
                    <>
                        <StagedOpponentsArea
                            includeSelf={false}
                            opponents={stagedOpponents}
                            unstageOpponent={(unstageMe) =>
                                setStagedOpponents(stagedOpponents.filter((op) => op.id !== unstageMe.id))
                            }
                        />
                        <ButtonAdd
                            onClick={async () => {
                                stagedOpponents.map((person) => {
                                    meeting.sendInvite(person.id, { meetingId: chime.getName(), meetingKind: chime.getKind() })
                                    return null
                                })
                                setStagedOpponents([])
                            }}
                        >
                            {branding.conferenceTexts.addParticipantsText}
                        </ButtonAdd>
                    </>
                )}
            </PannelHead>
            <div>
                <PannelBody style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                    <ContentScrollContainer adjustForHeaderWith={"300px"} paddingLeft={"0px"}>
                        <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} />
                    </ContentScrollContainer>
                </PannelBody>
            </div>
            {isUserEdited(userState) && (
                <div style={{ visibility: branding.hasConnectWithAllParticipants ? "visible" : "hidden" }}>
                    <PannelFooter>
                        {Object.keys(roster).length > 1 && (
                            <ButtonConnect onClick={() => setShowConnectWithAllModal(true)}>
                                {branding.conferenceTexts.connectWithAllParticipantsText}
                            </ButtonConnect>
                        )}
                    </PannelFooter>
                </div>
            )}
            {connectWithAllParticipantsModal}
        </>
    )
}

const AttendeesListContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const AttendeesSectionHeader = styled.div`
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 12px;
    display: inline-flex;
`

const AttendeesSectionTitle = styled.div`
    font-family: ${branding.font1};
    font-size: 12px;
    line-height: 17px;
    font-weight: 700;
    margin-top: 4px;
`
const AttendeesSectionToggleButton = styled.div`
    margin-right: 8px;
    cursor: pointer;
`

const MuteAllButton = styled(Button)`
    background-color: #fff !important;
    border: 1px solid ${branding.communicationArea.editProfileButtonColor} !important;
    box-sizing: border-box;
    padding: 1px 10px 1px 10px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    color: ${branding.communicationArea.editProfileButtonColor} !important;
    user-select: none;
    border-radius: 5px;
    margin-left: auto;
    margin-right: 20px;
    text-align: center;
    height: 18px;
    width: auto;
    min-width: 58px;
    margin-top: 4px;

    &:active,
    &:focus,
    &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: ${branding.communicationArea.editProfileButtonColor} !important;
        background-color: #fff;
        border: 1px solid ${branding.communicationArea.editProfileButtonColor};
    }
`

interface AttendeesLoadedListContentProps {
    meetingParticipants: RosterAttendeeType[]
    greenroom?: boolean
}

const AttendeesLoadedListContent: React.FC<AttendeesLoadedListContentProps> = React.memo((props) => {
    const mod = useModContext()
    const [showModerators, setShowModerators] = useState<boolean>(true)
    const [showParticipants, setShowParticipants] = useState<boolean>(true)

    const [loaded, setLoaded] = useState<boolean>(false)

    const [moderators, setModerators] = useState<RosterAttendeeType[]>([])
    const [participants, setParticipants] = useState<RosterAttendeeType[]>([])

    const contentShare = useContentShareContext()
    const roster = useRosterContext()
    const chime = useChimeContext()
    const loggedInUserId = useLoggedInState().user()?.profileId
    //const isMeetingPage = useRouteMatch(meetingPageRoute);
    const [kickOrBan, setKickOrBan] = useState<{ mode: KickOrBanMode; attendeeId?: string; userName?: string }>({
        mode: KickOrBanMode.NONE
    })
    const { KickUserModal } = useKickUserModal({ kickOrBan, setKickOrBan })
    const [showMuteAllModal, setShowMuteAllModal] = useState<boolean>(false)
    const queryParams: any = queryString.parse(window.location.search)
    const [timerStarted, setTimerStarted] = useState<boolean>(false)

    useEffect(() => {
        setModerators(props.meetingParticipants.filter((participant) => participant.role === "moderator"))
        setParticipants(props.meetingParticipants.filter((participant) => participant.role !== "moderator"))

        const timeout = setTimeout(() => {
            setLoaded(true)
        }, 5000)

        return () => {
            clearTimeout(timeout)
        }
    }, [props.meetingParticipants])

    useEffect(() => {
        if (loaded && moderators.length === 0 && chime.getKind() === "breakout" && !timerStarted) {
            chime.startMeetingTimer(branding.breakoutTimeoutDuration)
            setTimerStarted(true)
        } else if (loaded && moderators.length > 0 && chime.getKind() === "breakout") {
            chime.startMeetingTimer(null)
            setTimerStarted(false)
        }
        // eslint-disable-next-line
    }, [moderators, loaded, chime.getKind()])

    function getAttendee(attendee: RosterAttendeeType): any {
        const attendeeId = attendee.rosterId
        if (attendee.id === "tmp" || !attendee || (attendee.role === "recorder" && !mod.isMod() && !queryParams.debug)) {
            return null
        }
        const modActions = new Array<Action>()
        if (mod.isMod() && attendeeId) {
            modActions.push({
                disabled: false,
                title: branding.conferenceTexts.kick,
                hint: branding.conferenceTexts.kickHint,
                icon: IconRemoveFromCall({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => setKickOrBan({ mode: KickOrBanMode.KICK, attendeeId: attendeeId, userName: attendee.name })
            })
            modActions.push({
                disabled: false,
                title: branding.conferenceTexts.ban,
                hint: branding.conferenceTexts.banHint,
                icon: IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => setKickOrBan({ mode: KickOrBanMode.BAN, attendeeId: attendeeId, userName: attendee.name })
            })
            modActions.push({
                disabled: false,
                title: branding.conferenceTexts.mute,
                hint: branding.conferenceTexts.mute,
                icon: IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => mod.modMute(attendeeId)
            })
            if (contentShare.getShareScreenTile()?.boundAttendeeId?.replace("#content", "") === attendeeId) {
                modActions.push({
                    disabled: false,
                    title: branding.conferenceTexts.shareScreenStop,
                    hint: branding.conferenceTexts.shareScreenStop,
                    icon: IconShareScreenStop({ fill: branding.sideIconBar.sideIconColorDark }),
                    onClick: () => mod.modStopContentShare(attendeeId)
                })
            }
        }
        if (attendee.handRaised && attendeeId) {
            modActions.push({
                disabled: false,
                title: branding.conferenceTexts.lowerHand,
                hint: branding.conferenceTexts.lowerHand,
                icon: IconLowerHand({ fill: branding.sideIconBar.sideIconColorDark }),
                onClick: () => {
                    roster.raiseHand(attendeeId, false)
                    chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                        type: DataMessageType.RAISEHAND,
                        attendeeId: attendeeId,
                        data: false
                    })
                }
            })
        }

        const hideMenu = attendee.id === loggedInUserId || attendee.id === "recorder"

        return (
            <Attendee
                key={attendee.id!}
                attendee={attendee}
                hideMenu={hideMenu}
                modActions={modActions}
                isMyHandRaised={roster.isMyHandRaised()}
            />
        )
    }

    function muteAllParticipants(participants: RosterAttendeeType[]) {
        participants.forEach((participant) => {
            if (participant.rosterId) {
                mod.modMute(participant.rosterId)
            }
        })

        setShowMuteAllModal(false)
    }

    return (
        <AttendeesListContainer>
            {moderators.length > 0 && (
                <>
                    {props.greenroom ? (
                        <div />
                    ) : (
                        <AttendeesSectionHeader>
                            <AttendeesSectionToggleButton onClick={() => setShowModerators(!showModerators)}>
                                {showModerators
                                    ? IconArrowUpLangSwitcher({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "12",
                                          height: "12"
                                      })
                                    : IconArrowDownLangSwitcher({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "12",
                                          height: "12"
                                      })}
                            </AttendeesSectionToggleButton>
                            <AttendeesSectionTitle>
                                {branding.roster.moderatorsSectionTitle + " (" + moderators.length + ")"}
                            </AttendeesSectionTitle>
                        </AttendeesSectionHeader>
                    )}

                    {showModerators &&
                        moderators.map((attendee) => {
                            return getAttendee(attendee)
                        })}
                </>
            )}
            {participants.length > 0 && (
                <>
                    {props.greenroom ? (
                        <div />
                    ) : (
                        <AttendeesSectionHeader>
                            <AttendeesSectionToggleButton onClick={() => setShowParticipants(!showParticipants)}>
                                {showParticipants
                                    ? IconArrowUpLangSwitcher({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "12",
                                          height: "12"
                                      })
                                    : IconArrowDownLangSwitcher({
                                          fill: branding.sideIconBar.sideIconColorDark,
                                          width: "12",
                                          height: "12"
                                      })}
                            </AttendeesSectionToggleButton>
                            <AttendeesSectionTitle>
                                {branding.roster.participantsSectionTitle + " (" + participants.length + ")"}
                            </AttendeesSectionTitle>
                            {mod.isMod() && (
                                <MuteAllButton onClick={() => setShowMuteAllModal(true)}>
                                    {branding.roster.muteAllButtonText}
                                </MuteAllButton>
                            )}
                        </AttendeesSectionHeader>
                    )}

                    {showParticipants &&
                        participants.map((attendee) => {
                            return getAttendee(attendee)
                        })}
                </>
            )}
            <KickUserModal />
            {showMuteAllModal && (
                <MuteAllModal
                    muteAllParticipants={() => muteAllParticipants(participants)}
                    onClose={() => setShowMuteAllModal(false)}
                />
            )}
        </AttendeesListContainer>
    )
})

interface AttendeeProps {
    attendee: RosterAttendeeType
    hideMenu: boolean
    modActions: Action[]
    isMyHandRaised: boolean
}
function Attendee(props: AttendeeProps) {
    const activeSpeakerContext = useActiveSpeakerContext()

    const { attendee, hideMenu, modActions, isMyHandRaised } = props
    const contact = {
        id: attendee.id,
        profileId: attendee.id,
        lastName: attendee.name,
        logoUrl: attendee.avatarUrl,
        position: attendee.position,
        positionDe: attendee.positionDe,
        company: attendee.company
    }

    return (
        <AttendeeRoot>
            <div
                style={{
                    pointerEvents: attendee.role === "recorder" ? "none" : "auto"
                }}
            >
                <StyledContactEntry
                    contact={contact}
                    hideMenu={hideMenu}
                    additionalActions={modActions}
                    withoutPresence={true}
                    disabled={!attendee.rosterId || attendee.role === "recorder"}
                    entryType={ContactEntryType.ROSTER}
                    useOnlyLastNameForRoute={true}
                />
            </div>
            {attendee.role === "moderator" && <ModIconElem left={"7px"} />}
            {attendee.role === "recorder" && <RecorderIconElem />}
            {activeSpeakerContext.isActiveSpeaker(attendee.rosterId) && <SpeakingIconElem />}
            {attendee.muted && <MutedIconElem />}
            {(attendee.handRaised || (hideMenu && isMyHandRaised)) && attendee.role !== "recorder" && <HandRaisedIconElem />}
        </AttendeeRoot>
    )
}

const AttendeesLoadedAvatarContent: React.FC<{ meetingParticipants: RosterAttendeeType[]; isMyHandRaised?: any }> = (props) => {
    const activeSpeakerContext = useActiveSpeakerContext()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const roster = useRosterContext()
    return (
        <>
            {props.meetingParticipants
                .filter((attendee) => attendee.rosterId && attendee.role !== "recorder")
                .map((attendee) => {
                    return (
                        <div style={{ marginBottom: "20px", position: "relative" }}>
                            <AvatarWithDefault
                                size={36}
                                alt={attendee.name ? attendee.name : ""}
                                src={attendee.avatarUrl}
                                backgroundSize="cover"
                            />
                            {attendee.role === "moderator" && <ModIconElem left={"-5px"} top={"-4px"} fontSize={"14px"} />}
                            {(attendee.handRaised || (loggedInUserId === attendee.id && roster.isMyHandRaised())) && (
                                <HandRaisedIconElem top={"15px"} left={"-7px"} />
                            )}
                            {attendee.muted && <MutedIconElem top={"-2px"} left={"25px"} />}
                            {activeSpeakerContext.isActiveSpeaker(attendee.rosterId) && (
                                <SpeakingIconElem top={"-2px"} left={"25px"} />
                            )}
                        </div>
                    )
                })}
        </>
    )
}

const ToggleLiveButton = styled.button`
    width: calc(100% - 30px);
    height: 35px;
    margin: 5px 15px 10px 15px;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  color: white;
                  transition-property: none;
              `
            : css`
                  &.green:hover {
                      box-shadow: 0px 0px 0px 5px hsla(120, 100%, 35%, 0.5);
                  }
                  &.red:hover {
                      box-shadow: 0px 0px 0px 5px hsla(0, 100%, 35%, 0.5);
                  }
              `};

    &.green {
        background-color: hsl(120, 100%, 35%); /* #00B300 */
    }
    &.red {
        background-color: hsl(0, 100%, 35%); /* #B30000 */
    }
`

const RoundedButton = styled.button`
    min-width: 35px;
    height: 35px;
    margin: 0 15px 0 0;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  transition-property: none;
              `
            : css`
                  &.locked:hover {
                      box-shadow: 0px 0px 0px 5px rgba(70, 130, 180, 0.5);
                  }
                  &.unlocked:hover {
                      box-shadow: 0px 0px 0px 5px rgba(30, 155, 255, 0.5);
                  }
              `};

    &.locked {
        background-color: steelblue;
    }
    &.unlocked {
        background-color: dodgerblue;
    }
`

const RestartRecorderButton = styled.button`
    min-width: 35px;
    height: 35px;
    padding: 5px 0 5px 2px;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;
    background-color: steelblue;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  color: white;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      box-shadow: 0px 0px 0px 5px rgba(30, 155, 255, 0.5);
                  }
              `};
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 15px;
`

enum LiveStatus {
    UNKNOWN,
    OFF_AIR_UNLOCKED,
    PENDING_LOCKING,
    PENDING_UNLOCKING,
    OFF_AIR_LOCKED,
    PENDING_OFF_ON,
    ON_AIR,
    PENDING_ON_OFF
}

const minRecorderRestartDelay = 1000 * 120 // max. 1 restart every 2 minutes

const GreenRoomChannelStatusPresenter = styled.div`
    font-family: ${branding.font1};
    color: ${branding.roster.greenRoomPresenterViewColor};
`

const GreenRoomChannelStatusOperator = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
`

interface GreenRoomOperatorViewProps {
    channelId?: string
    meetingParticipants: RosterAttendeeType[]
}

const GreenRoomOperatorView: React.FC<GreenRoomOperatorViewProps> = (props) => {
    const chime = useChimeContext()
    const greenRoom = useGreenRoomContext()
    const audio = useAudioContext()
    const channelStatus = greenRoom.getChannelStatus()
    const isLocked = greenRoom.isLocked()
    const rosterContext = useRosterContext()

    const [liveButtonEnabled, setLiveButtonEnabled] = useState(false)
    const [enableRecorderButtonHandle, setEnableRecorderButtonHandle] = useState<number | undefined>()
    const [recorderButtonEnabled, setRecorderButtonEnabled] = useState(false)
    const [liveStatus, setLiveStatus] = useState(LiveStatus.UNKNOWN) // additional state for between ON_AIR & OFF_AIR
    const [eta, setEta] = useState(0)
    const [lastRecorderPresentState, setLastRecorderPresentState] = useState(false)
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    const rosterKeys = Object.keys(rosterContext.getRoster())
    const recorderPresent = !!rosterKeys.find((key) => rosterContext.getAttendee(key).id === "recorder")

    const onLiveStatusButtonClicked = async () => {
        if (channelStatus === ChannelStatus.OFF_AIR) {
            setLiveStatus(LiveStatus.PENDING_OFF_ON)
            greenRoom.startLive()
        } else if (channelStatus === ChannelStatus.ON_AIR) {
            setLiveStatus(LiveStatus.PENDING_ON_OFF)
            const success = await greenRoom.stopLive()
            if (!success) {
                // success is handled via chime.getChannelStatus()
                setLiveStatus(LiveStatus.ON_AIR)
            }
        }
    }

    const onLockParticipantsButtonClicked = async () => {
        if (liveStatus === LiveStatus.OFF_AIR_UNLOCKED) {
            const authorizedUsers = rosterKeys
                .filter((key) => rosterContext.getAttendee(key).id !== undefined)
                .map((key) => rosterContext.getAttendee(key).id!)
            setLiveStatus(LiveStatus.PENDING_LOCKING)
            const success = await greenRoom.lockChannel(authorizedUsers)
            if (!success) {
                // success is handled via chime.isLocked()
                setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        } else if (liveStatus === LiveStatus.OFF_AIR_LOCKED) {
            setLiveStatus(LiveStatus.PENDING_UNLOCKING)
            const success = await greenRoom.unlockChannel()
            if (!success) {
                // success is handled via chime.isLocked()
                setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        }
    }

    const onRestartRecorderButtonClicked = async () => {
        const externalMeetingId = chime.getExternalMeetingId()
        if (externalMeetingId) {
            setRecorderButtonEnabled(false)
            setEnableRecorderButtonHandle(3)
            const response = await restartRecorder(externalMeetingId.substr(3))
            if ((response as BackendServiceError).httpStatus) {
                setRecorderButtonEnabled(true)
            } else if ((response as RestartRecorderResponse).success) {
                const handle = window.setTimeout(() => {
                    // disable recorder button for minRecorderRestartDelay ms
                    const recorderPresent = !!rosterKeys.find((key) => rosterContext.getAttendee(key).id === "recorder")
                    setRecorderButtonEnabled(!recorderPresent)
                    setEnableRecorderButtonHandle(undefined)
                }, minRecorderRestartDelay)
                setEnableRecorderButtonHandle(handle)
            }
        }
    }

    useEffect(() => {
        if (isLocked) {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_UNLOCKED:
                case LiveStatus.PENDING_LOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        } else {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_LOCKED:
                case LiveStatus.PENDING_UNLOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        }
    }, [isLocked]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (channelStatus) {
            case ChannelStatus.OFF_AIR:
                setLiveStatus(isLocked ? LiveStatus.OFF_AIR_LOCKED : LiveStatus.OFF_AIR_UNLOCKED)
                break
            case ChannelStatus.ON_AIR:
                setLiveStatus(LiveStatus.ON_AIR)
                audio.realtimeMuteLocalAudio()
                break
            case ChannelStatus.PREPARING:
                setLiveStatus(LiveStatus.PENDING_OFF_ON)
                setEta(branding.greenroomGoLiveFollowupDelaySec * 1000) // TODO use time to live value returned from backend on going live instead
                const handle = setInterval(() => {
                    setEta((eta) => {
                        if (eta <= 1000) {
                            clearInterval(handle)
                            return 0
                        }
                        return eta - 1000
                    })
                }, 1000)
                return () => clearInterval(handle)
        }
    }, [channelStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            lastRecorderPresentState &&
            !recorderPresent &&
            (liveStatus === LiveStatus.ON_AIR || liveStatus === LiveStatus.PENDING_OFF_ON)
        ) {
            ;(async () => {
                setLiveStatus(LiveStatus.PENDING_ON_OFF)
                const success = await greenRoom.stopLive("error")
                if (!success) {
                    // success is handled via chime.getChannelStatus()
                    setLiveStatus(LiveStatus.ON_AIR)
                }
            })()
        }
        if (liveStatus === LiveStatus.OFF_AIR_UNLOCKED || (!recorderPresent && liveStatus === LiveStatus.OFF_AIR_LOCKED)) {
            setLiveButtonEnabled(false)
        } else {
            setLiveButtonEnabled(liveStatus === LiveStatus.OFF_AIR_LOCKED || liveStatus === LiveStatus.ON_AIR)
        }
        setLastRecorderPresentState(recorderPresent)
    }, [recorderPresent, liveStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (recorderPresent) {
            if (enableRecorderButtonHandle) {
                clearTimeout(enableRecorderButtonHandle)
                setEnableRecorderButtonHandle(undefined)
            }
            setRecorderButtonEnabled(false)
        } else {
            setRecorderButtonEnabled(enableRecorderButtonHandle === undefined)
        }
    }, [recorderPresent]) // eslint-disable-line react-hooks/exhaustive-deps

    const liveButtonLabel = calcLiveButtonLabel(liveStatus)
    const lockButtonLabel = calcLockButtonLabel(liveStatus)
    const liveButtonClassName = liveStatus === LiveStatus.OFF_AIR_LOCKED ? "green" : liveStatus === LiveStatus.ON_AIR ? "red" : ""
    const lockButtonClassName =
        liveStatus === LiveStatus.OFF_AIR_LOCKED ? "locked" : liveStatus === LiveStatus.OFF_AIR_UNLOCKED ? "unlocked" : ""
    const lockButtonEnabled = liveStatus === LiveStatus.OFF_AIR_UNLOCKED || liveStatus === LiveStatus.OFF_AIR_LOCKED

    return (
        <>
            <PannelBody style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                <ContentScrollContainer adjustForHeaderWith={"300px"} white={"true"} paddingLeft={"0px"}>
                    <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} greenroom />
                </ContentScrollContainer>
            </PannelBody>
            <PannelFooter>
                {channelStatus === ChannelStatus.ON_AIR && (
                    <GreenRoomChannelStatusOperator>
                        <LiveIconElem /> {branding.conferenceTexts.onAirMessage} <LiveIconElem />
                    </GreenRoomChannelStatusOperator>
                )}

                {channelStatus === ChannelStatus.PREPARING && (
                    <GreenRoomChannelStatusOperator>
                        {branding.conferenceTexts.liveInMessage} {Math.floor(eta / 1000)}
                    </GreenRoomChannelStatusOperator>
                )}

                {liveStatus === LiveStatus.OFF_AIR_LOCKED && (
                    <GreenRoomChannelStatusOperator>
                        <LockIconElem /> {branding.conferenceTexts.roomLockedTitle} <LockIconElem />
                    </GreenRoomChannelStatusOperator>
                )}
                <ToggleLiveButton
                    className={liveButtonClassName}
                    disabled={!liveButtonEnabled}
                    onClick={onLiveStatusButtonClicked}
                >
                    {liveButtonLabel}
                </ToggleLiveButton>
                <ButtonRow>
                    <RoundedButton
                        className={lockButtonClassName}
                        disabled={!lockButtonEnabled}
                        onClick={onLockParticipantsButtonClicked}
                        style={{ flex: "1 1 auto" }}
                    >
                        {lockButtonLabel}
                    </RoundedButton>
                    <RestartRecorderButton
                        disabled={!recorderButtonEnabled}
                        onClick={onRestartRecorderButtonClicked}
                        style={{ flex: "0 0 auto" }}
                    >
                        <span role="img" aria-label={"Restart Recorder"}>
                            🎥
                        </span>
                    </RestartRecorderButton>
                </ButtonRow>
            </PannelFooter>
        </>
    )
}

function calcLiveButtonLabel(liveStatus: LiveStatus) {
    switch (liveStatus) {
        case LiveStatus.OFF_AIR_UNLOCKED:
        case LiveStatus.PENDING_LOCKING:
        case LiveStatus.PENDING_UNLOCKING:
        case LiveStatus.OFF_AIR_LOCKED:
            return branding.conferenceTexts.goLiveMessage
        case LiveStatus.PENDING_OFF_ON:
            return branding.conferenceTexts.goingLiveMessage
        case LiveStatus.ON_AIR:
            return branding.conferenceTexts.stopLiveMessage
        case LiveStatus.PENDING_ON_OFF:
            return branding.conferenceTexts.stoppingLiveMessage
    }
    return branding.conferenceTexts.initializingMessage
}

function calcLockButtonLabel(liveStatus: LiveStatus) {
    switch (liveStatus) {
        case LiveStatus.OFF_AIR_UNLOCKED:
            return branding.conferenceTexts.lockRoomMessage
        case LiveStatus.PENDING_LOCKING:
            return branding.conferenceTexts.lockingMessage
        case LiveStatus.PENDING_UNLOCKING:
            return branding.conferenceTexts.unlockingMessage
        case LiveStatus.PENDING_OFF_ON:
        case LiveStatus.ON_AIR:
        case LiveStatus.PENDING_ON_OFF:
        case LiveStatus.OFF_AIR_LOCKED:
            return branding.conferenceTexts.unlockRoomMessage
    }
    return branding.conferenceTexts.initializingMessage
}

const JoinBreakoutButton = styled(NavLink)<{ disabled?: boolean }>`
    display: flex;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    border-radius: 40px;
    height: 50px;
    justify-content: center;
    align-items: center;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    opacity: ${(props) => (props.disabled ? "50%" : "100%")};
    font-family: ${branding.font1};
    background-color: ${branding.videoPageContentBranding.joinBreakoutButtonColor};
    color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};

    &:hover,
    &:active,
    &:link,
    &:focus {
        text-decoration: none;
        color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    }
`

const JoinBreakoutMessage = styled.div`
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-family: ${branding.font1};
    margin-bottom: 8px;
`

const GreenRoomPresentersView: React.FC<GreenRoomOperatorViewProps> = React.memo((props) => {
    const channelStatus = useGreenRoomContext().getChannelStatus()
    const [eta, setEta] = useState(0)
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    const [currentEventDate, setCurrentEventDate] = useState<EventDate>()

    const timezone = useAppState().timezone

    useEffect(() => {
        function loadData() {
            loadChannelFirstEventDate(props.channelId || "", getMinutesBeforeSwitchingToTheNextEvent() * 60 * 1000)
                .then((data) => {
                    if ((data as BackendServiceError).httpStatus) {
                        //TODO ERROR
                    } else {
                        const resp = data as EventDateChannelFirstDetailsResponse
                        setCurrentEventDate(resp.currentEventDate)

                        if (resp.currentEventDate && !resp.nextEventDate) {
                            const remainingCurrentEventDate = calculateEnd(resp.currentEventDate?.enddatetime, timezone)

                            if (remainingCurrentEventDate > 0) {
                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    loadData()
                                }, remainingCurrentEventDate)
                            }
                        } else if (resp.currentEventDate && resp.nextEventDate) {
                            const currentEventDateEndTime = resp.currentEventDate.enddatetime

                            let remainingCurrentEventDate = calculateEnd(currentEventDateEndTime, timezone)
                            if (remainingCurrentEventDate > 0) {
                                if (moment().isBefore(moment(resp.currentEventDate.startdatetime))) {
                                    remainingCurrentEventDate = moment(moment(resp.currentEventDate.startdatetime)).diff(
                                        momentWithoutTimezoneFromTimezonedMoment(moment(), timezone)
                                    )
                                }

                                remainingCurrentEventDateTimer = window.setTimeout(() => {
                                    // Trigger on event end
                                    const remainingCurrentEventDateTime = calculateEnd(currentEventDateEndTime, timezone)
                                    const timeToLive =
                                        calculateChange(currentEventDateEndTime, resp.nextEventDate!.startdatetime) +
                                        remainingCurrentEventDateTime

                                    changeExecutionTimer = window.setTimeout(() => {
                                        //Trigger data load
                                        loadData()
                                    }, timeToLive)
                                }, remainingCurrentEventDate)
                            } else {
                                const changeExecution =
                                    calculateChange(currentEventDateEndTime, resp.nextEventDate!.startdatetime) -
                                    Math.abs(remainingCurrentEventDate)

                                changeExecutionTimer = window.setTimeout(() => {
                                    loadData()
                                }, changeExecution)
                            }
                        }
                    }
                })
                .catch((err) => {
                    //TODO ERROR
                })
        }

        let remainingCurrentEventDateTimer: number
        let changeExecutionTimer: number

        loadData()
        return () => {
            clearTimeout(remainingCurrentEventDateTimer)
            clearTimeout(changeExecutionTimer)
        }

        // eslint-disable-next-line
    }, [props.channelId])

    useEffect(() => {
        if (channelStatus === ChannelStatus.PREPARING) {
            setEta(branding.greenroomGoLiveFollowupDelaySec * 1000) // TODO use time to live value returned from backend on going live instead
            const handle = setInterval(() => {
                setEta((eta) => {
                    if (eta <= 1000) {
                        clearInterval(handle)
                        return 0
                    }
                    return eta - 1000
                })
            }, 1000)
        }
    }, [channelStatus])

    return (
        <>
            <PannelBody style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                <ContentScrollContainer adjustForHeaderWith={"300px"} white={"true"} paddingLeft={"0px"}>
                    <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} greenroom />
                </ContentScrollContainer>
            </PannelBody>
            <PannelFooter style={{ paddingBottom: "20px" }}>
                {branding.configuration.breakoutEnabled && currentEventDate && currentEventDate?.breakoutAccess && (
                    <>
                        <JoinBreakoutMessage>{branding.videoPageContentBranding.joinBreakoutMessage}</JoinBreakoutMessage>
                        <JoinBreakoutButton to={`/meeting/br_${currentEventDate?.id! || ""}/createorjoin`}>
                            {branding.videoPageContentBranding.joinBreakoutButtonText}
                        </JoinBreakoutButton>
                    </>
                )}
                {channelStatus === ChannelStatus.OFF_AIR && (
                    <GreenRoomChannelStatusPresenter>
                        {branding.conferenceTexts.currentlyNotLiveMessage}
                    </GreenRoomChannelStatusPresenter>
                )}

                {channelStatus === ChannelStatus.PREPARING && (
                    <GreenRoomChannelStatusPresenter>
                        {branding.conferenceTexts.gettingLiveInMessage} {Math.floor(eta / 1000)}
                    </GreenRoomChannelStatusPresenter>
                )}
                {channelStatus === ChannelStatus.ON_AIR && (
                    <GreenRoomChannelStatusPresenter>
                        <LiveIconElem /> {branding.conferenceTexts.onAirMessage} <LiveIconElem />
                    </GreenRoomChannelStatusPresenter>
                )}
            </PannelFooter>
        </>
    )
})

const LockIconElem: React.FC = () => {
    return (
        <span role="img" aria-label={"ON AIR"}>
            🔒
        </span>
    )
}
const LiveIconElem: React.FC = () => {
    return (
        <span role="img" aria-label={"ON AIR"}>
            🔴
        </span>
    )
}
interface IconElemProps {
    top?: string
    right?: string
    bottom?: string
    left?: string
    fontSize?: string
}

const ModIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <ModIcon left={props.left} top={props.top} fontSize={props.fontSize}>
            <span role="img" aria-label={branding.conferenceTexts.moderator} title={branding.conferenceTexts.moderator}>
                {IconStaff({ fill: branding.sideIconBar.sideIconColorDark })}
            </span>
        </ModIcon>
    )
}
const RecorderIconElem: React.FC = () => {
    return (
        <ModIcon>
            <span role="img" aria-label={branding.conferenceTexts.moderator} title={branding.conferenceTexts.moderator}>
                🎥
            </span>
        </ModIcon>
    )
}
const HandRaisedIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <HandRaisedIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconRaiseHand({ fill: branding.sideIconBar.sideIconColorDark })}
        </HandRaisedIcon>
    )
}
const SpeakingIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <SpeakingIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark })}
        </SpeakingIcon>
    )
}
const MutedIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <SpeakingIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark })}
        </SpeakingIcon>
    )
}

const ChatContent = styled.div<{ guestBannerHeight: number }>`
    height: calc(100vh - 122px - ${(props) => props.guestBannerHeight + "px"}); /* Height of topbar + roster header */
`

interface ChatContainerProps {
    viewMode: SideBarViewMode
    guestBannerHeight: number
    virtualCafe?: boolean
}

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
    const { roomName }: any = useParams()
    return (
        <ChatContent
            style={props.viewMode === SideBarViewMode.CHAT ? {} : { display: "none" }}
            guestBannerHeight={props.guestBannerHeight}
        >
            <ChatPage
                displayStyle={DisplayStyle.DEFAULT}
                param={ChatConversationParam.conversationByConversationId(ConversationType.PUBLIC, calcConversationId(roomName))}
                viewMode={props.viewMode}
                virtualCafe={props.virtualCafe}
            />
        </ChatContent>
    )
}
