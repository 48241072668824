import styled from "styled-components"

export const RosterAttendeesClosedRoot = styled.div`
    height: 100%;
    padding: 15px 5px 15px 5px;
`
export const RosterAttendeesClosedGroup = styled.div<{ bottomDivider?: boolean }>`
    max-height: 600px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: ${(props) => (props.bottomDivider ? "1px solid #D9D9D9" : "none")};
    padding: 15px 5px 15px 5px;
`
