import styled, { css } from "styled-components"
import { ControlButtonSize } from "./ControlBar"

export const ControlBarContainer = styled.div`
    padding: 20px;
    width: 500px;
    height: 100%;
`

export const ControlButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
`

const handleControlButtonSize = (size: ControlButtonSize | null | undefined) => {
    switch (size) {
        case ControlButtonSize.Small:
            return "40px"
        case ControlButtonSize.Medium:
            return "45px"
        default:
            return "45px"
    }
}

export const ControlButtonRoot = styled.div<{ isActive?: boolean; backgroundColor?: string; size?: ControlButtonSize | null }>`
    ${(props) =>
        props.backgroundColor
            ? css`
                  background-color: ${props.backgroundColor};
              `
            : css`
                  background-color: ${props.isActive ? "#ffff" : "#ffffff26"};
              `}

    width: ${({ size }) => handleControlButtonSize(size)};
    height: ${({ size }) => handleControlButtonSize(size)};
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    :hover {
        background-color: #ffffff80;
        background-color: ${(props) => (props.backgroundColor ? "#aa045e" : "#ffffff80")};
    }
`
