import * as React from "react"
import { ToggleSwitchRoot } from "./ToggleSwitch.styled"

interface ToggleSwitchProps {
    isEnabled: boolean | undefined
    onClick?: () => void
    deactivatedToggleBackgroundColor?: string
    deactivatedToggleBorderColor?: string
    activatedToggleBorderColor?: string
    sliderBackgroundColor?: string
    deactivatedSliderBorderColor?: string
    activatedSliderBorderColor?: string
    scaleSize?: number
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = React.memo((props) => {
    const checkedClassName = props.isEnabled ? "checked" : ""

    return (
        <ToggleSwitchRoot
            scaleSize={props.scaleSize}
            deactivatedToggleBackgroundColor={props.deactivatedToggleBackgroundColor}
            deactivatedToggleBorderColor={props.deactivatedToggleBorderColor}
            activatedToggleBorderColor={props.activatedToggleBorderColor}
            sliderBackgroundColor={props.sliderBackgroundColor}
            deactivatedSliderBorderColor={props.deactivatedSliderBorderColor}
            activatedSliderBorderColor={props.activatedSliderBorderColor}
        >
            <label className="switch">
                <input type="checkbox" className={checkedClassName} onChange={props.onClick} />
                <span className="slider"></span>
            </label>
        </ToggleSwitchRoot>
    )
})

export default ToggleSwitch
