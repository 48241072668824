import { useLocalAudioInputActivityPreview, useAudioOutputs, useAudioInputs } from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import { useRef } from "react"
import { Spinner } from "react-bootstrap"
import branding from "../../../branding/branding"
import ActivityBar from "../ActivityBar"
import MicSelection from "../MicSelection/MicSelection"
import { CenteredSpinnerWrapper } from "../Settings/SettingsStyles"
import SpeakerSelection from "../SpeakerSelection/SpeakerSelection"
import TestSound from "../TestSound"
import { DeviceSettingsRoot, SettingsGroup, TestSpeakerButton, TestSpeakerButtonContainer } from "./AudioSettingsStyles"

function AudioSettings() {
    const activityBarRef = useRef<HTMLDivElement>()
    useLocalAudioInputActivityPreview(activityBarRef)

    const { selectedDevice } = useAudioOutputs()
    const [selectedOutput, setSelectedOutput] = useState(selectedDevice)
    const audioInputs = useAudioInputs()
    const hasInputDevices = audioInputs.selectedDevice

    const handleTestSpeaker = () => {
        new TestSound(selectedOutput)
    }

    useEffect(() => {
        setSelectedOutput(selectedDevice)
    }, [selectedDevice])

    return (
        <DeviceSettingsRoot>
            {hasInputDevices ? (
                <>
                    <SettingsGroup>
                        <MicSelection />
                        <ActivityBar ref={activityBarRef} />
                    </SettingsGroup>
                    <SettingsGroup>
                        <SpeakerSelection />
                        <TestSpeakerButtonContainer>
                            <TestSpeakerButton onClick={() => handleTestSpeaker()}>
                                {branding.audioVideoSettings.testSpeakerLabel}
                            </TestSpeakerButton>
                        </TestSpeakerButtonContainer>
                    </SettingsGroup>
                </>
            ) : (
                <CenteredSpinnerWrapper>
                    <Spinner animation={"border"} />
                </CenteredSpinnerWrapper>
            )}
        </DeviceSettingsRoot>
    )
}

export default AudioSettings
