import { createState, State, useState } from "@hookstate/core"
import {
    useAudioInputs,
    useAudioOutputs,
    useLocalVideo,
    useLogger,
    useMeetingManager,
    useVideoInputs
} from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"

export const AudioInputStorageKey = "virtualGuide-audioInput"
export const AudioOutputStorageKey = "virtualGuide-audioOutput"
export const VideoInputStorageKey = "virtualGuide-videoInput"

interface StateValues {}

const getStartValues = (): StateValues => {
    return {}
}

export interface DeviceController {
    getAudioInputDevices: () => DeviceType[] | undefined | null
    getSelectedAudioInputDevice: () => DeviceType | undefined | null
    setSelectedAudioInputDevice: (deviceId: string) => Promise<void>
    getAudioOutputDevices: () => DeviceType[] | undefined | null
    getSelectedAudioOutputDevice: () => DeviceType | undefined | null
    setSelectedAudioOutputDevice: (deviceId: string) => Promise<void>
    getVideoInputDevices: () => DeviceType[] | undefined | null
    getSelectedVideoInputDevice: () => DeviceType | undefined | null
    setSelectedVideoInputDevice: (deviceId: string) => Promise<void>
}

const useStateWrapper = (state: State<StateValues>) => {
    const meetingManager = useMeetingManager()
    const audioInputs = useAudioInputs()
    const audioOutputs = useAudioOutputs()
    const videoInputs = useVideoInputs()
    const { isVideoEnabled } = useLocalVideo()
    const logger = useLogger()
    return {
        getAudioInputDevices: () => {
            return audioInputs.devices
        },
        getSelectedAudioInputDevice: () => {
            if (audioInputs.devices.length) {
                const audioInputStorage = localStorage.getItem(AudioInputStorageKey)
                const selectedDeviceFromList = audioInputs.devices.find((device) => device.deviceId === audioInputStorage)
                if (selectedDeviceFromList) {
                    // give me the selected audio input device by id (from local storage)
                    return {
                        deviceId: selectedDeviceFromList.deviceId,
                        label: selectedDeviceFromList.label
                    }
                } else {
                    // just give me the first available input device
                    return audioInputs.devices[0]
                }
            }
        },
        setSelectedAudioInputDevice: async (deviceId: string) => {
            try {
                localStorage.setItem(AudioInputStorageKey, deviceId)
                await meetingManager.startAudioInputDevice(deviceId)
                state.merge({ selectedAudioInputDevice: deviceId })
            } catch (error: any) {
                logger.error(`Error while setting device input ${error}`)
            }
        },
        getAudioOutputDevices: () => {
            return audioOutputs.devices
        },
        getSelectedAudioOutputDevice: () => {
            if (audioOutputs.devices.length) {
                const audioOutputStorage = localStorage.getItem(AudioOutputStorageKey)
                const selectedDeviceFromList = audioOutputs.devices.find((device) => device.deviceId === audioOutputStorage)
                if (selectedDeviceFromList) {
                    // give me the selected audio output device by id (from local storage)
                    return {
                        deviceId: selectedDeviceFromList.deviceId,
                        label: selectedDeviceFromList.label
                    }
                } else {
                    // just give me the first available input device
                    return audioOutputs.devices[0]
                }
            }
        },
        setSelectedAudioOutputDevice: async (deviceId: string) => {
            try {
                localStorage.setItem(AudioOutputStorageKey, deviceId)
                await meetingManager.startAudioOutputDevice(deviceId)
            } catch (error) {
                logger.error(`Error switching audio output device ${error}`)
            }
        },
        getVideoInputDevices: () => {
            return videoInputs.devices
        },
        getSelectedVideoInputDevice: () => {
            if (videoInputs.devices.length) {
                const videoInputStorage = localStorage.getItem(VideoInputStorageKey)
                const selectedDeviceFromList = videoInputs.devices.find((device) => device.deviceId === videoInputStorage)
                if (selectedDeviceFromList) {
                    // give me the selected audio output device by id (from local storage)
                    return {
                        deviceId: selectedDeviceFromList.deviceId,
                        label: selectedDeviceFromList.label
                    }
                } else {
                    // just give me the first available input device
                    return audioOutputs.devices[0]
                }
            }
        },
        setSelectedVideoInputDevice: async (deviceId: string) => {
            try {
                localStorage.setItem(VideoInputStorageKey, deviceId)
                if (isVideoEnabled) {
                    await meetingManager.startVideoInputDevice(deviceId)
                } else {
                    meetingManager.selectVideoInputDevice(deviceId)
                }
            } catch (error) {
                logger.error(`VideoInputTransformControl failed to select video input device: ${error}`)
            }
        }
    }
}

const state = createState<StateValues>(getStartValues())
export const useDeviceController = (): DeviceController => useStateWrapper(useState(state))
