import { useRosterState } from "amazon-chime-sdk-component-library-react"
import { useEffect, useState } from "react"
import { AttendeeData } from "../RosterAttendees.tsx/RosterAttendees"
import RosterAttendeeClosed from "./RosterAttendeeClosed/RosterAttendeeClosed"
import { RosterAttendeesClosedGroup, RosterAttendeesClosedRoot } from "./RosterAttendeesClosed.styled"

function RosterAttendeesClosed() {
    const { roster } = useRosterState()
    const [attendees, setAttendees] = useState(Object.values(roster))
    const [participants, setParticipants] = useState<AttendeeData[]>([])
    const [moderators, setModerators] = useState<AttendeeData[]>([])

    useEffect(() => {
        setAttendees(Object.values(roster))
    }, [roster])

    useEffect(() => {
        setModerators(attendees.filter((participant: AttendeeData) => participant.role === "moderator"))
        setParticipants(attendees.filter((participant: AttendeeData) => participant.role === "member"))
        // eslint-disable-next-line
    }, [roster, attendees])

    return (
        <RosterAttendeesClosedRoot>
            {moderators.length > 0 && (
                <RosterAttendeesClosedGroup bottomDivider={moderators.length > 0}>
                    {moderators.map((attendee: AttendeeData) => {
                        return <RosterAttendeeClosed attendee={attendee} />
                    })}
                </RosterAttendeesClosedGroup>
            )}
            {participants.length > 0 && (
                <RosterAttendeesClosedGroup>
                    {participants.map((attendee: AttendeeData) => {
                        return <RosterAttendeeClosed attendee={attendee} />
                    })}
                </RosterAttendeesClosedGroup>
            )}
        </RosterAttendeesClosedRoot>
    )
}

export default RosterAttendeesClosed
