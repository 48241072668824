import {
    useRosterState,
    useRemoteVideoTileState,
    RosterAttendeeType,
    useContentShareState,
    useActiveSpeakersState
} from "amazon-chime-sdk-component-library-react"
import { useEffect, useLayoutEffect, useState } from "react"
import branding from "../../../branding/branding"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { IconChevronLeftSolid, IconChevronRightSolid } from "../../../ui/Icons"
import ContentShare from "../ContentShare/ContentShare"
import RemoteTile from "../RemoteTile/RemoteTile"
import StageNotifications from "../StageNotification/StageNotifications"
import { StageIndicators } from "./StageIndicators/StageIndicators"
import {
    Pagination,
    PaginationBar,
    PaginationButton,
    PaginationControls,
    StageRoot,
    TextCanvas,
    VideoTilesGrid
} from "./StageStyles"
export function Stage() {
    const { roster } = useRosterState()
    const userState = useLoggedInState()
    const [attendees, setAttendees] = useState<RosterAttendeeType[]>(
        Object.values(roster).filter(
            (attendee: any) => attendee.role !== "recorder" && attendee.externalUserId !== userState.user()?.profileId
        )
    )
    const { sharingAttendeeId } = useContentShareState()
    const { attendeeIdToTileId } = useRemoteVideoTileState()
    const tilesPerPage = 16
    const activeSpeakerState = useActiveSpeakersState()
    const [activeSpeakers, setActiveSpeakers] = useState(activeSpeakerState)
    const [currentPage, setCurrentPage] = useState(1)
    const [gridName, setGridName] = useState("")
    const [stageTiles, setStageTiles] = useState(<div></div>)
    const throttleInterval = 3000

    useEffect(() => {
        const throttleIntervalId = setInterval(() => {
            setActiveSpeakers(activeSpeakerState)
        }, throttleInterval)
        return () => clearTimeout(throttleIntervalId)
    }, [activeSpeakerState])

    /**
     * TODO: Replace these functions into utils and use them on every place where recorder and logged in user checks are implemented.
     * This will bring much cleaner filterings.
     */

    function isRecorder(attendee: any) {
        return attendee.role === "recorder"
    }
    function filterRecorderAndLoggedInUser() {
        return Object.values(roster).filter(
            (attendee: any) => !isRecorder(attendee) && !userState.isLoggedInUser(attendee.externalUserId)
        )
    }
    useEffect(() => {
        setAttendees(filterRecorderAndLoggedInUser)
        // eslint-disable-next-line
    }, [roster])

    /**
     * This function is used to sort/group tiles if we have more than 16 attendees
     * and groups/sorts tiles based on microphone activity and camera state.
     * Attendees who have cameras on are pushed on top of the list, then
     * we connate attendees which are active speakers which are ordered by
     * the activeSpeakerState.
     */
    const sortPaginatedTiles = () => {
        const baseAttendees = Object.values(roster).filter(
            (attendee: any) => attendee.role !== "recorder" && attendee.externalUserId !== userState.user()?.profileId
        )
        const attendeeTiles = Object.keys(attendeeIdToTileId).map((attendee) => attendee)
        const speakers = activeSpeakers.filter((speaker) => !attendeeTiles.includes(speaker))
        const topAttendees = [...attendeeTiles, ...speakers]
        const attendeesTest = [
            ...topAttendees,
            ...baseAttendees
                .filter((attendee: RosterAttendeeType) => !topAttendees.includes(attendee.chimeAttendeeId))
                .map((it) => it.chimeAttendeeId)
        ]
        const sortedAttendees = baseAttendees.sort((a, b) => {
            return attendeesTest?.indexOf(a.chimeAttendeeId) - attendeesTest?.indexOf(b.chimeAttendeeId)
        })
        setAttendees(sortedAttendees)
    }
    /**
     * This function is used to sort/group tiles if we have less than 16 attendees
     * and groups tiles based on camera on state. The microphone activity is not used as
     * a factor which influences the order. Camera tiles are pushed on top of the list.
     */
    function sortTiles() {
        const baseAttendees = Object.values(roster).filter(
            (attendee: any) => attendee.role !== "recorder" && attendee.externalUserId !== userState.user()?.profileId
        )
        const attendeeTiles = Object.keys(attendeeIdToTileId).map((attendee) => attendee)
        const attendeesTest = [
            ...attendeeTiles,
            ...baseAttendees
                .filter((attendee: RosterAttendeeType) => !attendeeTiles.includes(attendee.chimeAttendeeId))
                .map((it) => it.chimeAttendeeId)
        ]
        const sortedAttendees = baseAttendees.sort((a, b) => {
            return attendeesTest?.indexOf(a.chimeAttendeeId) - attendeesTest?.indexOf(b.chimeAttendeeId)
        })
        setAttendees(sortedAttendees)
    }

    useEffect(() => {
        if (Object.keys(roster).length - 1 > tilesPerPage) {
            sortPaginatedTiles()
        } else {
            sortTiles()
        }

        // eslint-disable-next-line
    }, [roster, attendeeIdToTileId, activeSpeakers])

    useEffect(() => {
        attendees.length > tilesPerPage
            ? setGridName(
                  `videos-${attendees.slice((currentPage - 1) * tilesPerPage, currentPage * tilesPerPage).length.toString()}`
              )
            : setGridName(`videos-${attendees.length.toString()}`)
    }, [attendees, currentPage, tilesPerPage])

    useLayoutEffect(() => {
        setStageTiles(
            <VideoTilesGrid className={gridName}>
                {attendees.length &&
                    attendees
                        .slice((currentPage - 1) * tilesPerPage, currentPage * tilesPerPage)
                        .map((attendee: any, key: number) => {
                            return (
                                <RemoteTile
                                    key={key}
                                    attendee={attendee}
                                    isVideoTile={Object.keys(attendeeIdToTileId).includes(attendee.chimeAttendeeId)}
                                    tileId={attendeeIdToTileId[attendee.chimeAttendeeId]}
                                />
                            )
                        })}
            </VideoTilesGrid>
        )
        // eslint-disable-next-line
    }, [attendees, currentPage, gridName])

    return (
        <StageRoot>
            <>
                <StageNotifications />
                <StageIndicators />
                {!sharingAttendeeId && attendees.length >= 1 && stageTiles}
                {sharingAttendeeId && <ContentShare />}
                {!sharingAttendeeId && attendees.length < 1 && <TextCanvas>{branding.conferenceTexts.noAttendees}</TextCanvas>}
                {/* Pagination of tiles */}
                {attendees.length > tilesPerPage && (
                    <PaginationBar>
                        <PaginationControls>
                            <PaginationButton
                                disabled={currentPage === 1}
                                onClick={() => {
                                    if (currentPage === 1) return
                                    setCurrentPage(currentPage - 1)
                                }}
                            >
                                <IconChevronLeftSolid width="10" height="10" />
                            </PaginationButton>
                            <Pagination>{`${currentPage} / ${Math.ceil(attendees.length / tilesPerPage)}`}</Pagination>
                            <PaginationButton
                                disabled={currentPage === Math.ceil(attendees.length / tilesPerPage)}
                                onClick={() => {
                                    if (currentPage === Math.ceil(attendees.length / tilesPerPage)) return
                                    setCurrentPage(currentPage + 1)
                                }}
                            >
                                <IconChevronRightSolid width="10" height="10" />
                            </PaginationButton>
                        </PaginationControls>
                    </PaginationBar>
                )}
            </>
        </StageRoot>
    )
}

export default Stage
