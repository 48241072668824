import { useEffect, useState } from "react"
import * as React from "react"
import styled from "styled-components"
import { NextPageLoader } from "../../communicationArea/CommunicationArea"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import SpeakersTilesLayout from "../../ui/SpeakersTilesLayout"
import { Category, ModalType, SotUser } from "../../backendServices/Types"
import TopFilterArea, { TopFilterAreaProps } from "./TopFilterArea"
import { viewType } from "./NetworkingPageContentBranding"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import EmptyTile from "../reception/EmptyTile"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import {
    ContactItem,
    loadRelevantProfilesListData,
    ContactListResponse,
    loadUsersData
} from "../../backendServices/SeriesOfTopicsUserServices"
import InView from "react-intersection-observer"
import { Staff, StaffEntry, StaffType } from "../../ui/StaffEntry"
import { ViewMode } from "../../ui/CrsTabs"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../../globalStates/TileViewConfig"
import useWindowDimensions from "../../ui/WindowDimensionsHook"

const RelevantTilesRoot = styled.div<{ reservedSpaceHeight: number; topicFilterPresent: boolean; topFilterArea: boolean }>`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;
    padding-top: ${(props) => (!props.topFilterArea ? "0px" : props.topicFilterPresent ? "110px" : "100px")};

    & .absolute-center {
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }

    & .ScrollbarsCustom-Content {
        position: relative;
    }
`

const InViewParent = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
`

const ScrollContainerRoot = styled.div`
    width: 100%;
`

const SpeakersListLayout = styled.div``

interface RelevantTilesAndListViewProps {
    page: number
    loggedInUserId: string | undefined
    searchString: string
    subContentHeight: number
    isLoader: boolean
    relevantUsers: ContactItem[]
    sotUsers: SotUser[]
    setRelevantUsers?: React.Dispatch<React.SetStateAction<ContactItem[]>>
    setSotUsers?: React.Dispatch<React.SetStateAction<SotUser[]>>
    topicFilter: number | null
    currentViewType: viewType
    isMatchActive?: boolean
    filterAreaProps?: TopFilterAreaProps
    setPage: (value: React.SetStateAction<number>) => void
    setIsLoader: (value: React.SetStateAction<boolean>) => void
    setLastLoadView?: (value: React.SetStateAction<viewType>) => void
    businessArea?: boolean
    setSelectedUser?: (value: any) => void
    callback?: (param: { modalType?: ModalType }) => void

    viewMode: ViewMode

    showOnlyBookmarks: boolean
}

const RelevantTilesAndListView = (props: RelevantTilesAndListViewProps) => {
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const { hideOnScroll, setHideOnScroll } = TopBannerSharedState()
    const windowSize = useWindowDimensions()
    const pageSize = 35
    const [hasMoreData, setHasMoreData] = useState<boolean>(false)
    const [isError, setIsError] = useState("")
    // Checking if the topic filter is available
    let topicFilterPresent = branding.networkingArea.topicFilterActive

    let userState = useLoggedInState()
    function shouldCompleteProfile(): boolean {
        if (
            !userState.user()?.firstName ||
            !userState.user()?.lastName ||
            !userState.user()?.position ||
            !userState.user()?.company
        ) {
            return true
        }

        return false
    }

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setPage(props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST ? props.page + pageSize : props.page + 1)
        }
    }
    const fetchData = () => {
        if (props.currentViewType === viewType.RELEVANT_TILES && props.loggedInUserId) {
            props.setIsLoader(true)
            if (props.setLastLoadView) props.setLastLoadView(viewType.RELEVANT_TILES)

            let params = {
                searchString: props.searchString,
                itemsPerPage: pageSize,
                page: props.page,
                topicFilter: props.topicFilter as any,
                filterlist: props.showOnlyBookmarks ? "-1" : "0"
            }

            if (!params.topicFilter) delete params.topicFilter

            loadRelevantProfilesListData(props.loggedInUserId || "", params)
                .then((res) => {
                    const response = res as ContactListResponse
                    let contactsTemp = response.contacts?.filter(function (contact) {
                        return contact.sotUser.id !== props.loggedInUserId
                    })

                    if (props.setRelevantUsers) {
                        props.setRelevantUsers(props.page === 0 ? contactsTemp : props.relevantUsers.concat(contactsTemp))
                    }
                    setHasMoreData(response.hasNextPage)
                })
                .catch((error) => {
                    setIsError(error)
                })
                .finally(() => {
                    props.setIsLoader(false)
                })
        } else if (props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST) {
            props.setIsLoader(true)
            if (props.setLastLoadView) props.setLastLoadView(viewType.USERS_FILTERED_BY_INTEREST)

            const catFilter = branding.networkingArea.userCategories.map((cat) => "cat_" + cat)

            let usersParameters: any = {
                filterlist: ["entity_sotu", catFilter, props.searchString].join(","),
                startresultrow: props.page,
                numresultrows: pageSize,
                order: "lexic"
            }

            loadUsersData(usersParameters)
                .then((res) => {
                    if (props.setSotUsers) props.setSotUsers(props.page === 0 ? res.users : props.sotUsers.concat(res.users))
                    setHasMoreData(props.page < res.count)
                })
                .catch((error) => {
                    props.setIsLoader(false)
                    setIsError(error)
                })
                .finally(() => {
                    props.setIsLoader(false)
                })
        }
    }

    useEffect(() => {
        props.setPage(0)
    }, [props.currentViewType, props.showOnlyBookmarks]) // eslint-disable-line
    const refreshKey = [props.currentViewType, props.page, props.searchString, props.topicFilter, lang].join(", ")
    useEffect(() => {
        if (props.isMatchActive) fetchData()
        // eslint-disable-next-line
    }, [refreshKey, props.isMatchActive, props.showOnlyBookmarks])

    useEffect(() => {
        if (props.showOnlyBookmarks) props.setPage(0)
        // eslint-disable-next-line
    }, [])

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    if (props.businessArea && props.relevantUsers?.length! === 0)
        return (
            <div style={{ marginTop: "15%" }}>
                <EmptyTile header={branding.businessAreaPageContent.emptyResultText} bgColor="transparent" hideButton={true} />
            </div>
        )

    return (
        <RelevantTilesRoot
            reservedSpaceHeight={props.subContentHeight}
            topicFilterPresent={topicFilterPresent}
            topFilterArea={props.filterAreaProps !== undefined && props.filterAreaProps !== null}
        >
            {isError ? (
                <div style={{ marginTop: "25%" }}>
                    <BackendError />
                </div>
            ) : (
                <>
                    {props.filterAreaProps && (
                        <TopFilterArea
                            currentViewType={props.filterAreaProps.currentViewType}
                            personTypeData={props.filterAreaProps.personTypeData}
                            searchValue={props.filterAreaProps.searchValue}
                            userAttributeFilter={props.filterAreaProps.userAttributeFilter}
                            onSetUserAttributeFilter={props.filterAreaProps.onSetUserAttributeFilter}
                            onSetSearchString={props.filterAreaProps.onSetSearchString}
                            onHandleSearch={props.filterAreaProps.onHandleSearch}
                            onGraphReset={props.filterAreaProps.onGraphReset}
                            setCurrentViewType={props.filterAreaProps.setCurrentViewType}
                            setUsersWithLoadedConnections={props.filterAreaProps.setUsersWithLoadedConnections}
                            topFilterRef={props.filterAreaProps.topFilterRef}
                            userAttributes={props.filterAreaProps.userAttributes}
                            viewTypeData={props.filterAreaProps.viewTypeData}
                            selectedTopic={props.filterAreaProps.selectedTopic}
                            onTopicChanged={props.filterAreaProps.onTopicChanged}
                            setSelectedPersonType={props.filterAreaProps.setSelectedPersonType}
                            setCurrentDataPage={props.filterAreaProps.setCurrentDataPage}
                            currentDataPage={props.filterAreaProps.currentDataPage}
                            showLoadNewSuggestionsBtn={props.filterAreaProps.showLoadNewSuggestionsBtn}
                            showShadow={hideOnScroll}
                            isMatchActive={props.isMatchActive}
                            selectedPersonType={props.filterAreaProps.selectedPersonType}
                            viewMode={props.viewMode}
                            setViewMode={props.filterAreaProps.setViewMode}
                            showOnlyBookmarks={props.showOnlyBookmarks}
                            setShowOnlyBookmarks={props.filterAreaProps.setShowOnlyBookmarks}
                        />
                    )}
                    {!shouldCompleteProfile() ? (
                        <ScrollContainerRoot>
                            <div style={{ marginTop: props.isMatchActive && props.filterAreaProps ? "35px" : "0" }}>
                                <ContentScrollContainer
                                    adjustForHeaderWith={`${props.subContentHeight}px`}
                                    handleScroll={onScroll}
                                >
                                    {props.isLoader && props.page === 0 ? (
                                        <CenteredLoader className={"absolute-center"} />
                                    ) : props.isMatchActive ? (
                                        <div
                                            style={{
                                                padding: "10px 25px",
                                                backgroundColor: branding.contentBgColorForEachPage ?? "#fff"
                                            }}
                                        >
                                            {props.viewMode === ViewMode.TILES &&
                                            windowSize.width >= getTileViewMinReqWidth(PagesUsingTileView.BUSSINES_AREA) ? (
                                                <SpeakersTilesLayout
                                                    speakersList={
                                                        props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST
                                                            ? props.sotUsers
                                                            : props.relevantUsers
                                                    }
                                                    componentType={
                                                        props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST
                                                            ? "user"
                                                            : "contact-item"
                                                    }
                                                    showContextMenuOnHover={true}
                                                    setSelectedUser={props.setSelectedUser}
                                                    callback={props.callback}
                                                />
                                            ) : (
                                                <SpeakersListLayout>
                                                    {props.relevantUsers.map((user, index) => (
                                                        <StaffEntry
                                                            key={index}
                                                            staff={
                                                                {
                                                                    firstName: user.sotUser.firstName,
                                                                    lastName: user.sotUser.lastName,
                                                                    id: user.sotUser.id,
                                                                    type: StaffType.USER,
                                                                    logoUrl: user.sotUser.logoUrl,
                                                                    position: user.sotUser.position,
                                                                    company: user.sotUser.company,
                                                                    sotUserId: user.sotUser.id,
                                                                    visible: true,
                                                                    showroomStandby: false,
                                                                    title: "",
                                                                    categories: [] as Category[],
                                                                    userType: user.sotUser.type
                                                                } as unknown as Staff
                                                            }
                                                            organizationId={""}
                                                            backOffice={false}
                                                            setModalType={undefined}
                                                            setSelectedUser={undefined}
                                                        />
                                                    ))}
                                                </SpeakersListLayout>
                                            )}

                                            {hasMoreData && (
                                                <InViewParent>
                                                    <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                                                        <NextPageLoader />
                                                    </InView>
                                                </InViewParent>
                                            )}
                                        </div>
                                    ) : (
                                        <div style={{ marginTop: "15.4%" }}>
                                            <EmptyTile
                                                header={branding.communicationArea.activateNetworkingText}
                                                bgColor="transparent"
                                                hideButton={true}
                                            />
                                        </div>
                                    )}
                                </ContentScrollContainer>
                            </div>
                        </ScrollContainerRoot>
                    ) : (
                        <div style={{ marginTop: "15.4%" }}>
                            <EmptyTile
                                headerClickable={true}
                                headerClickableWord={branding.communicationArea.completeProfileNetworkingClickableWord}
                                header={branding.communicationArea.completeProfileNetworkingText}
                                headerLink={"/myprofile?mode=EDIT"}
                                hideButton={true}
                                bgColor="transparent"
                            />
                        </div>
                    )}
                </>
            )}
        </RelevantTilesRoot>
    )
}

export default RelevantTilesAndListView
