import { format } from "date-fns"
import { isEmpty, mergeWith } from "lodash"
import moment from "moment"
import { useEffect, useState } from "react"
import * as React from "react"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { syncFavorites } from "../../../backendServices/FavoritesServices"
import { Category, Contact, EntityType, EventDate, JobOffer, ModalType, ShareTargetType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { CommunicationModals, createActions } from "../../../communicationArea/CommunicationOptions"
import { useContactState } from "../../../communicationArea/ContactState"
import { saveEvent } from "../../../communicationArea/ICal"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useMeetingContext } from "../../../conference/context/MeetingContext"
import { useAppState } from "../../../globalStates/AppState"
import { useCategoriesState } from "../../../globalStates/CategoriesState"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import Breadcrumb from "../../../navigationArea/Breadcrumb"
import {
    exhibitorsPageRoute,
    networkingPageRoute,
    programPageRoute,
    showfloorPageRoute,
    speakersPageRoute
} from "../../../navigationArea/RoutePaths"
import TopBar from "../../../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../../../tracking/RouteTracker"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import BackendError from "../../../ui/BackendError"
import BadgesContainer from "../../../ui/BadgesContainer"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import CenteredLoader from "../../../ui/CenteredLoader"
import CompanyDetails, { CompanyLogo } from "../../../ui/CompanyDetails"
import ContextMenu from "../../../ui/ContextMenu"
import {
    IconBookmarkFilled,
    IconBookmarkToggle,
    IconDownload,
    IconEmail,
    IconFacebook,
    IconFacebookOriginal,
    IconInstagram,
    IconInstagramOriginal,
    IconLanguages,
    IconLinkedIn,
    IconLinkedInOriginal,
    IconLocation,
    IconMobile,
    IconPhone,
    IconPinterest,
    IconPinterestOrginal,
    IconShare,
    IconThreeDotsMenuVertical,
    IconTikTok,
    IconTikTokOriginal,
    IconTwitter,
    IconTwitterOriginal,
    IconXing,
    IconXingOriginal,
    IconYouTube,
    IconYouTubeOriginal
} from "../../../ui/Icons"
import {
    BiographyText,
    CategoriesItem,
    CategoriesTitleRow,
    CategoriesViewRow,
    DummyDiv,
    PersonImageAndLogo,
    ProfileContactDetailsContainer,
    ProfileContactDetailsText,
    ProfileContactsWrapper,
    ProfileDetailRowHeader,
    ProfilePageSectionTitle,
    ProfileSocialItem,
    ProfileSocialsTitle,
    ProfileSocialsWrapper,
    UserBasicInfoContainer,
    UserFullName,
    UserImageDiv,
    UserPositionCompany,
    ProfileDetailNameTeaserWrapper,
    CustomRow
} from "../../../ui/ProfilePageStyledComponents"
import { useRecommendModal } from "../../../ui/modals/RecommendModal"
import { ContentScrollContainer } from "../../../ui/ScrollContainer"
import { MenuButton, MenuPanel } from "../../../ui/StaffEntry"
import useWindowDimensions from "../../../ui/WindowDimensionsHook"
import { MobileVersionContainer, DesktopVersionContainer } from "../../../utils/Device"
import TopBannerSharedState from "../../advertisingBanner/TopBannerSharedState"
import GuestUserBanner from "../../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../../guestUserBanner/GuestUserBannerSharedState"
import { findCountryName } from "../../myprofile/CountrySwitcherHelper"
import { getListOfLanguagesString } from "../../myprofile/LanguageSwitcherHelper"
import MyProfilePageContent from "../../myprofile/MyProfilePageContent"
import { CategoryType, SocialMediaType } from "../../myprofile/MyProfilePageContentBranding"
import { buildDetailLink, DetailNavLink, DetailNavLinkType } from "../DetailNavLink"
import MeetingSlots from "../../../ui/MeetingSlots"
import { isOnboardingPhase, isLivePhase } from "../../../utils/EventPhaseChecker"
import { isUserEdited } from "../../../App"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { DetailPageEntityRows } from "../components/DetailPageEntityRows"
import { useRosterContext } from "../../../conference/context/RosterContext"
import { BackendServiceError } from "../../../backendServices/BackendServicesUtils"
import { loadEventDateList } from "../../../backendServices/EventdateServices"
import { getInterest, loadUserData, UserResponse } from "../../../backendServices/SeriesOfTopicsUserServices"
import { loadPersonData, SpeakerResponse } from "../../../backendServices/PersonServices"
import { UserOrganizationVisitSource } from "../../../backendServices/TrackingServices"
import { useMeetingInvitation } from "../../../conferenceV2/context/MeetingInvitation"
import { useMeetingController } from "../../../conferenceV2/context/MeetingController"
import { getUserTitle } from "../../../communicationArea/helpers/GlobalFunctions"
import { PointsBadge } from "../../program/eventDateEntry/EventDateEntry.styled"
import {
    ContentRow,
    ContextMenuRoot,
    ContextMenuWrapper,
    EventDateContentCol,
    EventDateDescription,
    EventDateHeaderPositionRow,
    EventDateTimeLocation,
    EventDateTitle,
    EventDateViewActionsCol,
    EventDateViewIconCol,
    EventDateViewInfoCol,
    EventDateViewLogoDiv,
    EventDateViewRoot,
    EventDateViewRow,
    EventDatesFavorite,
    EventDatesTitle,
    EventDatesTitleWrapper,
    IconRootArea,
    ShareBookmarkButton,
    ShareBookmarkButtonIcon,
    ShareBookmarkButtonText,
    ShareBookmarkButtonsRoot,
    ShareIcon
} from "./PersonDetailPageContent.styled"
import MobilePersonDetailPageContent from "./MobilePersonDetailPageContent"
import { ContactType } from "./PersonDetailPageContentEnums"
import { trackSelectContent } from "../../../utils/GTMTracking"

const PersonDetailPageContent: React.FunctionComponent = (props) => {
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId || ""
    const [contactType, setContactType] = useState<ContactType>(ContactType.VISITOR)

    const [contact, setContact] = useState<Contact>()

    const { personId, userId }: any = useParams()
    const [sotUserId, setSotUserId] = useState<string>("")
    const [personAlias, setPersonAlias] = useState<string>("")

    const [isLoaded, setIsLoaded] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)

    const langState = useLanguageState()
    const lang = langState.getLanguage()

    const locations = calcBreadcrumbLocations()

    const [breadcrumb, setBreadcrumb] = useState<{ to: string; name: string; src?: UserOrganizationVisitSource }[]>([
        { to: "/", name: "" }
    ])

    const favoriteState = useFavoriteState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = useRosterContext()
    const meetingInvitation = useMeetingInvitation()
    const meetingController = useMeetingController()
    const isReportButtonHidden = false

    const [modalType, setModalType] = useState<ModalType>("none")
    const appState = useAppState()
    const contactState = useContactState()
    const [isUser, setIsUser] = useState(false)
    const connectionStatus = contact ? contactState.getConnectionStatus(contact.id) : "UNRELATED"

    const unrelated: boolean = connectionStatus === "UNRELATED" || connectionStatus === undefined

    const { isMobile } = useWindowDimensions()

    const isBookmarked =
        favoriteState.is("person", contact?.id || sotUserId) ||
        favoriteState.is("person", sotUserId) ||
        favoriteState.is("sotuser", contact?.id || sotUserId) ||
        favoriteState.is("sotuser", sotUserId)

    const [isMyProfile, setIsMyProfile] = useState<boolean>(false)

    const [showShadow, setShowShadow] = useState<boolean>(false)

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setShowShadow(scrollValues.scrollTop > 0)
        } else {
            setShowShadow(true)
        }
    }

    // Add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        (isUser ? 150 : 60)

    const menuCallback: (param: { isBookmark?: boolean; modalType?: ModalType; connectionStatus?: string }) => void = ({
        isBookmark,
        modalType,
        connectionStatus
    }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }

    function mergePersonData(primaryData: SpeakerResponse, secondaryData?: SpeakerResponse) {
        var primaryContact = primaryData.content
        if (secondaryData) {
            const secondaryContact = secondaryData.content
            primaryContact = mergeWith({}, secondaryContact, primaryContact, (obj, src) =>
                src == null || isEmpty(src) ? obj : src
            )

            if (!primaryData.content.phone) {
                primaryContact.phone = ""
                //if there is a phone number in person table, but not in seriesoftopicsuser
                //table, no number is shown
            }
        }
        setContact(primaryContact)
        contactState.setAll([
            {
                id: primaryContact.id,
                connectionStatus: primaryContact.myConnectionStatus,
                userType: primaryContact.type
            }
        ])
        if (primaryContact.eventDates && primaryContact.eventDates.length > 0) {
            setContactType(ContactType.SPEAKER)
        } else if (primaryContact.organizations && primaryContact.organizations.length > 0) {
            setContactType(ContactType.EXHIBITOR)
        } else {
            setContactType(ContactType.VISITOR)
        }
    }

    async function loadUser(userId: string) {
        if (!loggedInUserId) {
            setError("User is not logged in")
            return
        }

        try {
            setIsUser(true)
            const response = await loadUserData({ targetProfileId: userId, loggedInUserId: loggedInUserId })
            if ((response as BackendServiceError).httpStatus) {
                setError((response as BackendServiceError).httpStatusText)
                return
            }

            const userDataResponse = response as SpeakerResponse
            const connectedPersonId = userDataResponse.content.person

            if (connectedPersonId) {
                const personData: SpeakerResponse = await loadPersonData({ id: connectedPersonId })
                setPersonAlias(personData.content.id)
                mergePersonData(userDataResponse, personData)
            } else {
                mergePersonData(userDataResponse)
            }

            setSotUserId(userId)
        } catch (error: any) {
            setError(error)
        }

        setIsLoaded(true)
    }

    async function loadPerson(personId: string) {
        if (!loggedInUserId) {
            setError("User is not logged in")
            return
        }

        try {
            const personData: SpeakerResponse = await loadPersonData({ id: personId })
            const contactDataPerson = personData.content
            const connectedSotUserId = contactDataPerson.sotUserId

            if (connectedSotUserId) {
                const response = await loadUserData({
                    targetProfileId: connectedSotUserId,
                    loggedInUserId: loggedInUserId
                })
                if ((response as BackendServiceError).httpStatus) {
                    setError((response as BackendServiceError).httpStatusText)
                    return
                }

                setIsUser(true)
                const userDataResponse = response as UserResponse
                mergePersonData(userDataResponse, personData)
            } else {
                setIsUser(false)
                mergePersonData(personData)
            }
            setSotUserId(connectedSotUserId ? connectedSotUserId : personId)
        } catch (error: any) {
            setError(error)
        }

        setIsLoaded(true)
    }

    //loading person details
    useEffect(() => {
        if (userId) {
            loadUser(userId)
        } else {
            loadPerson(personId)
        }

        if (userId === loggedInUserId || personId === loggedInUserId) {
            setIsMyProfile(true)
        } else {
            setIsMyProfile(false)
        }
    }, [personId, userId, loggedInUserId, lang]) // eslint-disable-line react-hooks/exhaustive-deps

    const organizationId = contact?.organizations && contact?.organizations[0] ? contact?.organizations[0]?.id : ""

    const organizationName = contact?.organizations && contact?.organizations[0] ? contact?.organizations[0]?.name : ""

    useEffect(() => {
        if (contact) {
            const personName = [contact?.title, contact?.firstName, contact?.lastName].join(" ")
            switch (contactType) {
                case ContactType.SPEAKER:
                    setBreadcrumb([
                        { to: programPageRoute, name: branding.sideIconBar.programMenuText },
                        { to: speakersPageRoute, name: branding.programPageContent.speakersTabTitle },
                        { to: locations[1], name: personName }
                    ])
                    appState.setCurrentItem("program")
                    appState.setCurrentMobileBreadcrumb(branding.programPageContent.speakersTabTitle)
                    break
                case ContactType.EXHIBITOR:
                    setBreadcrumb([
                        { to: showfloorPageRoute, name: branding.sideIconBar.showfloorMenuText },
                        { to: exhibitorsPageRoute, name: branding.navigationArea.companiesItemTitle },
                        {
                            to: buildDetailLink(organizationId, organizationName, "organization"),
                            name: organizationName,
                            src: "PERSON"
                        },
                        { to: locations[1], name: personName }
                    ])
                    appState.setCurrentItem("organization")
                    appState.setCurrentMobileBreadcrumb(branding.sideIconBar.showfloorMenuText)
                    break
                default:
                    setBreadcrumb([
                        { to: networkingPageRoute, name: branding.sideIconBar.networkingMenuText },
                        { to: locations[1], name: personName }
                    ])
                    appState.setCurrentItem("network")
                    appState.setCurrentMobileBreadcrumb(branding.sideIconBar.networkingMenuText)
                    break
            }
        }
        // eslint-disable-next-line
    }, [contactType, contact, lang])

    useEffect(() => {
        setIsLoaded(true)
        //eslint-disable-next-line
    }, [sotUserId, loggedInUserId])

    let content: JSX.Element = <div />

    if (isMobile) {
        if (contact) {
            return <MobilePersonDetailPageContent contact={contact} connectionStatus={connectionStatus} isUser={isUser} />
        } else if (error) {
            return (
                <div style={{ marginTop: "25%" }}>
                    <BackendError />
                </div>
            )
        }
    }

    if (error) {
        content = (
            <div style={{ marginTop: "25%" }}>
                <BackendError />
            </div>
        )
    } else if (contact) {
        if (!isLoaded) {
            content = <CenteredLoader />
        } else {
            content = (
                <>
                    <Breadcrumb breadcrumb={breadcrumb} pageCategory="Detail" />
                    {isUser &&
                        isUserEdited(userLink) &&
                        contact &&
                        (unrelated ? contact.networkingActive : true) &&
                        (!unrelated || (unrelated && userLink.isMatchActive())) && (
                            <ContextMenuWrapper showShadow={showShadow}>
                                <ContextMenuRoot>
                                    {contact?.eventDates?.length! > 0 ? (
                                        <ContextMenu
                                            className="big"
                                            itemsPerRow={10}
                                            collapsed={false}
                                            items={() =>
                                                createActions(
                                                    userLink.user(),
                                                    contact,
                                                    favoriteState,
                                                    contactState,
                                                    appState,
                                                    meeting,
                                                    chime,
                                                    meetingInvitation,
                                                    meetingController,
                                                    isReportButtonHidden,
                                                    roster,
                                                    isBookmarked,
                                                    connectionStatus,
                                                    menuCallback,
                                                    undefined,
                                                    !isUser,
                                                    !isUser
                                                )
                                            }
                                        />
                                    ) : (
                                        <ContextMenu
                                            className="big"
                                            itemsPerRow={10}
                                            collapsed={false}
                                            items={() =>
                                                createActions(
                                                    userLink.user(),
                                                    contact,
                                                    favoriteState,
                                                    contactState,
                                                    appState,
                                                    meeting,
                                                    chime,
                                                    meetingInvitation,
                                                    meetingController,
                                                    isReportButtonHidden,
                                                    roster,
                                                    isBookmarked,
                                                    connectionStatus,
                                                    menuCallback,
                                                    undefined,
                                                    !isUser
                                                )
                                            }
                                        />
                                    )}
                                </ContextMenuRoot>
                            </ContextMenuWrapper>
                        )}
                    <ContentScrollContainer adjustForHeaderWith={adjustHeight + "px"} handleScroll={onScroll}>
                        <div style={{ position: "relative" }}>
                            <ProfileDetailBody
                                personId={personId ?? personAlias}
                                person={contact}
                                isUser={isUser}
                                isSpeaker={contactType === ContactType.SPEAKER}
                                isSotUser={contact.sotUserId ? true : false}
                                connectionStatus={connectionStatus}
                                unrelated={unrelated}
                            />
                        </div>
                    </ContentScrollContainer>
                </>
            )
        }
    } else {
        content = <CenteredLoader />
    }

    return isMyProfile ? (
        <MyProfilePageContent />
    ) : (
        <div style={{ fontFamily: branding.font1 }}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <CommunicationModals show={modalType} contact={contact} onHide={() => setModalType("none")} />
            {content}
        </div>
    )
}

export default PersonDetailPageContent

export function getProperUrl(url: string): string {
    return !url.startsWith("http://") && !url.startsWith("https://") ? "https://" + url : url
}

function ProfileContacts(props: PersonDetailBodyProps) {
    const lang = useLanguageState().getLanguage()

    function checkIfNoSocials(): boolean {
        const brandingList = branding.myProfilePageContent.availableSocialMedias

        if (props.person.facebook && props.person.facebook.length > 0 && brandingList.includes(SocialMediaType.FACEBOOK)) {
            return true
        }

        if (props.person.twitter && props.person.twitter.length > 0 && brandingList.includes(SocialMediaType.TWITTER)) {
            return true
        }

        if (props.person.instagram && props.person.instagram.length > 0 && brandingList.includes(SocialMediaType.INSTAGRAM)) {
            return true
        }

        if (props.person.linkedIn && props.person.linkedIn.length > 0 && brandingList.includes(SocialMediaType.LINKEDIN)) {
            return true
        }

        if (props.person.youTube && props.person.youTube.length > 0 && brandingList.includes(SocialMediaType.YOUTUBE)) {
            return true
        }

        if (props.person.xing && props.person.xing.length > 0 && brandingList.includes(SocialMediaType.XING)) {
            return true
        }

        if (props.person.pinterest && props.person.pinterest.length > 0 && brandingList.includes(SocialMediaType.PINTEREST)) {
            return true
        }

        return false
    }

    // determines if the div containing information about the speaker will be empty (fixes the double border bug)
    const isRelevantInfoEmpty =
        (!props.person.phone || props.person.phone === "" || !branding.personDetailPageContent.showPhoneNumber) &&
        (!props.person.mobile || props.person.mobile === "" || !branding.personDetailPageContent.showMobileNumber) &&
        !props.person.email &&
        (!props.person.countrycode || props.person.countrycode === "") &&
        (!props.person.languages || props.person.languages?.length === 0)

    return (
        <ProfileContactsWrapper isRelevantInfoEmpty={isRelevantInfoEmpty} style={{ width: props.width ?? "auto" }}>
            <Row>
                <Col xs={24} md={12}>
                    {props.person.phone && branding.personDetailPageContent.showPhoneNumber && (
                        <ProfileContactDetailsContainer key={"phone"}>
                            {IconPhone({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>{props.person.phone}</ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    )}

                    {props.person.mobile && branding.personDetailPageContent.showMobileNumber && (
                        <ProfileContactDetailsContainer key={"mobile"}>
                            {IconMobile({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            <ProfileContactDetailsText>{props.person.mobile}</ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    )}

                    {props.person.email && (
                        <ProfileContactDetailsContainer key={"email"}>
                            {IconEmail({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            <ProfileContactDetailsText style={{ overflow: "hidden", wordBreak: "break-all" }}>
                                {props.person.email}
                            </ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    )}

                    {props.person.countrycode && (
                        <ProfileContactDetailsContainer key={"countrycode"} style={{ alignItems: "flex-start" }}>
                            {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>
                                {findCountryName(props.person.countrycode, lang)}
                            </ProfileContactDetailsText>
                            {/* {props.person.city && hiddenFields.indexOf(Field.CITY) < 0 && <ProfileContactDetailsText>{user.city}</ProfileContactDetailsText>} */}
                        </ProfileContactDetailsContainer>
                    )}

                    {props.person.languages && props.person.languages.length > 0 && (
                        <ProfileContactDetailsContainer>
                            {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                            <ProfileContactDetailsText>
                                {getListOfLanguagesString(props.person.languages, lang)}
                            </ProfileContactDetailsText>
                        </ProfileContactDetailsContainer>
                    )}
                </Col>
            </Row>

            {checkIfNoSocials() && props.connectionStatus && props.connectionStatus === "CONNECTED" && (
                <>
                    <ProfileSocialsTitle>
                        <ProfilePageSectionTitle>{branding.myProfilePageContent.socialMediaSectionTitle}</ProfilePageSectionTitle>
                    </ProfileSocialsTitle>
                    <ProfileSocialsWrapper>
                        {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                            if (item === SocialMediaType.FACEBOOK) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.facebook && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.facebook)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconFacebookOriginal({ width: "20", height: "20" })
                                                    : IconFacebook({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.TWITTER) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.twitter && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.twitter)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconTwitterOriginal({ width: "20", height: "20" })
                                                    : IconTwitter({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.LINKEDIN) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.linkedIn && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.linkedIn)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconLinkedInOriginal({ width: "20", height: "20" })
                                                    : IconLinkedIn({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.XING) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.xing && (
                                            <ProfileSocialItem key={index} href={getProperUrl(props.person.xing)} target="_blank">
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconXingOriginal({ width: "20", height: "20" })
                                                    : IconXing({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.INSTAGRAM) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.instagram && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.instagram)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconInstagramOriginal({ width: "20", height: "20" })
                                                    : IconInstagram({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.YOUTUBE) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.youTube && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.youTube)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconYouTubeOriginal({ width: "20", height: "20" })
                                                    : IconYouTube({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.PINTEREST) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.pinterest && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.pinterest)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconPinterestOrginal({ width: "20", height: "20" })
                                                    : IconPinterest({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            if (item === SocialMediaType.TIKTOK) {
                                return (
                                    <React.Fragment key={index}>
                                        {props.person.tiktok && (
                                            <ProfileSocialItem
                                                key={index}
                                                href={getProperUrl(props.person.tiktok)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {branding.socialMediaIcons.useOriginalSocialMediaIcons
                                                    ? IconTikTokOriginal({ width: "20", height: "20" })
                                                    : IconTikTok({
                                                          fill: branding.sideIconBar.sideIconColorDark,
                                                          width: "20",
                                                          height: "20"
                                                      })}
                                            </ProfileSocialItem>
                                        )}
                                    </React.Fragment>
                                )
                            }

                            return null
                        })}
                    </ProfileSocialsWrapper>
                </>
            )}
        </ProfileContactsWrapper>
    )
}

// #region Person Details

interface PersonDetailBodyProps {
    person: Contact
    isUser?: boolean
    isSpeaker?: boolean
    connectionStatus?: string
    width?: string
    unrelated: boolean
    personId?: string
    isSotUser?: boolean
}

const ProfileDetailBody: React.FunctionComponent<PersonDetailBodyProps> = (props: PersonDetailBodyProps) => {
    const userState = useLoggedInState()

    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const currentUserInfo =
        props.person.infotext && props.connectionStatus !== "CONNECTED"
            ? props.person.infotext
            : props.person.description?.text
            ? props.person.description.text
            : null

    const currentUserName: string = branding.myProfilePageContent.salutationVisible
        ? [props.person?.salutation, props.person?.title, props.person?.firstName, props.person?.lastName]
              .filter(Boolean)
              .join(" ")
        : [props.person?.title, props.person?.firstName, props.person?.lastName].filter(Boolean).join(" ")

    const windowSize = useWindowDimensions()

    const largeScreen: boolean = windowSize.width >= 1502

    const otherBadges: string[] = []
    if (props.person.type === "press") {
        otherBadges.push(branding.press)
    }

    if (props.person.type === "staff" && branding.myProfilePageContent.showExhibitorBadge) {
        otherBadges.push(branding.globalSearchResultPage.exhibitorEntityTitle)
    }

    return (
        <ProfileDetailRowHeader>
            <PersonImageAndLogo className={largeScreen ? "col-3" : "col-md-12"}>
                <UserBasicInfoContainer>
                    <CustomRow style={{ justifyContent: "center" }}>
                        <UserImageDiv>
                            <AvatarWithDefault
                                size={100}
                                src={props.person.logoUrl}
                                alt={[props.person.firstName, props.person.lastName].join(" ") ?? "#"}
                                backgroundSize="cover"
                            />
                        </UserImageDiv>
                    </CustomRow>

                    <CustomRow style={{ justifyContent: "center" }}>
                        <UserFullName>{currentUserName}</UserFullName>
                    </CustomRow>

                    <CustomRow style={{ justifyContent: "center" }}>
                        <UserPositionCompany>
                            {getUserTitle(
                                props.person.position,
                                props.person.positionDe,
                                props.person.company,
                                props.person.organization
                            )}
                        </UserPositionCompany>
                    </CustomRow>
                    {(props.person.topics?.length! > 0 || props.person.categoryBadges?.length! > 0 || otherBadges.length > 0) && (
                        <CustomRow style={{ marginTop: "-20px", marginBottom: "10px" }}>
                            <BadgesContainer
                                topics={props.person.topics}
                                otherBadges={otherBadges}
                                categoryBadges={props.person.categoryBadges}
                            />
                        </CustomRow>
                    )}

                    {props.isSpeaker && !props.isSotUser && (
                        <CustomRow>
                            <ShareBookmarkButtons
                                type="person"
                                id={props.person.person ?? props.person.id}
                                name={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ")}
                                isUser={props.isUser}
                            />
                        </CustomRow>
                    )}

                    <CustomRow>
                        <DummyDiv />
                    </CustomRow>
                </UserBasicInfoContainer>

                <CustomRow>
                    <ProfileContacts
                        person={props.person}
                        width={largeScreen ? "auto" : "673px"}
                        unrelated={props.unrelated}
                        connectionStatus={props.connectionStatus}
                    />
                </CustomRow>
            </PersonImageAndLogo>

            <ProfileDetailNameTeaserWrapper style={{ marginLeft: largeScreen ? "60px" : "0px" }}>
                {currentUserInfo && (
                    <>
                        <ProfilePageSectionTitle>{branding.myProfilePageContent.biographyMobileLabel}</ProfilePageSectionTitle>
                        <CategoriesViewRow>
                            <div style={{ margin: ".3rem" }}>
                                <BiographyText>{currentUserInfo}</BiographyText>
                            </div>
                        </CategoriesViewRow>
                    </>
                )}

                <EventDates
                    type="UPCOMING"
                    eventDates={props.person?.eventDates?.length > 0 ? props.person.eventDates : []}
                    showRecommendModal={showRecommendModal}
                />

                <EventDates
                    type="PAST"
                    eventDates={props.person?.eventDates?.length > 0 ? props.person.eventDates : []}
                    showRecommendModal={showRecommendModal}
                />

                <>
                    {branding.myProfilePageContent.categoriesOrder.map((item, key) => {
                        switch (item) {
                            case "INTERESTS":
                                return (
                                    <React.Fragment key={key}>
                                        {props.person?.interests && props.person?.interests.length > 0 && (
                                            <ContactCategoryBindings person={props.person!} type={item} />
                                        )}
                                    </React.Fragment>
                                )

                            case "OFFERS":
                                return (
                                    <React.Fragment key={key}>
                                        {props.person?.offering && props.person?.offering.length > 0 && (
                                            <ContactCategoryBindings person={props.person!} type={item} />
                                        )}
                                    </React.Fragment>
                                )

                            case "NEEDS":
                                return (
                                    <React.Fragment key={key}>
                                        {props.person?.lookingfor && props.person?.lookingfor.length > 0 && (
                                            <ContactCategoryBindings person={props.person!} type={item} />
                                        )}
                                    </React.Fragment>
                                )

                            default:
                                return null
                        }
                    })}
                </>

                {props.person.organizations && props.person.organizations[0] && (
                    <CompanyDetails organization={props.person.organizations[0]} source="PERSON" />
                )}

                {props.person.joboffers && props.person.joboffers.length > 0 && props.personId && (
                    <PersonJobOffers joboffers={props.person.joboffers} personId={props.personId} />
                )}

                {props.isUser &&
                    (isOnboardingPhase || isLivePhase) &&
                    branding.personDetailPageContent.meetingSlotsSection.visible &&
                    (props.unrelated ? props.person.networkingActive : true) &&
                    (!props.unrelated || (props.unrelated && userState.isMatchActive())) && (
                        <Row>
                            <MeetingSlots user={props.person} />
                        </Row>
                    )}
            </ProfileDetailNameTeaserWrapper>
            <RecommendModal />
        </ProfileDetailRowHeader>
    )
}

// #endregion

// #endregion

// #region  Person EventDates

interface EventDateProps {
    type: "UPCOMING" | "PAST"
    eventDates: Array<EventDate>
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
    updateFavorites?: () => void
}

export const EventDates: React.FunctionComponent<EventDateProps> = React.memo((props) => {
    const { timezone } = useAppState()
    const sectionTitle =
        props.type === "PAST"
            ? branding.personDetailPageContent.pastSessionsTitle
            : branding.personDetailPageContent.upcomingSessionsTitle

    const favoriteState = useFavoriteState()

    const userState = useLoggedInState()

    const profileId = userState.user()?.profileId

    const [areAllSessionsBookmarked, setAreAllSessionsBookmarked] = useState<boolean>(false)
    const [filteredEventDates, setFilteredEventDates] = useState<EventDate[]>([])

    useEffect(() => {
        const eventDates = props.eventDates ?? []
        const temp =
            props.type === "PAST"
                ? eventDates.filter((ed) => moment(ed.startdatetime ?? ed.dateTimeStart).isBefore(moment().tz(timezone)))
                : eventDates.filter((ed) => moment(ed.startdatetime ?? ed.dateTimeStart).isSameOrAfter(moment().tz(timezone)))

        temp.sort((a, b) => {
            return new Date(a.startdatetime).valueOf() - new Date(b.startdatetime).valueOf()
        })

        checkIfAllSessionsBookmarked(temp)
        setFilteredEventDates(temp)
        // eslint-disable-next-line
    }, [])

    function syncFav(eventId: string) {
        if (profileId) {
            syncFavorites({
                profileId: profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: eventId,
                            kind: "EVENTDATE",
                            deleted: favoriteState.is("eventdate", eventId) ? false : true,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            })
                .then((resp) => {
                    favoriteState.setLastSyncTime(new Date())
                })
                .catch((e: { message: React.SetStateAction<string> }) => {
                    // syncFavorites failed, logged in BackendServices
                })
        }
    }

    const handleBookmarking = async () => {
        filteredEventDates.forEach((session: EventDate) => {
            let isBookmarked: boolean = favoriteState.is("eventdate", session.id) ?? false

            if (isBookmarked === areAllSessionsBookmarked) {
                favoriteState.toggle("eventdate", session.id)
                syncFav(session.id)
            }
        })

        setAreAllSessionsBookmarked(!areAllSessionsBookmarked)
    }

    function checkIfAllSessionsBookmarked(eventDates: EventDate[]) {
        if (eventDates.length > 0) {
            setAreAllSessionsBookmarked(
                eventDates.every((session: EventDate) => {
                    return favoriteState.is("eventdate", session.id)
                })
            )
        }
    }

    return filteredEventDates.length > 0 ? (
        <ContentRow>
            <EventDateHeaderPositionRow>
                <EventDatesTitleWrapper>
                    <EventDatesTitle>
                        {sectionTitle} ({filteredEventDates.length}){" "}
                    </EventDatesTitle>
                    <EventDatesFavorite>
                        <p onClick={handleBookmarking}>
                            {areAllSessionsBookmarked
                                ? branding.personDetailPageContent.unfavoriteSessionsTitle
                                : branding.personDetailPageContent.favoriteSessionsTitle}
                        </p>
                    </EventDatesFavorite>
                </EventDatesTitleWrapper>
            </EventDateHeaderPositionRow>
            {filteredEventDates.map((eventDate, idx) => {
                return (
                    <EventDateContentCol
                        xs={12}
                        key={idx}
                        style={{
                            borderBottom:
                                idx < filteredEventDates.length - 1
                                    ? branding.mainBorder
                                        ? branding.mainBorder
                                        : "1px solid #d9d9d9"
                                    : "none"
                        }}
                    >
                        <EventDateView
                            eventDate={eventDate}
                            type="eventdate"
                            updateFavorites={() => checkIfAllSessionsBookmarked(filteredEventDates)}
                            showRecommendModal={props.showRecommendModal}
                        />
                    </EventDateContentCol>
                )
            })}
        </ContentRow>
    ) : null
})

interface EventDateViewProps {
    eventDate: EventDate
    type: DetailNavLinkType
    updateFavorites?: () => void
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
}

export const EventDateView: React.FunctionComponent<EventDateViewProps> = (props) => {
    const lang = useLanguageState().getLanguage()

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()
    const [eventDate, setEventDate] = useState<EventDate>(props.eventDate)

    const favoriteState = useFavoriteState()
    const eventDateId = props.eventDate.id || ""
    const isBookmarked = favoriteState.is("eventdate", eventDateId)
    const userState = useLoggedInState()
    const user = userState.user()

    const [contextMenuOpened, setContextMenuOpened] = useState<boolean>(false)

    const windowSize = useWindowDimensions()

    function getFormattedDate(date: string, start: string) {
        return (
            format(moment(date).toDate(), branding.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                locale: lang === "de" ? de : en
            }) +
            (lang === "de" ? " um " : " at ") +
            start +
            (lang === "de" ? " Uhr" : "")
        ) //have to find a better way to do this but this works for now
    }

    useEffect(() => {
        if (categoriesState.getCategories().length)
            setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        loadEventDateList({
            filterlist: "entity_evtd, id_evtd_" + props.eventDate.id,
            startresultrow: 0,
            numresultrows: 1,
            order: "chrono",
            lang: lang
        })
            .then((resp) => {
                if (resp.eventDates[0]) setEventDate(resp.eventDates[0])
            })
            .catch((e: { message: React.SetStateAction<string> }) => {})
        // eslint-disable-next-line
    }, [props.eventDate])

    function onBookmark() {
        if (props.eventDate && user) {
            let date = new Date()
            date.setMinutes(date.getMinutes() - 1)
            favoriteState.toggle("eventdate", eventDateId)
            const isFavorite = favoriteState.is("eventdate", eventDateId)
            syncFavorites({
                profileId: user.profileId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //"2020-01-01 12:00:00",
                    lastSyncTime: favoriteState.getLastSyncTime(),
                    changedFavorites: [
                        {
                            id: eventDateId,
                            kind: "EVENTDATE",
                            deleted: !isFavorite,
                            lastModified: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                        }
                    ]
                }
            }).then(() => {
                favoriteState.setLastSyncTime(new Date())
            })
        }
    }

    return (
        <EventDateViewRoot contextMenuOpened={contextMenuOpened}>
            <EventDateViewRow>
                <EventDateViewIconCol xs={2}>
                    <DetailNavLink
                        source="PERSON"
                        name={props.eventDate.name}
                        id={props.eventDate.id}
                        type={props.type}
                        onClick={() => {
                            trackSelectContent("eventdate", props.eventDate.id, props.eventDate.name, "Entity link", "eventdate")
                        }}
                    >
                        {
                            <div style={{ marginTop: "-10px" }}>
                                <EventDateViewLogoDiv>
                                    <CompanyLogo src={props.eventDate.logoUrl ?? "/branding/no-event-default-image.png"} />
                                </EventDateViewLogoDiv>
                            </div>
                        }
                    </DetailNavLink>
                </EventDateViewIconCol>
                <EventDateViewInfoCol xs={8}>
                    <DetailNavLink
                        source="PERSON"
                        name={props.eventDate.name}
                        id={props.eventDate.id}
                        type={props.type}
                        onClick={() => {
                            trackSelectContent("eventdate", props.eventDate.id, props.eventDate.name, "Entity link", "eventdate")
                        }}
                    >
                        <EventDateTimeLocation>
                            {props.eventDate && eventDate.date && (
                                <>
                                    {getFormattedDate(eventDate.date, eventDate.start)}{" "}
                                    {eventDate.location && "| " + eventDate.location}
                                </>
                            )}

                            <DesktopVersionContainer>
                                {pointsBadgeData &&
                                    eventDate.categories
                                        ?.map((x) => x.alias)
                                        ?.includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                                        <PointsBadge
                                            className={"ml-2"}
                                            textColor={pointsBadgeData.colorText}
                                            fillColor={pointsBadgeData.colorFill}
                                            borderColor={pointsBadgeData.colorBorder}
                                        >
                                            {branding.programSchedule.pointsBadgeText}
                                        </PointsBadge>
                                    )}
                            </DesktopVersionContainer>
                        </EventDateTimeLocation>
                        <EventDateTitle>{eventDate.name || props.eventDate.name}</EventDateTitle>
                        <DesktopVersionContainer>
                            <div style={{ marginTop: "10px" }}>
                                {props.eventDate && eventDate.teaser && (
                                    <EventDateDescription>{eventDate.teaser}</EventDateDescription>
                                )}
                            </div>
                        </DesktopVersionContainer>
                    </DetailNavLink>
                </EventDateViewInfoCol>

                <EventDateViewActionsCol xs={windowSize.useMobileDesign ? 1 : 2}>
                    <DesktopVersionContainer>
                        <div style={{ paddingBottom: "90px" }}>
                            <ShareBookmarkIcons
                                type="eventdate"
                                id={props.eventDate.id}
                                name={props.eventDate.name}
                                updateFavorites={() => {
                                    if (props.updateFavorites) {
                                        props.updateFavorites()
                                    }
                                }}
                            />
                        </div>
                    </DesktopVersionContainer>

                    <MobileVersionContainer>
                        <MenuPanel>
                            <MenuButton style={{ paddingLeft: "15px" }} onClick={() => setContextMenuOpened(!contextMenuOpened)}>
                                {IconThreeDotsMenuVertical({ fill: branding.sideIconBar.sideIconColorDark })}
                            </MenuButton>
                        </MenuPanel>
                    </MobileVersionContainer>
                </EventDateViewActionsCol>
            </EventDateViewRow>
            <div style={{ flexGrow: 0, flexShrink: 0, marginTop: "15px" }}>
                <ContextMenu
                    collapsed={!contextMenuOpened}
                    items={() => [
                        {
                            title: branding.organizationDetailPageContent.bookmark,
                            icon: isBookmarked
                                ? branding.defaultToggleIcon
                                    ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                                    : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark })
                                : IconBookmarkToggle({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => onBookmark()
                        },
                        {
                            title: branding.organizationDetailPageContent.recommend,
                            icon: IconShare({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () =>
                                props.showRecommendModal(
                                    ShareTargetType.EVENTDATE,
                                    eventDateId,
                                    buildDetailLink(eventDateId, eventDate.name, "eventdate")
                                )
                        },
                        {
                            title: branding.contactEntry.downloadVCardTitle,
                            icon: IconDownload({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => saveEvent(eventDate)
                        }
                    ]}
                />
            </div>
        </EventDateViewRoot>
    )
}
// #endregion

// #region  Share and bookmark icons component

interface ShareBookmarkIconsProps {
    id: string
    type: EntityType
    name: string
    isUser?: boolean
    updateFavorites: () => void
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = React.memo((props) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    let type: "eventdate" | "person" | "user" = "eventdate"
    if (props.type === "person") type = props.isUser ? "user" : "person"
    const link = buildDetailLink(props.id, props.name, type)
    const shareType = props.type === "person" ? ShareTargetType.PERSON : ShareTargetType.EVENTDATE

    return (
        <IconRootArea>
            <ShareIcon onClick={() => showRecommendModal(shareType, props.id, link)}>
                {IconShare({ fill: branding.personDetailPageContent.upcomingSessionShareIconColor ?? "#000" })}
            </ShareIcon>
            <BookmarkWithToggle
                newBookmarkItem={true}
                fontSize={"25px"}
                color={branding.sideIconBar.sideIconColorDark}
                favIconBasic={true}
                type={props.type}
                id={props.id!}
                name={props.name}
                customFunction={() => props.updateFavorites()}
            />
            <RecommendModal />
        </IconRootArea>
    )
})

interface ShareBookmarkButtonsProps {
    id: string
    type: EntityType
    name: string
    isUser?: boolean
    sessions?: EventDate[]
}

const ShareBookmarkButtons: React.FunctionComponent<ShareBookmarkButtonsProps> = React.memo((props) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const lang = useLanguageState().getLanguage()
    const favoriteState = useFavoriteState()

    let type: "eventdate" | "person" | "user" = "eventdate"
    if (props.type === "person") type = props.isUser ? "user" : "person"
    const link = buildDetailLink(props.id, props.name, type)
    const shareType = props.type === "person" ? ShareTargetType.PERSON : ShareTargetType.EVENTDATE

    //refresh the component on language change
    useEffect(() => {}, [lang])

    return (
        <ShareBookmarkButtonsRoot>
            <RecommendModal />
            <ShareBookmarkButton onClick={() => showRecommendModal(shareType, props.id, link)}>
                <ShareBookmarkButtonIcon>
                    {IconShare({ fill: branding.sideIconBar.sideIconColorDark ?? "#000" })}
                </ShareBookmarkButtonIcon>

                <ShareBookmarkButtonText>{branding.personDetailPageContent.shareSpeakerButtonText}</ShareBookmarkButtonText>
            </ShareBookmarkButton>

            <br />
            <BookmarkWithToggle
                fullButton={true}
                buttonText={
                    favoriteState.is("person", props.id)
                        ? branding.personDetailPageContent.unbookmarkSpeakerButtonText
                        : branding.personDetailPageContent.bookmarkSpeakerButtonText
                }
                newBookmarkItem={true}
                fontSize={"25px"}
                color={branding.sideIconBar.sideIconColorDark}
                favIconBasic={true}
                type={props.type}
                id={props.id!}
                name={props.name}
                favouriteButton={true}
            />

            <br />

            {/* removed in 1.78, "favorite all" button moved to past sessions and future sessions respectively */}

            {/* <ShareBookmarkButton onClick={handleBookmarking}>
                <ShareBookmarkButtonIcon>
                    <NewFavIcon>
                        {props.allSessionsBookmarked
                            ? IconBookmarkFilled({ fill: branding.sideIconBar.sideIconColorDark })
                            : IconBookmark({ fill: branding.sideIconBar.sideIconColorDark })}
                    </NewFavIcon>
                </ShareBookmarkButtonIcon>
                <ShareBookmarkButtonText>
                    {props.allSessionsBookmarked
                        ? localization.personDetailPageContent.unbookmarkAllEventsButtonText
                        : localization.personDetailPageContent.bookmarkAllEventsButtonText}
                </ShareBookmarkButtonText>
            </ShareBookmarkButton> */}
        </ShareBookmarkButtonsRoot>
    )
})
// #endregion

export interface ContactCategoryBindingsProps {
    person: any
    type: CategoryType

    setIsEmpty?: (value: boolean) => void
}

function getCategorySectionTitle(type: CategoryType) {
    switch (type) {
        case CategoryType.INTERESTS:
            return branding.personDetailPageContent.interestsTitle

        case CategoryType.OFFERS:
            return branding.personDetailPageContent.offersTitle

        case CategoryType.NEEDS:
            return branding.personDetailPageContent.needsTitle

        default:
            return branding.personDetailPageContent.interestsTitle
    }
}

const ContactCategoryBindings: React.FunctionComponent<ContactCategoryBindingsProps> = React.memo((props) => {
    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const userState = useLoggedInState()

    const [categories, setCategories] = useState<{ common: Category[]; unique: Category[] }>({ common: [], unique: [] })
    let content: JSX.Element = <div />

    useEffect(() => {
        loadCategories()
        // eslint-disable-next-line
    }, [props.person, lang])

    function loadCategories() {
        getInterest().then((res) => {
            if (res.content.interests || res.content.lookingforandoffering) {
                let selectedCategoryIds: String[] = []
                let allCategories: Category[] = []
                let myCategoriesTemp: any[] = []

                switch (props.type) {
                    case CategoryType.INTERESTS:
                        selectedCategoryIds = props.person.interests ?? []
                        allCategories = res.content.interests
                        myCategoriesTemp = userState.user()?.interests!
                        break

                    case CategoryType.OFFERS:
                        selectedCategoryIds = props.person.offering ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.offering!
                        break

                    case CategoryType.NEEDS:
                        selectedCategoryIds = props.person.lookingfor ?? []
                        allCategories = res.content.lookingforandoffering
                        myCategoriesTemp = userState.user()?.lookingfor!
                        break
                }
                const commonCategories: Category[] = []
                const uniqueCategories: Category[] = []
                allCategories.forEach((i: Category) => {
                    if (myCategoriesTemp?.includes(i.id) && selectedCategoryIds?.includes(i.id)) commonCategories.push(i)
                    else if (myCategoriesTemp?.includes(i.id) === false && selectedCategoryIds?.includes(i.id))
                        uniqueCategories.push(i)
                })
                setCategories({ common: commonCategories, unique: uniqueCategories })
            }
        })
    }

    if (props.type === CategoryType.INTERESTS && branding.communicationArea.interestsAsBadges) {
        //if interests are used as category badges, we are able to disable the interests section in the profile page, in order to not show the interests twice
        content = <></>
    } else if (categories.unique.length || categories.common.length) {
        content = (
            <>
                <CategoriesTitleRow>
                    <ProfilePageSectionTitle>{getCategorySectionTitle(props.type)} </ProfilePageSectionTitle>
                </CategoriesTitleRow>
                <CategoriesViewRow>
                    {categories.common.map((category) => {
                        return (
                            <div key={category.id}>
                                <CategoriesItem common>{category.name}</CategoriesItem>
                            </div>
                        )
                    })}
                    {categories.unique.map((category) => {
                        return (
                            <div key={category.id}>
                                <CategoriesItem>{category.name}</CategoriesItem>
                            </div>
                        )
                    })}
                </CategoriesViewRow>
            </>
        )
    } else {
        content = <></>
    }

    return content
})

// #endregion

/* #region  Person Job Offers */
const groupJobOffersByPersonFunction = (joboffers: JobOffer[], personId: string) => {
    const jobOffersGroups: Map<string, JobOffer[]> = new Map()

    joboffers.forEach((jo) => {
        const personFunc = jo.persons.find((p) => p.id === personId)?.personFunctionTargetName
        if (personFunc) {
            const addedOffers = jobOffersGroups.get(personFunc) ?? []
            jobOffersGroups.set(personFunc, [...addedOffers, jo])
        }
    })

    return jobOffersGroups
}

interface PersonJobOffersProps {
    joboffers: JobOffer[]
    personId: string
}

export const PersonJobOffers = (props: PersonJobOffersProps | any) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const [joboffersGroups, setJoboffersGroups] = useState<Map<string, JobOffer[]>>(new Map())
    const { isMobile } = useWindowDimensions()

    useEffect(() => {
        if (props.joboffers && props.personId) setJoboffersGroups(groupJobOffersByPersonFunction(props.joboffers, props.personId))
    }, [props.joboffers, props.personId])

    let content = (
        <>
            {joboffersGroups &&
                joboffersGroups.size > 0 &&
                [...joboffersGroups.keys()].map((key) => {
                    const joboffers = joboffersGroups.get(key)
                    if (joboffers && joboffers.length > 0) {
                        return (
                            <DetailPageEntityRows
                                key={key}
                                type="joboffer"
                                pageType="joboffer"
                                headerTitle={key}
                                rowsData={joboffers}
                                showRecommendModal={showRecommendModal}
                                forceMobileDesign={isMobile}
                                customStyle={{ padding: "0 12px 0px 12px" }}
                            />
                        )
                    }
                    return null
                })}
            <RecommendModal />
        </>
    )

    if (!joboffersGroups || joboffersGroups?.size! === 0) {
        return null
    }

    if (!isMobile) {
        return <>{content}</>
    } else {
        return <ContentRow>{content}</ContentRow>
    }
}

/* #endregion */
