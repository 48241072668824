import React from "react"
import styled from "styled-components"
import { CalendarEntry } from "../../../backendServices/GraphQLServices"
import branding from "../../../branding/branding"
import { InputIcons } from "./CalendarEntryModal.styled"
import { calculateStartEndDate, CalendarEntryModalViewMode } from "./ModalFunctions"

interface CounterProps {
    startTime?: Date | null
    endTime?: Date | null
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
}

const TimerCounter = (props: CounterProps) => {
    return (
        <InputIcons>
            <CounterBackground>
                {props.endTime !== null &&
                    props.startTime !== null &&
                    calculateStartEndDate(props.startTime!, props.endTime!) + "min"}
            </CounterBackground>
        </InputIcons>
    )
}

export default TimerCounter

const CounterBackground = styled.div`
    position: absolute;
    top: 16px;
    width: 100%;
    height: 35px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${branding.font1};
    font-size: 14px;
    line-height: 19px;
    color: #202428;
`
