import { useState, useRef } from "react"

interface UseCallCountdownTimerReturn {
    timeLeft: number | null
    isRunning: boolean
    start: (countdownTime: number | null) => void
    stop: () => void
    reset: () => void
    formattedDuration: string | null
}

const useCallCountdownTimer = (): UseCallCountdownTimerReturn => {
    const [timeLeft, setTimeLeft] = useState<number | null>(null)
    const [isRunning, setIsRunning] = useState<boolean>(false)
    const intervalRef = useRef<number | null>(null)

    const start = (countdownTime: number | null): void => {
        if (intervalRef.current !== null) {
            return
        }

        setTimeLeft(countdownTime)
        setIsRunning(true)

        intervalRef.current = window.setInterval(() => {
            setTimeLeft((prevTimeLeft) => {
                const newTimeLeft = prevTimeLeft !== null ? prevTimeLeft - 1000 : null

                if (newTimeLeft === 0) {
                    stop()
                }

                return newTimeLeft
            })
        }, 1000)
    }

    const stop = (): void => {
        if (intervalRef.current === null) {
            return
        }

        window.clearInterval(intervalRef.current)
        intervalRef.current = null
        setIsRunning(false)
    }

    const reset = (): void => {
        if (intervalRef.current !== null) {
            window.clearInterval(intervalRef.current)
            intervalRef.current = null
        }

        setTimeLeft(null)
        setIsRunning(false)
    }

    const formatDuration = (timeLeft: number | null): string | null => {
        if (timeLeft === null) {
            return null
        }

        const totalSeconds = Math.floor(timeLeft / 1000)
        const hours = Math.floor(totalSeconds / 3600)
        const minutes = Math.floor((totalSeconds % 3600) / 60)
        const seconds = totalSeconds % 60

        const parts = []

        if (hours > 0) {
            parts.push(hours.toString().padStart(2, "0"))
        }

        parts.push(minutes.toString().padStart(2, "0"))
        parts.push(seconds.toString().padStart(2, "0"))

        return parts.join(":")
    }

    const formattedDuration = formatDuration(timeLeft)

    return { timeLeft, isRunning, start, stop, reset, formattedDuration }
}

export default useCallCountdownTimer
