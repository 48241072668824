import { useMeetingController } from "../../context/MeetingController"
import { GreenRoomControlsRoot } from "./GreenRoomControlsStyles"
import ModeratorControls from "./ModeratorControls/ModeratorControls"
import BreakoutRoomButton from "./BreakoutRoomButton/BreakoutRoomButton"

function GreenRoomControls() {
    const meetingController = useMeetingController()

    if (meetingController.getMeetingKind() !== "greenroom") {
        return <div style={{ width: "300px", height: "100%" }}></div>
    }

    return (
        <GreenRoomControlsRoot>
            {meetingController.getIsMod() ? <ModeratorControls /> : <BreakoutRoomButton />}
        </GreenRoomControlsRoot>
    )
}

export default GreenRoomControls
