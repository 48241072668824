import * as React from "react"
import styled from "styled-components"
import { personFilterType } from "./NetworkingPageContent"
import SuggestionsSlider, { SuggestionsSliderProps } from "./components/SuggestionsSlider"
import ZoomRangeSlider from "./components/ZoomRangeSlider"
import { viewType } from "./NetworkingPageContentBranding"

const BottomFilterContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 0;
    right: 0;
    padding-bottom: 1rem; /* was 2rem */
    z-index: 1300;
    background-color: white;
`

const BottomFilterContainerGradient = styled.div`
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 50px;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
`

interface BottomFilterAreaProps {
    bottomFilterRef: React.RefObject<HTMLDivElement>
    personTypeData: { value: personFilterType; label: string }[]
    selectedPersonType: personFilterType
    currentViewType: viewType
    zoomStrengthValue: number[]
    suggestionSliderProps: SuggestionsSliderProps
    showLoadNewSuggestionsBtn: boolean
    currentDataPage: number
    setCurrentDataPage: (value: React.SetStateAction<number>) => void
    setZoomStrengthValue: React.Dispatch<React.SetStateAction<number[]>>
    setSelectedPersonType: React.Dispatch<React.SetStateAction<personFilterType>>
}

const BottomFilterArea = (props: BottomFilterAreaProps) => {
    return (
        <BottomFilterContainer ref={props.bottomFilterRef}>
            <BottomFilterContainerGradient />
            {props.currentViewType === viewType.EXPLORE_MY_CON && (
                <ZoomRangeSlider zoomStrengthValue={props.zoomStrengthValue} setZoomStrengthValue={props.setZoomStrengthValue} />
            )}
            {props.currentViewType === viewType.RELEVANT_PPL && (
                <SuggestionsSlider
                    loadSuggestionsValue={props.suggestionSliderProps.loadSuggestionsValue}
                    setLoadSuggestionsValue={props.suggestionSliderProps.setLoadSuggestionsValue}
                    hasNextPage={props.suggestionSliderProps.hasNextPage}
                    setCurrentDataPage={props.suggestionSliderProps.setCurrentDataPage}
                />
            )}
        </BottomFilterContainer>
    )
}

export default BottomFilterArea
