import styled from "styled-components"

export const LocalVideoRoot = styled.div`
    width: 200px;
    height: 150px;
    position: absolute;
    right: 15px;
    bottom: 115px;
    background-color: #37383a;
    border: 1px solid #000;
    z-index: 15;
`
