import { useLocalVideo } from "amazon-chime-sdk-component-library-react"
import LocalAvatar from "../LocalAvatar/LocalAvatar"
import LocalVideo from "../LocalVideo/LocalVideo"
import { LocalVideoRoot } from "./LocalTileStyles"

function LocalTile() {
    const { isVideoEnabled } = useLocalVideo()
    return <LocalVideoRoot>{isVideoEnabled ? <LocalVideo /> : <LocalAvatar />}</LocalVideoRoot>
}

export default LocalTile
