import { createState, State, useState } from "@hookstate/core"
import {
    useBackgroundBlur,
    useBackgroundReplacement,
    useMeetingManager,
    useVideoInputs
} from "amazon-chime-sdk-component-library-react"
import {
    BackgroundReplacementVideoFrameProcessor,
    ConsoleLogger,
    DefaultVideoTransformDevice,
    Device,
    isVideoTransformDevice,
    VideoFrameProcessor
} from "amazon-chime-sdk-js"
import branding from "../../branding/branding"
import { LogoProcessor, Position } from "../../conference/processor/LogoProcessor"
import { VirtualBackgroundProcessor } from "../../conference/processor/VirtualBackgroundProcessor"
import { LogoPositions } from "../enums/LogoPosition"
const logoProcessor = new LogoProcessor("topleft")
const backgroundProcessor = new VirtualBackgroundProcessor()
const defaultPosition = "topleft"
interface StateValues {
    backgroundGalleryItems: string[]
    selectedBackground?: string | null
    isBlurActive: boolean
    customUploadedBackground: string | null
    isLoading: boolean
    logoGalleryItems: string[]
    customUploadedLogo: string | null
    selectedLogo: string | null
    logoProcessor: LogoProcessor | null
    selectedLogoPosition: LogoPositions
    processors: LogoProcessor | BackgroundReplacementVideoFrameProcessor | null
    isLogoProcessorActive: boolean
}

const getStartValues = (): StateValues => {
    return {
        backgroundGalleryItems: branding.audioVideoSettings.galleryItemList,
        selectedBackground: null,
        isBlurActive: false,
        customUploadedBackground: null,
        isLoading: false,
        customUploadedLogo: null,
        logoGalleryItems: ["/branding/organizerlogo_closed.png"], // TODO: Loop over user organizations logo
        selectedLogo: null,
        logoProcessor: new LogoProcessor(LogoPositions.TOP_LEFT),
        selectedLogoPosition: LogoPositions.TOP_LEFT,
        processors: null,
        isLogoProcessorActive: false
    }
}

const state = createState<StateValues>(getStartValues())

export interface ConferenceRoomSettingsContext {
    getBackgroundGalleryItems: () => string[]
    getSelectedBackground: () => string | null | undefined
    getCustomUploadedBackground: () => string | null
    setSelectedBackground: (url?: string) => Promise<void>
    uploadBackground: (e: any) => void
    removeBackground: () => Promise<void>
    deleteCustomBackground: () => void
    toggleBlur: () => Promise<void>
    getIsBlurActive: () => boolean
    setSelectedLogo: (url: string) => Promise<void>
    uploadLogo: (e: any) => void
    getLogoGalleryItems: () => string[]
    getCustomUploadedLogo: () => string | null
    getSelectedLogo: () => string | null
    removeLogo: () => void
    setLogoPosition: (pos: LogoPositions) => void
    getLogoPosition: () => LogoPositions
    getIsLogoProcessorActive: () => boolean
    deleteCustomUploadedLogo: () => void
}

const useStateWrapper = (state: State<StateValues>): ConferenceRoomSettingsContext => {
    const { selectedDevice } = useVideoInputs()
    const { isBackgroundBlurSupported } = useBackgroundBlur()
    const { startVideoInputDevice } = useMeetingManager()
    const { isBackgroundReplacementSupported } = useBackgroundReplacement()

    return {
        getBackgroundGalleryItems: () => {
            return state.value.backgroundGalleryItems
        },
        getSelectedBackground: () => {
            return state.value.selectedBackground
        },
        setSelectedBackground: async (url?: string) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            let currentDevice = selectedDevice
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                if (isVideoTransformDevice(currentDevice)) {
                    const intrinsicDevice = await currentDevice.intrinsicDevice()
                    await currentDevice.stop()
                    currentDevice = intrinsicDevice
                }

                backgroundProcessor.setBackground("choose", url || "")

                if (backgroundProcessor)
                    state.set((prevState) => {
                        prevState.processors = backgroundProcessor
                        return prevState
                    })

                const logger = new ConsoleLogger("Background replace")
                let transofrmDevice = new DefaultVideoTransformDevice(
                    logger,
                    currentDevice as Device,
                    [backgroundProcessor, logoProcessor] as VideoFrameProcessor[]
                )
                state.merge({ selectedBackground: url, isBlurActive: false })
                await startVideoInputDevice(transofrmDevice)
            } catch (e: any) {
                console.error("Error trying to apply Background image", e)
            } finally {
                state.merge({ isLoading: false })
                document.body.style.cursor = "default"
            }
        },
        getCustomUploadedBackground: () => {
            return state.value.customUploadedBackground
        },
        uploadBackground: (e: any) => {
            let { files } = e.target
            let images: any = [],
                fileReaders: any = []
            let isCancel = false
            if (files.length) {
                files.forEach((file: any) => {
                    const fileReader = new FileReader()
                    fileReaders.push(fileReader)
                    fileReader.onload = (e: any) => {
                        const { result } = e.target
                        if (result) {
                            images.push(result)
                        }
                        if (images.length === files.length && !isCancel) {
                            state.set((prevState) => {
                                prevState.backgroundGalleryItems = [
                                    images[0],
                                    // previous gallery items but filtered the previous uploaded item out
                                    ...prevState.backgroundGalleryItems.filter(
                                        (item) => item !== prevState.customUploadedBackground
                                    )
                                ]
                                prevState.customUploadedBackground = images[0]

                                return prevState
                            })
                        }
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            return () => {
                isCancel = true
                fileReaders.forEach((fileReader: any) => {
                    if (fileReader.readyState === 1) {
                        fileReader.abort()
                    }
                })
            }
        },
        removeBackground: async () => {
            if (state.value.isLoading) return
            state.merge({ isLoading: true })
            backgroundProcessor.setBackground("none", null)
            state.merge({ selectedBackground: null, isBlurActive: false, isLoading: false })
        },
        deleteCustomBackground: async () => {
            if (state.value.isLoading || !selectedDevice) return
            if (state.value.customUploadedBackground === state.value.selectedBackground) {
                state.set((prevState) => {
                    prevState.selectedBackground = null
                    return prevState
                })
                let currentDevice = selectedDevice
                try {
                    state.merge({ isLoading: true })
                    document.body.style.cursor = "wait"
                    if (isVideoTransformDevice(currentDevice)) {
                        const intrinsicDevice = await currentDevice.intrinsicDevice()
                        await currentDevice.stop()
                        currentDevice = intrinsicDevice
                    }

                    backgroundProcessor.setBackground("none", null)

                    if (backgroundProcessor)
                        state.set((prevState) => {
                            prevState.processors = backgroundProcessor
                            return prevState
                        })

                    const logger = new ConsoleLogger("Background replace")
                    let transofrmDevice = new DefaultVideoTransformDevice(
                        logger,
                        currentDevice as Device,
                        [backgroundProcessor, logoProcessor] as VideoFrameProcessor[]
                    )
                    state.merge({ selectedBackground: null, isBlurActive: false })
                    await startVideoInputDevice(transofrmDevice)
                } catch (e: any) {
                    console.error("Error trying to apply Background image", e)
                } finally {
                    state.merge({ isLoading: false })
                    document.body.style.cursor = "default"
                }
            }
            state.set((prevState) => {
                prevState.backgroundGalleryItems = prevState.backgroundGalleryItems.filter(
                    (galleryItem) => galleryItem !== prevState.customUploadedBackground
                )
                return prevState
            })
        },
        toggleBlur: async () => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundBlurSupported) return
            let currentDevice = selectedDevice
            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                if (isVideoTransformDevice(currentDevice)) {
                    const intrinsicDevice = await currentDevice.intrinsicDevice()
                    await currentDevice.stop()
                    currentDevice = intrinsicDevice
                }

                if (!state.value.isBlurActive) {
                    backgroundProcessor.setBackground("blur", null)
                } else {
                    backgroundProcessor.setBackground("none", null)
                }

                if (backgroundProcessor)
                    state.set((prevState) => {
                        prevState.processors = backgroundProcessor
                        return prevState
                    })

                const logger = new ConsoleLogger("Background replace")
                let transofrmDevice = new DefaultVideoTransformDevice(
                    logger,
                    currentDevice as Device,
                    [backgroundProcessor, logoProcessor] as VideoFrameProcessor[]
                )

                await startVideoInputDevice(transofrmDevice)
            } catch (e: any) {
                console.error("Error trying to apply Background image", e)
            } finally {
                state.merge({ isBlurActive: !state.value.isBlurActive, isLoading: false, selectedBackground: null })
                document.body.style.cursor = "default"
            }
        },
        getIsBlurActive: () => {
            return state.value.isBlurActive
        },
        setSelectedLogo: async (url: string) => {
            if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
            const defaultPosition: Position = "topleft"
            let currentDevice = selectedDevice

            try {
                state.merge({ isLoading: true })
                document.body.style.cursor = "wait"
                if (isVideoTransformDevice(currentDevice)) {
                    const intrinsicDevice = await currentDevice.intrinsicDevice()
                    await currentDevice.stop()
                    currentDevice = intrinsicDevice
                }

                logoProcessor.setPosition(defaultPosition)
                logoProcessor.setSrc(url)

                state.set((prevState) => {
                    prevState.logoProcessor = logoProcessor
                    prevState.isLogoProcessorActive = true
                    return prevState
                })

                const logger = new ConsoleLogger("Logo")
                let transformDevice = new DefaultVideoTransformDevice(
                    logger,
                    currentDevice as Device,
                    [backgroundProcessor, logoProcessor] as LogoProcessor[]
                )
                await startVideoInputDevice(transformDevice)
                state.merge({ selectedLogo: url })
            } catch (e: any) {
            } finally {
                state.merge({ isLoading: false })
                document.body.style.cursor = "default"
            }
        },
        uploadLogo: (e: any) => {
            let { files } = e.target
            let images: any = [],
                fileReaders: any = []
            let isCancel = false
            if (files.length) {
                files.forEach((file: any) => {
                    const fileReader = new FileReader()
                    fileReaders.push(fileReader)
                    fileReader.onload = (e: any) => {
                        const { result } = e.target
                        if (result) {
                            images.push(result)
                        }
                        if (images.length === files.length && !isCancel) {
                            state.set((prevState) => {
                                prevState.logoGalleryItems = [
                                    images[0], // previous gallery items but filtered the previous uploaded item out
                                    ...prevState.logoGalleryItems.filter((item) => item !== prevState.customUploadedLogo)
                                ]
                                prevState.customUploadedLogo = images[0]
                                return prevState
                            })
                        }
                    }
                    fileReader.readAsDataURL(file)
                })
            }
            return () => {
                isCancel = true
                fileReaders.forEach((fileReader: any) => {
                    if (fileReader.readyState === 1) {
                        fileReader.abort()
                    }
                })
            }
        },
        getLogoGalleryItems: () => {
            return state.value.logoGalleryItems
        },
        getCustomUploadedLogo: () => {
            return state.value.customUploadedLogo
        },
        getSelectedLogo: () => {
            return state.value.selectedLogo
        },
        removeLogo: () => {
            state.set((prevState) => {
                prevState.logoProcessor?.setSrc(null)
                prevState.isLogoProcessorActive = false
                return prevState
            })
        },
        setLogoPosition: (pos: LogoPositions) => {
            state.set((prevState) => {
                prevState.logoProcessor?.setPosition(pos)
                prevState.selectedLogoPosition = pos
                return prevState
            })
        },
        getLogoPosition: () => {
            return state.value.selectedLogoPosition
        },
        getIsLogoProcessorActive: () => {
            return state.value.isLogoProcessorActive
        },
        deleteCustomUploadedLogo: async () => {
            if (state.value.isLoading || !selectedDevice) return
            if (state.value.customUploadedLogo === state.value.selectedLogo) {
                state.set((prevState) => {
                    prevState.selectedLogo = null
                    return prevState
                })
                if (state.value.isLoading || selectedDevice === undefined || !isBackgroundReplacementSupported) return
                let currentDevice = selectedDevice
                try {
                    state.merge({ isLoading: true })
                    document.body.style.cursor = "wait"
                    if (isVideoTransformDevice(currentDevice)) {
                        const intrinsicDevice = await currentDevice.intrinsicDevice()
                        await currentDevice.stop()
                        currentDevice = intrinsicDevice
                    }
                    logoProcessor.setPosition(defaultPosition)
                    logoProcessor.setSrc(null)
                    state.set((prevState) => {
                        prevState.logoProcessor = logoProcessor
                        prevState.isLogoProcessorActive = true
                        return prevState
                    })
                    const logger = new ConsoleLogger("Logo")
                    let transformDevice = new DefaultVideoTransformDevice(
                        logger,
                        currentDevice as Device,
                        [backgroundProcessor, logoProcessor] as LogoProcessor[]
                    )
                    await startVideoInputDevice(transformDevice)
                    state.merge({ selectedLogo: null })
                } catch (e: any) {
                } finally {
                    state.merge({ isLoading: false })
                    document.body.style.cursor = "default"
                }
            }
            state.set((prevState) => {
                prevState.logoGalleryItems = prevState.logoGalleryItems.filter(
                    (galleryItem) => galleryItem !== prevState.customUploadedLogo
                )
                return prevState
            })
        }
    }
}

export const useConferenceRoomSettingsContext = (): ConferenceRoomSettingsContext => useStateWrapper(useState(state))
