import { GTMEventType } from "../branding/GTMBranding"
import branding from "../branding/branding"
import { getMyIdFromLocalStorage, getMyTypeFromLocalStorage } from "../globalStates/LoggedInUser"

declare global {
    interface Window {
        dataLayer: any[] | undefined
    }
}

/* #region MAIN FUNCTION */

export function saveToDataLayer(data: any) {
    if (branding.gtmConfig.scriptDataLayer) {
        let gaEvent: any = {
            ...data,
            website_type: "Web",
            login_status: getLoggedInState(),
            customer_type: getCustomerType()
        }

        if (getFairNumber()) {
            gaEvent = { ...gaEvent, exhibition_number: getFairNumber() }
        }

        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(gaEvent)
    }
    if ("true" === localStorage.getItem("virtualguide-gtm-debug")) {
        console.log("GTM track", data)
    }
}

/* #endregion */

/* #region EVENTS */

export interface LoginEvent {}

export function trackLogin() {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.login)) return
}

export interface SearchEvent {}

export function trackSearch() {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.search)) return
}

export type SelectContentEventCategory = "Entity link" | "Button" | "Web link" | "Text" | "Label" | "Promotion"

export interface SelectContentEvent {
    item_id: string
    item_name: string
    category: string
    content_type: string
    page_url: string
    group_id?: string
}

export function trackSelectContent(
    itemType: string,
    itemId: string,
    itemName: string,
    category: SelectContentEventCategory,
    contentType: string,
    groupId?: string
) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.select_content)) return

    let event: SelectContentEvent = {
        item_id: itemType + "/" + itemId,
        item_name: itemName,
        category: category,
        content_type: contentType,
        page_url: window.location.pathname
    }

    if (groupId) {
        event = { ...event, group_id: groupId }
    }

    saveToDataLayer(event)
}

export type PageViewEventCategory = "Home" | "List" | "Detail"
export interface PageViewEvent {
    page_URL: string
    page_title: string
    category: PageViewEventCategory
    content_type: string
}

let lastNavigation: string | undefined

export function trackPageView(pageTitle: string, category: PageViewEventCategory, contentType: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.page_view) || !pageTitle) return

    const newNavigation = window.location.pathname + pageTitle
    if (newNavigation === lastNavigation) return

    lastNavigation = newNavigation

    let event: PageViewEvent = {
        page_URL: window.location.pathname,
        page_title: pageTitle,
        category: category,
        content_type: contentType
    }

    saveToDataLayer(event)
}

export interface ShareEvent {
    shared_item_id: string
    shared_item_name: string
    content_type: string
    sharing_method: string
}

export function trackShare(sharedItemId: string, sharedItemName: string, contentType: string, sharingMethod: string) {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.share)) return

    let event: ShareEvent = {
        shared_item_id: sharedItemId,
        shared_item_name: sharedItemName,
        content_type: contentType,
        sharing_method: sharingMethod
    }

    saveToDataLayer(event)
}

export interface ViewPromotionEvent {}

export function trackViewPromotion() {
    if (!branding.gtmConfig.eventTypes.includes(GTMEventType.view_promotion)) return
}

/* #endregion */

/* #region UTILITIES */

function getLoggedInState(): string {
    return getMyIdFromLocalStorage() ? branding.gtmConfig.loginStates.loggedIn : branding.gtmConfig.loginStates.loggedOut
}

function getFairNumber(): string | undefined {
    return branding.gtmConfig.fairNumber || undefined
}

function getCustomerType(): string {
    const myType = getMyTypeFromLocalStorage()
    if (!myType) {
        return branding.gtmConfig.customerTypes.other
    }
    switch (myType) {
        case "speaker":
        case "staff":
            return branding.gtmConfig.customerTypes.staff
        case "press":
            return branding.gtmConfig.customerTypes.press
        case "guest":
            return branding.gtmConfig.customerTypes.guest
        case "attendee":
        case "none":
            return branding.gtmConfig.customerTypes.attendee
        default:
            return branding.gtmConfig.customerTypes.other
    }
}

/**
 * https://corussoft.atlassian.net/wiki/spaces/eventguide/pages/9466937442/TagManager+and+SSO
 * @returns
 */
export function generateGlParam() {
    const cookies: { [key in string]: string } = Object.fromEntries(document.cookie.split("; ").map((c) => c.split("=")))

    const modifiedCookies: { [key in string]: string } = {}
    for (const key in cookies) {
        // Only use TagManager Cookies
        if (key !== "_ga" && !key.startsWith("_ga_")) continue

        const cookieValue = cookies[key]
        let newCookieValue = cookieValue

        // We want everything after the second dot
        newCookieValue = newCookieValue.substring(cookieValue.indexOf("."))
        newCookieValue = newCookieValue.substring(cookieValue.indexOf("."))

        modifiedCookies[key] = newCookieValue
    }

    if (!(window as any).google_tag_data?.glBridge?.generate) {
        console.warn("window.google_tag_data.glBridge.generate missing. Analytics maybe not working?")
        return ""
    }
    const glToken = (window as any).google_tag_data.glBridge.generate(modifiedCookies)
    return glToken
}

/* #endregion */

/* #region LEGACY FUNCTIONS */

export function trackCheckIn(kind: "Erfolgreich" | "Kein Ticket" | "Fehlermeldung") {
    // DO NOT translate those values.
    saveToDataLayer({
        event: "gaEvent",
        eventCategory: "Check-in",
        eventAction: "DEP",
        eventLabel: kind,
        check_in_DEP: {
            action: kind
        }
    })
}

/* #endregion */
