import { useEffect, useState } from "react"
import * as React from "react"
// Awesome icons
import styled from "styled-components"
import branding, { Branding } from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLanguageState } from "../../globalStates/LanguageState"
import Breadcrumb from "../../navigationArea/Breadcrumb"
import TopBar from "../../navigationArea/TopBar"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import CrsTabs, { ActionTypes, getUserDefaultViewMode, TabItemType, ViewMode } from "../../ui/CrsTabs"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { device } from "../../utils/Device"
import { trackPageView } from "../../utils/GTMTracking"
import TopBanner from "../advertisingBanner/TopBanner"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import Schedule from "./Schedule"
import Speakers from "./Speakers"
import { useHistory } from "react-router-dom"
import { PagesUsingTileView } from "../../globalStates/TileViewConfig"
import { programPageRoute, speakersPageRoute } from "../../navigationArea/RoutePaths"
import { History } from "history"
import CategoriesSlider, { CATEGORIES_SLIDER_PREDEFINED_HEIGHT } from "../../ui/slider/CategoriesSlider"
import { CategoryConfigType } from "../ContentAreaPageBranding"

export enum ProgramPageTab {
    SCHEDULE = "schedule",
    SPEAKERS = "speakers"
}

/* #region  HELPER METHODS */
const getTabPath = (tabValue: string) => {
    switch (tabValue) {
        case ProgramPageTab.SCHEDULE:
            return programPageRoute
        case ProgramPageTab.SPEAKERS:
            return speakersPageRoute
        default:
            return `${programPageRoute}#${tabValue}`
    }
}

const getAdditionalTabConfig = (selectedTab: string) => {
    return branding.programPageContent.additionalScheduleTabs.find((additionalTab) => additionalTab.value === selectedTab)
}

const getCurrentTab = (lastVisitedTab: string | null) => {
    const additionalTabs = branding.programPageContent.additionalScheduleTabs.map(
        (additionalScheduleTab) => additionalScheduleTab.value
    )
    const itemsOrder = branding.programPageContent.crsTabsItemOrder
    const locationHref = window.location.hash.slice(1) // remove hash(#) at the beginning to get real tab name

    if (locationHref.includes(ProgramPageTab.SPEAKERS) || additionalTabs.includes(locationHref)) return locationHref
    else if (
        lastVisitedTab &&
        (lastVisitedTab === ProgramPageTab.SCHEDULE ||
            lastVisitedTab === ProgramPageTab.SPEAKERS ||
            additionalTabs.includes(lastVisitedTab))
    )
        return lastVisitedTab

    return itemsOrder[0].toLowerCase()
}

const createTabItems = () => {
    const itemsOrder = branding.programPageContent.crsTabsItemOrder
    const tabItems: TabItemType[] = []

    itemsOrder.forEach((item: any) => {
        let newItem
        switch (item) {
            case "SCHEDULE":
                newItem = {
                    value: ProgramPageTab.SCHEDULE,
                    label: branding.programPageContent.scheduleTabTitle,
                    actions: [ActionTypes.TILELIST, ActionTypes.BOOKMARK, ActionTypes.TOGGLEFILTERS]
                }
                break
            case "SPEAKERS":
                newItem = {
                    value: ProgramPageTab.SPEAKERS,
                    label: branding.programPageContent.speakersTabTitle,
                    actions: [ActionTypes.TILELIST, ActionTypes.BOOKMARK]
                }
                break
            default:
                const tabConfig = getAdditionalTabConfig(item)
                if (tabConfig) {
                    newItem = {
                        value: tabConfig.value,
                        label: tabConfig.label,
                        actions: tabConfig.actions
                    }
                }
        }
        if (newItem) tabItems.push(newItem)
    })

    return tabItems
}

/**
 * Read ViewMode section on the link below for detailed explanation
 * @link https://corussoft.atlassian.net/wiki/spaces/eventguide/pages/9513664513/Filters+persistence
 * @returns initial view mode for selected tab
 */
const getInitialViewMode = (history: History, currentPage: string, lastVisitebTab: string | null, activeTab: string) => {
    // View Mode from history state
    const viewModeFromState = (history.location.state as ProgramHistoryState)?.viewMode
    if (viewModeFromState) return viewModeFromState

    // User View Mode from local storage
    const userDefaultView = getUserDefaultViewMode(currentPage, lastVisitebTab ?? activeTab) ?? null
    if (userDefaultView !== null) return userDefaultView

    // Default View Mode
    let listViewAsDefault = false
    switch (activeTab) {
        case ProgramPageTab.SCHEDULE:
            listViewAsDefault = branding.programSchedule.listViewAsDefault
            break
        case ProgramPageTab.SPEAKERS:
            listViewAsDefault = false
            break
        default:
            const additionalTabConfig = getAdditionalTabConfig(activeTab)
            listViewAsDefault = additionalTabConfig?.listViewAsDefault || false
            break
    }
    return listViewAsDefault ? ViewMode.LIST : ViewMode.TILES
}

const getSubNavigationCategories = (selectedTab: string, branding: Branding): CategoryConfigType[] => {
    switch (selectedTab) {
        case ProgramPageTab.SPEAKERS:
            return []
        case ProgramPageTab.SCHEDULE:
            return branding.programSchedule.mainProgramSubNavigationConfig
        default:
            return getAdditionalTabConfig(selectedTab)?.subNavigationConfig || []
    }
}

/* #endregion */

export interface StageFilter {
    stage: string
    filter: string
}

export interface CategoryFilter {
    label: string
    value: string
}

export type MyScheduleType = "eventdate" | "meeting"
export interface MyScheduleTypeFilter {
    label: string
    value: MyScheduleType
}

export interface ProgramHistoryState {
    viewMode?: number
    activeSubCategory?: CategoryConfigType
    showOnlyBookmarks?: boolean
    stageFilter?: StageFilter | null
    badgeFilterValues?: CategoryFilter[] | null
    firstCategoryFilterValues?: CategoryFilter[] | null
    secondCategoryFilterValues?: CategoryFilter[] | null
    searchParam?: string
    selectedDateIndex?: number
    myScheduleTypeFilter?: MyScheduleTypeFilter | null
}
const NavigationAreaRoot = styled.div<{ showShadow?: boolean }>`
    margin-top: 20px;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-bottom: 25px;
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "none")};
    z-index: 10;
    @media ${device.mobile} {
        margin-top: 0px;
    }
`

const ProgramPageContent: React.FC = (props) => {
    const appState = useAppState()
    const history = useHistory()

    const langState = useLanguageState()
    const lang = langState.getLanguage()
    const [multiSwitchChoice, setMultiSwitchChoice] = useState(getCurrentTab(appState.lastVisitedTab))

    const tabItems: TabItemType[] = createTabItems()

    // Add banner state
    const { showBanner, setShowBanner, topBarRef, setTopBarRef, hideOnScroll, setHideOnScroll } = TopBannerSharedState()
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const guestBannerHeight = guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0
    const locations = calcBreadcrumbLocations()

    const [addBannerHeight, setAddBannerHeight] = useState<number>(
        topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0
    )

    const breadcrumb = [
        { to: locations[0], name: branding.programPageContent.programPageTitle },
        {
            to: "/" + multiSwitchChoice,
            name:
                tabItems.find((tabItem) => tabItem.value === multiSwitchChoice)?.label ||
                branding.programPageContent.scheduleTabTitle
        }
    ]

    const [viewMode, setViewMode] = useState<ViewMode>(
        getInitialViewMode(history, appState.currentItem, appState.lastVisitedTab, multiSwitchChoice)
    )
    const [showOnlyBookmarks, setShowOnlyBookmarks] = useState<boolean>(
        (history.location.state as ProgramHistoryState)?.showOnlyBookmarks ?? false
    )
    const [filtersVisible, setFiltersVisible] = useState<boolean>(true)
    const subNavigationCategories = getSubNavigationCategories(multiSwitchChoice, branding)
    const [activeSubCategory, setActiveSubCategory] = useState<CategoryConfigType | undefined>(
        (history.location.state as ProgramHistoryState)?.activeSubCategory ?? subNavigationCategories[0]
    )

    const { useMobileDesign } = useWindowDimensions()

    function setMobileBreadcrumb() {
        appState.setCurrentMobileBreadcrumb(
            tabItems.find((tabItem) => tabItem.value === multiSwitchChoice)?.label ?? branding.programPageContent.scheduleTabTitle
        )
    }

    useEffect(() => {
        setViewMode(getInitialViewMode(history, appState.currentItem, appState.lastVisitedTab, multiSwitchChoice))
        setActiveSubCategory((history.location.state as ProgramHistoryState)?.activeSubCategory ?? subNavigationCategories[0])
        //eslint-disable-next-line
    }, [appState.currentItem, multiSwitchChoice])

    useEffect(() => {
        setMobileBreadcrumb()
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        setHideOnScroll(false)
        setMobileBreadcrumb()

        const trackingLocation = tabItems.find((tabItem) => tabItem.value === multiSwitchChoice)?.label
        trackPageView(
            branding.programPageContent.programPageTitle + " - " + trackingLocation,
            "List",
            branding.programPageContent.programPageTitle
        )

        // eslint-disable-next-line
    }, [multiSwitchChoice])

    useEffect(() => {
        const setBannerHeight = setTimeout(
            () => {
                const subNavigationHidden = !subNavigationCategories || subNavigationCategories.length === 0

                const topBarHeight = topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0
                const subNavigationHeight = subNavigationHidden ? 0 : CATEGORIES_SLIDER_PREDEFINED_HEIGHT
                setAddBannerHeight(topBarHeight + subNavigationHeight)
            },
            viewMode === ViewMode.LIST ? 0 : 300
        )

        return () => clearTimeout(setBannerHeight)

        // eslint-disable-next-line
    }, [hideOnScroll, topBarRef, multiSwitchChoice])

    const content: JSX.Element =
        multiSwitchChoice !== ProgramPageTab.SPEAKERS ? (
            <Schedule
                onlyFavorites={showOnlyBookmarks}
                listViewMode={viewMode === ViewMode.LIST}
                onScroll={(scrollValues: any) => {
                    if (filtersVisible && useMobileDesign) {
                        setHideOnScroll(false)
                    } else if (showBanner) {
                        if (scrollValues.scrollHeight > scrollValues.clientHeight) {
                            setHideOnScroll(scrollValues.scrollTop > 20)
                        } else {
                            setHideOnScroll(true)
                        }
                    }
                }}
                addBannerHeight={addBannerHeight}
                guestBannerHeight={guestBannerHeight}
                filtersVisible={filtersVisible}
                activeTab={multiSwitchChoice}
                activeSubCategory={activeSubCategory?.rootCategoryAlias}
            />
        ) : (
            <Speakers
                showOnlyFavorites={showOnlyBookmarks}
                listViewMode={viewMode === ViewMode.LIST}
                onScroll={(scrollValues: any) => {
                    if (showBanner) {
                        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
                            setHideOnScroll(scrollValues.scrollTop > 20)
                        } else {
                            setHideOnScroll(true)
                        }
                    }
                }}
                addBannerHeight={
                    !hideOnScroll && showBanner && topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0
                }
                guestBannerHeight={guestBannerHeight}
            />
        )

    return (
        <div style={{ backgroundColor: "white" }}>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar />
            <TopBanner
                type="program"
                setRef={setTopBarRef}
                hideOnScroll={hideOnScroll}
                onHideBanner={() => {
                    setShowBanner(false)
                    setAddBannerHeight(0)
                }}
            />
            <Breadcrumb breadcrumb={breadcrumb} disableGTMTracking pageCategory="List" />
            <NavigationAreaRoot
                style={{ backgroundColor: "transparent" }}
                showShadow={multiSwitchChoice === ProgramPageTab.SPEAKERS ? hideOnScroll : false}
            >
                <CrsTabs
                    tabItems={tabItems}
                    activeItem={multiSwitchChoice}
                    onTabItemClicked={(value: any) => {
                        setMultiSwitchChoice(value)
                        appState.setLastVisitedTab(value)
                        const tabPath = getTabPath(value)
                        history.replace(tabPath, {
                            showOnlyBookmarks: showOnlyBookmarks
                        })
                    }}
                    viewMode={viewMode}
                    onViewModeChange={(viewMode) => {
                        setViewMode(viewMode)
                        history.replace(`${history.location.pathname}${history.location.hash}`, {
                            ...(history.location.state as ProgramHistoryState),
                            viewMode: viewMode
                        })
                    }}
                    bookmarkFilter={showOnlyBookmarks}
                    mobileItemWidth={lang === "en" ? 120 : 150}
                    setBookmarkFilter={(onlyBookmarks: boolean) => {
                        setShowOnlyBookmarks(onlyBookmarks)
                        history.replace(`${history.location.pathname}${history.location.hash}`, {
                            ...(history.location.state as ProgramHistoryState),
                            showOnlyBookmarks: onlyBookmarks,
                            stageFilter: null
                        })
                    }}
                    filtersVisible={filtersVisible}
                    setFiltersVisible={setFiltersVisible}
                    pageUsingTileView={PagesUsingTileView.SCHEDULE}
                    style={{ position: "relative", backgroundColor: "#fff", zIndex: 2 }}
                />

                {/* Categories sub-navigation displayed only for program tabs */}
                {multiSwitchChoice !== ProgramPageTab.SPEAKERS && subNavigationCategories.length > 0 && (
                    <CategoriesSlider
                        hideOnScroll={false}
                        filtersVisible={filtersVisible}
                        categoriesConfiguration={subNavigationCategories}
                        activeSubCategory={activeSubCategory}
                        onSliderItemClick={(category: CategoryConfigType, viewMode: ViewMode) => {
                            setActiveSubCategory(category)
                            history.replace(`${history.location.pathname}${history.location.hash}`, {
                                ...(history.location.state as ProgramHistoryState),
                                activeSubCategory: (category as any).jsonData
                            })
                        }}
                    />
                )}
            </NavigationAreaRoot>
            <PageContent>{content}</PageContent>
        </div>
    )
}
export default ProgramPageContent

const PageContent = styled.div``
