import { darkTheme } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import branding from "../../../branding/branding"
import { useDeviceController } from "../../../conference/context/DeviceController"
import { DeviceSelect, SettingsLabel } from "../Settings/SettingsStyles"

function SpeakerSelection() {
    const deviceController = useDeviceController()
    return (
        <>
            <SettingsLabel>{branding.audioVideoSettings.audioOutputLabel}</SettingsLabel>
            <DeviceSelect
                options={deviceController.getAudioOutputDevices()}
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                theme={darkTheme}
                value={deviceController.getSelectedAudioOutputDevice()}
                placeholder={deviceController.getSelectedAudioOutputDevice()}
                onChange={async (device: DeviceType) => {
                    deviceController.setSelectedAudioOutputDevice(device.deviceId)
                }}
            />
        </>
    )
}

export default SpeakerSelection
