import styled from "styled-components"

export const RemoteVideoMiniRoot = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

export const RemoteVideoOverlay = styled.div<{ isMouseHovering: boolean }>`
    transition: 0.3s;
    display: ${(props) => (props.isMouseHovering ? "block" : "none")};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    cursor: pointer;
`
