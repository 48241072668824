import styled from "styled-components"

export const HailingOverlayRoot = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1100;
`

export const MeetingNotificationModalRoot = styled.div`
    width: 260px;
    height: 300px;
    background-color: #202428;
    border-radius: 10px;
    float: right;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    color: #fff;

    opacity: 0;
    transition: all 0.3s ease-in;
    opacity: 1;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
`
export const CustomRow = styled.div``

export const MeetingNotificationTitle = styled.div`
    color: #7c7a7a;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 10px;
`

export const MeetingNotificationCallerName = styled.div`
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
`

export const ActionButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`

export const ActionButtonRoot = styled.div<{
    isActive?: boolean
    backgroundColor?: string
}>`
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#ffffff26")};
    width: 50px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    margin: 7px;

    :hover {
        background-color: #ffffff80;
        background-color: ${(props) => (props.backgroundColor ? "#aa050e" : "#ffffff80")};
    }
`
export const CallAnimationWrapper = styled.div`
    position: relative;
    /* overflow: hidden; */
    border-radius: 50%;
    width: auto;
    height: auto;
    padding: 10px;
`

export const CallAnimation = styled.div`
    border-radius: 50%;
    background-color: #0e9900;
    animation: callAnimation 2s linear infinite;
    @keyframes callAnimation {
        50% {
            box-shadow: -1px 1px 16px 7px #0e9900;
        }
    }
`

export const IconAvatarWrapper = styled.div`
    background-color: #c9c9c9;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const MeetingInvitationTextBox = styled.div`
    color: #fff;
    font-size: 14px;
    text-align: center;
    min-height: 80px;
`

export const BoldedText = styled.span`
    font-weight: 700;
`

export const MeetingInvitationJoinButton = styled.div`
    background-color: #414447;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0px;
    top: 250px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: 20px;
`

export const IconWrapper = styled.div`
    margin-right: 8px;
`
export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
`
