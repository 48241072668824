import { PointItem, PointRoot } from "./PointStyles"

function Point() {
    return (
        <PointRoot>
            <PointItem className={"animatePoint"} />
        </PointRoot>
    )
}

export default Point
