import { useState, useRef, ChangeEvent, useEffect } from "react"
import * as React from "react"
import styled, { css } from "styled-components"
import { IconCheckmark, IconEdit, IconClose, IconCloseParticipants } from "../ui/Icons"
import { Spinner, Tooltip } from "react-bootstrap"
import SearchPersonInput from "./SearchPersonInput"
import { Contact } from "../backendServices/Types"
import { ConversationParticipant } from "./ChatPage"
import AvatarWithPresenceState from "../ui/AvatarWithPresenceState"
import moment from "moment"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import ParticipantList from "../ui/ParticipationList"
import { useLanguageState } from "../globalStates/LanguageState"
import { ConversationType } from "../API"
import { findCalendarEntryById, CalendarEntry, sendChatMessage } from "../backendServices/GraphQLServices"
import { CalendarEntryModalViewMode } from "../ui/modals/CalendarEntryModal"
import ContentLoader from "react-content-loader"
import branding from "../branding/branding"
import { CustomOverlayTrigger } from "../ui/CustomOverlayTrigger"
import CalendarEntryModal2 from "../ui/modals/calendarEntry/CalendarEntryModal2"

const ConversationDetailsWrapper = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
`
const ConversationDetailsContainer = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
`
const DetailsSectionTitle = styled.div`
    margin-left: 16px;
    font-size: 12px;
`
const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin-left: 16px;
    padding-right: 4px;
    /* border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"}; */
    line-height: 35px;
`
const RoundedButton = styled.button`
    flex: 0 0 auto;
    margin: 0 16px;
    padding: 7px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: hsl(0, 0%, 100%);
    transition: 0.5s;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  border: 1px solid hsl(0, 0%, 60%);
                  background-color: hsl(0, 0%, 95%);
                  color: hsl(0, 0%, 60%);
                  transition-property: none;
              `
            : css`
                  &:hover {
                      background-color: hsl(0, 0%, 90%);
                  }
              `};
`
const RedRoundedButton = styled(RoundedButton)`
    border: 1px solid;
    border-color: ${branding.primaryColor ?? "black"};
    color: ${branding.primaryColor ?? "black"};
    &:hover {
        background-color: hsl(0, 100%, 90%);
    }
`
const ParticipantsListContainer = styled.div`
    margin: 16px 0;
    flex-grow: 1;
    overflow-y: auto;
`
const BottomButton = styled.div`
    cursor: pointer;
    border-bottom: 1px solid;
    border-color: ${branding.primaryColor ?? "black"};
    align-self: center;
    margin-bottom: 8px;
`
const BottomDialog = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: white;
    height: 200px;
    width: 100%;
    bottom: -200px;
    transition: 0.2s ease-out;
    border-top: 1px solid #000;

    &.showDialog {
        bottom: 0px;
    }
`

enum ConvFieldStatus {
    LOCKED,
    EDITABLE,
    PENDING
}

interface ConversationDetailsProps {
    conversationId: string
    conversationType: string
    conversationName?: string
    conversationDesc?: string
    opponents: ConversationParticipant[]
    isMuted: boolean
    closeDetails: () => void
    addOpponents: (opponents: ConversationParticipant[]) => Promise<boolean>
    removeOpponent?: (opponent?: ConversationParticipant) => Promise<boolean>
    setConversationName: (newName: string) => Promise<string | undefined>
    setConversationDesc: (newDesc: string) => Promise<string | undefined>
    exitGroup: () => Promise<boolean>
    muteGroup: () => Promise<boolean>
    maxParticipants: number
    admin?: boolean
}

const ConversationDetails: React.FunctionComponent<ConversationDetailsProps> = (props) => {
    if (props.conversationType === ConversationType.GROUP || props.conversationType === ConversationType.CALL) {
        return <GroupConversationDetails {...props} />
    } else if (props.conversationType === ConversationType.CALENDARENTRY) {
        return <CalendarEntryConversationDetails {...props} />
    }
    return <div>Error</div>
}

export const GroupConversationDetails: React.FunctionComponent<ConversationDetailsProps> = (props) => {
    const myProfile = useLoggedInState().user()!
    const me = {
        id: myProfile.profileId,
        name: [myProfile.firstName, myProfile.lastName].filter(Boolean).join(" "),
        pictureUrl: myProfile.logoUrl,
        position: myProfile.position,
        organization: myProfile.company
    }
    const [showBottomDialog, setShowBottomDialog] = useState(false)
    const [stagedOpponents, setStagedOpponents] = useState<ConversationParticipant[]>(props.conversationId ? [] : props.opponents)
    const [addingOpponents, setAddingOpponents] = useState(false)

    const sendSystemMessage = async (action: string, userId: string) => {
        const message = await sendChatMessage(props.conversationId, me.id, action + "/" + userId) // eslint-disable-line @typescript-eslint/no-unused-vars
    }

    const onPersonSelected = (person: Contact) => {
        const newOpponent = {
            id: person.id,
            name: [person.firstName, person.lastName].join(" "),
            pictureUrl: person.logoUrl,
            position: person.position,
            organization: person.organization
        }
        setStagedOpponents(stagedOpponents.concat([newOpponent]))
    }

    const onAddParticipantsClicked = async () => {
        if (props.conversationId && props.addOpponents) {
            setAddingOpponents(true)
            const success = await props.addOpponents(stagedOpponents)
            if (success) {
                setStagedOpponents([])
                stagedOpponents.forEach((opponent: ConversationParticipant) => {
                    sendSystemMessage("systemAdded", opponent.name)
                })
            } else {
                // TODO error handling
            }
            setAddingOpponents(false)
        } else {
            setStagedOpponents([])
        }
    }

    const onExitGroupClicked = async () => {
        if (props.exitGroup) {
            const result = await props.exitGroup()
            if (!result) {
                // TODO error handling
            }
        }
    }

    const onMuteGroupClicked = async () => {
        if (props.muteGroup) {
            const result = await props.muteGroup()
            setShowBottomDialog(false)
            if (!result) {
                // TODO error handling
            }
        }
    }

    const makeSubtitle = (participant: ConversationParticipant) => {
        return [participant.position, participant.organization]
            .filter(Boolean)
            .join(" " + branding.communicationArea.personCompanyLink + " ")
    }

    const participantsSectionTitle =
        branding.chatBranding.participantsText + " (" + (props.opponents.length + 1) + " / " + props.maxParticipants + ")"
    const addParticipantsButtonDisabled =
        stagedOpponents.length <= 0 || stagedOpponents.length + props.opponents.length >= props.maxParticipants || addingOpponents
    const participants = props.opponents.concat([me]).map((participant) => {
        return {
            id: participant.id,
            name: participant.name,
            pictureUrl: participant.pictureUrl,
            subtitle: makeSubtitle(participant)
        }
    })

    return (
        <ConversationDetailsWrapper>
            <ConversationDetailsContainer>
                <DetailsSectionTitle>{branding.chatBranding.detailsText}</DetailsSectionTitle>
                <LockableInput
                    defaultValue={props.conversationName}
                    placeholder={branding.chatBranding.setGroupNameText}
                    onSubmit={props.setConversationName!}
                    maxLength={50}
                />
                <LockableInput
                    defaultValue={props.conversationDesc}
                    placeholder={branding.chatBranding.addGroupDescriptionText}
                    onSubmit={props.setConversationDesc!}
                    maxLength={150}
                    style={{ marginTop: "8px" }}
                />
                <StagedOpponentsArea
                    opponents={stagedOpponents}
                    unstageOpponent={(unstageMe) => setStagedOpponents(stagedOpponents.filter((op) => op.id !== unstageMe.id))}
                />
                <StyledSearchPersonInput
                    placeholder={branding.chatBranding.findParticipantsText}
                    personClicked={onPersonSelected}
                    disableIds={props.opponents.concat(stagedOpponents).map((p) => p.id)}
                    disabledText={branding.chatBranding.disableAddedText}
                />
                <RoundedButton
                    style={{ marginTop: "24px" }}
                    onClick={onAddParticipantsClicked}
                    disabled={addParticipantsButtonDisabled}
                >
                    {branding.chatBranding.addParticipantsText}
                </RoundedButton>
                <DetailsSectionTitle style={{ marginTop: "24px" }}>{participantsSectionTitle}</DetailsSectionTitle>
                <ParticipantsListContainer>
                    <ParticipantList participants={participants} admin={props.admin} removeOpponent={props.removeOpponent} />
                </ParticipantsListContainer>
                <BottomButton onClick={() => setShowBottomDialog(true)}>{branding.chatBranding.exitText}</BottomButton>
            </ConversationDetailsContainer>
            <BottomDialog className={showBottomDialog ? "showDialog" : ""}>
                <BottomDialogContent
                    conversationName={props.conversationName}
                    closeDialog={() => setShowBottomDialog(false)}
                    exitGroup={onExitGroupClicked}
                    isMuted={props.isMuted}
                    muteGroup={onMuteGroupClicked}
                />
            </BottomDialog>
        </ConversationDetailsWrapper>
    )
}

const CalendarEntryDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 70px;

    &.active {
        cursor: pointer;
    }
    &.active:hover {
        background-color: rgba(240, 240, 240, 1);
    }
`
const CalendarEntryTitle = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
`
const CalendarEntryTime = styled.div`
    margin-top: 4px;
    font-size: 12px;
`

interface CalendarEntryDetailsProps {
    conversationId: string
    conversationName?: string
    opponents: ConversationParticipant[]
    isMuted: boolean
    closeDetails: () => void
    exitGroup: () => Promise<boolean>
    muteGroup: () => Promise<boolean>
}

export const CalendarEntryConversationDetails: React.FunctionComponent<CalendarEntryDetailsProps> = (props) => {
    const myProfile = useLoggedInState().user()!
    const me = {
        id: myProfile.profileId,
        name: [myProfile.firstName, myProfile.lastName].filter(Boolean).join(" "),
        pictureUrl: myProfile.logoUrl,
        position: myProfile.position,
        organization: myProfile.company
    }
    const [calendarEntry, setCalendarEntry] = useState<CalendarEntry | undefined | null>(null)
    const [showCreateCalendarEntry, setShowCreateCalendarEntry] = useState(false)

    useEffect(() => {
        ;(async () => {
            const calendarEntry = await findCalendarEntryById(props.conversationId)
            setCalendarEntry(calendarEntry)
        })()
    }, [props.conversationId])

    // const onExitGroupClicked = async () => {
    //     if (props.exitGroup) {
    //         const result = await props.exitGroup()
    //         if (!result) {
    //             // TODO error handling
    //         }
    //     }
    // }

    // const onMuteGroupClicked = async () => {
    //     if (props.muteGroup) {
    //         const result = await props.muteGroup();
    //         setShowBottomDialog(false)
    //         if (!result) {
    //             // TODO error handling
    //         }
    //     }
    // }

    const makeSubtitle = (participant: ConversationParticipant) => {
        return [participant.position, participant.organization]
            .filter(Boolean)
            .join(" " + branding.communicationArea.personCompanyLink + " ")
    }

    const participantsSectionTitle = branding.chatBranding.participantsText + " (" + (props.opponents.length + 1) + ")"
    const participants = props.opponents.concat([me]).map((participant) => {
        return {
            id: participant.id,
            name: participant.name,
            pictureUrl: participant.pictureUrl,
            subtitle: makeSubtitle(participant)
        }
    })

    // TODO make update of conversation details work properly after the CalendarEntry changed (conversation name & participants)
    const viewMode = CalendarEntryModalViewMode.VIEW //calendarEntry?.userId === myProfile.profileId ? CalendarEntryModalViewMode.EDIT : CalendarEntryModalViewMode.VIEW
    return (
        <ConversationDetailsWrapper>
            <ConversationDetailsContainer>
                <DetailsSectionTitle>{branding.chatBranding.titleMeeting}</DetailsSectionTitle>
                <CalendarEntryDetails className={calendarEntry ? "active" : ""} onClick={() => setShowCreateCalendarEntry(true)}>
                    <CalendarEntryContent calendarEntry={calendarEntry} notFoundText={branding.chatBranding.meetingCancelled} />
                </CalendarEntryDetails>
                <DetailsSectionTitle style={{ marginTop: "24px" }}>{participantsSectionTitle}</DetailsSectionTitle>
                <ParticipantsListContainer>
                    <ParticipantList participants={participants} />
                </ParticipantsListContainer>
                {/* No leaving the chat here for the moment to prevent inconsistencies (leaving meeting creator cannot come back into the chat) */}
                {/* <BottomButton onClick={() => setShowBottomDialog(true)}>{branding.chatBranding.exitText}</BottomButton> */}
            </ConversationDetailsContainer>
            {/* <BottomDialog className={showBottomDialog ? "showDialog" : ""}>
            <BottomDialogContent conversationName={props.conversationName} closeDialog={() => setShowBottomDialog(false)} exitGroup={onExitGroupClicked} isMuted={props.isMuted} muteGroup={onMuteGroupClicked} />
        </BottomDialog> */}
            {showCreateCalendarEntry && (
                <CalendarEntryModal2
                    viewMode={viewMode}
                    calendarEntry={calendarEntry ?? undefined}
                    close={() => {
                        setShowCreateCalendarEntry(false)
                    }}
                />
            )}
        </ConversationDetailsWrapper>
    )
}

interface CalendarEntryContentProps {
    calendarEntry?: CalendarEntry | null
    notFoundText: string
}

const CalendarEntryContent: React.FC<CalendarEntryContentProps> = (props) => {
    if (props.calendarEntry === undefined) {
        return <CalendarEntryTitle>{props.notFoundText}</CalendarEntryTitle>
    } else if (props.calendarEntry === null) {
        return (
            <ContentLoader height={38} speed={1} viewBox="0 0 380 38">
                <rect x="0" y="0" rx="4" ry="4" width="80%" height="14" />
                <rect x="0" y="20" rx="3" ry="3" width="70%" height="10" />
            </ContentLoader>
        )
    }
    return (
        <>
            <CalendarEntryTitle>{props.calendarEntry.title}</CalendarEntryTitle>
            <CalendarEntryDate start={props.calendarEntry.start} end={props.calendarEntry.end} />
        </>
    )
}

interface CalendarEntryDateProps {
    start: string
    end: string
}

const CalendarEntryDate: React.FC<CalendarEntryDateProps> = (props) => {
    const languageState = useLanguageState()
    moment.locale(languageState.getLanguage())
    const startHour = moment(props.start).format(branding.calendarEntryListView.dateHourFormat)
    const endHour = moment(props.end).format(branding.calendarEntryListView.dateHourFormat)
    const day = moment(props.start).format(branding.calendarEntryListView.dateDayFormat)

    return (
        <CalendarEntryTime>
            <div>{day + ", " + startHour + " " + branding.calendarEntryListView.dateTo + " " + endHour}</div>
        </CalendarEntryTime>
    )
}

const ConversationDetailInputRow = styled.div`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    margin-left: 16px;
    padding-right: 4px;
    border-bottom: 1px solid;
    border-color: ${branding.mainInfoColor ?? "black"};

    &:hover .showOnHover {
        visibility: visible;
    }
`
const ConversationDetailInput = styled.input`
    flex-grow: 1;
    width: 100%;
    border: 0px;
    margin-right: 4px;

    &:focus {
        outline: none;
    }
`
const HoverButton = styled.div`
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0%, 100%);
    padding: 10px 10px;
    transition: background-color 0.5s;
    border-radius: 25px;
    cursor: pointer;
    &:hover {
        background-color: hsl(0, 0%, 80%);
    }

    &.showOnHover {
        visibility: hidden;
    }

    & svg {
        width: 100%;
        height: 100%;
    }
`

interface LockableInputProps {
    defaultValue?: string
    placeholder?: string
    maxLength?: number
    onSubmit: (newValue: string) => Promise<string | undefined>
    style?: React.CSSProperties
}

const LockableInput: React.FunctionComponent<LockableInputProps> = (props) => {
    const [inputStatus, setInputStatus] = useState(ConvFieldStatus.LOCKED)
    const [value, setValue] = useState(props.defaultValue)
    const inputRef = useRef<HTMLInputElement>(null)

    const onEditInputClicked = async () => {
        if (inputStatus === ConvFieldStatus.LOCKED) {
            inputRef.current?.focus()
            inputRef.current?.select()
            setInputStatus(ConvFieldStatus.EDITABLE)
        }
    }
    const onConfirmInputClicked = async () => {
        if (inputStatus === ConvFieldStatus.EDITABLE) {
            const input = inputRef.current
            if (input) {
                setInputStatus(ConvFieldStatus.PENDING)
                const newValue = input.value
                if (newValue !== props.defaultValue) {
                    const updatedValue = await props.onSubmit(newValue)
                    setValue(updatedValue)
                }
                setInputStatus(ConvFieldStatus.LOCKED)
            }
        }
    }
    const onDiscardInputClicked = async () => {
        if (inputStatus === ConvFieldStatus.EDITABLE) {
            setValue(props.defaultValue)
            setInputStatus(ConvFieldStatus.LOCKED)
        }
    }

    return (
        <ConversationDetailInputRow style={props.style}>
            <ConversationDetailInput
                ref={inputRef}
                value={value ?? ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                onKeyPress={(e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                        onConfirmInputClicked()
                    }
                }}
                onBlur={onDiscardInputClicked}
                readOnly={inputStatus !== ConvFieldStatus.EDITABLE}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                onClick={onEditInputClicked}
            />

            {inputStatus === ConvFieldStatus.LOCKED && (
                <HoverButton className={"showOnHover"} onClick={onEditInputClicked}>
                    {IconEdit({ fill: branding.sideIconBar.sideIconColorDark, width: "18", height: "18" })}
                </HoverButton>
            )}
            {inputStatus === ConvFieldStatus.EDITABLE && (
                <>
                    <HoverButton onClick={onDiscardInputClicked}>{IconClose}</HoverButton>
                    <HoverButton
                        onMouseDown={(event) => {
                            event.preventDefault()
                            onConfirmInputClicked()
                        }}
                    >
                        {IconCheckmark({ fill: branding.sideIconBar.sideIconColorDark })}
                    </HoverButton>
                </>
            )}
            {inputStatus === ConvFieldStatus.PENDING && (
                <div style={{ width: "40px", height: "40px", padding: "10px 10px" }}>
                    <Spinner animation="border" size="sm" />
                </div>
            )}
        </ConversationDetailInputRow>
    )
}

const StagedOpponentsContent = styled.div`
    flex: 0 0 auto;
    padding: 16px;
    width: 100%;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
`
const StagedPersonContainer = styled.div`
    position: relative;
    margin-right: 4px;

    :hover #badgeArea {
        visibility: visible;
    }
`
const BadgeContentArea = styled.div`
    position: absolute;
    visibility: hidden;
    background: black;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 18px;
    height: 14px;
    cursor: pointer;

    & svg {
        width: 100%;
        height: 100%;
    }

    & svg path {
        stroke: white;
    }
`
export interface StagedOpponentsContentProps {
    opponents: ConversationParticipant[]
    includeSelf?: boolean
    unstageOpponent: (opponent: ConversationParticipant) => void
}

export const StagedOpponentsArea: React.FC<StagedOpponentsContentProps> = (props) => {
    const user = useLoggedInState().user()!

    return (
        <StagedOpponentsContent>
            {props.opponents.map((opponent) => {
                return (
                    <StagedPersonContainer key={opponent.id}>
                        <CustomOverlayTrigger
                            key={opponent.id}
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip`} style={{ fontFamily: branding.font1 }}>
                                    <strong>{opponent.name}</strong>
                                </Tooltip>
                            }
                        >
                            <div>
                                <AvatarWithPresenceState
                                    userId={opponent.id}
                                    avatarSize={56}
                                    badgeSize={15}
                                    showAvatarBadge={true}
                                    badgeRight={0.1}
                                    badgeTop={37}
                                    content={{ pictureUrl: opponent.pictureUrl, alt: opponent.name }}
                                />
                            </div>
                        </CustomOverlayTrigger>
                        <BadgeContentArea id="badgeArea" onClick={() => props.unstageOpponent(opponent)}>
                            <RemoveIconWrapper>
                                {IconCloseParticipants({ fill: branding.sideIconBar.sideIconColorLight })}
                            </RemoveIconWrapper>
                        </BadgeContentArea>
                    </StagedPersonContainer>
                )
            })}
            {props.includeSelf && (
                <StagedPersonContainer key={user.profileId}>
                    <CustomOverlayTrigger
                        key={user.profileId}
                        placement="bottom"
                        overlay={
                            <Tooltip id={`tooltip`} style={{ fontFamily: branding.font1 }}>
                                <strong>You</strong>
                            </Tooltip>
                        }
                    >
                        <div>
                            <AvatarWithPresenceState
                                userId={user.profileId}
                                initPresenceByList={false}
                                userType={user.type}
                                avatarSize={56}
                                badgeSize={15}
                                showAvatarBadge={true}
                                badgeRight={0.1}
                                badgeTop={37}
                                content={{ pictureUrl: user.logoUrl }}
                            />
                        </div>
                    </CustomOverlayTrigger>
                </StagedPersonContainer>
            )}
        </StagedOpponentsContent>
    )
}

const RemoveIconWrapper = styled.div`
    z-index: 1;
`

const ExitGroupDialogTitle = styled.p`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
`
const SubtleButton = styled.div`
    cursor: pointer;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
`
const DividerPadded = styled.div`
    height: 1px;
    width: calc(100% - 32px);
    background-color: #000;
`

interface BottomDialogContentProps {
    conversationName?: string
    isMuted: boolean
    exitGroup: () => void
    muteGroup: () => void
    closeDialog: () => void
}

const BottomDialogContent: React.FunctionComponent<BottomDialogContentProps> = (props) => {
    const dialogTitle = props.conversationName
        ? `${branding.chatBranding.exitText} "${props.conversationName}"?`
        : branding.chatBranding.exitGroupChatText
    return (
        <>
            <ExitGroupDialogTitle>{dialogTitle}</ExitGroupDialogTitle>
            <RedRoundedButton style={{ alignSelf: "stretch" }} onClick={props.exitGroup}>
                {branding.chatBranding.exitGroupText}
            </RedRoundedButton>
            {!props.isMuted && <SubtleButton onClick={props.muteGroup}>{branding.chatBranding.muteInsteadText}</SubtleButton>}
            <DividerPadded />
            <SubtleButton onClick={props.closeDialog}>{branding.chatBranding.cancelText}</SubtleButton>
        </>
    )
}

export default ConversationDetails
