import styled from "styled-components"
import branding from "../../../branding/branding"
import { IconReset } from "../../../ui/Icons"
import { device } from "../../../utils/Device"

const ResetFilterButtonRoot = styled.div<{ visible?: boolean; height?: string; mobileMarginTop?: string }>`
    display: flex;
    font-family: ${branding.font1};
    height: ${(props) => (props.height ? props.height : "35px")};
    padding: 15px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: ${branding.filterBar.resetFilterButtonBorderColor ?? "#6DD400"};
    background-color: ${branding.filterBar.resetFilterButtonBgColor ?? "#6DD400"};
    color: ${branding.filterBar.resetFilterButtonTextColor ?? "#FFF"};
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    visibility: ${(props) => (props.visible ? "visible" : "hidden")};

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    @media ${device.mobile} {
        width: 43vw;
        margin-top: ${(props) => (props.mobileMarginTop ? props.mobileMarginTop : "0px")};
    }

    @media (max-width: 380px) {
        width: 42vw;
    }
`

const ResetFilterButtonIcon = styled.span`
    width: 20px;
    height: 20px;
`

const ResetFilterButtonText = styled.span`
    position: relative;
    width: auto;
    text-align: center;
    margin-left: 10px;
    display: inline-block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
`

interface ResetFilterButtonsComponentProps {
    text: string
    onResetFilterButtonClick: () => void
    filterDisabled?: boolean
    iconColor?: string
    visibility?: boolean
    buttonHeight?: string
    customMobileMarginTop?: string
}

export const ResetFilterButton: React.FunctionComponent<ResetFilterButtonsComponentProps> = (
    props: ResetFilterButtonsComponentProps
) => {
    return (
        <>
            <ResetFilterButtonRoot
                onClick={() => props.onResetFilterButtonClick()}
                className={props.filterDisabled ? "disabled" : ""}
                visible={props.visibility}
                height={props.buttonHeight}
                mobileMarginTop={props.customMobileMarginTop}
            >
                <ResetFilterButtonIcon>
                    {IconReset({ fill: props.iconColor ?? branding.filterBar.resetFilterButtonIconColor })}
                </ResetFilterButtonIcon>
                <ResetFilterButtonText>{props.text}</ResetFilterButtonText>
            </ResetFilterButtonRoot>
        </>
    )
}
