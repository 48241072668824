/* tslint:disable */
/* eslint-disable */
// this are specific queries

/*
 * created by GNiewoehner
 */

export const createCalendarEntryLight = /* GraphQL */ `
    mutation CreateCalendarEntryLight($input: CreateCalendarEntryInput!, $condition: ModelCalendarEntryConditionInput) {
        createCalendarEntry(input: $input, condition: $condition) {
            id
            title
            description
            start
            end
            userId
            user {
                id
                name
                pictureUrl
            }
            participants {
                items {
                    id
                    status
                    userId
                    user {
                        id
                        name
                        pictureUrl
                    }
                }
            }
            locationName
            locationActionType
            locationActionParam
            isVirtual
        }
    }
`

export const updateCalendarEntryLight = /* GraphQL */ `
    mutation UpdateCalendarEntryLight($input: UpdateCalendarEntryInput!, $condition: ModelCalendarEntryConditionInput) {
        updateCalendarEntry(input: $input, condition: $condition) {
            id
            title
            description
            start
            end
            userId
            user {
                id
                name
                pictureUrl
            }
            participants {
                items {
                    id
                    status
                    userId
                    user {
                        id
                        name
                        pictureUrl
                    }
                }
            }
            locationName
            locationActionType
            locationActionParam
            isVirtual
        }
    }
`

export const createMessageLight = /* GraphQL */ `
    mutation CreateMessageLight($input: CreateMessageInput!) {
        createMessage(input: $input) {
            id
            authorId
            content
            conversationId
            isSent
            createdAt
            updatedAt
        }
    }
`

export const updateUserConversationLight = /* GraphQL */ `
    mutation UpdateUserConversationLight($input: UpdateUserConversationInput!, $condition: ModelUserConversationConditionInput) {
        updateUserConversation(input: $input, condition: $condition) {
            userId
            isMuted
            lastReadMessageCreatedAt
            conversation {
                id
                name
                mostRecentMessage
                mostRecentMessageCreatedAt
            }
        }
    }
`

export const deleteCalendarEntryParticipation = /* GraphQL */ `
    mutation DeleteCalendarEntryParticipationById(
        $input: DeleteCalendarEntryParticipationInput!
        $condition: ModelCalendarEntryParticipationConditionInput
    ) {
        deleteCalendarEntryParticipation(input: $input, condition: $condition) {
            id
            userId
        }
    }
`

export const createMeeting = /* GraphQL */ `
    mutation CreateMeetingLight($input: CreateMeetingInput!, $condition: ModelMeetingConditionInput) {
        createMeeting(input: $input, condition: $condition) {
            id
        }
    }
`

export const createUserConversation = /* GraphQL */ `
    mutation CreateUserConversationLight($condition: ModelUserConversationConditionInput, $input: CreateUserConversationInput!) {
        createUserConversation(condition: $condition, input: $input) {
            id
            userId
            conversationId
            isMuted
            isChatOpen
            mostRecentMessageCreatedAt
            lastReadMessageCreatedAt
            createdAt
            updatedAt
        }
    }
`

export const createMeetingParticipant = /* GraphQL */ `
    mutation CreateMeetingParticipantLight(
        $condition: ModelMeetingParticipantConditionInput
        $input: CreateMeetingParticipantInput!
    ) {
        createMeetingParticipant(condition: $condition, input: $input) {
            id
            meetingId
            meeting {
                id
                start
                end
                createdAt
                updatedAt
            }
            inviterId
            inviteeId
            status
            created
            createdAt
            updatedAt
            invitationType
            meetingTitle
            inviter {
                id
                presenceStatus
                lastConnected
                name
                pictureUrl
                createdAt
                updatedAt
            }
            invitee {
                id
                presenceStatus
                lastConnected
                name
                pictureUrl
                createdAt
                updatedAt
            }
        }
    }
`

export const updateMeetingParticipantLight = /* GraphQL */ `
    mutation UpdateMeetingParticipantLight(
        $condition: ModelMeetingParticipantConditionInput
        $input: UpdateMeetingParticipantInput!
    ) {
        updateMeetingParticipant(condition: $condition, input: $input) {
            id
            meetingId
            inviterId
            inviteeId
            status
            created
            createdAt
            updatedAt
            inviter {
                id
                presenceStatus
                lastConnected
                name
                pictureUrl
                createdAt
                updatedAt
            }
            invitee {
                id
                presenceStatus
                lastConnected
                name
                pictureUrl
                createdAt
                updatedAt
            }
            meeting {
                id
                start
                end
                createdAt
                updatedAt
            }
        }
    }
`

export const updateUserSessionLight = /* GraphQL */ `
    mutation UpdateUserSessionLight($input: UpdateUserSessionInput!, $condition: ModelUserSessionConditionInput) {
        updateUserSession(input: $input, condition: $condition) {
            id
            userId
            sotName
            topic
            countryCode
            userType
            location
            locationLevel1
            locationLevel2
            locationLevel3
            ttl
            time
            source
            createdAt
            updatedAt
            user {
                id
                presenceStatus
                lastConnected
                name
                pictureUrl
                createdAt
                updatedAt
            }
        }
    }
`

export const createCalendarEntryParticipationLight = /* GraphQL */ `
    mutation CreateCalendarEntryParticipationLight(
        $condition: ModelCalendarEntryParticipationConditionInput
        $input: CreateCalendarEntryParticipationInput!
    ) {
        createCalendarEntryParticipation(condition: $condition, input: $input) {
            id
            userId
            status
            start
            userIdStatus
            createdAt
            updatedAt
            user {
                id
                name
                pictureUrl
            }
        }
    }
`

export const deleteCalendarEntryLight = /* GraphQL */ `
    mutation DeleteCalendarEntryLight($condition: ModelCalendarEntryConditionInput, $input: DeleteCalendarEntryInput!) {
        deleteCalendarEntry(condition: $condition, input: $input) {
            id
            title
            description
            start
            end
            userId
            organizationId
            status
            organizationIdStatus
            topicName
            userPoolName
            createdAt
            updatedAt
            locationName
            locationActionType
            locationActionParam
            isVirtual
        }
    }
`

export const updateCalendarEntryParticipationLight = /* GraphQL */ `
    mutation UpdateCalendarEntryParticipationLight(
        $condition: ModelCalendarEntryParticipationConditionInput
        $input: UpdateCalendarEntryParticipationInput!
    ) {
        updateCalendarEntryParticipation(condition: $condition, input: $input) {
            id
            calendarEntryId
            userId
            status
        }
    }
`

export const createConversationLight = /* GraphQL */ `
    mutation CreateConversationLight($condition: ModelConversationConditionInput, $input: CreateConversationInput!) {
        createConversation(condition: $condition, input: $input) {
            id
            type
            name
            description
            userId
        }
    }
`

export const createUserLight = /* GraphQL */ `
    mutation CreateUserLight($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
            id
            presenceStatus
            lastConnected
            name
            pictureUrl
            createdAt
            updatedAt
        }
    }
`

export const deleteUserLight = /* GraphQL */ `
    mutation DeleteUserLight($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
            id
            presenceStatus
            lastConnected
            name
            pictureUrl
            createdAt
            updatedAt
        }
    }
`

export const deleteUserConversationLight = /* GraphQL */ `
    mutation DeleteUserConversationLight($condition: ModelUserConversationConditionInput, $input: DeleteUserConversationInput!) {
        deleteUserConversation(condition: $condition, input: $input) {
            id
            userId
            conversationId
            isMuted
            isChatOpen
            mostRecentMessageCreatedAt
            lastReadMessageCreatedAt
        }
    }
`

export const updateConversationLight = /* GraphQL */ `
    mutation UpdateConversationLight($condition: ModelConversationConditionInput, $input: UpdateConversationInput!) {
        updateConversation(condition: $condition, input: $input) {
            id
            type
            name
            description
            userId
        }
    }
`

export const updateUserLight = /* GraphQL */ `
    mutation UpdateUserLight($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
            id
            presenceStatus
            lastConnected
            name
            pictureUrl
            createdAt
            updatedAt
        }
    }
`
