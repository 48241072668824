// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { Microphone } from "amazon-chime-sdk-component-library-react"
import branding from "../../branding/branding"

const ActivityBarRoot = styled.div`
    color: #fff;
    font-size: 12px;
    line-height: 17px;
`

const Track = styled.div`
    width: 100%;
    height: 6px;
    background-color: #666666;
    border-radius: 5px;
    margin-top: 8px;
`

const Progress = styled.div`
    height: 6px;
    background-color: #5db130;
    border-radius: 5px;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 33ms ease-in-out;
    will-change: transform;
`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ActivityBar = React.forwardRef((props, ref: any) => (
    <ActivityBarRoot>
        <Row>
            <Col md={5} className="pr-0">
                <Microphone width="23px" />
                {branding.audioVideoSettings.microphoneActivityLabel}
            </Col>
            <Col md={7} className="pl-0">
                <Track>
                    <Progress ref={ref} />
                </Track>
            </Col>
        </Row>
    </ActivityBarRoot>
))

ActivityBar.displayName = "ActivityBar"

export default ActivityBar
