/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelCalendarEntryConditionInput = {
  and?: Array< ModelCalendarEntryConditionInput | null > | null,
  description?: ModelStringInput | null,
  end?: ModelStringInput | null,
  not?: ModelCalendarEntryConditionInput | null,
  or?: Array< ModelCalendarEntryConditionInput | null > | null,
  organizationId?: ModelStringInput | null,
  organizationIdStatus?: ModelStringInput | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  title?: ModelStringInput | null,
  topicName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userPoolName?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  locationActionType?: ModelStringInput | null,
  locationActionParam?: ModelStringInput | null,
  isVirtual?: ModelBooleanInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCalendarEntryParticipationStatusInput = {
  eq?: CalendarEntryParticipationStatus | null,
  ne?: CalendarEntryParticipationStatus | null,
};

export enum CalendarEntryParticipationStatus {
  REQUESTED = "REQUESTED",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}


export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
  in?: Array< string | null > | null,
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  eq?: boolean | null,
  ne?: boolean | null,
};

export type CreateCalendarEntryWithParticipantsInput = {
  description?: string | null,
  end: string,
  id?: string | null,
  organizationId?: string | null,
  start: string,
  status?: CalendarEntryParticipationStatus | null,
  title: string,
  topicName: string,
  userId: string,
  userPoolName: string,
  locationName?: string | null,
  locationActionType?: string | null,
  locationActionParam?: string | null,
  isVirtual?: boolean | null,
  otherParticipantIds?: Array< string | null > | null,
};

export type CreateCalendarEntryInput = {
  description?: string | null,
  end: string,
  id?: string | null,
  organizationId?: string | null,
  start: string,
  status?: CalendarEntryParticipationStatus | null,
  title: string,
  topicName: string,
  userId: string,
  userPoolName: string,
  locationName?: string | null,
  locationActionType?: string | null,
  locationActionParam?: string | null,
  isVirtual?: boolean | null,
};

export type UpdateCalendarEntryInput = {
  description?: string | null,
  end?: string | null,
  id: string,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
  title?: string | null,
  topicName?: string | null,
  userId?: string | null,
  userPoolName?: string | null,
  locationName?: string | null,
  locationActionType?: string | null,
  locationActionParam?: string | null,
  isVirtual?: boolean | null,
};

export type UpdateCalendarEntryWithParticipantsInput = {
  description?: string | null,
  end?: string | null,
  id: string,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
  title?: string | null,
  topicName?: string | null,
  userId?: string | null,
  userPoolName?: string | null,
  locationName?: string | null,
  locationActionType?: string | null,
  locationActionParam?: string | null,
  isVirtual?: boolean | null,
  participantIds?: Array< string | null > | null,
};

export type DeleteCalendarEntryInput = {
  id: string,
};

export type ModelCalendarEntryParticipationConditionInput = {
  and?: Array< ModelCalendarEntryParticipationConditionInput | null > | null,
  calendarEntryId?: ModelIDInput | null,
  not?: ModelCalendarEntryParticipationConditionInput | null,
  or?: Array< ModelCalendarEntryParticipationConditionInput | null > | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  userId?: ModelIDInput | null,
  userIdStatus?: ModelStringInput | null,
};

export type CreateCalendarEntryParticipationInput = {
  calendarEntryId: string,
  id?: string | null,
  start: string,
  status: CalendarEntryParticipationStatus,
  userId: string,
};

export type UpdateCalendarEntryParticipationInput = {
  id: string,
  start?: string | null,
  status?: CalendarEntryParticipationStatus | null,
};

export type DeleteCalendarEntryParticipationInput = {
  id: string,
};

export type ModelConversationConditionInput = {
  type?: ModelConversationTypeInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  mostRecentMessage?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  memberIdsHash?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConversationConditionInput | null > | null,
  or?: Array< ModelConversationConditionInput | null > | null,
  not?: ModelConversationConditionInput | null,
};

export type ModelConversationTypeInput = {
  eq?: ConversationType | null,
  ne?: ConversationType | null,
};

export enum ConversationType {
  PRIVATE = "PRIVATE",
  GROUP = "GROUP",
  PUBLIC = "PUBLIC",
  CALENDARENTRY = "CALENDARENTRY",
  CALL = "CALL",
}


export type CreateConversationInput = {
  id?: string | null,
  type?: ConversationType | null,
  name?: string | null,
  description?: string | null,
  userId?: string | null,
  mostRecentMessage?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  memberIdsHash?: string | null,
  updatedAt?: string | null,
};

export type UpdateConversationInput = {
  id: string,
  type?: ConversationType | null,
  name?: string | null,
  description?: string | null,
  mostRecentMessage?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  memberIdsHash?: string | null,
  updatedAt?: string | null,
};

export type ModelMeetingParticipantConditionInput = {
  and?: Array< ModelMeetingParticipantConditionInput | null > | null,
  created?: ModelStringInput | null,
  inviteeId?: ModelIDInput | null,
  inviterId?: ModelIDInput | null,
  meetingId?: ModelIDInput | null,
  not?: ModelMeetingParticipantConditionInput | null,
  or?: Array< ModelMeetingParticipantConditionInput | null > | null,
  status?: ModelInviteStatusInput | null,
  invitationType?: ModelInvitationTypeInput | null,
  meetingTitle?: ModelStringInput | null,
};

export type ModelInviteStatusInput = {
  eq?: InviteStatus | null,
  ne?: InviteStatus | null,
};

export enum InviteStatus {
  INVITING = "INVITING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  CANCELED = "CANCELED",
  TIMEOUT = "TIMEOUT",
}


export type ModelInvitationTypeInput = {
  eq?: InvitationType | null,
  ne?: InvitationType | null,
};

export enum InvitationType {
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  NOTIFY = "NOTIFY",
}


export type CreateMeetingParticipantInput = {
  created: string,
  id?: string | null,
  inviteeId: string,
  inviterId: string,
  meetingId: string,
  status: InviteStatus,
  invitationType?: InvitationType | null,
  meetingTitle?: string | null,
};

export type UpdateMeetingParticipantInput = {
  id: string,
  status?: InviteStatus | null,
  invitationType?: InvitationType | null,
  meetingTitle?: string | null,
};

export type ModelMessageConditionInput = {
  authorId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isSent?: ModelBooleanInput | null,
  sotName?: ModelStringInput | null,
  members?: ModelStringInput | null,
  _version?: ModelSizeInput | null,
  _deleted?: ModelBooleanInput | null,
  _lastChangedAt?: ModelStringInput | null,
  and?: Array< ModelMessageConditionInput | null > | null,
  not?: ModelMessageConditionInput | null,
  or?: Array< ModelMessageConditionInput | null > | null,
};

export type CreateMessageInput = {
  authorId: string,
  content: string,
  conversationId: string,
  createdAt?: string | null,
  id?: string | null,
  isSent?: boolean | null,
  members?: Array< string | null > | null,
  sotName?: string | null,
  _version?: number | null,
  _deleted?: boolean | null,
  _lastChangedAt?: number | null,
};

export type UpdateUnreadCounterInput = {
  id: string,
  requests?: number | null,
  contacts?: number | null,
  conversations?: number | null,
  schedules?: number | null,
};

export type ModelUnreadCounterConditionInput = {
  requests?: ModelIntInput | null,
  contacts?: ModelIntInput | null,
  conversations?: ModelIntInput | null,
  schedules?: ModelIntInput | null,
  and?: Array< ModelUnreadCounterConditionInput | null > | null,
  or?: Array< ModelUnreadCounterConditionInput | null > | null,
  not?: ModelUnreadCounterConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelUserConversationConditionInput = {
  and?: Array< ModelUserConversationConditionInput | null > | null,
  conversationId?: ModelIDInput | null,
  isChatOpen?: ModelBooleanInput | null,
  isMuted?: ModelBooleanInput | null,
  lastReadMessageCreatedAt?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  not?: ModelUserConversationConditionInput | null,
  or?: Array< ModelUserConversationConditionInput | null > | null,
  userId?: ModelIDInput | null,
};

export type CreateUserConversationInput = {
  conversationId: string,
  id?: string | null,
  isChatOpen?: boolean | null,
  isMuted?: boolean | null,
  lastReadMessageCreatedAt?: string | null,
  mostRecentMessageCreatedAt?: string | null,
  userId: string,
};

export type UpdateUserConversationInput = {
  id: string,
  isChatOpen?: boolean | null,
  isMuted?: boolean | null,
  lastReadMessageCreatedAt?: string | null,
  mostRecentMessageCreatedAt?: string | null,
};

export type DeleteUserConversationInput = {
  id: string,
};

export type CreateMeetingInput = {
  id?: string | null,
  start: string,
  end?: string | null,
};

export type ModelMeetingConditionInput = {
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  and?: Array< ModelMeetingConditionInput | null > | null,
  or?: Array< ModelMeetingConditionInput | null > | null,
  not?: ModelMeetingConditionInput | null,
};

export type DeleteMessageInput = {
  id: string,
  _version: number,
};

export type DeleteNotificationInput = {
  id?: string | null,
};

export type ModelNotificationConditionInput = {
  type?: ModelNotificationTypeInput | null,
  displayGroup?: ModelNotificationDisplayGroupInput | null,
  content?: ModelStringInput | null,
  userIdDisplayGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export enum NotificationType {
  PLAINTEXT = "PLAINTEXT",
  NETWORKING = "NETWORKING",
  MESSAGE = "MESSAGE",
  CALENDARENTRY = "CALENDARENTRY",
  BACKOFFICE = "BACKOFFICE",
  LOCAL = "LOCAL",
}


export type ModelNotificationDisplayGroupInput = {
  eq?: NotificationDisplayGroup | null,
  ne?: NotificationDisplayGroup | null,
};

export enum NotificationDisplayGroup {
  NETWORKING = "NETWORKING",
  CALL = "CALL",
  MEETING = "MEETING",
  SYSTEM = "SYSTEM",
}


export type CreateRaisedHandInput = {
  id?: string | null,
  chimeMeetingId?: string | null,
};

export type ModelRaisedHandConditionInput = {
  chimeMeetingId?: ModelStringInput | null,
  and?: Array< ModelRaisedHandConditionInput | null > | null,
  or?: Array< ModelRaisedHandConditionInput | null > | null,
  not?: ModelRaisedHandConditionInput | null,
};

export type UpdateRaisedHandInput = {
  id: string,
  chimeMeetingId?: string | null,
};

export type DeleteRaisedHandInput = {
  id?: string | null,
};

export type CreateUserInput = {
  id?: string | null,
  presenceStatus: string,
  lastConnected: string,
  name?: string | null,
  pictureUrl?: string | null,
};

export type ModelUserConditionInput = {
  presenceStatus?: ModelStringInput | null,
  lastConnected?: ModelStringInput | null,
  name?: ModelStringInput | null,
  pictureUrl?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  presenceStatus?: string | null,
  lastConnected?: string | null,
  name?: string | null,
  pictureUrl?: string | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type CreateUserActionInput = {
  id?: string | null,
  userId: string,
  sotName: string,
  actionType: UserActionType,
  param: string,
  startTime: string,
  endTime?: string | null,
  duration?: number | null,
  source?: string | null,
  topic: string,
  userType?: string | null,
  countryCode?: string | null,
};

export enum UserActionType {
  NAVIGATION = "NAVIGATION",
  CALL = "CALL",
  CHAT = "CHAT",
  STREAM = "STREAM",
  VODWATCHTIME = "VODWATCHTIME",
}


export type ModelUserActionConditionInput = {
  sotName?: ModelStringInput | null,
  actionType?: ModelUserActionTypeInput | null,
  param?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  source?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userType?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  and?: Array< ModelUserActionConditionInput | null > | null,
  or?: Array< ModelUserActionConditionInput | null > | null,
  not?: ModelUserActionConditionInput | null,
};

export type ModelUserActionTypeInput = {
  eq?: UserActionType | null,
  ne?: UserActionType | null,
};

export type UpdateUserActionInput = {
  id: string,
  userId?: string | null,
  sotName?: string | null,
  actionType?: UserActionType | null,
  param?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  duration?: number | null,
  source?: string | null,
  topic?: string | null,
  userType?: string | null,
  countryCode?: string | null,
};

export type DeleteUserActionInput = {
  id?: string | null,
};

export type CreateUserSessionInput = {
  id?: string | null,
  userId: string,
  sotName: string,
  location: string,
  locationLevel1: string,
  locationLevel2?: string | null,
  locationLevel3?: string | null,
  ttl?: number | null,
  time?: string | null,
  source?: string | null,
  queryHelper: string,
  topic: string,
  userType?: string | null,
  countryCode?: string | null,
};

export type ModelUserSessionConditionInput = {
  sotName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  locationLevel1?: ModelStringInput | null,
  locationLevel2?: ModelStringInput | null,
  locationLevel3?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  time?: ModelStringInput | null,
  source?: ModelStringInput | null,
  queryHelper?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userType?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  and?: Array< ModelUserSessionConditionInput | null > | null,
  or?: Array< ModelUserSessionConditionInput | null > | null,
  not?: ModelUserSessionConditionInput | null,
};

export type UpdateUserSessionInput = {
  id: string,
  userId?: string | null,
  sotName?: string | null,
  location?: string | null,
  locationLevel1?: string | null,
  locationLevel2?: string | null,
  locationLevel3?: string | null,
  ttl?: number | null,
  time?: string | null,
  source?: string | null,
  queryHelper?: string | null,
  topic?: string | null,
  userType?: string | null,
  countryCode?: string | null,
};

export type DeleteUserSessionInput = {
  id?: string | null,
};

export type UpdateMessageInput = {
  authorId?: string | null,
  content?: string | null,
  conversationId?: string | null,
  createdAt?: string | null,
  id: string,
  isSent?: boolean | null,
  sotName?: string | null,
  members?: Array< string | null > | null,
  _version?: number | null,
  _deleted?: boolean | null,
  _lastChangedAt?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  userId: string,
  type: NotificationType,
  displayGroup?: NotificationDisplayGroup | null,
  content: string,
  userIdDisplayGroup?: string | null,
  createdAt?: string | null,
};

export type UpdateNotificationInput = {
  id: string,
  userId?: string | null,
  type?: NotificationType | null,
  displayGroup?: NotificationDisplayGroup | null,
  content?: string | null,
  userIdDisplayGroup?: string | null,
  createdAt?: string | null,
};

export type CreateUnreadCounterInput = {
  id?: string | null,
  requests?: number | null,
  contacts?: number | null,
  conversations?: number | null,
  schedules?: number | null,
};

export type DeleteUnreadCounterInput = {
  id?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCalendarEntryParticipationFilterInput = {
  and?: Array< ModelCalendarEntryParticipationFilterInput | null > | null,
  calendarEntryId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  not?: ModelCalendarEntryParticipationFilterInput | null,
  or?: Array< ModelCalendarEntryParticipationFilterInput | null > | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  userId?: ModelIDInput | null,
  userIdStatus?: ModelStringInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelUserActionFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  sotName?: ModelStringInput | null,
  actionType?: ModelUserActionTypeInput | null,
  param?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  duration?: ModelIntInput | null,
  source?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userType?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  and?: Array< ModelUserActionFilterInput | null > | null,
  or?: Array< ModelUserActionFilterInput | null > | null,
  not?: ModelUserActionFilterInput | null,
};

export type ModelCalendarEntryFilterInput = {
  and?: Array< ModelCalendarEntryFilterInput | null > | null,
  description?: ModelStringInput | null,
  end?: ModelStringInput | null,
  id?: ModelIDInput | null,
  not?: ModelCalendarEntryFilterInput | null,
  or?: Array< ModelCalendarEntryFilterInput | null > | null,
  organizationId?: ModelStringInput | null,
  organizationIdStatus?: ModelStringInput | null,
  start?: ModelStringInput | null,
  status?: ModelCalendarEntryParticipationStatusInput | null,
  title?: ModelStringInput | null,
  topicName?: ModelStringInput | null,
  userId?: ModelIDInput | null,
  userPoolName?: ModelStringInput | null,
  locationName?: ModelStringInput | null,
  locationActionType?: ModelStringInput | null,
  locationActionParam?: ModelStringInput | null,
  isVirtual?: ModelBooleanInput | null,
};

export type ModelConversationFilterInput = {
  and?: Array< ModelConversationFilterInput | null > | null,
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  memberIdsHash?: ModelStringInput | null,
  mostRecentMessage?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  name?: ModelStringInput | null,
  not?: ModelConversationFilterInput | null,
  or?: Array< ModelConversationFilterInput | null > | null,
  type?: ModelConversationTypeInput | null,
  updatedAt?: ModelStringInput | null,
  userId?: ModelIDInput | null,
};

export type ModelMessageFilterInput = {
  id?: ModelIDInput | null,
  authorId?: ModelIDInput | null,
  content?: ModelStringInput | null,
  conversationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  isSent?: ModelBooleanInput | null,
  sotName?: ModelStringInput | null,
  members?: ModelStringInput | null,
  _version?: ModelSizeInput | null,
  _deleted?: ModelBooleanInput | null,
  _lastChangedAt?: ModelStringInput | null,
  and?: Array< ModelMessageFilterInput | null > | null,
  not?: ModelMessageFilterInput | null,
  or?: Array< ModelMessageFilterInput | null > | null,
};

export type ModelUserConversationFilterInput = {
  and?: Array< ModelUserConversationFilterInput | null > | null,
  conversationId?: ModelIDInput | null,
  id?: ModelIDInput | null,
  isChatOpen?: ModelBooleanInput | null,
  isMuted?: ModelBooleanInput | null,
  lastReadMessageCreatedAt?: ModelStringInput | null,
  mostRecentMessageCreatedAt?: ModelStringInput | null,
  not?: ModelUserConversationFilterInput | null,
  or?: Array< ModelUserConversationFilterInput | null > | null,
  userId?: ModelIDInput | null,
};

export type ModelIDKeyConditionInput = {
  beginsWith?: string | null,
  between?: Array< string | null > | null,
  eq?: string | null,
  ge?: string | null,
  gt?: string | null,
  le?: string | null,
  lt?: string | null,
};

export type ModelCurrentLocationCountsFilterInput = {
  id?: ModelIDInput | null,
  cnt?: ModelIntInput | null,
  and?: Array< ModelCurrentLocationCountsFilterInput | null > | null,
  or?: Array< ModelCurrentLocationCountsFilterInput | null > | null,
  not?: ModelCurrentLocationCountsFilterInput | null,
};

export type ModelMeetingFilterInput = {
  id?: ModelIDInput | null,
  start?: ModelStringInput | null,
  end?: ModelStringInput | null,
  and?: Array< ModelMeetingFilterInput | null > | null,
  or?: Array< ModelMeetingFilterInput | null > | null,
  not?: ModelMeetingFilterInput | null,
};

export type ModelRaisedHandFilterInput = {
  id?: ModelStringInput | null,
  chimeMeetingId?: ModelStringInput | null,
  and?: Array< ModelRaisedHandFilterInput | null > | null,
  or?: Array< ModelRaisedHandFilterInput | null > | null,
  not?: ModelRaisedHandFilterInput | null,
};

export type ModelUserSessionFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  sotName?: ModelStringInput | null,
  location?: ModelStringInput | null,
  locationLevel1?: ModelStringInput | null,
  locationLevel2?: ModelStringInput | null,
  locationLevel3?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  time?: ModelStringInput | null,
  source?: ModelStringInput | null,
  queryHelper?: ModelStringInput | null,
  topic?: ModelStringInput | null,
  userType?: ModelStringInput | null,
  countryCode?: ModelStringInput | null,
  and?: Array< ModelUserSessionFilterInput | null > | null,
  or?: Array< ModelUserSessionFilterInput | null > | null,
  not?: ModelUserSessionFilterInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  displayGroup?: ModelNotificationDisplayGroupInput | null,
  content?: ModelStringInput | null,
  userIdDisplayGroup?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  presenceStatus?: ModelStringInput | null,
  lastConnected?: ModelStringInput | null,
  name?: ModelStringInput | null,
  pictureUrl?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type EmptyMutationMutation = {
  _emptyMutation: string | null,
};

export type CreateCalendarEntryWithParticipantsMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: CreateCalendarEntryWithParticipantsInput,
};

export type CreateCalendarEntryWithParticipantsMutation = {
  createCalendarEntryWithParticipants:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: CreateCalendarEntryInput,
};

export type CreateCalendarEntryMutation = {
  createCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: UpdateCalendarEntryInput,
};

export type UpdateCalendarEntryMutation = {
  updateCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCalendarEntryWithParticipantsMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: UpdateCalendarEntryWithParticipantsInput,
};

export type UpdateCalendarEntryWithParticipantsMutation = {
  updateCalendarEntryWithParticipants:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCalendarEntryMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: DeleteCalendarEntryInput,
};

export type DeleteCalendarEntryMutation = {
  deleteCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: CreateCalendarEntryParticipationInput,
};

export type CreateCalendarEntryParticipationMutation = {
  createCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    calendarEntry:  {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: UpdateCalendarEntryParticipationInput,
};

export type UpdateCalendarEntryParticipationMutation = {
  updateCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    calendarEntry:  {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCalendarEntryParticipationMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: DeleteCalendarEntryParticipationInput,
};

export type DeleteCalendarEntryParticipationMutation = {
  deleteCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    calendarEntry:  {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateConversationMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: CreateConversationInput,
};

export type CreateConversationMutation = {
  createConversation:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
    mostRecentMessage: string | null,
    mostRecentMessageCreatedAt: string | null,
    memberIdsHash: string | null,
    updatedAt: string | null,
    createdAt: string,
    messages:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        authorId: string,
        content: string,
        conversationId: string,
        isSent: boolean | null,
        createdAt: string,
        sotName: string | null,
        members: Array< string | null > | null,
        _version: number | null,
        _deleted: boolean | null,
        _lastChangedAt: number | null,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
      startedAt: number | null,
    } | null,
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateConversationMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: UpdateConversationInput,
};

export type UpdateConversationMutation = {
  updateConversation:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
    mostRecentMessage: string | null,
    mostRecentMessageCreatedAt: string | null,
    memberIdsHash: string | null,
    updatedAt: string | null,
    createdAt: string,
    messages:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        authorId: string,
        content: string,
        conversationId: string,
        isSent: boolean | null,
        createdAt: string,
        sotName: string | null,
        members: Array< string | null > | null,
        _version: number | null,
        _deleted: boolean | null,
        _lastChangedAt: number | null,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
      startedAt: number | null,
    } | null,
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateMeetingParticipantMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: CreateMeetingParticipantInput,
};

export type CreateMeetingParticipantMutation = {
  createMeetingParticipant:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      participants:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    invitationType: InvitationType | null,
    meetingTitle: string | null,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateMeetingParticipantMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: UpdateMeetingParticipantInput,
};

export type UpdateMeetingParticipantMutation = {
  updateMeetingParticipant:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      participants:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    invitationType: InvitationType | null,
    meetingTitle: string | null,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateMessageMutationVariables = {
  condition?: ModelMessageConditionInput | null,
  input: CreateMessageInput,
};

export type CreateMessageMutation = {
  createMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateUnreadCounterMutationVariables = {
  input: UpdateUnreadCounterInput,
  condition?: ModelUnreadCounterConditionInput | null,
};

export type UpdateUnreadCounterMutation = {
  updateUnreadCounter:  {
    __typename: "UnreadCounter",
    id: string,
    requests: number | null,
    contacts: number | null,
    conversations: number | null,
    schedules: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: CreateUserConversationInput,
};

export type CreateUserConversationMutation = {
  createUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: UpdateUserConversationInput,
};

export type UpdateUserConversationMutation = {
  updateUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserConversationMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: DeleteUserConversationInput,
};

export type DeleteUserConversationMutation = {
  deleteUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateMeetingMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingMutation = {
  createMeeting:  {
    __typename: "Meeting",
    id: string,
    participants:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    start: string,
    end: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMessageMutationVariables = {
  input: DeleteMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type DeleteMessageMutation = {
  deleteMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification:  {
    __typename: "Notification",
    id: string,
    userId: string,
    type: NotificationType,
    displayGroup: NotificationDisplayGroup | null,
    content: string,
    userIdDisplayGroup: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRaisedHandMutationVariables = {
  input: CreateRaisedHandInput,
  condition?: ModelRaisedHandConditionInput | null,
};

export type CreateRaisedHandMutation = {
  createRaisedHand:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRaisedHandMutationVariables = {
  input: UpdateRaisedHandInput,
  condition?: ModelRaisedHandConditionInput | null,
};

export type UpdateRaisedHandMutation = {
  updateRaisedHand:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRaisedHandMutationVariables = {
  input: DeleteRaisedHandInput,
  condition?: ModelRaisedHandConditionInput | null,
};

export type DeleteRaisedHandMutation = {
  deleteRaisedHand:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: CreateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type CreateUserActionMutation = {
  createUserAction:  {
    __typename: "UserAction",
    id: string,
    userId: string,
    sotName: string,
    actionType: UserActionType,
    param: string,
    startTime: string,
    endTime: string | null,
    duration: number | null,
    source: string | null,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserActionMutationVariables = {
  input: UpdateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type UpdateUserActionMutation = {
  updateUserAction:  {
    __typename: "UserAction",
    id: string,
    userId: string,
    sotName: string,
    actionType: UserActionType,
    param: string,
    startTime: string,
    endTime: string | null,
    duration: number | null,
    source: string | null,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserActionMutationVariables = {
  input: DeleteUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type DeleteUserActionMutation = {
  deleteUserAction:  {
    __typename: "UserAction",
    id: string,
    userId: string,
    sotName: string,
    actionType: UserActionType,
    param: string,
    startTime: string,
    endTime: string | null,
    duration: number | null,
    source: string | null,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CreateUserSessionMutationVariables = {
  input: CreateUserSessionInput,
  condition?: ModelUserSessionConditionInput | null,
};

export type CreateUserSessionMutation = {
  createUserSession:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    location: string,
    locationLevel1: string,
    locationLevel2: string | null,
    locationLevel3: string | null,
    ttl: number | null,
    time: string | null,
    source: string | null,
    queryHelper: string,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateUserSessionMutationVariables = {
  input: UpdateUserSessionInput,
  condition?: ModelUserSessionConditionInput | null,
};

export type UpdateUserSessionMutation = {
  updateUserSession:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    location: string,
    locationLevel1: string,
    locationLevel2: string | null,
    locationLevel3: string | null,
    ttl: number | null,
    time: string | null,
    source: string | null,
    queryHelper: string,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type DeleteUserSessionMutationVariables = {
  input: DeleteUserSessionInput,
  condition?: ModelUserSessionConditionInput | null,
};

export type DeleteUserSessionMutation = {
  deleteUserSession:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    location: string,
    locationLevel1: string,
    locationLevel2: string | null,
    locationLevel3: string | null,
    ttl: number | null,
    time: string | null,
    source: string | null,
    queryHelper: string,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UpdateMessageMutationVariables = {
  input: UpdateMessageInput,
  condition?: ModelMessageConditionInput | null,
};

export type UpdateMessageMutation = {
  updateMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification:  {
    __typename: "Notification",
    id: string,
    userId: string,
    type: NotificationType,
    displayGroup: NotificationDisplayGroup | null,
    content: string,
    userIdDisplayGroup: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification:  {
    __typename: "Notification",
    id: string,
    userId: string,
    type: NotificationType,
    displayGroup: NotificationDisplayGroup | null,
    content: string,
    userIdDisplayGroup: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUnreadCounterMutationVariables = {
  input: CreateUnreadCounterInput,
  condition?: ModelUnreadCounterConditionInput | null,
};

export type CreateUnreadCounterMutation = {
  createUnreadCounter:  {
    __typename: "UnreadCounter",
    id: string,
    requests: number | null,
    contacts: number | null,
    conversations: number | null,
    schedules: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUnreadCounterMutationVariables = {
  input: DeleteUnreadCounterInput,
  condition?: ModelUnreadCounterConditionInput | null,
};

export type DeleteUnreadCounterMutation = {
  deleteUnreadCounter:  {
    __typename: "UnreadCounter",
    id: string,
    requests: number | null,
    contacts: number | null,
    conversations: number | null,
    schedules: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCalendarEntryLightMutationVariables = {
  input: CreateCalendarEntryInput,
  condition?: ModelCalendarEntryConditionInput | null,
};

export type CreateCalendarEntryLightMutation = {
  createCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string | null,
      pictureUrl: string | null,
    },
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
      } | null > | null,
    } | null,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
  } | null,
};

export type UpdateCalendarEntryLightMutationVariables = {
  input: UpdateCalendarEntryInput,
  condition?: ModelCalendarEntryConditionInput | null,
};

export type UpdateCalendarEntryLightMutation = {
  updateCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string | null,
      pictureUrl: string | null,
    },
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
      } | null > | null,
    } | null,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
  } | null,
};

export type CreateMessageLightMutationVariables = {
  input: CreateMessageInput,
};

export type CreateMessageLightMutation = {
  createMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserConversationLightMutationVariables = {
  input: UpdateUserConversationInput,
  condition?: ModelUserConversationConditionInput | null,
};

export type UpdateUserConversationLightMutation = {
  updateUserConversation:  {
    __typename: "UserConversation",
    userId: string,
    isMuted: boolean | null,
    lastReadMessageCreatedAt: string | null,
    conversation:  {
      __typename: "Conversation",
      id: string,
      name: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
    },
  } | null,
};

export type DeleteCalendarEntryParticipationByIdMutationVariables = {
  input: DeleteCalendarEntryParticipationInput,
  condition?: ModelCalendarEntryParticipationConditionInput | null,
};

export type DeleteCalendarEntryParticipationByIdMutation = {
  deleteCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    userId: string,
  } | null,
};

export type CreateMeetingLightMutationVariables = {
  input: CreateMeetingInput,
  condition?: ModelMeetingConditionInput | null,
};

export type CreateMeetingLightMutation = {
  createMeeting:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type CreateUserConversationLightMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: CreateUserConversationInput,
};

export type CreateUserConversationLightMutation = {
  createUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMeetingParticipantLightMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: CreateMeetingParticipantInput,
};

export type CreateMeetingParticipantLightMutation = {
  createMeetingParticipant:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    invitationType: InvitationType | null,
    meetingTitle: string | null,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateMeetingParticipantLightMutationVariables = {
  condition?: ModelMeetingParticipantConditionInput | null,
  input: UpdateMeetingParticipantInput,
};

export type UpdateMeetingParticipantLightMutation = {
  updateMeetingParticipant:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateUserSessionLightMutationVariables = {
  input: UpdateUserSessionInput,
  condition?: ModelUserSessionConditionInput | null,
};

export type UpdateUserSessionLightMutation = {
  updateUserSession:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    topic: string,
    countryCode: string | null,
    userType: string | null,
    location: string,
    locationLevel1: string,
    locationLevel2: string | null,
    locationLevel3: string | null,
    ttl: number | null,
    time: string | null,
    source: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateCalendarEntryParticipationLightMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: CreateCalendarEntryParticipationInput,
};

export type CreateCalendarEntryParticipationLightMutation = {
  createCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      name: string | null,
      pictureUrl: string | null,
    },
  } | null,
};

export type DeleteCalendarEntryLightMutationVariables = {
  condition?: ModelCalendarEntryConditionInput | null,
  input: DeleteCalendarEntryInput,
};

export type DeleteCalendarEntryLightMutation = {
  deleteCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    createdAt: string,
    updatedAt: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
  } | null,
};

export type UpdateCalendarEntryParticipationLightMutationVariables = {
  condition?: ModelCalendarEntryParticipationConditionInput | null,
  input: UpdateCalendarEntryParticipationInput,
};

export type UpdateCalendarEntryParticipationLightMutation = {
  updateCalendarEntryParticipation:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    userId: string,
    status: CalendarEntryParticipationStatus,
  } | null,
};

export type CreateConversationLightMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: CreateConversationInput,
};

export type CreateConversationLightMutation = {
  createConversation:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
  } | null,
};

export type CreateUserLightMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserLightMutation = {
  createUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserLightMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserLightMutation = {
  deleteUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserConversationLightMutationVariables = {
  condition?: ModelUserConversationConditionInput | null,
  input: DeleteUserConversationInput,
};

export type DeleteUserConversationLightMutation = {
  deleteUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
  } | null,
};

export type UpdateConversationLightMutationVariables = {
  condition?: ModelConversationConditionInput | null,
  input: UpdateConversationInput,
};

export type UpdateConversationLightMutation = {
  updateConversation:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
  } | null,
};

export type UpdateUserLightMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserLightMutation = {
  updateUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPresenceByUserQueryVariables = {
  id: string,
};

export type GetPresenceByUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
  } | null,
};

export type GetMeetingLightQueryVariables = {
  id: string,
};

export type GetMeetingLightQuery = {
  getMeeting:  {
    __typename: "Meeting",
    id: string,
  } | null,
};

export type GetMeetingsQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetMeetingsQuery = {
  getUser:  {
    __typename: "User",
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        invitee:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
        meeting:  {
          __typename: "Meeting",
          id: string,
          start: string,
          end: string | null,
          participants:  {
            __typename: "ModelMeetingParticipantConnection",
            items:  Array< {
              __typename: "MeetingParticipant",
              invitee:  {
                __typename: "User",
                id: string,
                name: string | null,
                pictureUrl: string | null,
              },
              inviter:  {
                __typename: "User",
                id: string,
                name: string | null,
                pictureUrl: string | null,
              },
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
        status: InviteStatus,
        created: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        inviter:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
        meeting:  {
          __typename: "Meeting",
          id: string,
          start: string,
          end: string | null,
          participants:  {
            __typename: "ModelMeetingParticipantConnection",
            items:  Array< {
              __typename: "MeetingParticipant",
              invitee:  {
                __typename: "User",
                id: string,
                name: string | null,
                pictureUrl: string | null,
              },
              inviter:  {
                __typename: "User",
                id: string,
                name: string | null,
                pictureUrl: string | null,
              },
            } | null > | null,
            nextToken: string | null,
          } | null,
        },
        status: InviteStatus,
        created: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type GetConversationsByMembersLightQueryVariables = {
  memberId1: string,
  memberId2: string,
};

export type GetConversationsByMembersLightQuery = {
  getConversationsByMembers:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      members:  {
        __typename: "ModelUserConversationConnection",
        items:  Array< {
          __typename: "UserConversation",
          id: string,
          userId: string,
          isMuted: boolean | null,
        } | null > | null,
      } | null,
    } | null > | null,
  } | null,
};

export type UserConversationsByUserAndConversationLightQueryVariables = {
  userId: string,
  conversationId: string,
};

export type UserConversationsByUserAndConversationLightQuery = {
  userConversationsByUserAndConversation:  {
    __typename: "ModelUserConversationConnection",
    items:  Array< {
      __typename: "UserConversation",
      id: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        name: string | null,
      },
      isMuted: boolean | null,
    } | null > | null,
  } | null,
};

export type MessagesByConversationIdLightQueryVariables = {
  conversationId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type MessagesByConversationIdLightQuery = {
  messagesByConversationId:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      authorId: string,
      content: string,
      createdAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetConversationsQueryVariables = {
  userId: string,
  callLimit?: number | null,
  memberLimit?: number | null,
  nextToken?: string | null,
};

export type GetConversationsQuery = {
  userConversationsByUser:  {
    __typename: "ModelUserConversationConnection",
    items:  Array< {
      __typename: "UserConversation",
      id: string,
      isMuted: boolean | null,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        userId: string | null,
        mostRecentMessage: string | null,
        members:  {
          __typename: "ModelUserConversationConnection",
          items:  Array< {
            __typename: "UserConversation",
            id: string,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name: string | null,
              pictureUrl: string | null,
            },
          } | null > | null,
        } | null,
      },
      mostRecentMessageCreatedAt: string | null,
      lastReadMessageCreatedAt: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserConversationLightQueryVariables = {
  id: string,
};

export type GetUserConversationLightQuery = {
  getUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UserConversationListEntryQueryVariables = {
  userId: string,
  conversationId: string,
  memberLimit?: number | null,
};

export type UserConversationListEntryQuery = {
  userConversationsByUserAndConversation:  {
    __typename: "ModelUserConversationConnection",
    items:  Array< {
      __typename: "UserConversation",
      id: string,
      isMuted: boolean | null,
      mostRecentMessageCreatedAt: string | null,
      lastReadMessageCreatedAt: string | null,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        mostRecentMessage: string | null,
        members:  {
          __typename: "ModelUserConversationConnection",
          items:  Array< {
            __typename: "UserConversation",
            id: string,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name: string | null,
              pictureUrl: string | null,
            },
          } | null > | null,
        } | null,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetConversationByIdQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetConversationByIdQuery = {
  getConversation:  {
    __typename: "Conversation",
    id: string,
    name: string | null,
    description: string | null,
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        isMuted: boolean | null,
        user:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
      } | null > | null,
    } | null,
  } | null,
};

export type GetConversationNameAndTypeByIdQueryVariables = {
  id: string,
};

export type GetConversationNameAndTypeByIdQuery = {
  getConversation:  {
    __typename: "Conversation",
    name: string | null,
    type: ConversationType | null,
  } | null,
};

export type GetConversationParticipationByIdQueryVariables = {
  id: string,
  membersLimit?: number | null,
};

export type GetConversationParticipationByIdQuery = {
  getConversation:  {
    __typename: "Conversation",
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        userId: string,
      } | null > | null,
    } | null,
  } | null,
};

export type GetUserNameAndPictureQueryVariables = {
  id: string,
};

export type GetUserNameAndPictureQuery = {
  getUser:  {
    __typename: "User",
    name: string | null,
    pictureUrl: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserAndStatusLightQueryVariables = {
  userIdStatus: string,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CalendarEntryParticipationByUserAndStatusLightQuery = {
  calendarEntryParticipationByUserAndStatusSorted:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items:  Array< {
      __typename: "CalendarEntryParticipation",
      id: string,
      calendarEntryId: string,
      calendarEntry:  {
        __typename: "CalendarEntry",
        id: string,
        title: string,
        description: string | null,
        start: string,
        end: string,
        organizationId: string | null,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
        participants:  {
          __typename: "ModelCalendarEntryParticipationConnection",
          items:  Array< {
            __typename: "CalendarEntryParticipation",
            id: string,
            status: CalendarEntryParticipationStatus,
            userId: string,
            user:  {
              __typename: "User",
              id: string,
              name: string | null,
              pictureUrl: string | null,
            },
          } | null > | null,
        } | null,
        locationName: string | null,
        locationActionType: string | null,
        locationActionParam: string | null,
        isVirtual: boolean | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type getOnlineUsersQueryVariables = {
  id: string,
};

export type getOnlineUsersQuery = {
  getCurrentLocationCounts:  {
    __typename: "CurrentLocationCounts",
    id: string,
    cnt: number,
  } | null,
};

export type usersInCallsInLoungeQueryVariables = {
  actionType?: UserActionType | null,
  duration?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type usersInCallsInLoungeQuery = {
  byActionType:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      id: string,
      param: string,
      user:  {
        __typename: "User",
        id: string,
        name: string | null,
        pictureUrl: string | null,
      },
    } | null > | null,
  } | null,
};

export type pageVisitsQueryVariables = {
  param?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type pageVisitsQuery = {
  userActionsByRoom:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      startTime: string,
      duration: number | null,
      endTime: string | null,
      source: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCalendarEntryLightQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetCalendarEntryLightQuery = {
  getCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    status: CalendarEntryParticipationStatus | null,
    userId: string,
    user:  {
      __typename: "User",
      id: string,
      name: string | null,
      pictureUrl: string | null,
    },
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        status: CalendarEntryParticipationStatus,
        userId: string,
        user:  {
          __typename: "User",
          id: string,
          name: string | null,
          pictureUrl: string | null,
        },
      } | null > | null,
    } | null,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
  } | null,
};

export type ListCalendarEntryByOrganizationQueryVariables = {
  organizationIdStatus?: string | null,
  start?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCalendarEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalendarEntryByOrganizationQuery = {
  calendarEntryByOrganizationAndStatusSorted:  {
    __typename: "ModelCalendarEntryConnection",
    items:  Array< {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      organizationId: string | null,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        name: string | null,
        pictureUrl: string | null,
      },
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        items:  Array< {
          __typename: "CalendarEntryParticipation",
          id: string,
          status: CalendarEntryParticipationStatus,
          userId: string,
          user:  {
            __typename: "User",
            id: string,
            name: string | null,
            pictureUrl: string | null,
          },
        } | null > | null,
      } | null,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserLightQueryVariables = {
  id: string,
};

export type GetUserLightQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type BatchGetUserPresenceLightQueryVariables = {
  listIds?: Array< string | null > | null,
};

export type BatchGetUserPresenceLightQuery = {
  batchGetUserPresence:  Array< {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
  } | null > | null,
};

export type OnNotificationCreatedByLambdaSubscriptionVariables = {
  userId: string,
};

export type OnNotificationCreatedByLambdaSubscription = {
  onNotificationCreated:  {
    __typename: "Notification",
    userId: string,
    type: NotificationType,
    content: string,
  } | null,
};

export type OnMessageCreatedLightSubscriptionVariables = {
  conversationId: string,
};

export type OnMessageCreatedLightSubscription = {
  onMessageCreated:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
  } | null,
};

export type OnConversationUpdatedLightSubscriptionVariables = {
  id: string,
};

export type OnConversationUpdatedLightSubscription = {
  onConversationUpdated:  {
    __typename: "Conversation",
    name: string | null,
    description: string | null,
  } | null,
};

export type OnUserConversationDeletedLightSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationDeletedLightSubscription = {
  onUserConversationDeleted:  {
    __typename: "UserConversation",
    id: string,
    conversationId: string,
  } | null,
};

export type OnUserConversationUpdatedLightSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationUpdatedLightSubscription = {
  onUserConversationUpdated:  {
    __typename: "UserConversation",
    conversation:  {
      __typename: "Conversation",
      id: string,
      name: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
    },
  } | null,
};

export type OnUserConversationCreatedByConversationLightSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationCreatedByConversationLightSubscription = {
  onUserConversationCreatedByConversation:  {
    __typename: "UserConversation",
    user:  {
      __typename: "User",
      id: string,
      name: string | null,
      pictureUrl: string | null,
    },
  } | null,
};

export type OnUserConversationDeletedByConversationLightSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationDeletedByConversationLightSubscription = {
  onUserConversationDeletedByConversation:  {
    __typename: "UserConversation",
    userId: string,
  } | null,
};

export type OnCalendarEntryParticipationCreatedLightSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationCreatedLightSubscription = {
  onCalendarEntryParticipationCreated:  {
    __typename: "CalendarEntryParticipation",
    id: string,
  } | null,
};

export type OnCalendarEntryParticipationDeletedLightSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationDeletedLightSubscription = {
  onCalendarEntryParticipationDeleted:  {
    __typename: "CalendarEntryParticipation",
    id: string,
  } | null,
};

export type OnMeetingInviteChangedLightSubscriptionVariables = {
  id: string,
};

export type OnMeetingInviteChangedLightSubscription = {
  onMeetingInviteChanged:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type EmptyQueryQuery = {
  _emptyQuery: string | null,
};

export type GetCalendarEntryQueryVariables = {
  id: string,
};

export type GetCalendarEntryQuery = {
  getCalendarEntry:  {
    __typename: "CalendarEntry",
    id: string,
    title: string,
    description: string | null,
    start: string,
    end: string,
    userId: string,
    participants:  {
      __typename: "ModelCalendarEntryParticipationConnection",
      items:  Array< {
        __typename: "CalendarEntryParticipation",
        id: string,
        calendarEntryId: string,
        userId: string,
        status: CalendarEntryParticipationStatus,
        start: string,
        userIdStatus: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    organizationId: string | null,
    status: CalendarEntryParticipationStatus | null,
    organizationIdStatus: string | null,
    topicName: string,
    userPoolName: string,
    locationName: string | null,
    locationActionType: string | null,
    locationActionParam: string | null,
    isVirtual: boolean | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type CalendarEntryByOrganizationAndStatusSortedQueryVariables = {
  filter?: ModelCalendarEntryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  organizationIdStatus?: string | null,
  sortDirection?: ModelSortDirection | null,
  start?: ModelStringKeyConditionInput | null,
};

export type CalendarEntryByOrganizationAndStatusSortedQuery = {
  calendarEntryByOrganizationAndStatusSorted:  {
    __typename: "ModelCalendarEntryConnection",
    items:  Array< {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type CalendarEntryParticipationByUserAndStatusSortedQueryVariables = {
  filter?: ModelCalendarEntryParticipationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  start?: ModelStringKeyConditionInput | null,
  userIdStatus: string,
};

export type CalendarEntryParticipationByUserAndStatusSortedQuery = {
  calendarEntryParticipationByUserAndStatusSorted:  {
    __typename: "ModelCalendarEntryParticipationConnection",
    items:  Array< {
      __typename: "CalendarEntryParticipation",
      id: string,
      calendarEntryId: string,
      calendarEntry:  {
        __typename: "CalendarEntry",
        id: string,
        title: string,
        description: string | null,
        start: string,
        end: string,
        userId: string,
        organizationId: string | null,
        status: CalendarEntryParticipationStatus | null,
        organizationIdStatus: string | null,
        topicName: string,
        userPoolName: string,
        locationName: string | null,
        locationActionType: string | null,
        locationActionParam: string | null,
        isVirtual: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      userId: string,
      status: CalendarEntryParticipationStatus,
      start: string,
      userIdStatus: string,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UserBusyTimesQueryVariables = {
  userId: string,
  start: string,
  end: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserBusyTimesQuery = {
  userBusyTimes:  {
    __typename: "UserBusyTimes",
    items:  Array< {
      __typename: "UserBusyTime",
      userId: string,
      start: string,
      end: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetConversationQueryVariables = {
  id: string,
};

export type GetConversationQuery = {
  getConversation:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
    mostRecentMessage: string | null,
    mostRecentMessageCreatedAt: string | null,
    memberIdsHash: string | null,
    updatedAt: string | null,
    createdAt: string,
    messages:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        authorId: string,
        content: string,
        conversationId: string,
        isSent: boolean | null,
        createdAt: string,
        sotName: string | null,
        members: Array< string | null > | null,
        _version: number | null,
        _deleted: boolean | null,
        _lastChangedAt: number | null,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
      startedAt: number | null,
    } | null,
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type GetConversationsByMembersQueryVariables = {
  memberId1: string,
  memberId2: string,
  filter?: ModelConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type GetConversationsByMembersQuery = {
  getConversationsByMembers:  {
    __typename: "ModelConversationConnection",
    items:  Array< {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetMessageQueryVariables = {
  id: string,
};

export type GetMessageQuery = {
  getMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type MessagesByConversationIdQueryVariables = {
  conversationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type MessagesByConversationIdQuery = {
  messagesByConversationId:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      authorId: string,
      content: string,
      conversationId: string,
      isSent: boolean | null,
      createdAt: string,
      sotName: string | null,
      members: Array< string | null > | null,
      _version: number | null,
      _deleted: boolean | null,
      _lastChangedAt: number | null,
      updatedAt: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        description: string | null,
        userId: string | null,
        mostRecentMessage: string | null,
        mostRecentMessageCreatedAt: string | null,
        memberIdsHash: string | null,
        updatedAt: string | null,
        createdAt: string,
      } | null,
      author:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
    startedAt: number | null,
  } | null,
};

export type SyncMessagesQueryVariables = {
  conversationId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelMessageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  lastSync?: number | null,
};

export type SyncMessagesQuery = {
  syncMessages:  {
    __typename: "ModelMessageConnection",
    items:  Array< {
      __typename: "Message",
      id: string,
      authorId: string,
      content: string,
      conversationId: string,
      isSent: boolean | null,
      createdAt: string,
      sotName: string | null,
      members: Array< string | null > | null,
      _version: number | null,
      _deleted: boolean | null,
      _lastChangedAt: number | null,
      updatedAt: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        description: string | null,
        userId: string | null,
        mostRecentMessage: string | null,
        mostRecentMessageCreatedAt: string | null,
        memberIdsHash: string | null,
        updatedAt: string | null,
        createdAt: string,
      } | null,
      author:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null > | null,
    nextToken: string | null,
    startedAt: number | null,
  } | null,
};

export type BatchGetUserPresenceQueryVariables = {
  listIds?: Array< string | null > | null,
};

export type BatchGetUserPresenceQuery = {
  batchGetUserPresence:  Array< {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null > | null,
};

export type GetUserConversationQueryVariables = {
  id: string,
};

export type GetUserConversationQuery = {
  getUserConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type UserConversationsByUserQueryVariables = {
  filter?: ModelUserConversationFilterInput | null,
  limit?: number | null,
  mostRecentMessageCreatedAt?: ModelStringKeyConditionInput | null,
  lastReadMessageCreatedAt?: ModelStringKeyConditionInput | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userId: string,
};

export type UserConversationsByUserQuery = {
  userConversationsByUser:  {
    __typename: "ModelUserConversationConnection",
    items:  Array< {
      __typename: "UserConversation",
      id: string,
      userId: string,
      conversationId: string,
      isMuted: boolean | null,
      isChatOpen: boolean | null,
      mostRecentMessageCreatedAt: string | null,
      lastReadMessageCreatedAt: string | null,
      createdAt: string,
      updatedAt: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        description: string | null,
        userId: string | null,
        mostRecentMessage: string | null,
        mostRecentMessageCreatedAt: string | null,
        memberIdsHash: string | null,
        updatedAt: string | null,
        createdAt: string,
      },
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UserConversationsByUserAndConversationQueryVariables = {
  conversationId?: ModelIDKeyConditionInput | null,
  filter?: ModelUserConversationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
  userId: string,
};

export type UserConversationsByUserAndConversationQuery = {
  userConversationsByUserAndConversation:  {
    __typename: "ModelUserConversationConnection",
    items:  Array< {
      __typename: "UserConversation",
      id: string,
      userId: string,
      conversationId: string,
      isMuted: boolean | null,
      isChatOpen: boolean | null,
      mostRecentMessageCreatedAt: string | null,
      lastReadMessageCreatedAt: string | null,
      createdAt: string,
      updatedAt: string,
      conversation:  {
        __typename: "Conversation",
        id: string,
        type: ConversationType | null,
        name: string | null,
        description: string | null,
        userId: string | null,
        mostRecentMessage: string | null,
        mostRecentMessageCreatedAt: string | null,
        memberIdsHash: string | null,
        updatedAt: string | null,
        createdAt: string,
      },
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCurrentLocationCountsQueryVariables = {
  id: string,
};

export type GetCurrentLocationCountsQuery = {
  getCurrentLocationCounts:  {
    __typename: "CurrentLocationCounts",
    id: string,
    cnt: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCurrentLocationCountssQueryVariables = {
  filter?: ModelCurrentLocationCountsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCurrentLocationCountssQuery = {
  listCurrentLocationCountss:  {
    __typename: "ModelCurrentLocationCountsConnection",
    items:  Array< {
      __typename: "CurrentLocationCounts",
      id: string,
      cnt: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetMeetingQueryVariables = {
  id: string,
};

export type GetMeetingQuery = {
  getMeeting:  {
    __typename: "Meeting",
    id: string,
    participants:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    start: string,
    end: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMeetingsQueryVariables = {
  filter?: ModelMeetingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMeetingsQuery = {
  listMeetings:  {
    __typename: "ModelMeetingConnection",
    items:  Array< {
      __typename: "Meeting",
      id: string,
      participants:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetRaisedHandQueryVariables = {
  id: string,
};

export type GetRaisedHandQuery = {
  getRaisedHand:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRaisedHandsQueryVariables = {
  filter?: ModelRaisedHandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRaisedHandsQuery = {
  listRaisedHands:  {
    __typename: "ModelRaisedHandConnection",
    items:  Array< {
      __typename: "RaisedHand",
      id: string | null,
      chimeMeetingId: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserActionQueryVariables = {
  id: string,
};

export type GetUserActionQuery = {
  getUserAction:  {
    __typename: "UserAction",
    id: string,
    userId: string,
    sotName: string,
    actionType: UserActionType,
    param: string,
    startTime: string,
    endTime: string | null,
    duration: number | null,
    source: string | null,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type ListUserActionsQueryVariables = {
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionsQuery = {
  listUserActions:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      id: string,
      userId: string,
      sotName: string,
      actionType: UserActionType,
      param: string,
      startTime: string,
      endTime: string | null,
      duration: number | null,
      source: string | null,
      topic: string,
      userType: string | null,
      countryCode: string | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserSessionQueryVariables = {
  id: string,
};

export type GetUserSessionQuery = {
  getUserSession:  {
    __typename: "UserSession",
    id: string,
    userId: string,
    sotName: string,
    location: string,
    locationLevel1: string,
    locationLevel2: string | null,
    locationLevel3: string | null,
    ttl: number | null,
    time: string | null,
    source: string | null,
    queryHelper: string,
    topic: string,
    userType: string | null,
    countryCode: string | null,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type ListUserSessionsQueryVariables = {
  filter?: ModelUserSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserSessionsQuery = {
  listUserSessions:  {
    __typename: "ModelUserSessionConnection",
    items:  Array< {
      __typename: "UserSession",
      id: string,
      userId: string,
      sotName: string,
      location: string,
      locationLevel1: string,
      locationLevel2: string | null,
      locationLevel3: string | null,
      ttl: number | null,
      time: string | null,
      source: string | null,
      queryHelper: string,
      topic: string,
      userType: string | null,
      countryCode: string | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UserActionsByRoomQueryVariables = {
  param?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserActionsByRoomQuery = {
  userActionsByRoom:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      id: string,
      userId: string,
      sotName: string,
      actionType: UserActionType,
      param: string,
      startTime: string,
      endTime: string | null,
      duration: number | null,
      source: string | null,
      topic: string,
      userType: string | null,
      countryCode: string | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type ByActionTypeQueryVariables = {
  actionType?: UserActionType | null,
  duration?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByActionTypeQuery = {
  byActionType:  {
    __typename: "ModelUserActionConnection",
    items:  Array< {
      __typename: "UserAction",
      id: string,
      userId: string,
      sotName: string,
      actionType: UserActionType,
      param: string,
      startTime: string,
      endTime: string | null,
      duration: number | null,
      source: string | null,
      topic: string,
      userType: string | null,
      countryCode: string | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UserSessionsByLocationQueryVariables = {
  sotName?: string | null,
  locationLevel2?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserSessionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserSessionsByLocationQuery = {
  userSessionsByLocation:  {
    __typename: "ModelUserSessionConnection",
    items:  Array< {
      __typename: "UserSession",
      id: string,
      userId: string,
      sotName: string,
      location: string,
      locationLevel1: string,
      locationLevel2: string | null,
      locationLevel3: string | null,
      ttl: number | null,
      time: string | null,
      source: string | null,
      queryHelper: string,
      topic: string,
      userType: string | null,
      countryCode: string | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification:  {
    __typename: "Notification",
    id: string,
    userId: string,
    type: NotificationType,
    displayGroup: NotificationDisplayGroup | null,
    content: string,
    userIdDisplayGroup: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: NotificationType,
      displayGroup: NotificationDisplayGroup | null,
      content: string,
      userIdDisplayGroup: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type NotificationsByUserIdQueryVariables = {
  userId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdQuery = {
  notificationsByUserId:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: NotificationType,
      displayGroup: NotificationDisplayGroup | null,
      content: string,
      userIdDisplayGroup: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type NotificationsByUserIdAndDisplayGroupSortedQueryVariables = {
  userIdDisplayGroup?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByUserIdAndDisplayGroupSortedQuery = {
  notificationsByUserIdAndDisplayGroupSorted:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      userId: string,
      type: NotificationType,
      displayGroup: NotificationDisplayGroup | null,
      content: string,
      userIdDisplayGroup: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUnreadCounterQueryVariables = {
  id: string,
};

export type GetUnreadCounterQuery = {
  getUnreadCounter:  {
    __typename: "UnreadCounter",
    id: string,
    requests: number | null,
    contacts: number | null,
    conversations: number | null,
    schedules: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type EmptySubscriptionSubscription = {
  _emptySubscription: string | null,
};

export type OnCalendarEntryParticipationCreatedSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationCreatedSubscription = {
  onCalendarEntryParticipationCreated:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    calendarEntry:  {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnCalendarEntryParticipationDeletedSubscriptionVariables = {
  userId: string,
};

export type OnCalendarEntryParticipationDeletedSubscription = {
  onCalendarEntryParticipationDeleted:  {
    __typename: "CalendarEntryParticipation",
    id: string,
    calendarEntryId: string,
    calendarEntry:  {
      __typename: "CalendarEntry",
      id: string,
      title: string,
      description: string | null,
      start: string,
      end: string,
      userId: string,
      participants:  {
        __typename: "ModelCalendarEntryParticipationConnection",
        nextToken: string | null,
      } | null,
      organizationId: string | null,
      status: CalendarEntryParticipationStatus | null,
      organizationIdStatus: string | null,
      topicName: string,
      userPoolName: string,
      locationName: string | null,
      locationActionType: string | null,
      locationActionParam: string | null,
      isVirtual: boolean | null,
      createdAt: string,
      updatedAt: string,
      user:  {
        __typename: "User",
        id: string,
        presenceStatus: string,
        lastConnected: string,
        name: string | null,
        pictureUrl: string | null,
        createdAt: string,
        updatedAt: string,
      },
    } | null,
    userId: string,
    status: CalendarEntryParticipationStatus,
    start: string,
    userIdStatus: string,
    createdAt: string,
    updatedAt: string,
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnConversationUpdatedSubscriptionVariables = {
  id: string,
};

export type OnConversationUpdatedSubscription = {
  onConversationUpdated:  {
    __typename: "Conversation",
    id: string,
    type: ConversationType | null,
    name: string | null,
    description: string | null,
    userId: string | null,
    mostRecentMessage: string | null,
    mostRecentMessageCreatedAt: string | null,
    memberIdsHash: string | null,
    updatedAt: string | null,
    createdAt: string,
    messages:  {
      __typename: "ModelMessageConnection",
      items:  Array< {
        __typename: "Message",
        id: string,
        authorId: string,
        content: string,
        conversationId: string,
        isSent: boolean | null,
        createdAt: string,
        sotName: string | null,
        members: Array< string | null > | null,
        _version: number | null,
        _deleted: boolean | null,
        _lastChangedAt: number | null,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
      startedAt: number | null,
    } | null,
    members:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnMeetingInviteReceivedSubscriptionVariables = {
  inviteeId: string,
};

export type OnMeetingInviteReceivedSubscription = {
  onMeetingInviteReceived:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      participants:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    invitationType: InvitationType | null,
    meetingTitle: string | null,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnMeetingInviteChangedSubscriptionVariables = {
  id: string,
};

export type OnMeetingInviteChangedSubscription = {
  onMeetingInviteChanged:  {
    __typename: "MeetingParticipant",
    id: string,
    meetingId: string,
    meeting:  {
      __typename: "Meeting",
      id: string,
      participants:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      start: string,
      end: string | null,
      createdAt: string,
      updatedAt: string,
    },
    inviterId: string,
    inviteeId: string,
    status: InviteStatus,
    created: string,
    invitationType: InvitationType | null,
    meetingTitle: string | null,
    createdAt: string,
    updatedAt: string,
    inviter:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    invitee:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnMessageCreatedSubscriptionVariables = {
  conversationId: string,
};

export type OnMessageCreatedSubscription = {
  onMessageCreated:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnCreateMessageSubscription = {
  onCreateMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdateMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnUpdateMessageSubscription = {
  onUpdateMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeleteMessageSubscriptionVariables = {
  conversationId: string,
};

export type OnDeleteMessageSubscription = {
  onDeleteMessage:  {
    __typename: "Message",
    id: string,
    authorId: string,
    content: string,
    conversationId: string,
    isSent: boolean | null,
    createdAt: string,
    sotName: string | null,
    members: Array< string | null > | null,
    _version: number | null,
    _deleted: boolean | null,
    _lastChangedAt: number | null,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
    author:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnNotificationCreatedSubscriptionVariables = {
  userId: string,
};

export type OnNotificationCreatedSubscription = {
  onNotificationCreated:  {
    __typename: "Notification",
    id: string,
    userId: string,
    type: NotificationType,
    displayGroup: NotificationDisplayGroup | null,
    content: string,
    userIdDisplayGroup: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnRaisedHandCreatedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnRaisedHandCreatedSubscription = {
  onRaisedHandCreated:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnRaisedHandDeletedSubscriptionVariables = {
  chimeMeetingId: string,
};

export type OnRaisedHandDeletedSubscription = {
  onRaisedHandDeleted:  {
    __typename: "RaisedHand",
    id: string | null,
    chimeMeetingId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUnreadCounterByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateUnreadCounterByIdSubscription = {
  onUpdateUnreadCounterById:  {
    __typename: "UnreadCounter",
    id: string,
    requests: number | null,
    contacts: number | null,
    conversations: number | null,
    schedules: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserByIdSubscriptionVariables = {
  id: string,
};

export type OnUpdateUserByIdSubscription = {
  onUpdateUserById:  {
    __typename: "User",
    id: string,
    presenceStatus: string,
    lastConnected: string,
    name: string | null,
    pictureUrl: string | null,
    outgoingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    incomingMeetings:  {
      __typename: "ModelMeetingParticipantConnection",
      items:  Array< {
        __typename: "MeetingParticipant",
        id: string,
        meetingId: string,
        inviterId: string,
        inviteeId: string,
        status: InviteStatus,
        created: string,
        invitationType: InvitationType | null,
        meetingTitle: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    conversations:  {
      __typename: "ModelUserConversationConnection",
      items:  Array< {
        __typename: "UserConversation",
        id: string,
        userId: string,
        conversationId: string,
        isMuted: boolean | null,
        isChatOpen: boolean | null,
        mostRecentMessageCreatedAt: string | null,
        lastReadMessageCreatedAt: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnUserConversationUpdatedSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationUpdatedSubscription = {
  onUserConversationUpdated:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnUserConversationDeletedSubscriptionVariables = {
  userId: string,
};

export type OnUserConversationDeletedSubscription = {
  onUserConversationDeleted:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnUserConversationCreatedByConversationSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationCreatedByConversationSubscription = {
  onUserConversationCreatedByConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};

export type OnUserConversationDeletedByConversationSubscriptionVariables = {
  conversationId: string,
};

export type OnUserConversationDeletedByConversationSubscription = {
  onUserConversationDeletedByConversation:  {
    __typename: "UserConversation",
    id: string,
    userId: string,
    conversationId: string,
    isMuted: boolean | null,
    isChatOpen: boolean | null,
    mostRecentMessageCreatedAt: string | null,
    lastReadMessageCreatedAt: string | null,
    createdAt: string,
    updatedAt: string,
    conversation:  {
      __typename: "Conversation",
      id: string,
      type: ConversationType | null,
      name: string | null,
      description: string | null,
      userId: string | null,
      mostRecentMessage: string | null,
      mostRecentMessageCreatedAt: string | null,
      memberIdsHash: string | null,
      updatedAt: string | null,
      createdAt: string,
      messages:  {
        __typename: "ModelMessageConnection",
        nextToken: string | null,
        startedAt: number | null,
      } | null,
      members:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      presenceStatus: string,
      lastConnected: string,
      name: string | null,
      pictureUrl: string | null,
      outgoingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      incomingMeetings:  {
        __typename: "ModelMeetingParticipantConnection",
        nextToken: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      conversations:  {
        __typename: "ModelUserConversationConnection",
        nextToken: string | null,
      } | null,
    },
  } | null,
};
