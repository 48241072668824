import { createState, State, useState } from "@hookstate/core"
import { ConsoleLogger, DefaultVideoTransformDevice, LogLevel, VideoTileState } from "amazon-chime-sdk-js"
import { useAlertState } from "../../globalStates/AlertState"
import { defaultLogger as logger } from "../../globalStates/AppState"
import branding from "../../branding/branding"
import { CrsAlertType } from "../../ui/CrsAlert"
import { chimeSdk } from "../ChimeSdkWrapper"
import { LocalVideoStatus } from "../enums/LocalVideoStatus"
import { VideoInputStorageKey } from "../hooks/useDevices"
import { LogoProcessor, Position } from "../processor/LogoProcessor"
import { BackgroundType, VirtualBackgroundProcessor } from "../processor/VirtualBackgroundProcessor"
import DeviceType from "../types/DeviceType"
import { ShallowVideoTileState } from "./ChimeContext"

const defaultPosition: Position = "topleft"

const logoProcessor = new LogoProcessor(defaultPosition)
const virtualBackgroundProcessor = new VirtualBackgroundProcessor()
let videoTransformDevice: DefaultVideoTransformDevice | undefined
interface StateValues {
    localTile: ShallowVideoTileState | null
    localVideoStatus: LocalVideoStatus
    localVideoStatusDesiredByUser: LocalVideoStatus
    remoteTiles: ShallowVideoTileState[]
    hasWebcam: boolean
    logoSrc: string | null
    logoPosition: Position
    background: BackgroundType
}

const getStartValues = (): StateValues => {
    return {
        hasWebcam: false,
        localVideoStatus: LocalVideoStatus.Disabled,
        localVideoStatusDesiredByUser: LocalVideoStatus.Disabled,
        localTile: null,
        remoteTiles: [],
        logoSrc: null,
        logoPosition: defaultPosition,
        background: "none"
    }
}
const state = createState<StateValues>(getStartValues())

export interface VideoContext {
    onCallStart: (currentVideoInputDevice: DeviceType | null) => Promise<void>
    onCallEnd: () => Promise<void>
    getLocalTile: () => ShallowVideoTileState | null
    getLocalVideoStatus: () => LocalVideoStatus
    getRemoteTiles: () => ShallowVideoTileState[]
    bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => void
    unbindVideoElement: (tileId: number) => void
    startPreviewVideo: (videoElement: HTMLVideoElement) => void
    stopPreviewVideo: (videoElement: HTMLVideoElement) => void
    toggleLocalVideoTile: () => Promise<void>
    disableLocalVideoTile: () => Promise<void>
    updateVideoInputDevice: () => Promise<void>
    setLogoPosition: (position: Position) => void
    getLogoPosition: () => Position
    setLogoSrc: (src: string | null) => void
    getLogoSrc: () => string | null
    setBackground: (backgroundType: BackgroundType, src: string | null) => void
    getBackground: () => BackgroundType
    hasWebcam: () => boolean
}

const useStateWrapper = (state: State<StateValues>): VideoContext => {
    const remoteTilesState = useState(state.remoteTiles)
    const alertState = useAlertState()

    const setVideoInput = async () => {
        setLocalVideoStatus(LocalVideoStatus.Loading)
        const deviceId = localStorage.getItem(VideoInputStorageKey) // From Storage because of circular reference when using useDevices
        if (!deviceId) {
            await Promise.all([chimeSdk.chooseVideoInputDevice(null), videoTransformDevice?.stop()])
            videoTransformDevice = undefined
        } else {
            if (!videoTransformDevice) {
                videoTransformDevice = new DefaultVideoTransformDevice(
                    new ConsoleLogger("Video Transformer", LogLevel.OFF),
                    deviceId,
                    [virtualBackgroundProcessor, logoProcessor]
                )
            } else videoTransformDevice = videoTransformDevice.chooseNewInnerDevice(deviceId)
            await chimeSdk.chooseVideoInputDevice(videoTransformDevice)
        }
        setLocalVideoStatus(state.value.localVideoStatusDesiredByUser)
    }

    const toggleLocalVideoTile = async () => {
        // no webcam? Then we won't toggle the local video
        if (!state.value.hasWebcam) return

        if (state.value.localVideoStatus === LocalVideoStatus.Disabled) {
            setLocalVideoStatus(LocalVideoStatus.Loading)
            try {
                await setVideoInput()
                chimeSdk.audioVideo?.startLocalVideoTile()
                setLocalVideoStatus(LocalVideoStatus.Enabled)
                setlocalVideoStatusDesiredByUser(LocalVideoStatus.Enabled)
            } catch (error: any) {
                // eslint-disable-next-line
                logger.error({
                    message: "Chime Context choose video input device failed",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
                alertState.show({
                    message: branding.globalStatePopupTexts.errorNoCameraPermission,
                    type: CrsAlertType.DANGER,
                    duration: 3000
                })
                setLocalVideoStatus(LocalVideoStatus.Disabled)
                setlocalVideoStatusDesiredByUser(LocalVideoStatus.Disabled)
            }
        } else if (state.value.localVideoStatus === LocalVideoStatus.Enabled) {
            disableLocalVideoTile()
        }
    }

    const disableLocalVideoTile = async () => {
        if (!state.value.hasWebcam) return
        setLocalVideoStatus(LocalVideoStatus.Loading)
        chimeSdk.audioVideo?.stopLocalVideoTile()
        await chimeSdk.chooseVideoInputDevice(null)
        setLocalVideoStatus(LocalVideoStatus.Disabled)
        setlocalVideoStatusDesiredByUser(LocalVideoStatus.Disabled)
    }

    const setLocalVideoStatus = (videoStatus: LocalVideoStatus) => {
        state.merge({ localVideoStatus: videoStatus })
    }

    const setlocalVideoStatusDesiredByUser = (videoStatus: LocalVideoStatus) => {
        state.merge({ localVideoStatusDesiredByUser: videoStatus })
    }

    const observer = {
        videoTileDidUpdate: (tileState: VideoTileState): void => {
            if (!tileState.boundAttendeeId || !tileState.tileId) {
                return
            }
            const newTileState = getShallowVideoTileState(tileState)

            if (newTileState.localTile) {
                state.merge({ localTile: newTileState })
            } else if (tileState.boundAttendeeId && tileState.tileId && !newTileState.isContent && !newTileState.localTile) {
                const oldTiles = remoteTilesState.get()
                let newTiles: ShallowVideoTileState[] = []
                let contains = false
                for (let i = 0; i < oldTiles.length; i++) {
                    if (oldTiles[i].tileId === newTileState.tileId) {
                        contains = true
                        newTiles = newTiles.concat(newTileState)
                    } else {
                        newTiles = newTiles.concat(getShallowVideoTileState(oldTiles[i]))
                    }
                }
                if (!contains) {
                    newTiles = newTiles.concat(newTileState)
                }
                remoteTilesState.set(newTiles)
            }
        },
        videoTileWasRemoved: (tileId: number): void => {
            const oldTiles = remoteTilesState.get()
            const newTiles = []
            let removed: boolean = false
            for (let i = 0; i < oldTiles.length; i++) {
                if (oldTiles[i].tileId !== tileId) {
                    newTiles.push(getShallowVideoTileState(oldTiles[i]))
                } else {
                    removed = true
                }
            }
            // Change only needed if we removed a tile
            if (removed) remoteTilesState.set(newTiles)
        }
    }

    return {
        onCallStart: async (currentVideoInputDevice: DeviceType | null) => {
            if (currentVideoInputDevice) {
                state.merge({ hasWebcam: true })
                chimeSdk.audioVideo?.startLocalVideoTile()
            } else {
                state.merge({ hasWebcam: false })
            }
            chimeSdk.audioVideo?.addObserver(observer)
        },
        onCallEnd: async () => {
            chimeSdk.audioVideo?.stopLocalVideoTile()
            chimeSdk.audioVideo?.removeObserver(observer)
            await Promise.all([
                chimeSdk.audioVideo?.stopVideoInput(),
                chimeSdk.chooseVideoInputDevice(null),
                videoTransformDevice?.stop()
            ])
        },
        getLocalTile: () => {
            return state.value.localTile
        },
        getLocalVideoStatus: () => {
            return state.value.localVideoStatus
        },
        getRemoteTiles: () => {
            return state.value.remoteTiles
        },
        bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => {
            chimeSdk.audioVideo?.bindVideoElement(tileId, videoElement)
        },
        unbindVideoElement: (tileId: number) => {
            chimeSdk.audioVideo?.unbindVideoElement(tileId)
        },
        startPreviewVideo: (videoElement: HTMLVideoElement) => {
            chimeSdk.audioVideo?.startVideoPreviewForVideoInput(videoElement)
        },
        stopPreviewVideo: (videoElement: HTMLVideoElement) => {
            chimeSdk.audioVideo?.stopVideoPreviewForVideoInput(videoElement)
        },
        toggleLocalVideoTile: toggleLocalVideoTile,
        disableLocalVideoTile: disableLocalVideoTile,
        updateVideoInputDevice: async () => {
            setVideoInput()
        },
        setLogoPosition: (position: Position) => {
            logoProcessor.setPosition(position)
            state.merge({ logoPosition: position })
        },
        getLogoPosition: () => {
            return state.value.logoPosition
        },
        setLogoSrc: (src: string | null) => {
            if (src === null) logoProcessor.setSrc("")
            else logoProcessor.setSrc(src)
            state.merge({ logoSrc: src })
        },
        getLogoSrc: () => {
            return state.value.logoSrc
        },
        setBackground: (type: BackgroundType, src: string | null) => {
            virtualBackgroundProcessor.setBackground(type, src)
            state.merge({ background: type })
        },
        getBackground: () => {
            return state.value.background
        },
        hasWebcam: () => {
            return state.value.hasWebcam
        }
    }
}

export const getShallowVideoTileState = (videoTileState: VideoTileState | ShallowVideoTileState): ShallowVideoTileState => {
    return {
        active: videoTileState.active,
        tileId: videoTileState.tileId,
        isContent: videoTileState.isContent,
        localTile: videoTileState.localTile,
        boundAttendeeId: videoTileState.boundAttendeeId
    }
}

export const useVideoContext = (): VideoContext => useStateWrapper(useState(state))
