import branding from "../../branding/branding"

// determines if a string is falsy or contains whitespaces only
export const isNullOrEmpty = (inputString: string | undefined): boolean => {
    return !inputString || /^ *$/.test(inputString)
}

// returns the title of a given user in the default format. example: "Software Developer at Corussoft GmbH". if a user is missing one of the two(company or position), returns only the one that is available,
// without the filler word, example: "Software Developer" or "Corussoft GmbH".
// if the given user is missing both, an empty string gets returned
export function getUserTitle(positionEn?: string, positionDe?: string, company?: string, organization?: string): string {
    const position = localStorage.getItem("virtualGuide-language") === "en" ? positionEn : positionDe ?? positionEn

    const companyFinal = company || organization || ``
    const positionFinal = position || ``
    const filler =
        !isNullOrEmpty(positionFinal) && !isNullOrEmpty(companyFinal) ? branding.communicationArea.personCompanyLink : ``

    return `${positionFinal} ${filler} ${companyFinal}`
}

// a version of getUserTitle that is used when searching users through search (when scheduling a meeting, etc.), couldn't be merged into a single function because it would require more parameters
// returns the title of a given user in the userSearch format. example: " | Software Developer / Corussoft"
export function getSearchPersonTitle(company: string, position: string): string {
    const companyFinal = !isNullOrEmpty(company) ? company : ``
    const positionFinal = !isNullOrEmpty(position) ? position : ``
    const divider = positionFinal && companyFinal ? "/" : ``

    return `${positionFinal} ${divider} ${companyFinal}`
}
