import { DeviceSettingsRoot, SettingsGroup } from "../AudioSettings/AudioSettingsStyles"

import { NoContentShift } from "../BackgroundSettings/BackgroundSettingsStyles"
import { detect } from "detect-browser"
import { PreviewVideoContainer, PreviewVideoOverlay } from "./VideoSettingsStyles"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import CameraSelection from "../CameraSelection/CameraSelection"
import { CenteredSpinnerWrapper, SettingsLabel } from "../Settings/SettingsStyles"
import { useVideoInputs } from "amazon-chime-sdk-component-library-react"
import Spinner from "react-bootstrap/esm/Spinner"
import branding from "../../../branding/branding"

function VideoSettings() {
    const browserInfo = detect()
    const audioInputs = useVideoInputs()
    const hasInputDevices = audioInputs.selectedDevice

    return (
        <DeviceSettingsRoot>
            {hasInputDevices ? (
                <>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            <NoContentShift browser={browserInfo?.name}>
                                <PreviewVideo />
                                <PreviewVideoOverlay>
                                    <span> {branding.audioVideoSettings.preview}</span>
                                </PreviewVideoOverlay>
                            </NoContentShift>
                        </PreviewVideoContainer>
                        <SettingsLabel>{branding.audioVideoSettings.videoInputLabel}</SettingsLabel>
                        <CameraSelection />
                    </SettingsGroup>
                </>
            ) : (
                <CenteredSpinnerWrapper>
                    <Spinner animation={"border"} />
                </CenteredSpinnerWrapper>
            )}
        </DeviceSettingsRoot>
    )
}

export default VideoSettings
