import React from "react"
import { useNotificationState, Severity } from "amazon-chime-sdk-component-library-react"
import StageNotification from "./StageNotification"
import { StageNotificationsRoot } from "./StageNotificationStyles"

export interface NotificationPayloadType {
    severity: Severity
    icon?: React.ReactElement
    message: string
    onClose?: () => void
    isClosable: boolean
    autoCloseDelay?: number
    button?: React.ReactElement
    replaceAll?: boolean
}

const StageNotifications = () => (
    <StageNotificationsRoot>
        <NotificationGroup />
    </StageNotificationsRoot>
)

const NotificationGroup = () => {
    const { notifications } = useNotificationState()

    const notificationItems = notifications.map(({ id, message, severity, autoCloseDelay, replaceAll, ...rest }): any => (
        <StageNotification
            id={id}
            key={id}
            message={message}
            severity={severity}
            autoCloseDelay={autoCloseDelay}
            replaceAll={replaceAll}
            {...rest}
        />
    ))

    return <>{notificationItems}</>
}

export default StageNotifications
