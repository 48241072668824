import styled from "styled-components"
import classNames from "classnames"
import TreeMenu, { Item, TreeMenuItem } from "react-simple-tree-menu"
import "../../../../node_modules/react-simple-tree-menu/dist/main.css"
import branding from "../../../branding/branding"
import { useSuggestContext } from "../../../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../../../navigationArea/RoutePaths"
import { device } from "../../../utils/Device"
import React, { ReactNode } from "react"
import Highlighter from "react-highlight-words"
import { IconShareLink, IconTriangleDown, IconTriangleRight } from "../../../ui/Icons"
import { IconTriangleDivDown, IconTriangleDivRight } from "./Categories"

const SimpleTreeRoot = styled.div<{ horizontalPadding?: boolean }>`
    padding-left: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    padding-right: ${(props) => (props.horizontalPadding ? "7px" : "0")};
    & .rstm-tree-item-group {
        & li.rstm-tree-item--active,
        li.rstm-tree-item--focused {
            background-color: white;
            box-shadow: unset;
            font-weight: 700;
            color: ${branding.categoriesPageContent.selectedCategoryTextColor};
        }
    }

    & .rstm-tree-item-level0 {
        color: ${branding.categoriesPageContent.categoriesListInactiveColor};
        font-family: ${branding.categoriesPageContent.categoriesListFont};
    }

    @media ${device.mobile} {
        padding-right: 30px;

        & .rstm-tree-item-level0 {
            color: ${branding.categoriesPageContent.categoriesListInactiveColor};
            font-family: ${branding.categoriesPageContent.categoriesListFont};
            font-size: 14px !important;
        }

        & .rstm-tree-item-level1 {
            color: ${branding.categoriesPageContent.categoriesListInactiveColor};
            font-size: 14px !important;
        }
    }
`

const StyledList = styled.ul`
    /* account for blue border on :focus-visible for keyboard navigation*/
    padding: 4px 4px 0.5px 4px;
    margin-left: -4px;
`

export interface TreeData {
    key: string
    label: string
    nodes?: Array<TreeData>
    code?: string
}

interface SimpleTreeDataProps {
    treeData: Array<TreeData>
    horizontalPadding?: boolean
    showSearchResults?: boolean
    searchParam?: string
    showCategoryHierarchy?: boolean
}

const SimpleTreeComponent = (treeProps: SimpleTreeDataProps) => {
    const searchParam: string = treeProps.searchParam || ""

    return (
        <SimpleTreeRoot horizontalPadding={treeProps.horizontalPadding} onClick={() => {}}>
            {treeProps.treeData.length && (
                <>
                    {/* component call of search results is separated to be able to always show opened nodes for search results, so results in all the levels of the tree are shown */}
                    {treeProps.showSearchResults ? (
                        <TreeMenu
                            data={treeProps.treeData}
                            hasSearch={false}
                            initialOpenNodes={treeProps.treeData.map((item: TreeData) => {
                                return item.key
                            })}
                            initialFocusKey={"f0EB135mneyIOAtf8dqO"} // force that no item is focussed initially
                        >
                            {({ items }) => (
                                <>
                                    {items.map(({ key, ...itemProps }) => (
                                        <ItemComponent
                                            key={key}
                                            customKey={key}
                                            treeData={treeProps.treeData}
                                            searchParam={searchParam}
                                            openedIcon={
                                                <IconTriangleDivDown>
                                                    {IconTriangleDown({
                                                        fill: branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </IconTriangleDivDown>
                                            }
                                            closedIcon={
                                                <IconTriangleDivRight>
                                                    {IconTriangleRight({
                                                        fill: branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </IconTriangleDivRight>
                                            }
                                            showCategoryHierarchy={treeProps.showCategoryHierarchy}
                                            {...itemProps}
                                        ></ItemComponent>
                                    ))}
                                </>
                            )}
                        </TreeMenu>
                    ) : (
                        <TreeMenu
                            data={treeProps.treeData}
                            hasSearch={false}
                            debounceTime={125}
                            initialFocusKey={"f0EB135mneyIOAtf8dqO"} // force that no item is focussed initially
                        >
                            {({ items }) => (
                                <StyledList>
                                    {items.map(({ key, ...props }) => (
                                        <ItemComponent
                                            key={key}
                                            customKey={key}
                                            treeData={treeProps.treeData}
                                            searchParam={searchParam}
                                            openedIcon={
                                                <IconTriangleDivDown>
                                                    {IconTriangleDown({
                                                        fill: branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </IconTriangleDivDown>
                                            }
                                            closedIcon={
                                                <IconTriangleDivRight>
                                                    {IconTriangleRight({
                                                        fill: branding.sideIconBar.sideIconColorDark
                                                    })}
                                                </IconTriangleDivRight>
                                            }
                                            showCategoryHierarchy={treeProps.showCategoryHierarchy}
                                            {...props}
                                        ></ItemComponent>
                                    ))}
                                </StyledList>
                            )}
                        </TreeMenu>
                    )}
                </>
            )}
        </SimpleTreeRoot>
    )
}

//code below is adjusted from react-simple-tree-menu package itself: https://github.com/iannbing/react-simple-tree-menu/blob/master/src/TreeMenu/renderProps.tsx

const LEVEL_SPACE = 2.75 // rem

const ToggleIcon = ({ on, openedIcon, closedIcon }: { on: boolean; openedIcon: ReactNode; closedIcon: ReactNode }) => (
    <div role="img" aria-label="Toggle" className="rstm-toggle-icon-symbol">
        {on ? openedIcon : closedIcon}
    </div>
)

const StyledListItem = styled.li<{ clickable: boolean }>`
    display: flex;
    padding: 8px 15px;
    background-color: ${() => branding.organizationDetailPageContent.categoryFillColor};
    color: ${() => branding.organizationDetailPageContent.categoryTextColor};
    font-size: 14px;
    font-weight: 700;

    margin: 0 0 5px 5px;
    border-radius: 5px;
    cursor: ${(props) => (props.clickable ? "pointer" : "default !important")};
    border: none;
`

const CenterVerticallyContainer = styled.div`
    display: inline-flex;
    align-items: center;
    width: 100%;
`

const StyledHighlighter = styled(Highlighter)`
    padding-top: 2px;
`

const UnderlineOnHoverContainer = styled.div`
    & :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const RedirectButtonContainer = styled.div`
    right: 15px;
    position: absolute;
    cursor: pointer;
`

const IconPlaceHolder = styled.div`
    width: 32px;
    height: 32px;
`

const NumberOfSubcategoriesContainer = styled.span`
    margin-left: 3px;
`

interface ItemComponentProps extends TreeMenuItem {
    treeData: TreeData[]
    customKey: string
    showCategoryHierarchy?: boolean
}

export const ItemComponent: React.FunctionComponent<ItemComponentProps> = (props) => {
    const suggestState = useSuggestContext()
    const history = useHistory()

    const addCategoryToSearchFilter = (key: string, label: string, props: Item) => {
        const formattedKey = key.replace(props.parent + "/", "")
        suggestState.addExternalSuggestion({
            icon: "",
            id: "",
            subtitle: "",
            title: label,
            value: "cat_" + formattedKey,
            active: true
        })
        history.push(globalSearchResultPageRoute)
    }

    const handleItemClick: React.MouseEventHandler<Element> = (event: React.MouseEvent) => {
        if (props.showCategoryHierarchy === false) {
            handleRedirectClick(event)
        } else {
            props.hasNodes && props.toggleNode && props.toggleNode()
        }
        event.stopPropagation()
    }

    const handleRedirectClick: React.MouseEventHandler<Element> = (event: React.MouseEvent) => {
        addCategoryToSearchFilter(props.customKey, props.label, props)
        event.stopPropagation()
    }

    function getNumberOfChildren(): number {
        const node = getNode(props.customKey, props.treeData)
        return node?.nodes?.length ?? 0
    }

    function getNode(key: string, treeData: TreeData[]): TreeData | undefined {
        const formattedKey = getNameFromKey(key)
        let tmpNode: TreeData | undefined
        treeData.forEach((node: TreeData) => {
            if (formattedKey === node.key) {
                tmpNode = node
            }
        })
        if (tmpNode === undefined) {
            treeData.forEach((node) => {
                if (node.nodes && tmpNode === undefined) {
                    tmpNode = getNode(formattedKey, node.nodes)
                }
            })
        }
        return tmpNode
    }

    function getNameFromKey(key: string): string {
        const tokens = key.split("/")
        return tokens[tokens.length - 1]
    }

    return (
        <StyledListItem
            className={classNames(
                "rstm-tree-item",
                `rstm-tree-item-level${props.level}`,
                { "rstm-tree-item--active": props.active },
                { "rstm-tree-item--focused": props.focused }
            )}
            style={{
                marginLeft: `${props.level * LEVEL_SPACE}rem`,
                ...props.style
            }}
            role="button"
            aria-pressed={props.active}
            onClick={handleItemClick}
            clickable={props.hasNodes || !props.showCategoryHierarchy}
        >
            <CenterVerticallyContainer>
                {props.hasNodes ? (
                    <div className="rstm-toggle-icon">
                        <ToggleIcon on={props.isOpen} openedIcon={props.openedIcon} closedIcon={props.closedIcon} />
                    </div>
                ) : (
                    <IconPlaceHolder />
                )}
                <UnderlineOnHoverContainer onClick={handleRedirectClick}>
                    <StyledHighlighter
                        caseSensitive={false}
                        searchWords={[props.searchParam]}
                        highlightStyle={{
                            display: "inline",
                            backgroundColor: branding.categoriesPageContent.categorySearchHighlightBackgroundColor,
                            color: branding.categoriesPageContent.categorySearchHighlightFontColor,
                            fontWeight: "bold",
                            padding: 0
                        }}
                        textToHighlight={props.label}
                    />
                </UnderlineOnHoverContainer>
                {props.hasNodes && <NumberOfSubcategoriesContainer>({getNumberOfChildren()})</NumberOfSubcategoriesContainer>}
                {props.showCategoryHierarchy && (
                    <RedirectButtonContainer onClick={handleRedirectClick}>
                        {IconShareLink({ fill: branding.sideIconBar.sideIconColorDark, width: "14px", height: "14px" })}
                    </RedirectButtonContainer>
                )}
            </CenterVerticallyContainer>
        </StyledListItem>
    )
}

export default SimpleTreeComponent
