import {
    ActionType,
    MicrophoneActivity,
    Severity,
    useAttendeeStatus,
    useMeetingManager,
    useNotificationDispatch
} from "amazon-chime-sdk-component-library-react"
import { useContext, useEffect, useState } from "react"
import { ModalType } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { Action, CommunicationModals, createActions } from "../../../communicationArea/CommunicationOptions"
import { useContactState } from "../../../communicationArea/ContactState"
import { useChimeContext } from "../../../conference/context/ChimeContext"
import { useMeetingContext } from "../../../conference/context/MeetingContext"
import { useRosterContext } from "../../../conference/context/RosterContext"
import { DetailNavLink } from "../../../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../../../globalStates/AppState"
import { useFavoriteState } from "../../../globalStates/Favorites"
import { useLoggedInState } from "../../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import ContextMenu from "../../../ui/ContextMenu"
import {
    IconBannedFromMeeting,
    IconHandYellow,
    IconLowerHandControls,
    IconMicrophoneOff,
    IconRemovedFromMeeting,
    IconShareScreenStop,
    IconThreeDotsMenu
} from "../../../ui/Icons"
import { useKickUserModal } from "../../../ui/modals/KickUserModal"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { useMeetingController } from "../../context/MeetingController"
import { useMeetingInvitation } from "../../context/MeetingInvitation"
import { DataMessageType } from "../../enums/DataMessageType"
import { AttendeeData, KickOrBanMode } from "../RosterAttendees.tsx/RosterAttendees"
import {
    Activities,
    AttendeeRoot,
    Avatar,
    ContextMenuBtn,
    Info,
    Name,
    PositionAndCompany
} from "../RosterAttendees.tsx/RosterAttendees.styled"
import { trackSelectContent } from "../../../utils/GTMTracking"

export interface AttendeeProps {
    attendee: AttendeeData
}

function RosterAttendee({ attendee }: AttendeeProps) {
    const raisedHands = useContext(RaisedHandContext)
    const [isContextMenuOpen, setContextMenuOpen] = useState(false)
    const { user } = useLoggedInState()
    const favorites = useFavoriteState()
    const contactState = useContactState()
    const appState = useAppState()
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = useRosterContext()
    const meetingInvitation = useMeetingInvitation()
    const meetingController = useMeetingController()
    const isBookmarked = favorites.is("person", attendee.id || "")
    const [connectionStatus, setConnectionStatus] = useState("")
    const [modalType, setModalType] = useState<ModalType>("none")
    const userState = useLoggedInState()
    // eslint-disable-next-line
    const [isBlocked, setIsBlocked] = useState(false)
    const isDarkMode = true
    const [kickOrBan, setKickOrBan] = useState<{ mode: KickOrBanMode; attendeeId?: string; userName?: string }>({
        mode: KickOrBanMode.NONE
    })
    const { KickUserModal } = useKickUserModal({ kickOrBan, setKickOrBan })
    const { muted, sharingContent } = useAttendeeStatus(attendee.chimeAttendeeId || "")
    const [isHandRaised, setIsHandRaised] = useState(raisedHands && raisedHands.includes(attendee.id || ""))
    const meetingManager = useMeetingManager()
    const dispatch = useNotificationDispatch()
    useEffect(() => {
        setIsHandRaised(raisedHands && raisedHands.includes(attendee.id || ""))
        //eslint-disable-next-line
    }, [raisedHands])

    useEffect(() => {
        setConnectionStatus(contactState.getConnectionStatus(attendee.id || ""))
        setIsBlocked(connectionStatus === "BLOCKED" || connectionStatus === "BLOCKING")
        // eslint-disable-next-line
    }, [contactState])

    const menuCallback: (param: { bookmarkChanged?: boolean; modalType?: ModalType }) => void = ({ modalType }) => {
        if (modalType) {
            setModalType(modalType)
        }
    }
    const modActions = new Array<Action>()

    if (meetingController.getIsMod()) {
        modActions.push(
            {
                disabled: false,
                title: branding.conferenceTexts.kick,
                hint: branding.conferenceTexts.kickHint,
                icon: <IconRemovedFromMeeting fill="#fff" />,
                onClick: () => {
                    setKickOrBan({ mode: KickOrBanMode.KICK, attendeeId: attendee.id, userName: attendee.name })
                }
            },
            {
                disabled: false,
                title: branding.conferenceTexts.ban,
                hint: branding.conferenceTexts.banHint,
                icon: <IconBannedFromMeeting fill="#fff" />,
                onClick: () => {
                    setKickOrBan({ mode: KickOrBanMode.BAN, attendeeId: attendee.id, userName: attendee.name })
                }
            },
            {
                disabled: muted,
                title: branding.conferenceTexts.mute,
                hint: branding.conferenceTexts.mute,
                icon: <IconMicrophoneOff fill={"#fff"} />,
                onClick: () => {
                    meetingManager.audioVideo?.realtimeSendDataMessage(attendee?.id?.slice(0, 10) || "", {
                        type: DataMessageType.MUTE
                    })
                    dispatch({
                        type: ActionType.ADD,
                        payload: {
                            severity: Severity.SUCCESS,
                            message: `You muted the user ${attendee.name}`,
                            autoCloseDelay: 3000
                        }
                    })
                }
            },
            {
                disabled: !sharingContent,
                title: branding.conferenceTexts.shareScreenStop,
                hint: branding.conferenceTexts.shareScreenStop,
                icon: <IconShareScreenStop fill={"#fff"} />,
                onClick: () => {
                    meetingManager.audioVideo?.realtimeSendDataMessage(attendee?.id?.slice(0, 10) || "", {
                        type: DataMessageType.STOP_SCREENSHARE
                    })
                    dispatch({
                        type: ActionType.ADD,
                        payload: {
                            severity: Severity.SUCCESS,
                            message: `You stopped the screenshare for the user ${attendee.name}`,
                            autoCloseDelay: 3000
                        }
                    })
                }
            },
            {
                disabled: !isHandRaised,
                title: branding.conferenceTexts.lowerHand,
                hint: branding.conferenceTexts.lowerHand,
                icon: <IconLowerHandControls fill="#fff" />,
                onClick: () => {
                    meetingManager.audioVideo?.realtimeSendDataMessage(attendee?.id?.slice(0, 10) || "", {
                        type: DataMessageType.RAISEHAND
                    })
                    dispatch({
                        type: ActionType.ADD,
                        payload: {
                            severity: Severity.SUCCESS,
                            message: `You lowered the hand for the user ${attendee.name}`,
                            autoCloseDelay: 3000
                        }
                    })
                }
            }
        )
    }

    return (
        <>
            <AttendeeRoot hideContextBtn={user()?.profileId === attendee.id}>
                <Avatar>
                    <DetailNavLink
                        type="user"
                        id={attendee.id || ""}
                        name={attendee.name || ""}
                        onClick={() => {
                            trackSelectContent("user", attendee.id!, attendee.name!, "Entity link", "user")
                        }}
                    >
                        <AvatarWithDefault
                            size={55}
                            badgesize={55}
                            alt={attendee.name ? attendee.name : ""}
                            src={attendee.avatarUrl}
                            backgroundSize="cover"
                        />
                    </DetailNavLink>
                </Avatar>

                <Info>
                    <DetailNavLink
                        type="user"
                        id={attendee.id || ""}
                        name={attendee.name || ""}
                        onClick={() => {
                            trackSelectContent("user", attendee.id!, attendee.name!, "Entity link", "user")
                        }}
                    >
                        <Name>{attendee.name}</Name>
                        <PositionAndCompany>{attendee.position + " at " + attendee.company}</PositionAndCompany>
                    </DetailNavLink>
                </Info>

                <Activities id="actions">
                    {raisedHands && raisedHands.includes(attendee.id || "") && <IconHandYellow height="18px" width="18px" />}
                    <MicrophoneActivity className="activity" attendeeId={attendee.chimeAttendeeId} />
                </Activities>

                <ContextMenuBtn id="contextBtn" onClick={() => setContextMenuOpen(!isContextMenuOpen)}>
                    {IconThreeDotsMenu({ fill: "#fff", width: "20px", height: "20px" })}
                </ContextMenuBtn>
            </AttendeeRoot>
            <ContextMenu
                collapsed={!isContextMenuOpen}
                items={() =>
                    createActions(
                        userState.user(),
                        attendee,
                        favorites,
                        contactState,
                        appState,
                        meeting,
                        chime,
                        meetingInvitation,
                        meetingController,
                        true,
                        roster,
                        isBookmarked,
                        connectionStatus,
                        menuCallback,
                        modActions,
                        undefined,
                        true,
                        true,
                        true
                    )
                }
                isDarkMode={isDarkMode}
            />
            <CommunicationModals
                show={modalType}
                contact={attendee}
                removePosition={true}
                onWithdrawRequest={() => {}}
                onHide={() => setModalType("none")}
            />

            <KickUserModal />
        </>
    )
}

export default RosterAttendee
