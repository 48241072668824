import styled from "styled-components"

const SwitcherContainer = styled.ul`
    margin-top: 15px;
    cursor: pointer;
    svg {
        margin-right: 8px;
    }
`

const SwitcherItem = styled.li<{ isActive: boolean }>`
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
    padding: 8px;
    border-radius: 3px;
    margin-bottom: 2px;
    background-color: ${(props) => (props.isActive ? "#666666" : "#202428")};
    transition: 0.3s;
    :hover {
        background-color: rgba(102, 102, 102, 0.3);
    }
`

export interface SettingsSwitcherItem {
    icon: any
    label: string
    key: string
}

export interface SettingsSwitcherProps {
    settingsItems: SettingsSwitcherItem[]
    setActiveItem: (activeItem: string) => void
    activeItem: string
}

function SettingsSwitcher({ settingsItems, setActiveItem, activeItem }: SettingsSwitcherProps) {
    return (
        <SwitcherContainer>
            {settingsItems &&
                settingsItems.map((settingsItem, index) => {
                    return (
                        <SwitcherItem
                            onClick={() => setActiveItem(settingsItem.key)}
                            key={index}
                            isActive={settingsItem.key === activeItem}
                        >
                            {settingsItem.icon} {settingsItem.label}
                        </SwitcherItem>
                    )
                })}
        </SwitcherContainer>
    )
}

export default SettingsSwitcher
