import styled from "styled-components"

export const ConferenceOverlayV2Root = styled.div`
    background-color: #000;
    position: absolute;
    z-index: 999;
    width: 408px;
    height: 240px;
    border-radius: 7px;
    transition: all 0.1s;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.75);
    color: #fff;
    overflow: hidden;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    @media screen and (max-width: 1920px) {
        width: 340px;
        height: 200px;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .hideUIElements {
        opacity: 0;
    }

    :hover {
        .hideUIElements {
            opacity: 1;
        }
    }
`

export const ConferenceOverlayV2Header = styled.div`
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px 15px;
    z-index: 10;
    transition: 0.3s;
`

export const ConferenceOverlayV2Controls = styled.div`
    width: 70%;
    height: 50px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: 0.3s;
    z-index: 10;
`

export const MeetingTitle = styled.div`
    color: #fff;
    width: 50%;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const Time = styled.div`
    font-size: 14px;
    width: 25%;
    text-align: center;
`

export const BackToMeetingButton = styled.div`
    cursor: pointer;
    width: 25%;
    text-align: right;
`
