import {
    ButtonsContainer,
    CustomMessage,
    MeetingStatusContainer,
    Message,
    NegativeActionButtonRoot,
    PositiveActionButtonRoot,
    Title
} from "./MeetingStatusStyles"

export enum MeetingStatusMessageSize {
    SMALL = "SMALL",
    LARGE = "LARGE"
}

interface MeetingStatusMessageProps {
    title?: string
    titleIcon?: any
    message?: string
    messageIcon?: any
    onPositiveActionClick?: () => void
    positiveActionButtonText?: string
    location?: string
    onNegativeActionClick?: () => void
    negativeActionButtonText?: string
    size?: MeetingStatusMessageSize
    customMessage?: string | null
}

export function MeetingStatusMessage(props: MeetingStatusMessageProps) {
    return (
        <MeetingStatusContainer size={props.size}>
            <Title>
                {props.titleIcon} {props.title}
            </Title>
            <Message>
                {props.messageIcon} {props.message}
            </Message>
            {props.customMessage && <CustomMessage maxHeight={"300px"}>{`Reason: ${props.customMessage}`}</CustomMessage>}
            <ButtonsContainer>
                {props.onPositiveActionClick && (
                    <PositiveActionButton
                        onClickHandler={props.onPositiveActionClick}
                        text={props.positiveActionButtonText || ""}
                    />
                )}
                {props.onNegativeActionClick && (
                    <NegativeActionButton
                        onClickHandler={props.onNegativeActionClick}
                        text={props.negativeActionButtonText || ""}
                    />
                )}
            </ButtonsContainer>
        </MeetingStatusContainer>
    )
}

interface ButtonProps {
    text: string
    onClickHandler: () => void
}

function PositiveActionButton(props: ButtonProps) {
    return <PositiveActionButtonRoot onClick={props.onClickHandler}>{props.text}</PositiveActionButtonRoot>
}

function NegativeActionButton(props: ButtonProps) {
    return <NegativeActionButtonRoot onClick={props.onClickHandler}>{props.text}</NegativeActionButtonRoot>
}

export default MeetingStatusMessage
