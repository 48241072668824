import { Button, ButtonProps } from "react-bootstrap"
import styled from "styled-components"
import branding from "../branding/branding"

export const ButtonColored = styled(Button)<{ color: string; height?: string }>`
    background-color: #fff !important;
    border: 1px solid ${(props) => props.color} !important;
    box-sizing: border-box;
    padding: 0.55rem 2.5rem;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: ${(props) => props.color} !important;
    user-select: none;
    border-radius: 1.25rem;
    margin-left: 10px;
    text-align: center;
    height: ${(props) => props.height ?? "40px"};

    &:active,
    &:focus,
    &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: ${(props) => props.color} !important;
        background-color: #fff;
        border: 1px solid ${(props) => props.color};
    }
`

export const ButtonPrimaryColor = (props: ButtonProps) => <ButtonColored {...props} color={branding.primaryColor} />

export const ButtonColorFilled = styled(Button)<{ color: string }>`
    background-color: ${(props) => props.color} !important;
    border: 1px solid ${(props) => props.color};
    box-sizing: border-box;
    padding: 0.65rem 2.5rem;
    margin-left: 20px;
    margin-top: 39px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    color: #fff;
    user-select: none;
    border-radius: 1.25rem;
    height: 40px;

    &:active,
    &:focus,
    &:focus:active {
        background-image: none;
        outline: 0;
        box-shadow: none;
    }

    &:hover {
        color: #fff !important;
        background-color: ${(props) => props.color};
        border: 1px solid ${(props) => props.color};
    }
`

export const ButtonPrimaryColorFilled = (props: ButtonProps) => <ButtonColorFilled {...props} color={branding.primaryColor} />
