import { LocalizedString } from "@corussoft/branding-generator"

export enum GTMEventType {
    login = "login",
    sign_up = "sign_up",
    search = "search",
    select_content = "select_content",
    page_view = "page_view",
    share = "share",
    view_promotion = "view_promotion"
}

export interface CustomerTypes {
    /**
     * @title Customer type staff
     * @description ...
     * @title_de Customer type staff
     * @description_de ...
     */
    staff: LocalizedString

    /**
     * @title Customer type attendee
     * @description ...
     * @title_de Customer type attendee
     * @description_de ...
     */
    attendee: LocalizedString

    /**
     * @title Customer type guest
     * @description ...
     * @title_de Customer type guest
     * @description_de ...
     */
    guest: LocalizedString

    /**
     * @title Customer type press
     * @description ...
     * @title_de Customer type press
     * @description_de ...
     */
    press: LocalizedString

    /**
     * @title Customer type other
     * @description ...
     * @title_de Customer type other
     * @description_de ...
     */
    other: LocalizedString
}

export interface LoginStates {
    /**
     * @title Logged in
     * @description ...
     * @title_de Logged in
     * @description_de ...
     */
    loggedIn: LocalizedString

    /**
     * @title Logged out
     * @description ...
     * @title_de Logged out
     * @description_de ...
     */
    loggedOut: LocalizedString
}

export interface GTMConfig {
    /**
     * @title GTM noscript
     * @description Google Tag Manager's noscript element
     * @title_de GTM noscript
     * @description_de Noscript-Element von Google Tag Manager
     */
    noScript: string

    /**
     * @title GTM data layer
     * @description Google Tag Manager's data layer script
     * @title_de GTM Data-Layer
     * @description_de Google Tag Manager Data-Layer-Skript
     */
    scriptDataLayer: string

    /**
     * @title GTM script
     * @description Google Tag Manager's initially loaded script
     * @title_de GTM Skript
     * @description_de Das ursprünglich geladene Skript von Google Tag Manager
     */
    tagScript: string

    /**
     * @title GTM fair number
     * @description Code used by Google Tag Manager for single Event Guide's tracking
     * @title_de GTM-Messenummer
     * @description_de Code, der vom Google Tag Manager für die Nachverfolgung einzelner Event Guides verwendet wird
     */
    fairNumber: string

    /**
     * @title GTM event types
     * @description Configure list of event types used in this Event Guide instance
     * @title_de GTM event types
     * @description_de Konfigurieren Sie die Liste der in dieser Event Guide-Instanz verwendeten Event-Typen
     */
    eventTypes: GTMEventType[]

    /**
     * @title Customer types
     * @description ...
     * @title_de Customer types
     * @description_de ...
     */
    customerTypes: CustomerTypes

    /**
     * @title Login states
     * @description ...
     * @title_de Login states
     * @description_de ...
     */
    loginStates: LoginStates
}
