import * as React from "react"
import { useHistory, NavLink } from "react-router-dom"
import styled from "styled-components"
import { useNavigationSource } from "../../tracking/NavigationSource"
import { UserOrganizationVisitSource } from "../../backendServices/TrackingServices"
import { EntityType } from "../../backendServices/Types"
import { transliterate } from "transliteration"
import branding from "../../branding/branding"

export type DetailNavLinkType =
    | EntityType
    | "user"
    | "coupon"
    | "program"
    | "channel"
    | "virtualCafeMeetingGroup"
    | "event"
    | "conferenceRoom"
const DetailNavLinkRoot = styled(NavLink)`
    cursor: pointer;
    ${(props) => (props.width ? `width: ${props.width};` : "")}

    ${(props) => (props.height ? `height: ${props.height};` : "")}
    ${(props) =>
        props.orgaDetailPage
            ? `
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        `
            : ""}


    &:hover,
    &:active,
    &:visited,
    &:link,
    &:focus {
        ${(props: DetailNavLinkProps) => (props.showLinkStyles ? "" : "color: inherit;text-decoration: none;")}
    }
`
type DetailNavLinkProps = {
    id: string
    name: string
    type: DetailNavLinkType
    showLinkStyles?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    source?: UserOrganizationVisitSource
    searchKrit?: string
    width?: string
    height?: string
    disabled?: boolean
    orgaDetailPage?: boolean
}
export const DetailNavLink: React.FC<DetailNavLinkProps> = (props) => {
    const history = useHistory()
    const navigationSource = useNavigationSource()

    const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (props.disabled) {
            event.preventDefault()
            return
        }
        // The meeting recorder has no profile page
        if (props.id === "recorder") return

        if (props.onClick) {
            props.onClick(event)
        }
        if (!event.isDefaultPrevented()) {
            if (props.source) {
                navigationSource.set(props.source)
                navigationSource.setKrit(props.searchKrit)
            }
            history.push({
                pathname: buildDetailLink(props.id, props.name, props.type),
                search: history.location.search
            })
        }

        event.stopPropagation()
        event.preventDefault()
    }

    return (
        <DetailNavLinkRoot
            to={buildDetailLink(props.id, props.name, props.type)}
            className="detailNavLink"
            {...props}
            onClick={handleClick}
        >
            {props.children}
        </DetailNavLinkRoot>
    )
}

export const buildDetailLink = (id: string, name: string, type: DetailNavLinkType): string => {
    let nameForLink = transliterate(name).replace(/[^a-z0-9]+/gi, "-")
    nameForLink = nameForLink.replace(/^[^a-z0-9]|[^a-z0-9]$/gi, "")

    const idAppendage = type === "person" ? "p-" + id : type === "user" || type === "networking_user" ? "u-" + id : id
    const typeAppendage = type === "user" || type === "networking_user" ? "person" : type

    if (type === "channel") {
        // Channel link is introduced on backend. There wont be branding for it.
        return "/" + typeAppendage + "/" + id
        // return "/" + type + "/" + branding.thirdPartyResources.find(elem => elem.id === "slido")?.config.slidoRooms.find(elem => elem.id === id)?.id;
    } else if (type === "organization") {
        // https://jira.corussoft.de/browse/DEP-1023
        return "/company/" + nameForLink + "--" + idAppendage
    } else if (type === "event") {
        return "/event/" + nameForLink + "--" + idAppendage
    } else if (type === "virtualCafeMeetingGroup") {
        return "/meetings/" + idAppendage
    } else {
        if ((type === "person" || type === "user") && !branding.showPersonNameInUrl) {
            return "/" + typeAppendage + "/person--" + idAppendage
        } else {
            if (type === "conferenceRoom") {
                if (branding.configuration.useConferenceRoomV2) {
                    return "/meetingV2/cr_" + idAppendage
                } else {
                    return "/meeting/cr_" + idAppendage + "/createorjoin"
                }
            }
        }

        return "/" + typeAppendage + "/" + nameForLink + "--" + idAppendage
    }
}

export function useDetailLink() {
    const history = useHistory()

    return {
        goTo: (id: string, name: string, type: DetailNavLinkType) => {
            history.push({
                pathname: buildDetailLink(id, name, type),
                search: history.location.search
            })
        }
    }
}
