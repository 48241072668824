import styled from "styled-components"
import SearchPersonInput from "../../../communicationArea/SearchPersonInput"

export const AttendeesRoot = styled.div`
    z-index: 10;
    position: relative;
    overflow: scroll;
    align-items: flex-start;
    padding: 10px;
    min-height: 350px;
`

export const AttendeesGroupRoot = styled.div`
    width: 100%;
    color: #fff;
    overflow: scroll;
`
export const AttendeesGroupHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
`

export const AttendeesGroupTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 3px;
    flex: 1;
`

export const AttendeesGroupIcon = styled.div<{ isOpen: boolean }>`
    transition: 0.3s;
    transform: ${(props) => (props.isOpen ? "" : "rotate(-90deg)")};
    display: inline-block;
    margin-right: 5px;
    margin-left: 3px;
`

export const AttendeesGroupContent = styled.div<{ isOpen: boolean }>`
    max-height: ${(props) => (props.isOpen ? "600px" : "0")};
    height: ${(props) => (props.isOpen ? "auto" : "0")};
    transition: 0.3s;
    margin-bottom: 20px;
    overflow: scroll;
`

export const AttendeesListRoot = styled.div``

export const AttendeeRoot = styled.div<{ hideContextBtn: boolean }>`
    padding: 5px;
    display: flex;
    min-height: 65px;
    margin-bottom: 5px;
    transition: 0.3s;
    align-items: space-between;
    margin-bottom: 10px;
    background-color: #313233;
    cursor: pointer;

    #actions {
        display: flex;
    }

    #contextBtn {
        display: none;
    }

    :hover {
        background-color: #202428;

        #actions {
            display: ${(props) => (props.hideContextBtn ? "flex" : "none")};
        }

        #contextBtn {
            display: ${(props) => (props.hideContextBtn ? "none" : "flex")};
        }
    }
`

export const Avatar = styled.div`
    flex: 1;
    margin-right: 5px;
    padding-left: 5px;
    border-radius: 50%;
`

export const Info = styled.div`
    flex: 3;
`

export const Activities = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    width: 50px;

    > svg {
        padding: 0;
    }

    .activity {
        width: 23px;
        display: inline-block;

        .ch-bg-volume-wrapper {
            width: 23%;
            left: 36%;
        }
        .ch-bg-volume-fill {
            background-color: #5db130;
        }
    }

    .hand {
        fill: #eecb11;

        path {
            fill: #eecb11;
        }
    }
`

export const ContextMenuBtn = styled.div`
    padding: 3px 0 3px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    text-align: center;
`

export const ContextMenuRoot = styled.div<{ isOpen: boolean }>`
    width: 100%;
    height: ${(props) => (props.isOpen ? "auto" : 0)};
    max-height: ${(props) => (props.isOpen ? "350px" : "0")};
    padding: ${(props) => (props.isOpen ? "10px" : 0)};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    overflow: hidden;
`

export const ContextMenuItem = styled.div`
    height: 60px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 3px;
    transition: 0.3s;

    :hover {
        background-color: #202428;
    }
`

export const ContextMenuItemTitle = styled.div``

export const ContextMenuIcon = styled.div`
    margin-bottom: 5px;
`

export const Name = styled.div`
    font-weight: 700;
    padding: 3px 3px 0 3px;
    font-size: 14px;
    line-height: 15px;
`

export const PositionAndCompany = styled.div`
    padding: 3px;
    font-size: 12px;
    line-height: 13px;
`

export const SearchPersonsContainer = styled.div`
    margin-bottom: 15px;
    height: auto;
    width: 100%;

    /** Search icon in the input component */
    svg {
        path {
            fill: #fff;
        }
    }
`

export const MaxAttendeesMessage = styled.div`
    font-size: 15px;
    color: #fff;
    text-align: center;
`

export const StyledSearchPersonInput = styled(SearchPersonInput)`
    z-index: 12;
    background-color: #616364;
    border-color: #616364;
    color: #fff !important;

    input {
        background-color: #616364;
        border-radius: 5px;
        color: #fff !important;
    }

    input::placeholder {
        color: #fff !important;
        opacity: 0.6;
    }

    #searchPersons {
        background-color: #616364 !important;
    }

    .infiniteScrollPersonInput {
        background-color: #303233 !important;
        > div {
            background-color: #303233 !important;
            color: #fff !important;
        }
    }
    .disabled {
        opacity: 0.5 !important;
    }

    .searchPersonResultItem {
        background-color: #303233 !important;
        color: #fff !important;
        transition: 0.3s;
    }

    .searchPersonResultItem:hover {
        background-color: #616364 !important;
    }
`

export const InvitedPersonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    padding: 5px;
`

export const InvitedPersonItem = styled.div`
    width: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
export const RemoveInvitedPersonButton = styled.div`
    z-index: 10;
    position: absolute;
    background: black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 3px;
    right: 1px;
    width: 16px;
    height: 12px;
    cursor: pointer;

    & svg {
        width: 100%;
        height: 100%;
    }

    & svg path {
        stroke: white;
    }
`

export const InvitePersonsButton = styled.div`
    width: 100%;
    background-color: #6dd400;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    padding: 10px 30px;
    margin-right: 10px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-align: center;
    z-index: 10;

    :hover {
        box-shadow: inset #6dd400;
        opacity: 0.8;
    }
`
