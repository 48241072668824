import styled from "styled-components"
import branding from "../../../branding/branding"
import { MeetingStatusMessageSize } from "./MeetingStatusMessage"

export const MeetingStatusContainer = styled.div<{ size?: MeetingStatusMessageSize }>`
    background-color: #202428;
    height: ${(props) => (props.size === MeetingStatusMessageSize.SMALL ? "100%" : "100vh")};
    width: 100%;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    text-align: center;
`

export const Title = styled.div<{ size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "18px" : "16px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "18px" : "16px")};
    color: #d9d9d9;
    font-weight: 400;
    display: block;
    transition: opacity 1s;
    margin-bottom: 10px;
`

export const Message = styled.div<{ size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "16px" : "14px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "16px" : "14px")};
    color: #fff;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
`

export const CustomMessage = styled.div<{ maxHeight?: string; size?: MeetingStatusMessageSize }>`
    font-size: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "14px" : "12px")};
    line-height: ${(props) => (props.size === MeetingStatusMessageSize.LARGE || !props.size ? "14px" : "12px")};
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "none")};
    overflow: ${(props) => (props.maxHeight ? "scroll" : "visible")};
`

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
`

export const PositiveActionButtonRoot = styled.div`
    background-color: ${branding.sayHelloModal.submitBtnPrimaryBgColor};
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    padding: 8px 30px;
    margin-right: 10px;

    :hover {
        box-shadow: inset ${branding.sayHelloModal.submitBtnPrimaryBgColor};
        opacity: 0.8;
    }
`

export const NegativeActionButtonRoot = styled.div`
    background-color: transparent;
    border-radius: 3px;
    border: 1px solid #666666;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    padding: 8px 30px;
    transition: 0.3s;

    :hover {
        background-color: #666666;
        border: 1px solid #666666;
    }
`
