import styled, { css } from "styled-components"

export const RemoteVideoRoot = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 3px;
    video {
        object-fit: contain !important;
    }
    width: 100%;
    height: 100%;

    /* implement this with outline */
`

export const RemoteVideoBorderContainer = styled.div<{ isHandRaised: boolean; isSpeaking: boolean }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    /* Raised hand border */
    ${(props) =>
        props.isHandRaised &&
        css`
            outline: 3px solid #eecb11;
        `}

    /* Speaking border */
    ${(props) =>
        props.isSpeaking &&
        !props.isHandRaised &&
        css`
            outline: 3px solid #5db130;
        `}
`
