import styled from "styled-components"

export const LockRoomButtonRoot = styled.div`
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid #666666;
    border-radius: 3px;
    height: 35px;
    min-width: 140px;
    margin-right: 8px;

    :hover {
        background-color: #666666;
        border: 1px solid #666666;
    }
`

export const LockRoomButtonIcon = styled.div`
    margin-right: 5px;
`
