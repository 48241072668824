import styled from "styled-components"
import Select from "react-select"
import branding from "../branding/branding"
import { device } from "../utils/Device"

/* #region  Styled Select - Dropdown */
export const StyledSelect = styled(Select)<{ height?: string }>`
    width: 250px;
    height: ${(props) => (props.height ? props.height : "auto")};
    margin-right: 10px;
    z-index: 10;
    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    @media ${device.mobile} {
        width: 48vw;
    }
`
/* #endregion */
