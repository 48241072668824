import * as React from "react"
import styled from "styled-components"
import branding from "../../../branding/branding"
import Highlighter from "react-highlight-words"
import { IconClearSearchItem, IconSearchSmall } from "../../../ui/Icons"
import { viewType } from "../NetworkingPageContentBranding"
import { useState } from "react"
import Select, { ActionMeta, InputActionMeta, OptionProps, OptionTypeBase, ValueType } from "react-select"

const AutocompleteHighlighterRoot = styled.div<{ hideDropdown: boolean }>`
    width: 350px;
    position: relative;
    z-index: 1;

    & .search-icon {
        position: absolute;
        height: 100%;
        left: 15px;
        top: 67%;
        transform: translateY(-50%);
        color: ${branding.networkingArea.filterIconColor};
    }

    & .clear-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
`

const AHItem = styled.div`
    width: 100%;
    background-color: white;
    color: #727272;
    cursor: pointer;
    & .user-attribute-highlighter {
        padding: 8px;
        display: inline-block;
    }
    &:hover {
        background-color: ${branding.networkingArea.autocompleteItemHoverColor};
        color: #fff;
    }
    &:hover mark {
        color: #fff;
    }
`

interface AutocompleteSearchProps {
    currentViewType: viewType
    userAttributeFilter: string
    searchValue: string
    userAttributes: string[]
    onHandleSearch(searchValue: string): void
    onSetUserAttributeFilter: (value: React.SetStateAction<string>) => void
    onSetSearchString: (value: React.SetStateAction<string>) => void
}

const AutocompleteSearch = (props: AutocompleteSearchProps) => {
    const isrelevantOrInterestView =
        props.currentViewType === viewType.RELEVANT_TILES || props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const options = !isrelevantOrInterestView
        ? props.userAttributes.map((label) => {
              return { label: label }
          })
        : []

    const CustomItem = ({ innerRef, innerProps, ...itemProps }: OptionProps<any, false>) => {
        return (
            <AHItem ref={innerRef} {...innerProps}>
                <Highlighter
                    className={"user-attribute-highlighter"}
                    caseSensitive={false}
                    searchWords={[props.userAttributeFilter]}
                    highlightStyle={{ display: "inline", backgroundColor: "transparent", fontWeight: "bold", padding: 0 }}
                    textToHighlight={itemProps.data.label}
                />
            </AHItem>
        )
    }

    // implement this function to create a custom filter for displaying option items in the dropdown menu
    // const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
    //     return true
    // }

    return (
        <AutocompleteHighlighterRoot className={"autocomplete-root"} hideDropdown={isrelevantOrInterestView}>
            <div className={"position-autocomplete"}>
                <Select
                    // components should not be declared inline: https://react-select.com/components#defining-components
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null, // hide drop down button
                        Option: CustomItem
                    }}
                    inputValue={isrelevantOrInterestView ? props.searchValue : props.userAttributeFilter}
                    value={branding.networkingArea.searchPlaceholderText}
                    placeholder={branding.networkingArea.searchPlaceholderText}
                    isClearable={false}
                    openMenuOnClick={!isrelevantOrInterestView}
                    menuIsOpen={menuIsOpen}
                    noOptionsMessage={(obj) => {
                        return null
                    }}
                    options={options}
                    // filterOption={filterOptions}
                    onFocus={(event) => {
                        setMenuIsOpen(true)
                    }}
                    onBlur={(event) => {
                        setMenuIsOpen(false)
                    }}
                    onInputChange={(inputValue: string, { action }: InputActionMeta) => {
                        if (action === "input-change") {
                            isrelevantOrInterestView
                                ? props.onHandleSearch(inputValue)
                                : props.onSetUserAttributeFilter(inputValue)
                        }
                    }}
                    onChange={(value: ValueType<OptionTypeBase, boolean>, { action }: ActionMeta<OptionTypeBase>) => {
                        if (value !== null && action === "select-option") {
                            setMenuIsOpen(false)
                            const option = value as OptionTypeBase
                            isrelevantOrInterestView
                                ? props.onSetSearchString(option.label)
                                : props.onSetUserAttributeFilter(option.label)
                        }
                    }}
                    styles={{
                        control: (provided: any, state: any) => ({
                            borderRadius: "5px",
                            display: "flex",
                            height: "35px",
                            paddingLeft: "30px",
                            fontFamily: branding.font1,
                            width: "100%",
                            border: state.isFocused ? branding.networkingArea.filterBorderBottomColor : "1px solid #d9d9d9",
                            color: branding.networkingArea.filterInputColor,
                            outline: "none"
                        }),
                        input: (provided) => ({
                            ...provided,
                            width: "100% !important",
                            div: {
                                width: "100% !important"
                            },
                            input: {
                                opacity: "1 !important", // workaround for bug that makes input disappear
                                width: "100% !important"
                            }
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            border: branding.networkingArea.filterBorderBottomColor,
                            boxShadow: "none",
                            fontFamily: branding.font1,
                            borderRadius: "3px",
                            marginTop: "0px",
                            marginBottom: "0px"
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontFamily: branding.font1,
                            fontSize: "12px"
                        })
                    }}
                ></Select>
            </div>
            <div>
                <span
                    onClick={() => {
                        setMenuIsOpen(false)
                        props.userAttributeFilter !== "" ? props.onSetUserAttributeFilter("") : props.onHandleSearch("")
                    }}
                    className={"clear-icon"}
                    style={{ visibility: props.searchValue !== "" || props.userAttributeFilter !== "" ? "visible" : "hidden" }}
                >
                    {IconClearSearchItem({ fill: branding.networkingArea.filterIconColor })}
                </span>
            </div>
            <div className={"search-icon"}>{IconSearchSmall({ fill: branding.networkingArea.filterIconColor })}</div>
        </AutocompleteHighlighterRoot>
    )
}

export default AutocompleteSearch
